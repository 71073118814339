import React, {useState, useEffect, useRef} from 'react'
import {Link, useNavigate} from 'react-router-dom';

import Logo from '../../assets/img/logo.png'
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../store/auth/auth.slice";
import axiosInstance from "../../helpers/axiosInstance";
import {setOngoingAuctions} from "../../store/common/common.slice";
import {getRemainingTimeToFinish} from "../../helpers/helpers";
import Modal from 'react-bootstrap/Modal';
import {sha256Message} from "../../Utils";
import {store} from "../../store";
import {hideLoader, showLoader} from "../../store/loader/actions";
import {toast} from "react-toastify";

function Header1() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showHeaderArea, setShowHeaderArea] = useState(false)
    const [showCollapse, setShowCollapse] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(false);
    const [ongoingAuctionList, setOngoingAuctionList] = useState([]);
    const [ongoingAuctionDate, setOngoingAuctionDate] = useState({});
    const [ongoingAuctionStartDate, setOngoingAuctionStartDate] = useState(null);
    const [ongoingAuctionEndDate, setOngoingAuctionEndDate] = useState(null);
    const ongoingAuctionEndDateReg = useRef(null);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const {user} = useSelector((store) => store.auth);
    let interval;

    const [tempUid, setTempUid] = useState("");
    const [updatePasswordData, setUpdatePasswordData] = useState({
        phone_number: '',
        email_address: '',
        new_password: '',
        re_password: ''
    })


    const handleScroll = () => {
        var elHeaderGnb = document.querySelector('.header-area__grid--menu');

        var offsetY = elHeaderGnb.getBoundingClientRect().top;

        if (offsetY < 0) {
            setScrollPosition(true);
            return;
        }
        setScrollPosition(false);
        // const position = window.pageYOffset;
        // console.log(position);
        // if (position > 200) {
        //   setScrollPosition(true);
        // } else {
        //   setScrollPosition(false);
        // }
    };

    useEffect(() => {


        interval = setInterval(() => {
            const currentDate = new Date();
            if (ongoingAuctionEndDateReg.current !== null) {
                const data = getRemainingTimeToFinish(currentDate, ongoingAuctionEndDateReg.current);
                setOngoingAuctionDate(data);
            }
            if (ongoingAuctionEndDateReg.current != null && (currentDate > ongoingAuctionEndDateReg.current)) {
                setOngoingAuctionList([]);
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: true});

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);

    useEffect(() => {
        getOngoingAuctionList();
    }, [user])


    const getOngoingAuctionList = async () => {
        const res = await axiosInstance.get('/auction/ongoingAuction');
        console.log(res);
        if (res.code === 200) {
            if (res.data.ongoing_auctions.length > 0) {
                const currentDate = new Date();
                const startDate = new Date(res.data.ongoing_auctions[0].auction_start_dt);
                const endDate = new Date(res.data.ongoing_auctions[0].auction_end_dt);
                setOngoingAuctionStartDate(startDate);
                setOngoingAuctionEndDate(endDate);
                if (currentDate < endDate) {
                    setOngoingAuctionList(res.data.ongoing_auctions);
                }
                const data = getRemainingTimeToFinish(currentDate, endDate);
                setOngoingAuctionDate(data);
                ongoingAuctionEndDateReg.current = endDate;
            }
        }
    }

    const changePassword = async () => {
        if (updatePasswordData.phone_number === '' || updatePasswordData.new_password === '' || updatePasswordData.re_password === '') {
            alert("Please enter values")
            return;
        }
        if (updatePasswordData.new_password !== updatePasswordData.re_password) {
            alert("Passwords does not match")
            return;
        }
        const payload = {
            email: updatePasswordData.email_address,
            phone: updatePasswordData.phone_number,
            password: await sha256Message(updatePasswordData.new_password),
        }
        try {
            store.dispatch(showLoader());
            const resUpdateUser = await axiosInstance.post('/user/update', payload);
            if (resUpdateUser.code === 200) {
                toast.dismiss();
                toast.success(('Success'))
                setShowChangePasswordModal(false);
                store.dispatch(hideLoader());
            } else {
                store.dispatch(hideLoader());
                alert(resUpdateUser.message);
            }
        } catch (e) {
            store.dispatch(hideLoader());
        }
    }


    async function logoutFn() {
        dispatch(logout());
    }


    return (
        <>
            <div
                className={"header-area " + (showHeaderArea ? 'is-fold-notice' : '') + (showCollapse ? 'header-area--expanded' : '')}>
                {ongoingAuctionList.length > 0 && (
                    <div className="header-notice type-ing1">
                        <a href="#!" className="header-notice__link">
                            <div className="header-notice__grid">
                                <p>
                                    <i className="icon24 icon-notice"/>
                                    <strong>경매 진행중입니다.</strong>
                                    <span>{ongoingAuctionDate.start_date_fm}</span>
                                    <strong>{ongoingAuctionDate.start_hours_fm}</strong>
                                </p>
                                <p>
                                    <span>남은시간</span>
                                    <span className="timer-area">
                  <strong>{ongoingAuctionDate.remaining_hours}</strong>
                  <span>:</span>
                  <strong>{ongoingAuctionDate.remaining_minutes}</strong>
                  <span>:</span>
                  <strong>{ongoingAuctionDate.remaining_seconds}</strong>
                </span>
                                </p>
                            </div>
                        </a>
                        <button type="button" className="header-notice__btn"
                                onClick={() => setShowHeaderArea(!showHeaderArea)}/>
                    </div>
                )}
                <div className="header-area__inner">
                    <div className="header-area__grid">
                        <h1 className="header-area__logo">
                            <Link to={'/'}>
                                <img src={Logo} alt="카스코철강" style={{backgroundColor: '#fff'}}/>
                            </Link>
                        </h1>
                        <div className="header-area__util">
                            <div className="header-area__user">
                                {user.corp_type_cd_nm} <strong>{user.user_name}</strong> 님
                            </div>
                            <a onClick={() => logoutFn()} className="btn-util" style={{cursor: "pointer"}}>
                                <i className="icon20 icon-logout"/>
                                <span>로그아웃</span>
                            </a>
                            <a href="#!" className="btn-util" onClick={() => setShowChangePasswordModal(true)}>
                                <i className="icon20 icon-mypage"/>
                                <span>비밀번호변경</span>
                            </a>
                            <a href="#!" className="btn-util">
                                <i className="icon20 icon-like"/>
                                <span>관심제품</span>
                            </a>
                            <div className="portal-search">
                                <input type="text" title="검색어 입력" className="portal-search__inp"/>
                                <button type="button" className="portal-search__btn">
                                    <i className="icon24 icon-search"/>
                                    <span className="a11y">검색하기</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={"header-area__grid header-area__grid--menu " + (scrollPosition ? 'sticky' : '')}>
                        <div className="header-area__menu">
                            <ul className="menu-list" onMouseEnter={() => setShowCollapse(true)}
                                onMouseLeave={() => setShowCollapse(false)}>
                                {user.role === 'ADMIN' && (
                                    <li className={"menu-list__category " + (showCollapse ? 'is-hover' : '')}>
                                        <a href="#!" className="menu-list__btn">
                                            <span>제품관리</span>
                                        </a>
                                        <ul className="menu-list__depth2">
                                            <li>
                                                <a href="#!" className="menu-list__btn-sub">
                                                    <span>제품관리</span>
                                                    <i className="icon-dot"/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!" className="menu-list__btn-sub">
                                                    <span>경매할당대상제품조회</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                )}
                                {user.role === 'ADMIN' && (
                                    <li className="menu-list__category"> {/* [D] 현재 위치인 메뉴에 is-active 클래스 추가 */}
                                        <a href="#!" className="menu-list__btn">
                                            <span>경매관리</span>
                                        </a>
                                        <ul className="menu-list__depth2">
                                            <li>
                                                <Link to="/kas-0500" className="menu-list__btn-sub">
                                                    <span>회차별 경매관리</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/kas-0506" className="menu-list__btn-sub">
                                                    <span>경매시작단가관리</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>)}
                                {(user.role === "ADMIN" || user.role === 'CUSTOMER') && (
                                    <li className="menu-list__category">
                                        <a href="#!" className="menu-list__btn">
                                            <span>경매몰관리</span>
                                        </a>
                                        <ul className="menu-list__depth2">
                                            <li>
                                                <Link to="/kas-0601" className="menu-list__btn-sub">
                                                    <span>경매응찰</span>
                                                    <i className="icon-dot"/>
                                                </Link>
                                            </li>
                                            {user.role === 'ADMIN' && (
                                                <>
                                                    <li>
                                                        <Link to="/kas-0603" className="menu-list__btn-sub">
                                                            <span>경매응찰현황</span>
                                                        </Link>
                                                    </li>
                                                </>
                                            )}
                                            <li>
                                                <Link to="/kas-0607" className="menu-list__btn-sub">
                                                    <span>경매낙찰</span>
                                                </Link>
                                            </li>

                                        </ul>
                                    </li>
                                )}

                                {user.role === "ADMIN" && (
                                    <li className="menu-list__category">
                                        <a href="#!" className="menu-list__btn">
                                            <span>특가판매몰관리</span>
                                        </a>
                                        <ul className="menu-list__depth2">
                                            <li>
                                                <a href="#!" className="menu-list__btn-sub">
                                                    <span>특가판매몰관리</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!" className="menu-list__btn-sub">
                                                    <span>특가판매몰</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                )}
                                {(user.role === "ADMIN" || user.role === "WAREHOUSE") && (
                                    <li className="menu-list__category">
                                        <a href="#!" className="menu-list__btn">
                                            <span>주문관리</span>
                                        </a>
                                        <ul className="menu-list__depth2">
                                            <li>
                                                <Link to={'/kas-0800'} className="menu-list__btn-sub">
                                                    <span>주문관리</span>
                                                    <i className="icon-dot"/>
                                                </Link>

                                            </li>
                                            {/*<li>*/}
                                            {/*    <a href="#!" className="menu-list__btn-sub">*/}
                                            {/*        <span>주문단가관리</span>*/}
                                            {/*    </a>*/}
                                            {/*</li>*/}
                                        </ul>
                                    </li>
                                )}
                                {user.role === "ADMIN" && (
                                    <li className="menu-list__category">
                                        <a href="#!" className="menu-list__btn">
                                            <span>출하·출고관리</span>
                                        </a>
                                        <ul className="menu-list__depth2">
                                            <li>
                                                <Link to="/kas-0850" className="menu-list__btn-sub">
                                                    <span>출하지시관리</span>
                                                    <i className="icon-dot"/>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/kas-0851" className="menu-list__btn-sub">
                                                    <span>출고관리</span>
                                                    <i className="icon-dot"/>
                                                </Link>
                                                <div className="menu-list__more-btn">
                                                    <Link to="/kas-0851" className="btn-round">
                                                        <span>출고요청</span>
                                                    </Link>
                                                </div>
                                                <div className="menu-list__more-btn">
                                                    <Link to="/kas-0852" className="btn-round">
                                                        <span>배차/출고등록</span>
                                                    </Link>
                                                </div>
                                                <div className="menu-list__more-btn">
                                                    <Link to="/kas-0856_01" className="btn-round">
                                                        <span>출고현황</span>
                                                    </Link>
                                                </div>
                                                <div className="menu-list__more-btn">
                                                    <Link to="/kas-0858" className="btn-round">
                                                        <span>출고실적</span>
                                                    </Link>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                )}

                                {user.role === "ADMIN" && (
                                    <li className="menu-list__category">
                                        <a href="#!" className="menu-list__btn">
                                            <span>반품관리</span>
                                        </a>
                                        <ul className="menu-list__depth2">
                                            <li>
                                                <a href="#!" className="menu-list__btn-sub">
                                                    <span>반품관리</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!" className="menu-list__btn-sub">
                                                    <span>반품확정</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                )}

                                {(user.role === "ADMIN" || user.role === 'CUSTOMER') && (
                                    <li className="menu-list__category">
                                        <a href="#!" className="menu-list__btn">
                                            <span>기준정보관리</span>
                                        </a>
                                        <ul className="menu-list__depth2">
                                            <li>
                                                <Link to="/kas-1901" className="menu-list__btn-sub">
                                                    <span>고객 목적지관리</span>
                                                </Link>
                                            </li>
                                            {/*<li>*/}
                                            {/*    <a href="#!" className="menu-list__btn-sub">*/}
                                            {/*        <span>운반비관리</span>*/}
                                            {/*    </a>*/}
                                            {/*</li>*/}
                                        </ul>
                                    </li>
                                )}

                                {user.role === "ADMIN" && (
                                    <li className="menu-list__category">
                                        <a href="#!" className="menu-list__btn">
                                            <span>시스템관리</span>
                                        </a>
                                        <ul className="menu-list__depth2">
                                            <li>
                                                <a href="#!" className="menu-list__btn-sub">
                                                    <span>고객관리</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!" className="menu-list__btn-sub">
                                                    <span>사용자관리</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!" className="menu-list__btn-sub">
                                                    <span>공지사항관리</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!" className="menu-list__btn-sub">
                                                    <span>FAQ관리</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!" className="menu-list__btn-sub">
                                                    <span>팝업관리</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                )}


                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <Modal
                dialogClassName="justify-content-center"
                show={showChangePasswordModal}
                size="lg"
                onHide={() => setShowChangePasswordModal(false)}
            >
                <Modal.Body style={{background: '#fff'}}>
                    <div id="wrap" className="popup-area">
                        <section className="main-area">
                            <article className="small-pop">
                                <div className="article-header">
                                    <h1 className="popup-header__tit">비밀번호 재설정</h1>
                                    <ul className="gap-x40 dott-list">
                                        <li>관리자가 등록했거나 비밀번호를 초기화 한 상태입니다.</li>
                                        <li className="note">비밀번호 변경 후 사용 가능합니다.</li>
                                    </ul>
                                    <button type="button" className="btn-close"
                                            onClick={() => setShowChangePasswordModal(false)}><span className="a11y">팝업 닫기</span>
                                    </button>
                                </div>
                                <div className="article-content">
                                    <div className="tbl-area">
                                        <div className="table-wrap">
                                            <table>
                                                <colgroup>
                                                    <col style={{width: '160px'}}/>
                                                    <col/>
                                                </colgroup>
                                                <tbody>
                                                <tr>
                                                    <th>아이디</th>
                                                    <td>{user.uid}</td>
                                                </tr>
                                                <tr>
                                                    <th>휴대폰 번호</th>
                                                    <td>
                                                        <div className="form-area">
                                                            <div className="form-item">
                                                                <input type="text" className="inp" onChange={(e) => {
                                                                    setUpdatePasswordData({
                                                                        ...updatePasswordData,
                                                                        phone_number: e.target.value
                                                                    })
                                                                }}/>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {/*<tr>*/}
                                                {/*    <th>이메일</th>*/}
                                                {/*    <td>*/}
                                                {/*        <div className="form-area">*/}
                                                {/*            <div className="form-item">*/}
                                                {/*                <input type="text" className="inp" onChange={(e) => {*/}
                                                {/*                    setUpdatePasswordData({*/}
                                                {/*                        ...updatePasswordData,*/}
                                                {/*                        email_address: e.target.value*/}
                                                {/*                    })*/}
                                                {/*                }}/>*/}
                                                {/*            </div>*/}
                                                {/*        </div>*/}
                                                {/*    </td>*/}
                                                {/*</tr>*/}
                                                <tr>
                                                    <th>비밀번호 입력</th>
                                                    <td>
                                                        <div className="form-area">
                                                            <div className="form-item">
                                                                <input type="password" className="inp"
                                                                       onChange={(e) => {
                                                                           setUpdatePasswordData({
                                                                               ...updatePasswordData,
                                                                               new_password: e.target.value
                                                                           })
                                                                       }}/>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>비밀번호 재입력</th>
                                                    <td>
                                                        <div className="form-area">
                                                            <div className="form-item">
                                                                <input type="password" className="inp"
                                                                       onChange={(e) => {
                                                                           setUpdatePasswordData({
                                                                               ...updatePasswordData,
                                                                               re_password: e.target.value
                                                                           })
                                                                       }}/>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="btn-area">
                                        <button onClick={() => setShowChangePasswordModal(false)}
                                                className="btn-base type-secondary size-m">
                                            <span>취소</span>
                                        </button>
                                        <button onClick={() => changePassword()}
                                                className="btn-base type-primary size-m grid-m">
                                            <span>등록</span>
                                        </button>
                                    </div>
                                </div>
                            </article>
                        </section>
                    </div>
                </Modal.Body>
            </Modal>


        </>
    )
}

export default Header1
import React from 'react'

import ImgNone from '../../assets/img/img_none.png'

function kas0600() {
  return (
    <>
      <div id="wrap" className="popup-area">
        <section className="main-area">
          <article className="small-pop">
            <div className="article-header">
              <h1 className="popup-header__tit">경매응찰동의서</h1>
              <button type="button" className="btn-close" onclick="window.close();">
                <span className="a11y">팝업 닫기</span>
              </button>
            </div>
            <div className="article-content">
              <figure>
                <img src={ImgNone} alt="" />
              </figure>
              <div className="btn-area">
                <div className="radio-box">
                  <input
                    type="radio"
                    name="radioCheck2"
                    id="radio3"
                    defaultChecked=""
                  />
                  <label htmlFor="radio3" className="rdo-txt">
                    동의
                  </label>
                  <input type="radio" name="radioCheck2" id="radio4" />
                  <label htmlFor="radio4" className="rdo-txt">
                    미동의
                  </label>
                </div>
                <a href="#" className="btn-base type-primary size-m grid-m gap-x40">
                  <span>경매응찰동의서에 동의합니다</span>
                </a>
              </div>
            </div>
          </article>
        </section>
      </div>
    </>
  )
}

export default kas0600
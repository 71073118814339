import React from 'react'

import Header from '../../containers/header/Header1'
import Footer from '../../containers/footer/Footer'

import Background from '../../assets/img/menu-visual1.jpg'

function kas1903() {
  return (
    <>
      <div id="wrap">
        <Header />
        {/* [Start] main-area */}
        <section className="main-area" id="main">
          <div className="menu-visual" style={{ backgroundImage: `url(${Background})` }}>
            <h2 className="menu-visual__tit">
              목적지 상세/수정
            </h2>
            <div className="breadcrumbs-area">
              <ol>
                <li className="breadcrumbs-area__step breadcrumbs-area__step--root">
                  <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                    <i className="icon24 icon-home" />
                    <span className="a11y">홈으로</span>
                  </a>
                </li>
                <li className="breadcrumbs-area__step breadcrumbs-area__step--more"> {/* [D] sub menu 있을 시, breadcrumbs-area__step--more 클래스 추가 */}
                  <a href="javascript:void(0);" className="breadcrumbs-area__link">
                    <span>시스템관리</span>
                  </a>
                  <ul className="breadcrumbs-area__sub-menu">
                    <li>
                      <a href="/manageProduct1.html">시스템관리 1</a>
                    </li>
                    <li>
                      <a href="/manageProduct1.html">시스템관리 2</a>
                    </li>
                    <li>
                      <a href="/manageProduct1.html">시스템관리 3</a>
                    </li>
                  </ul>
                </li>
                <li className="breadcrumbs-area__step">
                  <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                    <span>목적지 상세/수정</span>
                  </a>
                </li>
              </ol>
            </div>
          </div>
          <div className="cont-area">
            <div className="cont-area__grid">
              <div className="cont-area__section">
                <article>
                  <div className="article-content">
                    <div className="tbl-area">
                      <div className="table-wrap">
                        <table>
                          <colgroup>
                            <col style={{ width: '160px' }} />
                            <col style={{ width: '400px' }} />
                            <col style={{ width: '160px' }} />
                            <col style={{ width: '140px' }} />
                            <col style={{ width: '160px' }} />
                            <col style={{ width: '140px' }} />
                            <col style={{ width: '160px' }} />
                            <col />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>고객명</th>
                              <td>
                                <div className="form-area">
                                  <div className="form-item overlap-check">
                                    <input type="text" className="inp" defaultValue="카스코철강" disabled />
                                    <button type="button" className="btn-check">찾기</button>
                                  </div>
                                </div>
                              </td>
                              <th>대표여부</th>
                              <td>
                                <div className="form-area">
                                  <div className="check-box">
                                    <input type="checkbox" id="check1" />
                                    <label htmlFor="check1" className="lb-chk">
                                      <span>대표</span>
                                    </label>
                                  </div>
                                </div>
                              </td>
                              <th>사용여부</th>
                              <td>
                                <div className="form-area">
                                  <div className="check-box">
                                    <input type="checkbox" id="check2" />
                                    <label htmlFor="check2" className="lb-chk">
                                      <span>사용</span>
                                    </label>
                                  </div>
                                </div>
                              </td>
                              <th className="necessary">순번</th>
                              <td>
                                <div className="form-area">
                                  <div className="form-item">
                                    <input type="text" className="inp" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>목적지</th>
                              <td>
                                <div className="form-area">
                                  <div className="form-item overlap-check">
                                    <input type="text" className="inp" defaultValue="카스코철강" disabled />
                                    <button type="button" className="btn-check">찾기</button>
                                  </div>
                                </div>
                              </td>
                              <th className="necessary">주소</th>
                              <td colSpan={5}>
                                <div className="form-area">
                                  <div className="form-item">
                                    <input type="text" className="inp" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>차량</th>
                              <td>
                                <div className="form-area">
                                  <div className="form-item">
                                    <input type="text" className="inp" defaultValue="25톤" disabled />
                                  </div>
                                </div>
                              </td>
                              <th className="necessary">전화번호1</th>
                              <td colSpan={2}>
                                <div className="form-area">
                                  <div className="form-item">
                                    <input type="text" className="inp" />
                                  </div>
                                </div>
                              </td>
                              <th className="necessary">전화번호2</th>
                              <td colSpan={2}>
                                <div className="form-area">
                                  <div className="form-item">
                                    <input type="text" className="inp" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th className="necessary">하차지명</th>
                              <td>
                                <div className="form-area">
                                  <div className="form-item">
                                    <input type="text" className="inp" />
                                  </div>
                                </div>
                              </td>
                              <th>하차지특이사항</th>
                              <td colSpan={5}>
                                <p>진입가능한 차량종류, 하차지의 상황을 작성하세요.</p>
                                <div className="form-area">
                                  <div className="form-item">
                                    <input type="text" className="inp" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>비고</th>
                              <td colSpan={7}>
                                <div className="form-area">
                                  <div className="form-item">
                                    <input type="text" className="inp" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>최종수정자</th>
                              <td colSpan={3}>카스코</td>
                              <th>최종수정일시</th>
                              <td colSpan={3}>2022-11-11 11:11:11</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </article>
                <div className="btn-area gap-x40">
                  <a href="#" className="btn-base type-black size-m">
                    <span>
                      삭제
                    </span>
                  </a>
                  <a href="#" className="btn-base type-secondary size-m">
                    <span>취소</span>
                  </a>
                  <a href="#" className="btn-base type-primary size-m grid-m">
                    <span>
                      저장
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* // [End] main-area */}
        <Footer />
      </div>
    </>
  )
}

export default kas1903
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import Logo from '../../assets/img/logo.png'
import {setLogin, setToken, setUser} from "../../store/auth/auth.slice";
import {sha256Message} from "../../Utils";
import {toast} from 'react-toastify'
import axiosInstance from "../../helpers/axiosInstance";
import {store} from "../../store";
import {hideLoader, showLoader} from "../../store/loader/actions";
import Modal from "react-bootstrap/Modal";
import {useCookies} from 'react-cookie';


function Login() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const {token} = useSelector((store) => store.auth);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [rePasswordRequired, setRePasswordRequired] = useState(false);
    const [tempUid, setTempUid] = useState("");
    const [updatePasswordData, setUpdatePasswordData] = useState({
        phone_number: '',
        email_address: '',
        new_password: '',
        re_password: ''
    })

    const [isRemember, setIsRemember] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['rememberId']);

    useEffect(() => {
        if (cookies.rememberId !== undefined) {
            setEmail(cookies.rememberId);
            setIsRemember(true);
        }
    }, []);

    const login = async () => {
        if (email === "" || password === "") {
            alert("Please enter values");
            return;
        }
        const payload = {
            uid: email,
            password: await sha256Message(password)
        }
        try {
            store.dispatch(showLoader());
            const resLoginUser = await axiosInstance.post('/auth/login', payload);
            if (resLoginUser.code === 200) {
                if (resLoginUser.data.info.new_pwd_yn === 0) {
                    setTempUid(email);
                    store.dispatch(hideLoader());
                    setShowChangePasswordModal(true);
                } else {
                    toast.dismiss();
                    toast.success(('Success'));
                    dispatch(setToken(resLoginUser.data.token));
                    dispatch(setUser(resLoginUser.data.info));
                    dispatch(setLogin());
                    store.dispatch(hideLoader());
                }
            } else {
                store.dispatch(hideLoader());
                alert(resLoginUser.message);
            }
        } catch (e) {
            store.dispatch(hideLoader());
        }
    }

    const changePassword = async () => {
        if (updatePasswordData.phone_number === '' || updatePasswordData.new_password === '' || updatePasswordData.re_password === '') {
            alert("Please enter values")
            return;
        }
        if (updatePasswordData.new_password !== updatePasswordData.re_password) {
            alert("Passwords does not match")
            return;
        }
        const payload = {
            uid: tempUid,
            email: updatePasswordData.email_address,
            phone: updatePasswordData.phone_number,
            password: await sha256Message(updatePasswordData.new_password),
        }
        try {
            store.dispatch(showLoader());
            const resUpdateUser = await axiosInstance.post('/auth/update', payload);
            if (resUpdateUser.code === 200) {
                toast.dismiss();
                toast.success(('Success'))
                setShowChangePasswordModal(false);
                store.dispatch(hideLoader());
            } else {
                store.dispatch(hideLoader());
                alert(resUpdateUser.message);
            }
        } catch (e) {
            store.dispatch(hideLoader());
        }
    }


    const handleOnChangeRemember = (e) => {
        setIsRemember(e.target.checked);
        console.log(e.target.checked)
        if (e.target.checked) {
            setCookie('rememberId', email, {maxAge: 2000});
        } else {
            removeCookie('rememberId');
        }
    }

    return (
        <>
            <div id="wrap" className="login-wrap">
                <header className="login-header">
                    <div className="login-header__logo">
                        <a href="#!">
                            <img src={Logo} alt="카스코철강"/>
                        </a>
                    </div>
                </header>
                <section className="login-area" id="main">
                    <div className="login-area__grid">
                        <div className="login-box">
                            <div className="login-box__visual"/>
                            <div className="login-box__forms">
                                <div className="form-area">
                                    <div className="form-tit">
                                        <label htmlFor="inpId">아이디</label>
                                    </div>
                                    <div className="form-item">
                                        <input type="text" id="inpId" value={email}
                                               onChange={(e) => setEmail(e.target.value)}
                                               className="inp type-gray"
                                               placeholder="아이디를 입력하세요"/>
                                    </div>
                                </div>
                                <div className="form-area">
                                    <div className="form-tit">
                                        <label htmlFor="inpPw">비밀번호</label>
                                    </div>
                                    <div className="form-item">
                                        <input type="password" id="inpPw" className="inp type-gray"
                                               onChange={(e) => setPassword(e.target.value)}
                                               placeholder="비밀번호를 입력하세요"/>
                                    </div>
                                </div>
                                <div className="form-area gap-x20">
                                    {/*<div className="lb-item">*/}
                                    {/*    <input type="checkbox" id="chkAutoLogin" name/>*/}
                                    {/*    <label htmlFor="chkAutoLogin" className="lb-chk">*/}
                                    {/*        <span>자동로그인</span>*/}
                                    {/*    </label>*/}
                                    {/*</div>*/}

                                    <div className="lb-item">
                                        <input type="checkbox" id="chkSaveId" name
                                               onChange={(e) => handleOnChangeRemember(e)}/>
                                        <label htmlFor="chkSaveId" className="lb-chk">
                                            <span>아이디 저장</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="btn-area gap-x20">
                                    <button type="button" className="btn-base type-primary size-m grid-full"
                                            onClick={() => login()}>
                                        <span>로그인</span>
                                        <i className="icon30 icon-go"/>
                                    </button>
                                </div>
                                <div className="btn-area tal gap-x20">
                                    <a href="/signup-customer" className="btn-icon">
                                        <i className="icon34 icon-user"/>
                                        <span>회원가입</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="login-area__footer">
                            <p>아이디, 비밀번호 분실 등의 문의는 업무 담당자에게 직접 연락 주세요.</p>
                            <p>
                                <i className="icon24 icon-chat"/>
                                <strong>2023년 1월 1일 이후 공인인증서 로그인이 삭제되었습니다. 아이디/비밀번호 로그인 후 사용해주세요.</strong>
                            </p>
                        </div>
                    </div>
                </section>
            </div>

            <Modal
                dialogClassName="justify-content-center"
                show={showChangePasswordModal}
                size="lg"
                onHide={() => setShowChangePasswordModal(false)}
            >
                <Modal.Body style={{background: '#fff'}}>
                    <div id="wrap" className="popup-area">
                        <section className="main-area">
                            <article className="small-pop">
                                <div className="article-header">
                                    <h1 className="popup-header__tit">비밀번호 재설정</h1>
                                    <ul className="gap-x40 dott-list">
                                        <li>관리자가 등록했거나 비밀번호를 초기화 한 상태입니다.</li>
                                        <li className="note">비밀번호 변경 후 사용 가능합니다.</li>
                                    </ul>
                                    <button type="button" className="btn-close"
                                            onClick={() => setShowChangePasswordModal(false)}><span className="a11y">팝업 닫기</span>
                                    </button>
                                </div>
                                <div className="article-content">
                                    <div className="tbl-area">
                                        <div className="table-wrap">
                                            <table>
                                                <colgroup>
                                                    <col style={{width: '160px'}}/>
                                                    <col/>
                                                </colgroup>
                                                <tbody>
                                                <tr>
                                                    <th>아이디</th>
                                                    <td>{tempUid}</td>
                                                </tr>
                                                <tr>
                                                    <th>휴대폰 번호</th>
                                                    <td>
                                                        <div className="form-area">
                                                            <div className="form-item">
                                                                <input type="text" className="inp" onChange={(e) => {
                                                                    setUpdatePasswordData({
                                                                        ...updatePasswordData,
                                                                        phone_number: e.target.value
                                                                    })
                                                                }}/>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {/*<tr>*/}
                                                {/*    <th>이메일</th>*/}
                                                {/*    <td>*/}
                                                {/*        <div className="form-area">*/}
                                                {/*            <div className="form-item">*/}
                                                {/*                <input type="text" className="inp" onChange={(e) => {*/}
                                                {/*                    setUpdatePasswordData({*/}
                                                {/*                        ...updatePasswordData,*/}
                                                {/*                        email_address: e.target.value*/}
                                                {/*                    })*/}
                                                {/*                }}/>*/}
                                                {/*            </div>*/}
                                                {/*        </div>*/}
                                                {/*    </td>*/}
                                                {/*</tr>*/}
                                                <tr>
                                                    <th>비밀번호 입력</th>
                                                    <td>
                                                        <div className="form-area">
                                                            <div className="form-item">
                                                                <input type="password" className="inp"
                                                                       onChange={(e) => {
                                                                           setUpdatePasswordData({
                                                                               ...updatePasswordData,
                                                                               new_password: e.target.value
                                                                           })
                                                                       }}/>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>비밀번호 재입력</th>
                                                    <td>
                                                        <div className="form-area">
                                                            <div className="form-item">
                                                                <input type="password" className="inp"
                                                                       onChange={(e) => {
                                                                           setUpdatePasswordData({
                                                                               ...updatePasswordData,
                                                                               re_password: e.target.value
                                                                           })
                                                                       }}/>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="btn-area">
                                        <button onClick={() => setShowChangePasswordModal(false)}
                                                className="btn-base type-secondary size-m">
                                            <span>취소</span>
                                        </button>
                                        <button onClick={() => changePassword()}
                                                className="btn-base type-primary size-m grid-m">
                                            <span>등록</span>
                                        </button>
                                    </div>
                                </div>
                            </article>
                        </section>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default Login
import React from 'react'

function kas0507() {
  return (
    <>
      <div id="wrap" className="popup-area">
        <section className="main-area">
          <article className="small-pop">
            <div className="popup-header">
              <h1 className="popup-header__tit">경매시작단가 등록</h1>
              <button type="button" className="btn-close" onclick="window.close();"><span className="a11y">팝업 닫기</span></button>
            </div>
            <div className="article-content">
              <div className="tbl-area">
                <div className="table-wrap">
                  <table>
                    <colgroup>
                      <col style={{ width: '160px' }} />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>적용일시</th>
                        <td>
                          <div className="form-item col3">
                            <input type="date" id className="inp type-date" defaultValue="2022-11-01" />
                            <select className="selectbox">
                              <option value>00시</option>
                              <option value>옵션1</option>
                              <option value>옵션2</option>
                              <option value>옵션3</option>
                            </select>
                            <select className="selectbox">
                              <option value>00분</option>
                              <option value>옵션1</option>
                              <option value>옵션2</option>
                              <option value>옵션3</option>
                            </select>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="necessary">제조사</th>
                        <td>
                          <div className="form-area">
                            <div className="form-item">
                              <select className="selectbox">
                                <option value>선택</option>
                                <option value>옵션1</option>
                                <option value>옵션2</option>
                                <option value>옵션3</option>
                              </select>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="necessary">제품등급</th>
                        <td>
                          <div className="form-area">
                            <div className="form-item">
                              <select className="selectbox">
                                <option value>선택</option>
                                <option value>옵션1</option>
                                <option value>옵션2</option>
                                <option value>옵션3</option>
                              </select>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="necessary">제품군</th>
                        <td>
                          <div className="form-area">
                            <div className="form-item">
                              <select className="selectbox">
                                <option value>선택</option>
                                <option value>옵션1</option>
                                <option value>옵션2</option>
                                <option value>옵션3</option>
                              </select>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="necessary">정척여부</th>
                        <td>
                          <div className="form-area">
                            <div className="form-item">
                              <select className="selectbox">
                                <option value>선택</option>
                                <option value>옵션1</option>
                                <option value>옵션2</option>
                                <option value>옵션3</option>
                              </select>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="necessary">유찰횟수</th>
                        <td>
                          <div className="form-item type-range">
                            <input type="text" className="inp" />
                            <span className="form-division">~</span>
                            <input type="text" className="inp" />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>적용직전단가</th>
                        <td>
                          <div className="form-area">
                            <div className="form-item">
                              <input type="text" className="inp" defaultValue="98,000,000" disabled />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="necessary">적용단가</th>
                        <td>
                          <div className="form-area">
                            <div className="form-item">
                              <input type="text" className="inp" />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="btn-area">
                <a href="#" className="btn-base type-secondary size-m">
                  <span>취소</span>
                </a>
                <a href="#" className="btn-base type-primary size-m grid-m">
                  <span>저장</span>
                </a>
              </div>
            </div>
          </article>
        </section>
      </div>
    </>
  )
}

export default kas0507
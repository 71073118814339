import React, {useEffect, useRef, useState} from 'react'

import Header from '../../containers/header/Header1'
import Footer from '../../containers/footer/Footer'

import Background from '../../assets/img/menu-visual1.jpg'
import {format} from "date-fns";
import {getPath, getUnixTimeByDate, sleep} from "../../helpers/helpers";
import useDidMountEffect from "../../helpers/useDidMountEffect";
import {store} from "../../store";
import {hideLoader, showLoader} from "../../store/loader/actions";
import axiosInstance from "../../helpers/axiosInstance";
import {toast} from "react-toastify";
import {addComma} from "../../Utils";
import {useSelector} from "react-redux";
import {DownloadTableExcel} from "react-export-table-to-excel";
import {Link} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Pagination from "../pagination/pagination";

function Kas0851() {

    const {token, user} = useSelector((store) => store.auth);

    const [query, setQuery] = useState({
        start_confirm_dt: '',
        end_confirm_dt: '',
        start_send_dt: '',
        end_send_dt: '',
        storage_div_cd: 'G101',
        cust_corp_nm: '',
        cust_corp_code: '',
        cust_corp_no: '',
        goods_no_arr: '',

    });


    const [submitData, setSubmitData] = useState({
        date: '',
        date_fm: '',
        cust_corp_nm: '',
        cust_corp_code: '',
        cust_corp_no: '',
        goods_cnt: 0,
        goods_wt_total: 0,
        dest_sigun_nm: '',
        dest_sigun_cd: '',
        dest_phone: '',
        order_no_arr: '',
        deliveryYn: '',
        useYn: ''
    })


    const isMounted = useRef(false);

    const [startDateFm, setStartDateFm] = useState(null);
    const [endDateFm, setEndDateFm] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [page, setPage] = useState(1);
    const [size, setSize] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [list, setList] = useState([]);

    useEffect(() => {
        if (!isMounted.current) {
            let currentDate = new Date();
            let startOfDayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);
            setStartDateFm(format(startOfDayDate, 'yyyy-MM-dd'));
            setStartDate(getUnixTimeByDate(startOfDayDate));

            let endOfDayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);
            setEndDateFm(format(endOfDayDate, 'yyyy-MM-dd'))
            setEndDate(getUnixTimeByDate(endOfDayDate))


            setSubmitData({
                ...submitData,
                date_fm: format(startOfDayDate, 'yyyy-MM-dd'),
                date: getUnixTimeByDate(startOfDayDate)
            })
            isMounted.current = true;
        }
    }, []);


    const tableRef = useRef(null);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const [queryData, setQueryData] = useState({
        corp_nm: '', business_number: ''
    });
    const [queryResult, setQueryResult] = useState([]);
    const [queryPage, setQueryPage] = useState(1);
    const [querySize, setQuerySize] = useState(10);
    const [queryTotalPage, setQueryTotalPage] = useState(0);
    const [showFindCustomer, setShowFindCustomer] = useState(false);

    const [delivery1Checked, setDelivery1Checked] = useState(false);
    const [delivery2Checked, setDelivery2Checked] = useState(false);
    const [useYn1Checked, setUseYn1Checked] = useState(false);
    const [useYn2Checked, setUseYn2Checked] = useState(true);
    const deliveryYn = useRef('');
    const useYn = useRef('N');
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItemNew, setSelectedItemNew] = useState([]);
    const [nextShipmentSeq, setNextShipmentSeq] = useState('');

    useDidMountEffect(() => {
        searchQuery();
    }, [size, page]);

    useDidMountEffect(() => {
        searchCustomer();
    }, [querySize, queryPage]);


    useDidMountEffect(() => {
        if (selectedItemNew.length > 0) {
            console.log(selectedItemNew);
            const cust_corp_nm = selectedItemNew[0].corp_nm;
            const goods_cnt = selectedItemNew.length;
            let goods_wt_total = 0;
            let orderNoArr = [];
            for (let i = 0; i < selectedItemNew.length; i++) {
                goods_wt_total += Number.parseFloat(selectedItemNew[i].goods_wt);
                orderNoArr.push(selectedItemNew[i].ord_no);
            }
            const dest_sigun_nm = selectedItemNew[0].dest_sigun_nm;
            const dest_phone = selectedItemNew[0].dest_phone;
            const dest_sigun_cd = selectedItemNew[0].dest_sigun_cd;
            const storage_div_cd = selectedItemNew[0].storage_div_cd;
            const cust_corp_no = selectedItemNew[0].cust_corp_no;
            setSubmitData({
                ...submitData,
                cust_corp_nm: cust_corp_nm,
                goods_cnt: goods_cnt,
                goods_wt_total: goods_wt_total,
                dest_sigun_nm: dest_sigun_nm,
                dest_sigun_cd: dest_sigun_cd,
                storage_div_cd: storage_div_cd,
                cust_corp_no: cust_corp_no,
                dest_phone: dest_phone,
                ord_no_arr: orderNoArr
            })
        } else {
            clearSubmit()
        }


    }, [selectedItemNew]);

    useEffect(() => {
        getNextShipmentSeq();
    }, []);

    const getNextShipmentSeq = async () => {

        let currentDate = new Date();
        let startOfDayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);
        try {
            store.dispatch(showLoader());
            const response = await axiosInstance.get('/shipment/nextShipmentSeq?date=' + getUnixTimeByDate(startOfDayDate));
            console.log(response);
            setNextShipmentSeq(response.data);
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }

    }


    const searchCustomer = async () => {
        let params;
        let filterParams = queryData;
        queryData.size = querySize;
        queryData.page = queryPage;
        params = getPath(filterParams);
        try {
            store.dispatch(showLoader());
            const response = await axiosInstance.get('/auth/searchCustomer' + params);
            console.log(response);
            if (response.code === 200) {
                toast.dismiss();
                toast.success(('Success'))
                setQueryResult(response.data.items);
                setQueryTotalPage(Number.parseInt(response.data.totalPage));
                store.dispatch(hideLoader());
            } else {
                store.dispatch(hideLoader());
                alert(response.message);
            }
        } catch (e) {
            store.dispatch(hideLoader());
        }
    }

    const selectCustomer = (item) => {
        console.log(item);
        setQuery({
            ...query,
            cust_corp_code: item.cust_corp_code,
            business_num: item.business_num,
            cust_corp_no: item.cust_corp_no,
            cust_corp_nm: item.corp_nm
        })
        setShowFindCustomer(false);
    }

    function onChangeOrderNo(e) {
        const lines = e.split(/\n/);
        let arrays = [];
        for (let i = 0; i < lines.length; i++) {
            if (/\S/.test(lines[i])) {
                arrays.push(lines[i].trim());
            }
        }
        setQuery({
            ...query,
            goods_no_arr: arrays
        })
    }

    const searchQuery = async () => {
        setTotalAmount(0);
        let params = "";
        let filterParams = query;
        filterParams.start_dt = startDate;
        filterParams.end_dt = endDate;
        filterParams.page = page;
        filterParams.size = size;
        if (Object.entries(filterParams).length > 0) {
            params = getPath(filterParams);
        }
        try {
            store.dispatch(showLoader());
            const response = await axiosInstance.get('/shipment/requestList' + params);
            if (response.code === 200) {
                let listOfObjs = [];
                const dict = response.data.items;
                for (const key in dict) {
                    let item = dict[key];
                    item.is_parent_checked = false;
                    listOfObjs.push({
                        is_checked: false,
                        array: item,
                        gum: key
                    });
                }
                setList(listOfObjs);
                console.log(listOfObjs);
                setTotalPage(Number.parseInt(response.data.totalPage));
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }


    function selectAllOrders(e, item) {
        console.log(item.gum);
        let listItems = [...list];
        for (let i = 0; i < listItems.length; i++) {
            if (listItems[i].gum === item.gum) {
                listItems[i].is_parent_checked = e;
                for (let j = 0; j < listItems[i].array.length; j++) {
                    listItems[i].array[j].is_checked = e;
                }
            } else {
                listItems[i].is_parent_checked = false;
                for (let j = 0; j < listItems[i].array.length; j++) {
                    listItems[i].array[j].is_checked = false;
                }
            }
        }

        let selectedItemsList = [...selectedItemNew];
        const parentList = [];
        for (let i = 0; i < listItems.length; i++) {
            if (listItems[i].is_parent_checked) {
                parentList.push(listItems[i]);
            }
        }

        let totalAmount = 0;
        for (let i = 0; i < parentList.length; i++) {
            for (let j = 0; j < parentList[i].array.length; j++) {
                selectedItemsList.push(parentList[i].array[j]);
                totalAmount = (totalAmount + Number(parentList[i].array[j].goods_wt))
            }
        }

        selectedItemsList = selectedItemsList.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.ord_no === value.ord_no
                ))
        )

        selectedItemsList = selectedItemsList.filter(function (el) {
            return el.is_checked;
        });
        console.log(selectedItemsList);
        setTotalAmount(totalAmount);
        setSelectedItemNew(selectedItemsList);
        setList(listItems);
    }


    function selectOrderItem(e, item) {
        let listItems = [...list];
        for (let i = 0; i < listItems.length; i++) {
            if (Number.parseInt(listItems[i].gum) === Number.parseInt(item.gum)) {
                console.log("AS")
                // listItems[i].is_parent_checked = e;
                for (let j = 0; j < listItems[i].array.length; j++) {
                    if (listItems[i].array[j].ord_no === item.ord_no) {
                        listItems[i].array[j].is_checked = e;
                    }
                }
            } else {
                listItems[i].is_parent_checked = false;
                for (let j = 0; j < listItems[i].array.length; j++) {
                    listItems[i].array[j].is_checked = false;
                }
            }
        }

        // check one more time
        for (let i = 0; i < listItems.length; i++) {
            let counter = 0;
            for (let j = 0; j < listItems[i].array.length; j++) {
                if (listItems[i].array[j].is_checked) {
                    counter += 1;
                }
            }
            listItems[i].is_parent_checked = counter === listItems[i].array.length;
        }
        let selectedItemsList = [...selectedItemNew];
        let totalAmount = 0;
        for (let i = 0; i < listItems.length; i++) {
            for (let j = 0; j < listItems[i].array.length; j++) {
                if (listItems[i].array[j].is_checked) {
                    selectedItemsList.push(listItems[i].array[j]);
                    totalAmount = (totalAmount + Number(listItems[i].array[j].goods_wt))
                }
            }
        }

        selectedItemsList = selectedItemsList.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.ord_no === value.ord_no
                ))
        )

        selectedItemsList = selectedItemsList.filter(function (el) {
            return el.is_checked;
        });
        console.log(selectedItemsList);


        setTotalAmount(totalAmount);
        setSelectedItemNew(selectedItemsList);
        setList(listItems);
    }


    const checkDelivery1 = (e) => {

        if (selectedItemNew.length === 0) {
            return;
        }

        if (e.target.checked) {
            setDelivery1Checked(true);
            deliveryYn.current = 'Y';
            if (delivery2Checked) {
                setDelivery2Checked(false);
            }
        } else {
            setDelivery1Checked(false);
            deliveryYn.current = '';
        }

        setSubmitData({
            ...submitData,
            deliveryYn: deliveryYn.current
        })
    }
    const checkDelivery2 = (e) => {
        if (selectedItemNew.length === 0) {
            return;
        }

        if (e.target.checked) {
            setDelivery2Checked(true);
            deliveryYn.current = 'Y';
            if (delivery1Checked) {
                setDelivery1Checked(false);
            }
        } else {
            setDelivery2Checked(false);
            deliveryYn.current = '';
        }
        setSubmitData({
            ...submitData,
            deliveryYn: deliveryYn.current
        })
    }

    const checkUseYn1 = (e) => {
        if (selectedItemNew.length === 0) {
            return;
        }

        if (e.target.checked) {
            setUseYn1Checked(true);
            useYn.current = 'Y';
            if (useYn2Checked) {
                setUseYn2Checked(false);
            }
        } else {
            setUseYn1Checked(false);
            useYn.current = '';
        }
        setSubmitData({
            ...submitData,
            useYn: useYn.current
        })
    }
    const checkUseYn2 = (e) => {
        if (selectedItemNew.length === 0) {
            return;
        }

        if (e.target.checked) {
            setUseYn2Checked(true);
            useYn.current = 'N';
            if (useYn1Checked) {
                setUseYn1Checked(false);
            }
        } else {
            setUseYn2Checked(false);
            useYn.current = '';
        }
        setSubmitData({
            ...submitData,
            useYn: useYn.current
        })
    }


    const submit = async () => {
        if (useYn.current === '') {
            alert("Please select");
            return;
        }
        store.dispatch(showLoader());
        try {
            const response = await axiosInstance.post('/shipment/registerShipment', submitData);
            if (response.code === 200) {
                setList([]);
                await sleep(2000);
                await searchQuery();
                await clearSubmit();
                toast.dismiss();
                toast.success(('Success'));
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }

    const clearSubmit = () => {
        setSubmitData({
            ...submitData,
            cust_corp_nm: '',
            goods_cnt: 0,
            goods_wt_total: '',
            dest_sigun_nm: '',
            dest_sigun_cd: '',
            storage_div_cd: '',
            cust_corp_no: '',
            dest_phone: '',
            ord_no_arr: ''
        });
        let listItems = [...list];
        setTotalAmount(0);
        for (let i = 0; i < listItems.length; i++) {
            listItems[i].is_parent_checked = false;
            for (let j = 0; j < listItems[i].array.length; j++) {
                listItems[i].array[j].is_checked = false;
            }
        }
        setList(listItems);
    }

    return (
        <>
            <div id="wrap">
                <Header/>
                {/* [Start] main-area */}
                <section className="main-area" id="main">
                    <div className="menu-visual" style={{backgroundImage: `url(${Background})`}}>
                        <h2 className="menu-visual__tit">
                            출고관리
                        </h2>
                        <div className="breadcrumbs-area">
                            <ol>
                                <li className="breadcrumbs-area__step breadcrumbs-area__step--root">
                                    <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                                        <i className="icon24 icon-home"/>
                                        <span className="a11y">홈으로</span>
                                    </a>
                                </li>
                                <li className="breadcrumbs-area__step breadcrumbs-area__step--more"> {/* [D] sub menu 있을 시, breadcrumbs-area__step--more 클래스 추가 */}
                                    <a href="#!" className="breadcrumbs-area__link">
                                        <span>시스템관리</span>
                                    </a>
                                    <ul className="breadcrumbs-area__sub-menu">
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 1</a>
                                        </li>
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 2</a>
                                        </li>
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 3</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="breadcrumbs-area__step">
                                    <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                                        <span>출고관리</span>
                                    </a>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className="cont-area">
                        <div className="cont-area__grid">
                            <div className="cont-area__header">
                                <div className="cont-area__header-inner">
                                    <div className="tab-area">
                                        <Link to={'/kas-0851'}
                                              className="tab-area__btn  tab-area__btn--active"> {/* [D] 활성화 탭 버튼에 tab-area__btn--active 클래스 추가 */}
                                            <span>출고요청</span>
                                        </Link>
                                        <Link to={'/kas-0852'} className="tab-area__btn">
                                            <span>배차/출고등록</span>
                                        </Link>
                                        <Link to={'/kas-0856_01'} className="tab-area__btn">
                                            <span>출고현황</span>
                                        </Link>
                                        <Link to={'/kas-0858'} className="tab-area__btn">
                                            <span>출고실적</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="cont-area__section">
                                <article>
                                    <div className="article-content">
                                        <div className="tbl-area">
                                            <div className="table-wrap">
                                                <table>
                                                    <colgroup>
                                                        <col style={{width: '160px'}}/>
                                                        <col/>
                                                        <col style={{width: '160px'}}/>
                                                        <col/>
                                                        <col style={{width: '160px'}}/>
                                                        <col/>
                                                    </colgroup>
                                                    <tbody>
                                                    <tr>
                                                        <th>출하지시일자</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item type-range">
                                                                    <input type="date" id className="inp type-date"
                                                                           defaultValue={startDateFm}
                                                                           onChange={(e) => setStartDate(getUnixTimeByDate(new Date(e.target.value)))}/>
                                                                    <span className="form-division">~</span>
                                                                    <input type="date" id className="inp type-date"
                                                                           defaultValue={endDateFm}
                                                                           onChange={(e) => setEndDate(getUnixTimeByDate(new Date(e.target.value)))}/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>창고</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item">
                                                                    <select className="selectbox"
                                                                            onChange={(e) => setQuery({
                                                                                ...query,
                                                                                storage_div_cd: e.target.value
                                                                            })}>
                                                                        <option value='G101'>우성</option>
                                                                        <option value='G041'>삼우</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>고객코드/고객명</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item col2 overlap-check">
                                                                    <input
                                                                        type="text"
                                                                        className="inp"
                                                                        value={query.cust_corp_code}
                                                                        disabled
                                                                    />
                                                                    <input
                                                                        type="text"
                                                                        className="inp"
                                                                        value={query.cust_corp_nm}
                                                                        disabled
                                                                    />
                                                                    <button onClick={() => setShowFindCustomer(true)}
                                                                            type="button" className="btn-check_001">
                                                                        찾기
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th rowSpan={3} className="border-0">제품번호</th>
                                                        <td rowSpan={3} className="border-0">
                                                            <div className="product-num">
                                                                <div className="item">
                                                                    <div className="form-area">
                                                                        <div className="form-item">
                                                                            <textarea className="inp"
                                                                                      defaultValue={""}
                                                                                      onChange={(e) => {
                                                                                          onChangeOrderNo(e.target.value)
                                                                                      }}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="summary">
                                                                    <p>
                                                                        *제품번호, 패키지번호 구분은 , 또는 엔터로 구분<br/><br/>
                                                                        예) A3022,A0033
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>목적지코드/목적지명</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item col2 overlap-check">
                                                                    <input id className="inp"/>
                                                                    <input id className="inp"/>
                                                                    <button type="button" className="btn-check_001">찾기
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                <div className="btn-area gap-x40">
                                    <a href="#!" className="btn-base type-secondary size-m">
                                        <span>초기화</span>
                                    </a>
                                    <button onClick={() => searchQuery()}
                                            className="btn-base type-primary size-m grid-m">
                                        <i className="icon24 icon-search-light"/>
                                        <span>
                      조회
                    </span>
                                    </button>
                                </div>
                                <article className="space">
                                    <div className="article-content type-2">
                                        <div className="box left">
                                            <div className="tbl-area">
                                                <div className="tbl-upper">
                                                    <strong className="tbl-area__tit">
                                                        출고등록
                                                    </strong>
                                                </div>
                                                <div className="tbl-base">
                                                    <div className="table-wrap">
                                                        <table>
                                                            <colgroup>
                                                                <col style={{width: '160px'}}/>
                                                                <col/>
                                                            </colgroup>
                                                            <tbody>
                                                            <tr>
                                                                <th>출고일자-순번</th>
                                                                <td>
                                                                    <div className="form-area">
                                                                        <div className="form-item col2">
                                                                            <input type="date" id
                                                                                   className="inp type-date"
                                                                                   onChange={(e) => {
                                                                                       setSubmitData({
                                                                                           ...submitData,
                                                                                           date: getUnixTimeByDate(new Date(e.target.value))
                                                                                       })
                                                                                   }}
                                                                                   defaultValue={submitData.date_fm}/>
                                                                            <input type="text" id className="inp"
                                                                                   disabled value={nextShipmentSeq}/>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th className="necessary">고객사명</th>
                                                                <td>
                                                                    <div className="form-area">
                                                                        <div className="form-item">
                                                                            <input type="text" className="inp" disabled
                                                                                   value={submitData.cust_corp_nm}/>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>제품수</th>
                                                                <td>{submitData.goods_cnt}개</td>
                                                            </tr>
                                                            <tr>
                                                                <th>제품중량</th>
                                                                <td>
                                                                    <div className="form-area">
                                                                        <div className="form-item overlap-check">
                                                                            <input type="text" className="inp" disabled
                                                                                   value={addComma(submitData.goods_wt_total)}/>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th className="necessary">목적지(시군구)</th>
                                                                <td>
                                                                    <div className="form-area">
                                                                        <div className="form-item overlap-check">
                                                                            <input type="text" className="inp" disabled
                                                                                   value={submitData.dest_sigun_nm}/>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th className="necessary">도착지전화번호</th>
                                                                <td>
                                                                    <div className="form-area">
                                                                        <div className="form-item">
                                                                            <input type="text" className="inp" disabled
                                                                                   value={submitData.dest_phone}/>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            {/*<tr>*/}
                                                            {/*    <th>인도조건</th>*/}
                                                            {/*    <td>*/}
                                                            {/*        <div className="form-area">*/}
                                                            {/*            <div className="check-box">*/}
                                                            {/*                <input type="checkbox" id="check1"*/}
                                                            {/*                       checked={delivery1Checked}*/}
                                                            {/*                       onChange={(e) => checkDelivery1(e)}/>*/}
                                                            {/*                <label htmlFor="check1" className="lb-chk">*/}
                                                            {/*                    <span>Y</span>*/}
                                                            {/*                </label>*/}
                                                            {/*                <input type="checkbox" id="check2"*/}
                                                            {/*                       checked={delivery2Checked}*/}
                                                            {/*                       onChange={(e) => checkDelivery2(e)}/>*/}
                                                            {/*                <label htmlFor="check2" className="lb-chk">*/}
                                                            {/*                    <span>N</span>*/}
                                                            {/*                </label>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </td>*/}
                                                            {/*</tr>*/}
                                                            <tr>
                                                                <th className="necessary">상차도 여부</th>
                                                                <td>
                                                                    <div className="form-area">
                                                                        <div className="check-box">
                                                                            <input type="checkbox" id="check11"
                                                                                   checked={useYn1Checked}
                                                                                   onChange={(e) => checkUseYn1(e)}/>
                                                                            <label htmlFor="check11" className="lb-chk">
                                                                                <span>Y</span>
                                                                            </label>
                                                                            <input type="checkbox" id="check22"
                                                                                   checked={useYn2Checked}
                                                                                   onChange={(e) => checkUseYn2(e)}/>
                                                                            <label htmlFor="check22" className="lb-chk">
                                                                                <span>N</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="btn-area gap-x40">
                                                <button onClick={() => clearSubmit()}
                                                        className="btn-base type-secondary size-m">
                          <span>
                            취소
                          </span>
                                                </button>
                                                <button onClick={() => submit()}
                                                        className="btn-base type-primary size-m grid-m">
                          <span>
                            등록
                          </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div className="tbl-area">
                                                <div className="tbl-upper">
                                                    <div className="tbl-upper__util">
                                                        <strong className="tbl-area__tit">
                                                            선택 중량(KG) {addComma(totalAmount)}
                                                        </strong>
                                                        <DownloadTableExcel filename="kasco" sheet="users"
                                                                            currentTableRef={tableRef.current}>
                                                            <a href="#" className="btn-base type-dark">
                                                                <span>다운로드</span>
                                                            </a>
                                                        </DownloadTableExcel>
                                                        <select className="selectbox type-light"
                                                                onChange={(e) => setSize(e.target.value)}>
                                                            <option value='50'>50개씩</option>
                                                            <option value='100'>100개씩</option>
                                                            <option value='200'>200개씩</option>
                                                            <option value='500'>500개씩</option>
                                                            <option value='1000'>1000개씩</option>
                                                            <option value='5000'>5000개씩</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="tbl-base type-hover">
                                                    <table className="table-product" ref={tableRef}>
                                                        <caption>
                                                            정보 테이블
                                                        </caption>
                                                        <thead>
                                                        <tr>
                                                            <th scope="col">
                                                                {/*<input type="checkbox" id="chk2-all"*/}
                                                                {/*       name="chk2Group"*/}
                                                                {/*       onChange={(e) => checkAllOrders(e.target.checked)}*/}
                                                                {/*/>*/}
                                                                {/*<label htmlFor="chk2-all" className="lb-chk">*/}
                                                                {/*    <span className="a11y">전체선택</span></label>*/}
                                                            </th>
                                                            <th scope="col"></th>
                                                            <th scope="col">제품번호</th>
                                                            <th scope="col">창고</th>
                                                            <th scope="col">두께</th>
                                                            <th scope="col">폭</th>
                                                            <th scope="col">길이</th>
                                                            <th scope="col">중량</th>
                                                            <th scope="col">규격약호</th>
                                                            <th scope="col">고객코드</th>
                                                            <th scope="col">고객사</th>
                                                            <th scope="col">목적지</th>
                                                            <th scope="col">도착지전화번호</th>
                                                            <th scope="col">경매번호</th>
                                                            <th scope="col">출하지시일자</th>
                                                            {user.role === 'ADMIN' && (
                                                                <>
                                                                    <th scope="col">매출운임비</th>
                                                                    <th scope="col">매입운임비</th>
                                                                </>
                                                            )}

                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            list.map((item, index) => (
                                                                <tr id={index.toString()} key={index}>
                                                                    <td className="padding-none"
                                                                        rowSpan={item.array.size}>
                                                                        <input type="checkbox"
                                                                               checked={item.is_parent_checked}
                                                                               id={`chkGoodsParent${index}-1`}
                                                                               name={`chkGoodsGroupParent${index}-1`}
                                                                               onChange={(e) => selectAllOrders(e.target.checked, item)}/>
                                                                        <label htmlFor={`chkGoodsParent${index}-1`}
                                                                               className="lb-chk">
                                                                            <span className="a11y">선택</span>
                                                                        </label>
                                                                    </td>
                                                                    <td className="padding-none">
                                                                        {
                                                                            item.array.map((item1, index1) => (
                                                                                <div className="table_inner_border">
                                                                                    <input type="checkbox"
                                                                                           checked={item1.is_checked}
                                                                                           id={`chkGoods${index}-${index1}`}
                                                                                           name={`chkGoodsGroup${index}-${index1}`}
                                                                                           onChange={(e) => selectOrderItem(e.target.checked, list[index].array[index1])}/>
                                                                                    <label
                                                                                        htmlFor={`chkGoods${index}-${index1}`}
                                                                                        className="lb-chk">
                                                                                        <span className="a11y">선택</span>
                                                                                    </label>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </td>
                                                                    <td className="padding-none">
                                                                        {
                                                                            item.array.map((item1, index1) => (
                                                                                <div className="table_inner_border">
                                                                                    {item1.goods_no}
                                                                                </div>

                                                                            ))
                                                                        }</td>
                                                                    <td className="padding-none">
                                                                        {
                                                                            item.array.map((item1, index1) => (
                                                                                <div className="table_inner_border">
                                                                                    {item1.storage_nm}
                                                                                </div>

                                                                            ))
                                                                        }</td>
                                                                    <td className="padding-none">

                                                                        {
                                                                            item.array.map((item1, index1) => (
                                                                                <div className="table_inner_border">
                                                                                    {addComma(item1.goods_t_mm)}
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </td>
                                                                    <td className="padding-none">

                                                                        {
                                                                            item.array.map((item1, index1) => (
                                                                                <div className="table_inner_border">
                                                                                    {addComma(item1.goods_w_mm)}
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </td>
                                                                    <td className="padding-none">

                                                                        {
                                                                            item.array.map((item1, index1) => (
                                                                                <div className="table_inner_border">
                                                                                    {addComma(item1.goods_len_mm)}
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </td>
                                                                    <td className="padding-none">

                                                                        {
                                                                            item.array.map((item1, index1) => (
                                                                                <div className="table_inner_border">
                                                                                    {addComma(item1.goods_wt)}
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </td>
                                                                    <td className="padding-none">

                                                                        {
                                                                            item.array.map((item1, index1) => (
                                                                                <div className="table_inner_border">
                                                                                    {item1.spec_abbsym}
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </td>
                                                                    <td className="padding-none">

                                                                        {
                                                                            item.array.map((item1, index1) => (
                                                                                <div className="table_inner_border">
                                                                                    {item1.cust_corp_code}
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </td>
                                                                    <td className="padding-none">

                                                                        {
                                                                            item.array.map((item1, index1) => (
                                                                                <div className="table_inner_border">
                                                                                    {item1.corp_nm}
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </td>
                                                                    <td className="padding-none">

                                                                        {
                                                                            item.array.map((item1, index1) => (
                                                                                <div className="table_inner_border">
                                                                                    {item1.dest_sigun_nm}
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </td>
                                                                    <td className="padding-none">

                                                                        {
                                                                            item.array.map((item1, index1) => (
                                                                                <div className="table_inner_border">
                                                                                    {item1.dest_phone}
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </td>

                                                                    <td className="padding-none">

                                                                        {
                                                                            item.array.map((item1, index1) => (
                                                                                <div className="table_inner_border">
                                                                                    {item1.auction_no}
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </td>
                                                                    <td className="padding-none">

                                                                        {
                                                                            item.array.map((item1, index1) => (
                                                                                <div className="table_inner_border">
                                                                                    {item1.out_order_dt}
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </td>

                                                                    {user.role === 'ADMIN' && (
                                                                        <>
                                                                            <td className="padding-none">

                                                                                {
                                                                                    item.array.map((item1, index1) => (
                                                                                        <div
                                                                                            className="table_inner_border">
                                                                                            {addComma(item1.freight_amount)}
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </td>
                                                                            <td className="padding-none">

                                                                                {
                                                                                    item.array.map((item1, index1) => (
                                                                                        <div
                                                                                            className="table_inner_border">
                                                                                            {addComma(item1.inbound_feright_amount)}
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </td>
                                                                        </>
                                                                    )}
                                                                </tr>
                                                            ))}


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </section>
                <Modal
                    dialogClassName="justify-content-center"
                    show={showFindCustomer}
                    size="lg"
                    onHide={() => setShowFindCustomer(false)}
                >
                    <Modal.Body style={{background: '#fff'}}>
                        <section className="main-area">
                            <article className="small-pop">
                                <div className="article-header">
                                    <h1 className="popup-header__tit"> 고객사 찾기</h1>
                                    <button type="button" className="btn-close"
                                            onClick={() => setShowFindCustomer(false)}><span
                                        className="a11y">팝업 닫기</span></button>
                                </div>
                                <div className="article-content">
                                    <div className="cont-area">
                                        <div className="cont-area__section">
                                            <article>
                                                <div className="article-content">
                                                    <div className="tbl-area">
                                                        <div className="table-wrap">
                                                            <table>
                                                                <colgroup>
                                                                    <col style={{width: '160px'}}/>
                                                                    <col/>
                                                                    <col style={{width: '160px'}}/>
                                                                    <col/>
                                                                </colgroup>
                                                                <tbody>
                                                                <tr>
                                                                    <th>고객사명</th>
                                                                    <td>
                                                                        <div className="form-area">
                                                                            <div className="form-item">
                                                                                <input type="text" className="inp"
                                                                                       value={queryData.corp_nm}
                                                                                       onChange={(e) => {
                                                                                           setQueryData({
                                                                                               ...queryData,
                                                                                               corp_nm: e.target.value
                                                                                           })
                                                                                       }}/>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <th>사업자번호</th>
                                                                    <td>
                                                                        <div className="form-area">
                                                                            <div className="form-item">
                                                                                <input type="text" className="inp"
                                                                                       value={queryData.business_number}
                                                                                       onChange={(e) => {
                                                                                           setQueryData({
                                                                                               ...queryData,
                                                                                               business_number: e.target.value
                                                                                           })
                                                                                       }}/>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                            <div className="btn-area gap-x40">
                                                <button onClick={() => {
                                                    setQueryData({
                                                        ...queryData,
                                                        corp_nm: '',
                                                        business_number: ''
                                                    });
                                                    setQueryResult([]);
                                                    setQuerySize(10);
                                                    setQueryTotalPage(0);
                                                }} className="btn-base type-secondary size-m">
                                                    <span>초기화</span>
                                                </button>
                                                <button onClick={() => searchCustomer()}
                                                        className="btn-base type-primary size-m grid-m">
                                                    <i className="icon24 icon-search-light"/>
                                                    <span>
                        검색
                      </span>
                                                </button>
                                            </div>
                                            <article className="space">
                                                <div className="article-content">
                                                    <div className="tbl-area">
                                                        <div className="tbl-upper">
                                                            <div className="tbl-upper__util">
                                                                <select className="selectbox type-light"
                                                                        onChange={(e) => setQuerySize(e.target.value)}>
                                                                    <option value='50'>50개씩</option>
                                                                    <option value='100'>100개씩</option>
                                                                    <option value='200'>200개씩</option>
                                                                    <option value='500'>500개씩</option>
                                                                    <option value='1000'>1000개씩</option>
                                                                    <option value='5000'>5000개씩</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="tbl-base type-hover">
                                                            <table>
                                                                <colgroup>
                                                                    <col style={{width: '100px'}}/>
                                                                    <col/>
                                                                    <col/>
                                                                </colgroup>
                                                                <thead>
                                                                <tr>
                                                                    <th>고객코드</th>
                                                                    <th>고객사명</th>
                                                                    <th>사업자번호</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    queryResult.map((item, index) => (
                                                                        <tr onClick={() => selectCustomer(item)}>
                                                                            <td>{item.cust_corp_code}</td>
                                                                            <td>{item.corp_nm}</td>
                                                                            <td>{item.business_num}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <Pagination pageCount={queryTotalPage}
                                                                paginate={(p) => setQueryPage(p.selected + 1)}></Pagination>
                                                </div>
                                            </article>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </section>
                    </Modal.Body>
                </Modal>
                {/* // [End] main-area */}
                <Footer/>
            </div>
        </>
    )
}

export default Kas0851
import {createSlice} from '@reduxjs/toolkit';

export const {actions: commonActions, reducer: commonReducers} = createSlice({
    name: 'common',
    initialState: {
        ongoing_auctions: [],
        pending_auctions: [],
    },
    reducers: {
        setOngoingAuctions: (state) => {
            state.ongoing_auctions = state;
        },
        setPendingAuctions: (state) => {
            state.pending_auctions = state;
        },

    }
});

export const {setOngoingAuctions, setPendingAuctions} = commonActions;

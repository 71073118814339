import React from 'react'
import {Navigate} from 'react-router-dom';

import Login from '../pages/login/Login'
import Settings from '../pages/settings/Settings'
import FindCustomer from '../pages/find_customer/find_customer';
import Kas_0850 from '../pages/kas-0850/kas-0850'
import SignUp from '../pages/kas-0104/sign-up'
import SignupCustomer from '../pages/kas-0105/signup-customer'
import Kas0801 from '../pages/kas-0801/kas-0801'
import Kas0851 from '../pages/kas-0851/kas0851'
import Kas0852 from '../pages/kas-0852/kas-0852'
import Kas0853 from '../pages/kas-0853/kas-0853'
import Kas0854 from '../pages/kas-0854/kas-0854'
import Kas0855 from '../pages/kas-0855/kas-0855'
import Kas0856 from '../pages/kas-0856/kas-0856'
import Kas0856_01 from '../pages/kas-0856_01/kas-0856_01'
import Kas0858 from '../pages/kas-0858/kas0858'
import Kas0000 from '../pages/kas-0000/kas-0000'
import Kas0500 from '../pages/kas-0500/kas-0500'
import Kas0502 from '../pages/kas-0502/kas-0502'
import Kas0506 from '../pages/kas-0506/kas-0506'
import Kas0507 from '../pages/kas-0507/kas-0507'
import Kas0600 from '../pages/kas-0600/kas-0600'
import Kas0601 from '../pages/kas-0601/kas-0601'
import Kas0602 from '../pages/kas-0602/kas-0602'
import Kas0603 from '../pages/kas-0603/kas-0603'
import Kas0604 from '../pages/kas-0604/kas-0604'
import Kas0605 from '../pages/kas-0605/kas-0605'
import Kas0607 from '../pages/kas-0607/kas0607'
import Kas0607_20 from '../pages/kas-0607_20/kas0607_20'
import Kas0502Edit from "../pages/kas-0502/kas-0502-Edit";
import Kas0800 from '../pages/kas-0800/kas0800'
import Kas0857 from '../pages/kas-0857/kas-0857'
import Kas0859 from '../pages/kas-0859/kas-0859'
import Kas1904 from '../pages/kas-1904/kas-1904'
import DestinationManagement from '../pages/kas-1905/destination-management'
import Kas1901 from "../pages/kas-1901/kas1901";
import Kas1902 from "../pages/kas-1902/kas1902";
import Kas1903 from "../pages/kas-1903/kas-1903";
import {ROLES} from "../helpers/roles";

const publicRoutes = [
    {path: "*", component: () => <Navigate to="/"/>},
    {path: "/login", component: <Login/>},
    {path: "/signup", component: <SignUp/>},
    {path: "/signup-customer", component: <SignupCustomer/>},
]

const privateRoutes = [
    {
        path: "*",
        component: () => <Navigate to="/"/>,
        permissions: [
            ROLES.ADMIN,
            ROLES.CUSTOMER,
        ]
    },
    {
        path: "/",
        component: <Kas0000/>,
        permissions: [
            ROLES.ADMIN,
            ROLES.CUSTOMER,
        ]
    },
    {
        path: "/find-customer",
        component: <FindCustomer/>,
        permissions: [
            ROLES.ADMIN,
        ]
    },
    // {path: "/kas-0000", component: <Kas0000/>},
    {
        path: "/kas-0500",
        component: <Kas0500/>,
        permissions: [
            ROLES.ADMIN,
            ROLES.CUSTOMER,
        ]
    },
    {
        path: "/kas-0502",
        component: <Kas0502/>,
        permissions: [
            ROLES.ADMIN,
            ROLES.CUSTOMER,
        ]
    },
    {
        path: "/kas-0502-edit/:auction_no",
        component: <Kas0502Edit/>,
        permissions: [
            ROLES.ADMIN,
            // ROLES.CUSTOMER,
        ]
    },
    {
        path: "/kas-0506",
        component: <Kas0506/>,
        permissions: [
            ROLES.ADMIN,
            // ROLES.CUSTOMER,
        ]
    },
    {
        path: "/kas-0507",
        component: <Kas0507/>,
        permissions: [
            ROLES.ADMIN,
        ]
    },
    {
        path: "/kas-0600",
        component: <Kas0600/>,
        permissions: [
            ROLES.ADMIN,
        ]
    },
    {
        path: "/kas-0601",
        component: <Kas0601/>,
        permissions: [
            ROLES.ADMIN,
            ROLES.CUSTOMER,
        ]
    },
    {
        path: "/kas-0602",
        component: <Kas0602/>,
        permissions: [
            ROLES.ADMIN,
        ]
    },
    {
        path: "/kas-0603",
        component: <Kas0603/>,
        permissions: [
            ROLES.ADMIN,
        ]
    },
    {
        path: "/kas-0604",
        component: <Kas0604/>,
        permissions: [
            ROLES.ADMIN,
        ]
    },
    // {
    //     path: "/kas-0605",
    //     component: <Kas0605/>,
    //     permissions: [
    //         ROLES.ADMIN,
    //         ROLES.CUSTOMER,
    //     ]
    // },
    {
        path: "/kas-0607",
        component: <Kas0607/>,
        permissions: [
            ROLES.ADMIN,
            ROLES.CUSTOMER,
        ]
    },
    {
        path: "/kas-0607_20",
        component: <Kas0607_20/>,
        permissions: [
            ROLES.ADMIN,
        ]
    },
    {
        path: "/kas-0800",
        component: <Kas0800/>,
        permissions: [
            ROLES.ADMIN,
            ROLES.CUSTOMER,
        ]
    },
    {
        path: "/kas-0850",
        component: <Kas_0850/>,
        permissions: [
            ROLES.ADMIN,
            ROLES.CUSTOMER,
        ]
    },
    {
        path: "/kas-0801",
        component: <Kas0801/>,
        permissions: [
            ROLES.ADMIN,
            ROLES.CUSTOMER,
        ]
    },
    {
        path: "/kas-0851",
        component: <Kas0851/>,
        permissions: [
            ROLES.ADMIN,
            ROLES.CUSTOMER,
        ]
    },
    {
        path: "/kas-0852",
        component: <Kas0852/>,
        permissions: [
            ROLES.ADMIN,
            ROLES.CUSTOMER,
        ]
    },
    {
        path: "/kas-0853",
        component: <Kas0853/>,
        permissions: [
            ROLES.ADMIN,
        ]
    },
    {
        path: "/kas-0854",
        component: <Kas0854/>,
        permissions: [
            ROLES.ADMIN,
        ]
    },
    {
        path: "/kas-0855",
        component: <Kas0855/>,
        permissions: [
            ROLES.ADMIN,
        ]
    },
    {
        path: "/kas-0856",
        component: <Kas0856/>,
        permissions: [
            ROLES.ADMIN,
        ]
    },
    {
        path: "/kas-0857",
        component: <Kas0857/>,
        permissions: [
            ROLES.ADMIN,
        ]
    },
    {
        path: "/kas-0856_01",
        component: <Kas0856_01/>,
        permissions: [
            ROLES.ADMIN,
            ROLES.CUSTOMER,
        ]
    },
    {
        path: "/kas-0858",
        component: <Kas0858/>,
        permissions: [
            ROLES.ADMIN,
        ]
    },
    {
        path: "/kas-0859",
        component: <Kas0859/>,
        permissions: [
            ROLES.ADMIN,
        ]
    },
    {
        path: "/kas-1901",
        component: <Kas1901/>,
        permissions: [
            ROLES.ADMIN,
            ROLES.CUSTOMER,
        ]
    },
    {
        path: "/kas-1902",
        component: <Kas1902/>,
        permissions: [
            ROLES.ADMIN,
            ROLES.CUSTOMER,
        ]
    },
    {
        path: "/kas-1902/:cust_dest_seq",
        component: <Kas1902/>,
        permissions: [
            ROLES.ADMIN,
            ROLES.CUSTOMER,
        ]
    },
    {
        path: "/kas-1903",
        component: <Kas1903/>,
        permissions: [
            ROLES.ADMIN,
        ]
    },
    {
        path: "/kas-1904",
        component: <Kas1904/>,
        permissions: [
            ROLES.ADMIN,
        ]
    },
    {
        path: "/kas-1905",
        component: <DestinationManagement/>,
        permissions: [
            ROLES.ADMIN,
        ]
    },
    {
        path: "/settings",
        component: <Settings/>,
        permissions: [
            ROLES.ADMIN,
        ]
    },

    // { path: "*", component: () => <Navigate to="/" /> },
]

export {publicRoutes, privateRoutes}
import React, {useEffect} from 'react'
import {Route, Routes, Navigate, Outlet, BrowserRouter, Switch, Redirect} from 'react-router-dom';
import {privateRoutes, publicRoutes} from './pages'

import {useSelector} from "react-redux";
import Login from "../pages/login/Login";
import Kas0601 from "../pages/kas-0601/kas-0601";
import SignUp from "../pages/kas-0104/sign-up";
import SignupCustomer from "../pages/kas-0105/signup-customer";
import {ROLES} from "../helpers/roles";
import Kas0000 from "../pages/kas-0000/kas-0000";

function createPrivateRoutes(user) {
    return privateRoutes.map((item, key) => {
        return (
            <Route key={key}>
                <Route path={item.path} element={item.component}/>
            </Route>
        );
    });
}

function createPublicRoutes() {
    return publicRoutes.map((item, key) => {
        return (
            <Route key={key}>
                <Route path={item.path} element={item.component}/>
            </Route>
        );
    });
}


export const ProtectedRoute = () => {
    const {token, user} = useSelector((store) => store.auth);
    console.log("Protected" + user);
    return token ? <Outlet/> : <Navigate to="/login"/>
};

export const PublicRoute = () => {
    const {token, user} = useSelector((store) => store.auth);
    console.log("Public" + user);
    if (token) {
        if (user.role === ROLES.ADMIN) {
            return <Navigate to="/"/>;
        } else {
            return <Navigate to="/kas-0601"/>;
        }
    }
    return <Outlet/>
};

const Index = () => {
    const {token, user} = useSelector((store) => store.auth);
    console.log("PrivateRoutes " + user);
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={
                        <ProtectedRoute/>
                    }>
                        {createPrivateRoutes(user)}
                    </Route>
                    <Route exact path="/login" element={<PublicRoute/>}>
                        <Route path="/login" element={<Login/>}/>
                    </Route>
                    <Route path="/signup" element={<PublicRoute/>}>
                        <Route path="/signup" element={<SignUp/>}/>
                    </Route>
                    <Route path="/signup-customer" element={<PublicRoute/>}>
                        <Route path="/signup-customer" element={<SignupCustomer/>}/>
                    </Route>

                </Routes>
            </BrowserRouter>
        </>
    )
}

export default Index;
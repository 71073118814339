import React, {useEffect, useState, useRef} from 'react'
import {DownloadTableExcel, useDownloadExcel} from 'react-export-table-to-excel';
import Header from '../../containers/header/Header1'
import Footer from '../../containers/footer/Footer'

import Background from '../../assets/img/menu-visual1.jpg'
import {useSelector} from "react-redux";
import {format} from "date-fns";
import {formatUnixTime, getPath, getUnixTimeByDate} from "../../helpers/helpers";
import useDidMountEffect from "../../helpers/useDidMountEffect";
import {store} from "../../store";
import {hideLoader, showLoader} from "../../store/loader/actions";
import axiosInstance from "../../helpers/axiosInstance";
import {toast} from "react-toastify";
import {addComma} from "../../Utils";
import Pagination from "../pagination/pagination";
import Modal from "react-bootstrap/Modal";

function Kas0800() {

    const tableRef = useRef(null);
    const {token, user} = useSelector((store) => store.auth);

    const [query, setQuery] = useState({
        'start_confirm_dt': '', // 주문일자
        'end_confirm_dt': '', // 주문일자
        'start_send_dt': '', // 확정전송일자
        'end_send_dt': '', // 확정전송일자
        'receipt_stat_cd': [], // 입고상태
        'order_stat_cd': [], // 주문상태
        'goods_no_arr': [], // OrderNo
        'cust_corp_nm': '',
        'cust_corp_code': '',
        'cust_corp_no': ''
    });

    const isMounted = useRef(false);


    const [startDateConfirmFm, setStartDateConfirmFm] = useState(null);
    const [endDateConfirmFm, setEndDateConfirmFm] = useState(null);
    const [startDateConfirm, setStartDateConfirm] = useState(null);
    const [endDateConfirm, setEndDateConfirm] = useState(null);

    const [startDateSendFm, setStartDateSendFm] = useState(null);
    const [endDateSendFm, setEndDateSendFm] = useState(null);
    const [startDateSend, setStartDateSend] = useState(null);
    const [endDateSend, setEndDateSend] = useState(null);


    const [isDateSendEnabled, setDataSendEnabled] = useState(false);

    const [orderStatCd1, setOrderStatCd1] = useState(false); //OSC11
    const [orderStatCd2, setOrderStatCd2] = useState(false); //OSC13

    const [receiptStatCd1, setReceiptStatCd1] = useState(false); //RSC01
    const [receiptStatCd2, setReceiptStatCd2] = useState(false); //RSC03
    const [receiptStatCd3, setReceiptStatCd3] = useState(false); //RSC05

    const [orderList, setOrderList] = useState([]);
    const [selectedAuction, setSelectedAuction] = useState(null);

    const [queryData, setQueryData] = useState({
        corp_nm: '', business_number: ''
    });
    const [queryResult, setQueryResult] = useState([]);
    const [queryPage, setQueryPage] = useState(1);
    const [querySize, setQuerySize] = useState(10);
    const [queryTotalPage, setQueryTotalPage] = useState(0);
    const [showFindCustomer, setShowFindCustomer] = useState(false);


    const [goodsList, setGoodsList] = useState([]);
    const [goodsTotalPage, setTotalPage] = useState(0);
    const [totalAmountGoods, setTotalAmountGoods] = useState(0);
    const [pageGoods, setPageGoods] = useState(1);
    const [sizeGoods, setSizeGoods] = useState(50);


    useEffect(() => {
        if (!isMounted.current) {
            let currentDate = new Date();
            let startOfDayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);
            setStartDateConfirmFm(format(startOfDayDate, 'yyyy-MM-dd'));
            setStartDateConfirm(getUnixTimeByDate(startOfDayDate));

            setStartDateSendFm(format(startOfDayDate, 'yyyy-MM-dd'));
            setStartDateSend(getUnixTimeByDate(startOfDayDate));
            let endOfDayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);
            setEndDateConfirm(getUnixTimeByDate(endOfDayDate))
            setEndDateConfirmFm(format(endOfDayDate, 'yyyy-MM-dd'))

            setEndDateSend(getUnixTimeByDate(endOfDayDate))
            setEndDateSendFm(format(endOfDayDate, 'yyyy-MM-dd'))

            isMounted.current = true;
        }
        if (user.role === 'CUSTOMER') {
            getCustomerInfo()
        }
    }, []);

    useDidMountEffect(() => {
        getAuctionGoodsList();
    }, [sizeGoods, pageGoods]);

    function onChangeOrderNo(e) {
        const lines = e.split(/\n/);
        let arrays = [];
        for (let i = 0; i < lines.length; i++) {
            if (/\S/.test(lines[i])) {
                arrays.push(lines[i].trim());
            }
        }
        setQuery({
            ...query, goods_no_arr: arrays
        })
    }

    const getCustomerInfo = async () => {
        try {
            store.dispatch(showLoader());
            const custCorpCode = user.cust_corp_code;
            const res = await axiosInstance.get('/dest/getCustInfo?custCorpCode=' + custCorpCode);
            console.log(res);
            if (res.code === 200) {
                setQuery({
                    ...query,
                    cust_corp_no: res.data.cust_corp_no,
                    cust_corp_code: res.data.cust_corp_code,
                    cust_corp_nm: res.data.corp_nm
                })
            }
            store.dispatch(hideLoader());
        } catch (e) {
            store.dispatch(hideLoader());
        }
    }

    useDidMountEffect(() => {
        searchCustomer();
    }, [querySize, queryPage]);

    const searchCustomer = async () => {
        let params;
        let filterParams = queryData;
        queryData.size = querySize;
        queryData.page = queryPage;
        params = getPath(filterParams);
        try {
            store.dispatch(showLoader());
            const response = await axiosInstance.get('/auth/searchCustomer' + params);
            console.log(response);
            if (response.code === 200) {
                toast.dismiss();
                toast.success(('Success'))
                setQueryResult(response.data.items);
                setQueryTotalPage(Number.parseInt(response.data.totalPage));
                store.dispatch(hideLoader());
            } else {
                store.dispatch(hideLoader());
                alert(response.message);
            }
        } catch (e) {
            store.dispatch(hideLoader());
        }
    }

    const searchQuery = async () => {
        setSelectedAuction(null);
        let params = "";
        let filterParams = query;
        filterParams.start_confirm_dt = startDateConfirm;
        filterParams.end_confirm_dt = endDateConfirm;
        if (isDateSendEnabled) {
            filterParams.start_send_dt = startDateSend
            filterParams.end_send_dt = endDateSend
        }
        let orderStatCdList = [];
        if (orderStatCd1) {
            orderStatCdList.push("OSC11");
        }
        if (orderStatCd2) {
            orderStatCdList.push("OSC13");
        }

        let receiptStatCdList = [];
        if (receiptStatCd1) {
            receiptStatCdList.push("RSC01")
        }
        if (receiptStatCd2) {
            receiptStatCdList.push("RSC03")
        }
        if (receiptStatCd3) {
            receiptStatCdList.push("RSC05")
        }
        filterParams.order_stat_cd = orderStatCdList;
        filterParams.receipt_stat_cd = receiptStatCdList;
        if (Object.entries(filterParams).length > 0) {
            params = getPath(filterParams);
        }
        try {
            store.dispatch(showLoader());
            const response = await axiosInstance.get('/order/list' + params);
            console.log(response);
            if (response.code === 200) {
                let listOfObjs = [];
                for (let i = 0; i < response.data.items.length; i++) {
                    let item = response.data.items[i];
                    item.is_checked = false;
                    listOfObjs.push(item);
                }
                setOrderList(listOfObjs);
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }

    }

    function selectGoodsItem(e, item) {
        let listItems = [...goodsList];
        if (e) {
            setTotalAmountGoods(totalAmountGoods + Number(item.goods_wt))
        }
        if (!e) {
            setTotalAmountGoods(totalAmountGoods - Number(item.goods_wt))
        }
        for (let i = 0; i < listItems.length; i++) {
            if (listItems[i].goods_no === item.goods_no) {
                listItems[i].is_checked = e;
            }
        }
        setGoodsList(listItems);
    }

    function checkAllGoods(e) {
        let listItems = [...goodsList];
        let totalAmount = 0
        for (let i = 0; i < goodsList.length; i++) {
            let item = goodsList[i];
            totalAmount += Number(item.goods_wt);
        }
        setTotalAmountGoods(e ? totalAmount : 0);
        for (let i = 0; i < listItems.length; i++) {
            listItems[i].is_checked = e;
        }
        setGoodsList(listItems);
    }

    useEffect(() => {
        if (selectedAuction !== null) {
            getAuctionGoodsList();
        }
    }, [selectedAuction]);

    async function getAuctionGoodsList() {
        setTotalAmountGoods(0);
        let params = "";
        let filterParams = {};
        filterParams.cust_corp_no = selectedAuction.cust_corp_no;
        filterParams.dest_addr = selectedAuction.dest_addr;
        filterParams.dest_sigun_cd = selectedAuction.dest_sigun_cd;
        filterParams.storage_div_cd = selectedAuction.storage_div_cd;
        filterParams.auction_no = selectedAuction.auction_no;
        filterParams.start_confirm_dt = startDateConfirm;
        filterParams.end_confirm_dt = endDateConfirm;
        if (isDateSendEnabled) {
            filterParams.start_send_dt = startDateSend
            filterParams.end_send_dt = endDateSend
        }
        filterParams.page = pageGoods;
        filterParams.size = sizeGoods;
        if (Object.entries(filterParams).length > 0) {
            params = getPath(filterParams);
        }
        console.log(params)
        store.dispatch(showLoader());
        try {
            const response = await axiosInstance.get('/order/goodsList' + params);
            if (response.code === 200) {
                let listOfObjs = [];
                for (let i = 0; i < response.data.items.length; i++) {
                    let item = response.data.items[i];
                    item.is_checked = false;
                    listOfObjs.push(item);
                }
                setGoodsList(listOfObjs);
                console.log(response.data.totalPage)
                setTotalPage(Number.parseInt(response.data.totalPage));
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }

    const selectCustomer = (item) => {
        console.log(item);
        setQuery({
            ...query,
            cust_corp_code: item.cust_corp_code,
            business_num: item.business_num,
            cust_corp_no: item.cust_corp_no,
            cust_corp_nm: item.corp_nm
        })
        setShowFindCustomer(false);
    }

    const clearCheckOrders = () => {
        let listItems = [...orderList];
        for (let i = 0; i < listItems.length; i++) {
            listItems[i].is_checked = false
        }
        setOrderList(listItems);
    }

    const cancelOrder = async () => {
        let listOfObjs = [];
        for (let i = 0; i < goodsList.length; i++) {
            if (goodsList[i].is_checked) {
                listOfObjs.push(goodsList[i].ord_no);
            }
        }
        if (listOfObjs.length === 0) {
            alert("Please select items")
            return;
        }
        console.log(listOfObjs);
        const payload = {
            order_no_arr: listOfObjs
        }
        console.log(payload)
        store.dispatch(showLoader());
        try {
            const response = await axiosInstance.post('/order/cancel', payload);
            if (response.code === 200) {
                toast.dismiss();
                toast.success(('Success'));
                await clearCheckOrders();
                await searchQuery();
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }
    const updateOrderReceipt = async () => {
        let listOfObjs = [];
        for (let i = 0; i < goodsList.length; i++) {
            if (goodsList[i].is_checked) {
                listOfObjs.push(goodsList[i].ord_no);
            }
        }
        if (listOfObjs.length === 0) {
            alert("Please select items")
            return;
        }
        console.log(listOfObjs);
        const payload = {
            order_no_arr: listOfObjs
        }
        store.dispatch(showLoader());
        try {
            const response = await axiosInstance.post('/order/updateOrderReceipt', payload);
            if (response.code === 200) {
                toast.dismiss();
                toast.success(('Success'));
                await clearCheckOrders();
                // await searchQuery();
                await getAuctionGoodsList();
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }

    const cancelOrderReceipt = async () => {
        let listOfObjs = [];
        for (let i = 0; i < goodsList.length; i++) {
            if (goodsList[i].is_checked) {
                listOfObjs.push(goodsList[i].ord_no);
            }
        }
        if (listOfObjs.length === 0) {
            alert("Please select items")
            return;
        }
        console.log(listOfObjs);
        const payload = {
            order_no_arr: listOfObjs
        }
        store.dispatch(showLoader());
        try {
            const response = await axiosInstance.post('/order/updateOrderReceipt', payload);
            if (response.code === 200) {
                toast.dismiss();
                toast.success(('Success'));
                await clearCheckOrders();
                // await searchQuery();
                await getAuctionGoodsList();
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }

    function selectOrderItem(e, item) {
        let listItems = [...orderList];
        for (let i = 0; i < listItems.length; i++) {
            if (listItems[i].ord_no === item.ord_no) {
                listItems[i].is_checked = e;
            }
        }
        setOrderList(listItems);
    }

    const sendSuccessfulBidTest = async () => {
        let listOfObjs = [];
        for (let i = 0; i < goodsList.length; i++) {
            if (goodsList[i].is_checked) {
                listOfObjs.push(goodsList[i].ord_no);
            }
        }
        if (listOfObjs.length === 0) {
            alert("Please select items")
            return;
        }
        console.log(listOfObjs);
        const payload = {
            order_no_arr: listOfObjs
        }
        store.dispatch(showLoader());
        try {
            const response = await axiosInstance.post('/order/sendSuccessfulBidTest', payload);
            if (response.code === 200) {
                toast.dismiss();
                toast.success(('Success'));
                await clearCheckOrders();
                // await searchQuery();
                await getAuctionGoodsList();
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }

    const sendSuccessfulBid = async (isOrderList) => {
        let listOfObjs = [];
        if (isOrderList) {
            for (let i = 0; i < orderList.length; i++) {
                if (orderList[i].is_checked) {
                    listOfObjs.push(orderList[i].ord_no);
                }
            }
        } else {
            for (let i = 0; i < goodsList.length; i++) {
                if (goodsList[i].is_checked) {
                    listOfObjs.push(goodsList[i].ord_no);
                }
            }
        }
        if (listOfObjs.length === 0) {
            alert("Please select items")
            return;
        }
        console.log(listOfObjs);
        const payload = {
            order_no_arr: listOfObjs
        }
        store.dispatch(showLoader());
        try {
            const response = await axiosInstance.post('/order/sendSuccessfulBid', payload);
            if (response.code === 200) {
                if (response.data.OUT_DATA) {
                    if (response.data.OUT_DATA.E_IFRESULT !== 'S') {
                        alert(response.data.OUT_DATA.E_IFFILMSG);
                    } else {
                        toast.dismiss();
                        toast.success(('Success'));
                        await clearCheckOrders();
                        // await searchQuery();
                        await getAuctionGoodsList();
                    }
                }
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }

    return (<>
        <div id="wrap">
            <Header/>
            {/* [Start] main-area */}
            <section className="main-area" id="main">
                <div className="menu-visual" style={{backgroundImage: `url(${Background})`}}>
                    <h2 className="menu-visual__tit">
                        주문관리
                    </h2>
                    <div className="breadcrumbs-area">
                        <ol>
                            <li className="breadcrumbs-area__step breadcrumbs-area__step--root">
                                <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                                    <i className="icon24 icon-home"/>
                                    <span className="a11y">홈으로</span>
                                </a>
                            </li>
                            <li className="breadcrumbs-area__step breadcrumbs-area__step--more"> {/* [D] sub menu 있을 시, breadcrumbs-area__step--more 클래스 추가 */}
                                <a href="javascript:void(0);" className="breadcrumbs-area__link">
                                    <span>시스템관리</span>
                                </a>
                                <ul className="breadcrumbs-area__sub-menu">
                                    <li>
                                        <a href="/manageProduct1.html">시스템관리 1</a>
                                    </li>
                                    <li>
                                        <a href="/manageProduct1.html">시스템관리 2</a>
                                    </li>
                                    <li>
                                        <a href="/manageProduct1.html">시스템관리 3</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="breadcrumbs-area__step">
                                <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                                    <span>주문관리</span>
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div className="cont-area">
                    <div className="cont-area__grid">
                        <div className="cont-area__section">
                            <article>
                                <div className="article-content">
                                    <div className="tbl-area">
                                        <div className="table-wrap">
                                            <table>
                                                <colgroup>
                                                    <col style={{width: '160px'}}/>
                                                    <col/>
                                                    <col style={{width: '160px'}}/>
                                                    <col/>
                                                    <col style={{width: '160px'}}/>
                                                    <col/>
                                                </colgroup>
                                                <tbody>
                                                <tr>
                                                    <th>주문일자</th>
                                                    <td>
                                                        <div className="form-area">
                                                            <div className="form-item type-range">
                                                                <input type="date" className="inp type-date"
                                                                       defaultValue={startDateConfirmFm}
                                                                       onChange={(e) => setStartDateConfirm(getUnixTimeByDate(new Date(e.target.value)))}/>
                                                                <span className="form-division">~</span>
                                                                <input type="date" className="inp type-date"
                                                                       defaultValue={endDateConfirmFm}
                                                                       onChange={(e) => setEndDateConfirm(getUnixTimeByDate(new Date(e.target.value)))}/>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <th>확정전송일자</th>
                                                    <td>
                                                        <div className="form-area">
                                                            <div className="form-item type-range">

                                                                <input type="date" className="inp type-date"
                                                                       disabled={!isDateSendEnabled}
                                                                       defaultValue={startDateSendFm}
                                                                       onChange={(e) => setStartDateSend(getUnixTimeByDate(new Date(e.target.value)))}/>
                                                                <span className="form-division">~</span>
                                                                <input type="date" className="inp type-date"
                                                                       defaultValue={endDateSendFm}
                                                                       disabled={!isDateSendEnabled}
                                                                       onChange={(e) => setEndDateSend(getUnixTimeByDate(new Date(e.target.value)))}/>
                                                                <input type="checkbox" id="check33"
                                                                       checked={isDateSendEnabled}
                                                                       onChange={(e) => {
                                                                           setDataSendEnabled(e.target.checked)
                                                                       }}/>
                                                                <label htmlFor="check33" className="lb-chk">
                                                                    <span>확정전송일자 여부</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <th>주문상태</th>
                                                    <td>
                                                        <div className="form-area">
                                                            <div className="form-area">
                                                                <div className="check-box">
                                                                    <input type="checkbox" id="check3"
                                                                           onChange={(e) => setOrderStatCd1(e.target.checked)}/>
                                                                    <label htmlFor="check3" className="lb-chk">
                                                                        <span>입금확인</span>
                                                                    </label>
                                                                    <input type="checkbox" id="check4"
                                                                           onChange={(e) => setOrderStatCd2(e.target.checked)}/>
                                                                    <label htmlFor="check4" className="lb-chk"
                                                                    >
                                                                        <span>확정전송</span>
                                                                    </label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </td>


                                                </tr>

                                                <tr>
                                                    <th rowSpan={3} className="border-0">제품번호</th>
                                                    <td rowSpan={3} className="border-0">
                                                        <div className="product-num">
                                                            <div className="item">
                                                                <div className="form-area">
                                                                    <div className="form-item">
                                                                            <textarea className="inp"
                                                                                      onChange={(e) => onChangeOrderNo(e.target.value)}
                                                                                      defaultValue={""}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="summary">
                                                                <p>
                                                                    *주문번호, 구분은 , 또는 엔터로 구분<br/><br/>
                                                                    예) A3022,A0033
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <th>고객코드/고객명</th>
                                                    {user.role === 'ADMIN' ? (<td>
                                                        <div className="form-area">
                                                            <div className="form-item col2 overlap-check">
                                                                <input
                                                                    type="text"
                                                                    className="inp"
                                                                    value={query.cust_corp_code}
                                                                    disabled
                                                                />
                                                                <input
                                                                    type="text"
                                                                    className="inp"
                                                                    value={query.cust_corp_nm}
                                                                    disabled
                                                                />
                                                                <button onClick={() => setShowFindCustomer(true)}
                                                                        type="button" className="btn-check_001">
                                                                    찾기
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>) : (<td>
                                                        <div className="form-area">
                                                            <div className="form-item col2">
                                                                <input id className="inp" value={query.cust_corp_code}
                                                                       disabled/>
                                                                <input id className="inp" value={query.cust_corp_nm}
                                                                       disabled/>
                                                            </div>
                                                        </div>
                                                    </td>)}
                                                    <th>입고상태</th>
                                                    <td>
                                                        <div className="form-area">
                                                            <div className="form-area">
                                                                <div className="check-box">
                                                                    <input type="checkbox" id="check5"
                                                                           onChange={(e) => setReceiptStatCd1(e.target.checked)}/>
                                                                    <label htmlFor="check5" className="lb-chk">
                                                                        <span>입고요청</span>
                                                                    </label>
                                                                    <input type="checkbox" id="check6"
                                                                           onChange={(e) => setReceiptStatCd2(e.target.checked)}/>
                                                                    <label htmlFor="check6" className="lb-chk"
                                                                    >
                                                                        <span>입고대기</span>
                                                                    </label>
                                                                    <input type="checkbox" id="check7"
                                                                           onChange={(e) => setReceiptStatCd3(e.target.checked)}/>
                                                                    <label htmlFor="check7" className="lb-chk"
                                                                    >
                                                                        <span>입고확청</span>
                                                                    </label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <div className="btn-area gap-x40">
                                <a href="#" className="btn-base type-secondary size-m">
                                    <span>초기화</span>
                                </a>
                                <button onClick={() => searchQuery()}
                                        className="btn-base type-primary size-m grid-m">
                                    <i className="icon24 icon-search-light"/>
                                    <span>
                      조회
                    </span>
                                </button>
                            </div>
                            <article className="space">
                                <div className="aticle-content">
                                    <div className="tbl-area">
                                        <div className="tbl-upper">
                                            {/*<div className="tbl-upper__cont">*/}
                                            {/*    {user.role === 'ADMIN' && (*/}
                                            {/*        <>*/}
                                            {/*            <button onClick={() => cancelOrder()} type="button"*/}
                                            {/*                    className="btn-round-util"*/}
                                            {/*            >*/}
                                            {/*                <span>주문취소</span>*/}
                                            {/*            </button>*/}
                                            {/*            <button type="button"*/}
                                            {/*                    className="btn-round-util"*/}
                                            {/*                    onClick={() => sendSuccessfulBid(true)}>*/}
                                            {/*                <span>확정전송</span>*/}
                                            {/*            </button>*/}
                                            {/*            <button onClick={() => updateOrderReceipt()} type="button"*/}
                                            {/*                    className="btn-round-util">*/}
                                            {/*                <span>입고확정</span>*/}
                                            {/*            </button>*/}
                                            {/*        </>*/}
                                            {/*    )}*/}
                                            {/*</div>*/}
                                        </div>
                                        <div className="tbl-base">
                                            <table>
                                                <colgroup>
                                                    <col/>
                                                    <col/>
                                                    <col/>
                                                    <col/>
                                                    <col/>
                                                    <col/>
                                                    <col/>
                                                    <col/>
                                                    <col/>
                                                </colgroup>
                                                <thead>
                                                <tr>
                                                    <th>경매번호</th>
                                                    <th>주문상태</th>
                                                    <th>고객사명</th>
                                                    <th>출발지</th>
                                                    <th>목적지</th>
                                                    <th>제품수량</th>
                                                    <th>중량</th>
                                                    <th>제품금액</th>
                                                    <th>운임비</th>
                                                    <th>입금요청액</th>
                                                    <th>주소</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    orderList.map((item, index) => (
                                                        <tr>
                                                            <td><a href="#!" onClick={() => {
                                                                setSelectedAuction(item);
                                                            }}>{item.auction_no}</a></td>
                                                            <td>{item.order_stat_cd_nm}</td>
                                                            <td>{item.corp_nm}</td>
                                                            <td>{item.storage_nm}</td>
                                                            <td>{item.dest_sigun_nm}</td>
                                                            <td>{addComma(item.goods_cnt)}</td>
                                                            <td>{addComma(item.goods_wt)}</td>
                                                            <td>{addComma(item.order_amount)}</td>
                                                            <td>{addComma(item.freight_amount)}</td>
                                                            <td>{addComma(item.amount)}</td>
                                                            <td>{item.dest_addr}</td>
                                                        </tr>
                                                    ))
                                                }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <article className="space">
                                <div className="article-content">
                                    <div className="tbl-area">
                                        <div className="tbl-upper">
                                            <div className="tbl-upper__cont">
                                                {user.role === 'ADMIN' && (
                                                    <>
                                                        <button onClick={() => cancelOrder()} type="button"
                                                                className="btn-round-util"
                                                        >
                                                            <span>주문취소</span>
                                                        </button>
                                                        <button type="button"
                                                                className="btn-round-util"
                                                        >
                                                            <span>입금취소</span>
                                                        </button>
                                                        <button type="button"
                                                                className="btn-round-util"
                                                                onClick={() => sendSuccessfulBid(false)}>
                                                            <span>확정전송</span>
                                                        </button>
                                                        <button onClick={() => updateOrderReceipt()} type="button"
                                                                className="btn-round-util">
                                                            <span>입고확정</span>
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                            <div className="tbl-upper__util">
                                                <strong className="tbl-area__tit">
                                                    총 중량(KG) {addComma(totalAmountGoods)}
                                                </strong>
                                                <DownloadTableExcel filename="kasco" sheet="users"
                                                                    currentTableRef={tableRef.current}>
                                                    <a href="#" className="btn-base type-dark">
                                                        <span>다운로드</span>
                                                    </a>
                                                </DownloadTableExcel>
                                                <select className="selectbox type-light"
                                                        onChange={(e) => setSizeGoods(e.target.value)}>
                                                    <option value='50'>50개씩</option>
                                                    <option value='100'>100개씩</option>
                                                    <option value='200'>200개씩</option>
                                                    <option value='500'>500개씩</option>
                                                    <option value='1000'>1000개씩</option>
                                                    <option value='5000'>5000개씩</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="tbl-base type-hover">
                                            <table className="table-product" ref={tableRef}>
                                                <caption>
                                                    정보 테이블
                                                </caption>
                                                <colgroup>
                                                    <col/>
                                                    <col/>
                                                    <col/>
                                                    <col/>
                                                    <col/>
                                                    <col/>
                                                    <col/>
                                                    <col/>
                                                    <col/>
                                                    <col/>
                                                    <col/>
                                                    <col/>
                                                    <col/>
                                                    <col/>
                                                    <col/>
                                                    <col/>
                                                    <col/>
                                                    <col/>
                                                </colgroup>
                                                <thead>
                                                <tr>
                                                    <th scope="col">
                                                        <input type="checkbox" id="chk2-all"
                                                               name="chk2Group"
                                                               onChange={(e) => checkAllGoods(e.target.checked)}
                                                        />
                                                        <label htmlFor="chk2-all" className="lb-chk">
                                                            <span className="a11y">전체선택</span></label>
                                                    </th>
                                                    <th scope="col">번호</th>
                                                    <th scope="col">주문번호</th>
                                                    <th scope="col">주문상태</th>
                                                    <th scope="col">확정전송일자</th>
                                                    <th scope="col">제품번호</th>
                                                    <th scope="col">두께</th>
                                                    <th scope="col">폭</th>
                                                    <th scope="col">길이</th>
                                                    <th scope="col">중량</th>
                                                    <th scope="col">규격약호</th>
                                                    <th scope="col">목적지</th>
                                                    <th scope="col">도착지전화번호</th>
                                                    <th scope="col">경매번호</th>
                                                    <th scope="col">입고상태</th>
                                                    <th scope="col">제품금액</th>
                                                    <th scope="col">운임비</th>
                                                    <th scope="col">총합계</th>

                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    goodsList.map((item, index) => (
                                                        <tr>
                                                            <td>
                                                                <input type="checkbox"
                                                                       checked={item.is_checked}
                                                                       id={`chkGoods${index}-1`}
                                                                       name="chkGoodsGroup"
                                                                       onChange={(e) => selectGoodsItem(e.target.checked, item)}/>
                                                                <label htmlFor={`chkGoods${index}-1`}
                                                                       className="lb-chk">
                                                                    <span className="a11y">선택</span>
                                                                </label>
                                                            </td>
                                                            <td>{goodsList.length - index}</td>
                                                            <td><a href>{item.ord_no}</a></td>
                                                            <td>{item.order_stat_nm}</td>
                                                            <td>{formatUnixTime(item.award_send_dt)}</td>
                                                            <td>{item.goods_no}</td>
                                                            <td>{item.goods_t_mm}</td>
                                                            <td>{item.goods_w_mm}</td>
                                                            <td>{item.goods_len_mm}</td>
                                                            <td>{item.goods_wt}</td>
                                                            <td>{item.spec_abbsym}</td>
                                                            <td>{item.dest_sigun_nm}</td>
                                                            <td>{item.dest_phone}</td>
                                                            <td>{item.auction_no}</td>
                                                            <td>{item.receipt_stat_cd_nm}</td>
                                                            <td>{addComma(item.order_amount)}</td>
                                                            <td>{addComma(item.freight_amount)}</td>
                                                            <td>{addComma(item.amount)}</td>
                                                        </tr>
                                                    ))
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <Pagination pageCount={goodsTotalPage}
                                        paginate={(p) => setPageGoods(p.selected + 1)}></Pagination>
                            <div className="tbl-footer">
                                <div className="tbl-footer__cont">
                                    <strong className="tbl-footer__tit">총 {goodsList.length}건</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal
                dialogClassName="justify-content-center"
                show={showFindCustomer}
                size="lg"
                onHide={() => setShowFindCustomer(false)}
            >
                <Modal.Body style={{background: '#fff'}}>
                    <section className="main-area">
                        <article className="small-pop">
                            <div className="article-header">
                                <h1 className="popup-header__tit"> 고객사 찾기</h1>
                                <button type="button" className="btn-close"
                                        onClick={() => setShowFindCustomer(false)}><span
                                    className="a11y">팝업 닫기</span></button>
                            </div>
                            <div className="article-content">
                                <div className="cont-area">
                                    <div className="cont-area__section">
                                        <article>
                                            <div className="article-content">
                                                <div className="tbl-area">
                                                    <div className="table-wrap">
                                                        <table>
                                                            <colgroup>
                                                                <col style={{width: '160px'}}/>
                                                                <col/>
                                                                <col style={{width: '160px'}}/>
                                                                <col/>
                                                            </colgroup>
                                                            <tbody>
                                                            <tr>
                                                                <th>고객사명</th>
                                                                <td>
                                                                    <div className="form-area">
                                                                        <div className="form-item">
                                                                            <input type="text" className="inp"
                                                                                   value={queryData.corp_nm}
                                                                                   onChange={(e) => {
                                                                                       setQueryData({
                                                                                           ...queryData,
                                                                                           corp_nm: e.target.value
                                                                                       })
                                                                                   }}/>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <th>사업자번호</th>
                                                                <td>
                                                                    <div className="form-area">
                                                                        <div className="form-item">
                                                                            <input type="text" className="inp"
                                                                                   value={queryData.business_number}
                                                                                   onChange={(e) => {
                                                                                       setQueryData({
                                                                                           ...queryData,
                                                                                           business_number: e.target.value
                                                                                       })
                                                                                   }}/>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                        <div className="btn-area gap-x40">
                                            <button onClick={() => {
                                                setQueryData({
                                                    ...queryData,
                                                    corp_nm: '',
                                                    business_number: ''
                                                });
                                                setQueryResult([]);
                                                setQuerySize(10);
                                                setQueryTotalPage(0);
                                            }} className="btn-base type-secondary size-m">
                                                <span>초기화</span>
                                            </button>
                                            <button onClick={() => searchCustomer()}
                                                    className="btn-base type-primary size-m grid-m">
                                                <i className="icon24 icon-search-light"/>
                                                <span>
                        검색
                      </span>
                                            </button>
                                        </div>
                                        <article className="space">
                                            <div className="article-content">
                                                <div className="tbl-area">
                                                    <div className="tbl-upper">
                                                        <div className="tbl-upper__util">
                                                            <select className="selectbox type-light"
                                                                    onChange={(e) => setQuerySize(e.target.value)}>
                                                                <option value='50'>50개씩</option>
                                                                <option value='100'>100개씩</option>
                                                                <option value='200'>200개씩</option>
                                                                <option value='500'>500개씩</option>
                                                                <option value='1000'>1000개씩</option>
                                                                <option value='5000'>5000개씩</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="tbl-base type-hover">
                                                        <table>
                                                            <colgroup>
                                                                <col style={{width: '100px'}}/>
                                                                <col/>
                                                                <col/>
                                                            </colgroup>
                                                            <thead>
                                                            <tr>
                                                                <th>고객코드</th>
                                                                <th>고객사명</th>
                                                                <th>사업자번호</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                queryResult.map((item, index) => (
                                                                    <tr onClick={() => selectCustomer(item)}>
                                                                        <td>{item.cust_corp_code}</td>
                                                                        <td>{item.corp_nm}</td>
                                                                        <td>{item.business_num}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <Pagination pageCount={queryTotalPage}
                                                            paginate={(p) => setQueryPage(p.selected + 1)}></Pagination>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </section>
                </Modal.Body>
            </Modal>
            {/* // [End] main-area */}
            <Footer/>
        </div>
    </>)
}

export default Kas0800
// var {forge} = require('node-forge');
import {sha256} from "js-sha256"

export async function sha256Message(message) {
    const hash = sha256.create();
    hash.update(message);
    return hash.hex();
}

export function addComma(x) {
    if (x === undefined || x === null) {
        return "";
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function isValidValue(x) {
    return x !== undefined && x !== null;
}

export function isValidValueOrEmpty(x) {
    return isValidValue(x) && x !== '';
}
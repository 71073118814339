import React from 'react'

import ImgNone from '../../assets/img/img_none.png'

function kas0857() {
  return (
    <>
      <div id="wrap" className="popup-area">
        <section className="main-area">
          <article className="small-pop">
            <div className="article-header">
              <h1 className="popup-header__tit">수취서 출력</h1>
              <button type="button" className="btn-close" onclick="window.close();"><span className="a11y">팝업 닫기</span></button>
            </div>
            <div className="article-content">
              <figure>
                <img src={ImgNone} alt="" />
              </figure>
            </div>
          </article>
        </section>
      </div>
    </>
  )
}

export default kas0857
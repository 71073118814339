import React, {useState, useEffect, useRef} from 'react'

import {formatMoney, formatUnixTime, getPath, getUnixTimeByDate, todayDate} from "../../helpers/helpers";
import axiosInstance from "../../helpers/axiosInstance";
import {downloadExcel, DownloadTableExcel, useDownloadExcel} from 'react-export-table-to-excel';

import Header from '../../containers/header/Header1'
import Footer from '../../containers/footer/Footer'

import Background from '../../assets/img/menu-visual1.jpg'
import {format} from "date-fns";
import {useDispatch} from "react-redux";
import {store} from "../../store";
import {hideLoader, showLoader} from "../../store/loader/actions";
import {toast} from "react-toastify";
import {addComma} from "../../Utils";
import useDidMountEffect from "../../helpers/useDidMountEffect";
import Pagination from '../pagination/pagination';
import Modal from "react-bootstrap/Modal";
import {Link} from "react-router-dom";


function Kas0500() {
    const tableRef = useRef(null);
    const tableRef1 = useRef(null);
    const [filter, setFilter] = useState({
        selling_type: '',
        auction_status: '',
        size: 50,
        page: 1
    });

    const [filterGoods, setFilterGoods] = useState({
        sale_div_cd: '',
        storage_div_cd: '',
        sale_type_cd: '',
        goods_no_arr: '',
        auction_start_price_from: '',
        auction_start_price_to: '',
        sale_price_from: '',
        sale_price_to: '',
        goods_div_cd: '',
        spart: '',
        goods_grade_cd: '',
        spec_abbsym: '',
        goods_t_mm_from: '',
        goods_t_mm_to: '',
        goods_w_mm_from: '',
        goods_w_mm_to: '',
        goods_len_mm_from: '',
        goods_len_mm_to: '',
        size: 50,
        page: 1
    });

    const [list, setList] = useState([]);
    const [startDateFm, setStartDateFm] = useState(null);
    const [endDateFm, setEndDateFm] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalAmountGoods, setTotalAmountGoods] = useState(0);
    const [isCheckedAll, setCheckedAll] = useState(false);
    const [isCheckedAllGoods, setCheckedAllGoods] = useState(false);
    const [auctionStartPrice, setAuctionStartPrice] = useState(0);
    const [auctionGoodsStartPrice, setAuctionGoodsStartPrice] = useState(0);
    const [selectedAuction, setSelectedAuction] = useState(null);
    const isMounted = useRef(false);

    const [pageAuction, setPageAuction] = useState(1);
    const [sizeAuction, setSizeAuction] = useState(50);
    const [auctionTotalPage, setAuctionTotalPage] = useState(0);
    const [pageGoods, setPageGoods] = useState(1);
    const [sizeGoods, setSizeGoods] = useState(50);
    const [goodsTotalPage, setTotalPage] = useState(0);


    const [showAuctionDelete, setShowAuctionDelete] = useState(false);
    const [showAuctionGoodsDelete, setShowAuctionGoodsDelete] = useState(false);


    const [grade1Checked, setGrade1Checked] = useState(false);
    const [preferTGb1Checked, setPreferTGb1Checked] = useState(false);
    const [grade2Checked, setGrade2Checked] = useState(false);
    const [preferTGb2Checked, setPreferTGb2Checked] = useState(false);
    const goodsGradeCd = useRef('');
    const preferTGb = useRef('');

    const [goodsList, setGoodsList] = useState([]);


    useDidMountEffect(() => {
        getList();
    }, [sizeAuction, pageAuction]);

    useDidMountEffect(() => {

        getAuctionGoodsList();
    }, [sizeGoods, pageGoods]);

    // useDidMountEffect(() => {
    //     getAuctionGoodsList();
    // }, [pageGoods]);


    useEffect(() => {
        if (!isMounted.current) {
            let currentDate = new Date();
            let startOfDayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);
            setStartDateFm(format(startOfDayDate, 'yyyy-MM-dd'))
            setStartDate(getUnixTimeByDate(startOfDayDate))
            let endOfDayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);
            setEndDate(getUnixTimeByDate(endOfDayDate))
            setEndDateFm(format(endOfDayDate, 'yyyy-MM-dd'))
            isMounted.current = true;
        }
    }, []);

    const checkGradeCd1 = (e) => {
        if (e.target.checked) {
            setGrade1Checked(true);
            goodsGradeCd.current = '1';
            if (grade2Checked) {
                setGrade2Checked(false);
            }
        } else {
            setGrade1Checked(false);
            goodsGradeCd.current = '';
        }
    }
    const checkGradeCd2 = (e) => {
        if (e.target.checked) {
            setGrade2Checked(true);
            goodsGradeCd.current = '2';
            if (grade1Checked) {
                setGrade1Checked(false);
            }
        } else {
            setGrade2Checked(false);
            goodsGradeCd.current = '';
        }
    }

    const checkPreferTGbCd1 = (e) => {
        if (e.target.checked) {
            setPreferTGb1Checked(true);
            preferTGb.current = 'Y';
            if (preferTGb2Checked) {
                setPreferTGb2Checked(false);
            }
        } else {
            setPreferTGb1Checked(false);
            preferTGb.current = '';
        }
    }
    const checkPreferTGbCd2 = (e) => {
        if (e.target.checked) {
            setPreferTGb2Checked(true);
            preferTGb.current = 'N';
            if (preferTGb1Checked) {
                setPreferTGb1Checked(false);
            }
        } else {
            setPreferTGb2Checked(false);
            preferTGb.current = '';
        }
    }

    function selectItem(e, item) {
        let listItems = [...list];
        if (e) {
            setTotalAmount(totalAmount + Number(item.goods_wt))
        }
        if (!e) {
            setTotalAmount(totalAmount - Number(item.goods_wt))
        }
        for (let i = 0; i < listItems.length; i++) {
            if (listItems[i].auction_no === item.auction_no) {
                listItems[i].is_checked = e;
            }
        }
        setList(listItems);
    }

    function selectGoodsItem(e, item) {
        let listItems = [...goodsList];
        if (e) {
            setTotalAmountGoods(totalAmountGoods + Number(item.goods_wt))
        }
        if (!e) {
            setTotalAmountGoods(totalAmountGoods - Number(item.goods_wt))
        }
        for (let i = 0; i < listItems.length; i++) {
            if (listItems[i].goods_no === item.goods_no) {
                listItems[i].is_checked = e;
            }
        }
        setGoodsList(listItems);
    }

    function checkAll(e) {
        let listItems = [...list];
        let totalAmount = 0
        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            totalAmount += Number(item.goods_wt);
        }
        setTotalAmount(e ? totalAmount : 0);
        for (let i = 0; i < listItems.length; i++) {
            listItems[i].is_checked = e;
        }
        setList(listItems);
    }

    function checkAllGoods(e) {
        let listItems = [...goodsList];
        let totalAmount = 0
        for (let i = 0; i < goodsList.length; i++) {
            let item = goodsList[i];
            totalAmount += Number(item.goods_wt);
        }
        setTotalAmountGoods(e ? totalAmount : 0);
        for (let i = 0; i < listItems.length; i++) {
            listItems[i].is_checked = e;
        }
        setGoodsList(listItems);
    }

    async function clearList() {
        setFilter({
            selling_type: '',
            auction_status: '',
            auction_quantity_from: '',
            auction_quantity_to: '',
            winning_bid_from: '',
            winning_bid_to: '',
            size: 50,
            page: 1
        });
        setSizeAuction(50);
        setPageAuction(1)
        setList([]);
    }

    async function getList() {
        setTotalAmount(0);
        let params = "";
        let filterParams = filter;
        filterParams.start_date = startDate;
        filterParams.end_date = endDate;
        filterParams.page = pageAuction;
        filterParams.size = sizeAuction;
        if (Object.entries(filterParams).length > 0) {
            params = getPath(filterParams);
        }
        try {
            store.dispatch(showLoader());
            const response = await axiosInstance.get('/auction/list' + params);
            if (response.code === 200) {
                let listOfObjs = [];
                for (let i = 0; i < response.data.items.length; i++) {
                    let item = response.data.items[i];
                    item.is_checked = false;
                    listOfObjs.push(item);
                }
                setList(listOfObjs);
                setAuctionTotalPage(Number.parseInt(response.data.totalPage));
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }

    }

    async function updateAuctionStartPrice() {
        if (auctionStartPrice === 0) {
            alert("Please enter Price")
            return;
        }
        let updateList = [];
        for (let i = 0; i < list.length; i++) {
            if (list[i].is_checked) {
                updateList.push({
                    auctionNo: list[i].auction_no, effectPrice: auctionStartPrice
                });
            }
        }
        if (updateList.length === 0) {
            alert("Please select Item")
            return;
        }
        const payload = {
            auctionNo: selectedAuction.auction_no, updateList: updateList
        }
        store.dispatch(showLoader());
        try {
            const res = await axiosInstance.post('/auction/auctionEffectPriceUpdate', payload);
            console.log(res);
            if (res.code === 200) {
                await checkAll(false);
                setAuctionStartPrice(0);
                toast.success("SUCCESS");
            } else {
                alert(res.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            store.dispatch(hideLoader());
        }
    }

    async function updateAuctionGoodStartPrice() {
        if (auctionGoodsStartPrice === 0) {
            alert("Please enter Price")
            return;
        }
        if (selectedAuction === null) {
            alert("There is no selected Auction")
            return;
        }
        let updateList = [];
        for (let i = 0; i < goodsList.length; i++) {
            if (goodsList[i].is_checked) {
                updateList.push({
                    auctionNo: selectedAuction.auction_no,
                    goodsNo: goodsList[i].goods_no,
                    effectPrice: auctionGoodsStartPrice
                });
            }
        }
        if (updateList.length === 0) {
            alert("Please select Item")
            return;
        }
        const payload = {
            auctionNo: selectedAuction.auction_no, updateList: updateList
        }
        store.dispatch(showLoader());
        try {
            const res = await axiosInstance.post('/auction/auctionGoodEffectPriceUpdate', payload);
            console.log(res);
            if (res.code === 200) {
                await checkAllGoods(false);
                setAuctionGoodsStartPrice(0);
                toast.success(('SUCCESS'));
            } else {
                alert(res.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }

    async function selectAuction(item) {
        setSelectedAuction(item);
    }

    async function getAuctionGoodsList() {
        setTotalAmountGoods(0);
        let params = "";
        let filterParams = filterGoods;
        console.log(selectedAuction);
        filterParams.auction_no = selectedAuction.auction_no;
        filterParams.goods_grade_cd = goodsGradeCd.current;
        filterParams.prefer_t_gb = preferTGb.current;
        filterParams.page = pageGoods;
        filterParams.size = sizeGoods;
        if (Object.entries(filterParams).length > 0) {
            params = getPath(filterParams);
        }
        console.log(params)
        store.dispatch(showLoader());
        try {
            const response = await axiosInstance.get('/auction/getGoodsListByAuction' + params);
            if (response.code === 200) {
                let listOfObjs = [];
                for (let i = 0; i < response.data.items.length; i++) {
                    let item = response.data.items[i];
                    item.is_checked = false;
                    listOfObjs.push(item);
                }
                setGoodsList(listOfObjs);
                console.log(response.data.totalPage)
                setTotalPage(Number.parseInt(response.data.totalPage));
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }

    async function deleteAuction() {
        let listOfObjs = [];
        let isExistOngoingAuction = false;
        for (let i = 0; i < list.length; i++) {
            if (list[i].is_checked) {
                listOfObjs.push(list[i].auction_no);
                if (list[i].auction_stat_cd === 'ASC02') {
                    isExistOngoingAuction = true;
                }
            }
        }
        if (isExistOngoingAuction) {
            alert("One of Auctions are ONGOING state")
            return;
        }
        if (listOfObjs.length === 0) {
            alert("Please select items")
            return;
        }
        const payload = {
            ids: listOfObjs
        }
        setShowAuctionDelete(false);
        store.dispatch(showLoader());
        try {
            const response = await axiosInstance.post('/auction/delete', payload);
            if (response.code === 200) {
                toast.dismiss();
                toast.success(('Success'));
                await getList();
                if (goodsList.length > 0) {
                    await getAuctionGoodsList();
                }
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }

    async function deleteAuctionGoods() {
        let listOfObjs = [];
        for (let i = 0; i < goodsList.length; i++) {
            if (goodsList[i].is_checked) {
                listOfObjs.push(goodsList[i].goods_no);
            }
        }
        if (listOfObjs.length === 0) {
            alert("Please select items")
            return;
        }
        const payload = {
            auction_no: selectedAuction.auction_no, auction_goods_ids: listOfObjs
        }
        setShowAuctionGoodsDelete(false);
        store.dispatch(showLoader());
        try {
            const response = await axiosInstance.post('/auction/deleteGoods', payload);
            if (response.code === 200) {
                toast.dismiss();
                toast.success(('Success'));
                await getAuctionGoodsList();
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }

    function onChangeGoodsNo(e) {
        const lines = e.split(/\n/);
        let arrays = [];
        for (let i = 0; i < lines.length; i++) {
            if (/\S/.test(lines[i])) {
                arrays.push(lines[i].trim());
            }
        }
        setFilterGoods({
            ...filterGoods, goods_no_arr: arrays
        })
    }

    function clearGoodsList() {
        setFilterGoods({
            ...filterGoods,
            sale_div_cd: '',
            storage_div_cd: '',
            sale_type_cd: '',
            goods_no_arr: '',
            auction_start_price_from: '',
            auction_start_price_to: '',
            sale_price_from: '',
            sale_price_to: '',
            goods_div_cd: '',
            spart: '',
            goods_grade_cd: '',
            spec_abbsym: '',
            goods_t_mm_from: '',
            goods_t_mm_to: '',
            goods_w_mm_from: '',
            goods_w_mm_to: '',
            goods_len_mm_from: '',
            goods_len_mm_to: '',
            size: 50,
            page: 1
        });
        setGoodsList([]);
        setPageGoods(1);
        setSizeGoods(50);
    }

    return (<>
        <div id="wrap">
            <Header/>
            {/* [Start] main-area */}
            <section className="main-area" id="main">
                <div className="menu-visual" style={{backgroundImage: `url(${Background})`}}>
                    <h2 className="menu-visual__tit">
                        회차별 경매관리
                    </h2>
                    <div className="breadcrumbs-area">
                        <ol>
                            <li className="breadcrumbs-area__step breadcrumbs-area__step--root">
                                <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                                    <i className="icon24 icon-home"/>
                                    <span className="a11y">홈으로</span>
                                </a>
                            </li>
                            <li className="breadcrumbs-area__step breadcrumbs-area__step--more">
                                {/* [D] sub menu 있을 시, breadcrumbs-area__step--more 클래스 추가 */}
                                <a href="#!" className="breadcrumbs-area__link">
                                    <span>시스템관리</span>
                                </a>
                                <ul className="breadcrumbs-area__sub-menu">
                                    <li>
                                        <a href="/manageProduct1.html">시스템관리 1</a>
                                    </li>
                                    <li>
                                        <a href="/manageProduct1.html">시스템관리 2</a>
                                    </li>
                                    <li>
                                        <a href="/manageProduct1.html">시스템관리 3</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="breadcrumbs-area__step">
                                <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                                    <span>회차별 경매관리</span>
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div className="cont-area">
                    <div className="cont-area__grid">
                        <div className="cont-area__section">
                            <div className="cont-area__header">
                                <div className="btn-area right">
                                    <Link to={"/kas-0502"} className="btn-base type-dark">
                      <span>
                        경매등록
                      </span>
                                        <i className="icon20 icon-round-arrow-right"/>
                                    </Link>
                                    <Link to={"/kas-0506"} className="btn-base type-dark">
                      <span>
                        경매시작단가관리
                      </span>
                                        <i className="icon20 icon-round-arrow-right"/>
                                    </Link>
                                    <a href="#" className="btn-base type-sub">
                      <span>
                        경매스케줄 관리
                      </span>
                                        <i className="icon20 icon-round-arrow-right"/>
                                    </a>
                                </div>
                            </div>
                            <article>
                                <div className="article-content">
                                    <div className="tbl-area">
                                        <div className="table-wrap">
                                            <table>
                                                <colgroup>
                                                    <col style={{width: '140px'}}/>
                                                    <col/>
                                                    <col style={{width: '140px'}}/>
                                                    <col/>
                                                    <col style={{width: '140px'}}/>
                                                    <col/>
                                                    <col style={{width: '140px'}}/>
                                                    <col/>
                                                </colgroup>
                                                <tbody>
                                                <tr>
                                                    <th>경매일시</th>
                                                    <td colSpan={7}>
                                                        <div className="form-area">
                                                            <div className="form-item cols3">
                                                                <input type="date" id className="inp type-date"
                                                                       defaultValue={startDateFm}
                                                                       onChange={(e) => setStartDate(getUnixTimeByDate(new Date(e.target.value)))}/>
                                                                <span className="form-division">~</span>
                                                                <input type="date" id className="inp type-date"
                                                                       defaultValue={endDateFm}
                                                                       onChange={(e) => setEndDate(getUnixTimeByDate(new Date(e.target.value)))}/>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>경매상태</th>
                                                    <td>
                                                        <div className="form-area">
                                                            <div className="form-item">
                                                                <select className="selectbox"
                                                                        onChange={(e) => setFilter({
                                                                            ...filter,
                                                                            auction_status: e.target.value
                                                                        })}>
                                                                    <option value=''>전체</option>
                                                                    <option value='ASC01'>경매대기</option>
                                                                    <option value='ASC02'>경매진행중</option>
                                                                    <option value='ASC03'>경매중단</option>
                                                                    <option value='ASC04'>경매종료</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <div className="btn-area gap-x40">
                                <button className="btn-base type-secondary size-m" onClick={() => clearList()}>
                                    <span>초기화</span>
                                </button>
                                <button className="btn-base type-primary size-m grid-m"
                                        onClick={() => getList()}>
                                    <i className="icon24 icon-search-light"/>
                                    <span>
                      조회
                    </span>
                                </button>
                            </div>
                            <article className="space">
                                <div className="article-content">
                                    <div className="tbl-area">
                                        <div className="tbl-upper">
                                            <div className="tbl-upper__cont">
                                                <div className="form-group">
                                                    <dl>
                                                        <dt>시작단가</dt>
                                                        <dd>
                                                            <input type="text"
                                                                   value={auctionStartPrice}
                                                                   onChange={(e) => setAuctionStartPrice(e.target.value)}
                                                                   className="inp"/>
                                                        </dd>
                                                    </dl>
                                                </div>
                                                <button type="button" className="btn-round-util"
                                                        onClick={() => updateAuctionStartPrice()}>
                                                    <span>시작단가일괄 변경</span>
                                                </button>
                                                <button type="button" className="btn-round-util"
                                                        onClick={() => setShowAuctionDelete(true)}>
                                                    <span>경매삭제</span>
                                                </button>
                                            </div>
                                            <div className="tbl-upper__util">
                                                <strong className="tbl-area__tit">
                                                    선택중량(KG) {addComma(totalAmount)}
                                                </strong>


                                                <DownloadTableExcel filename="kasco" sheet="users"
                                                                    currentTableRef={tableRef.current}>
                                                    <a href="#" className="btn-base type-dark">
                                                        <span>다운로드</span>
                                                    </a>

                                                </DownloadTableExcel>
                                                <select className="selectbox type-light"
                                                        onChange={(e) => setSizeAuction(e.target.value)}>
                                                    <option value='50'>50개씩</option>
                                                    <option value='100'>100개씩</option>
                                                    <option value='200'>200개씩</option>
                                                    <option value='500'>500개씩</option>
                                                    <option value='1000'>1000개씩</option>
                                                    <option value='5000'>5000개씩</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="tbl-base type-hover scroll-warp">
                                            <table ref={tableRef}>
                                                <caption>
                                                    정보 테이블
                                                </caption>
                                                <colgroup>
                                                    <col style={{width: '100px'}}/>
                                                    <col style={{width: '100px'}}/>
                                                    <col style={{width: '100px'}}/>
                                                    <col/>
                                                    <col style={{width: '110px'}}/>
                                                    <col/>
                                                    <col/>
                                                    <col style={{width: '100px'}}/>
                                                    <col style={{width: '100px'}}/>
                                                    <col style={{width: '100px'}}/>
                                                    <col/>
                                                    <col style={{width: '100px'}}/>
                                                    <col style={{width: '100px'}}/>
                                                    <col style={{width: '100px'}}/>
                                                    <col/>
                                                    <col style={{width: '100px'}}/>
                                                    <col style={{width: '160px'}}/>
                                                </colgroup>
                                                <thead>
                                                <tr>
                                                    <th scope="col">
                                                        <input type="checkbox" id="chk1-all" name="chk1Group"
                                                               onChange={(e) => checkAll(e.target.checked)}/>
                                                        <label htmlFor="chk1-all" className="lb-chk"><span
                                                            className="a11y">전체선택</span></label>
                                                    </th>
                                                    <th scope="col">번호</th>
                                                    {/*<th scope="col">노출</th>*/}
                                                    <th scope="col">판매구분</th>
                                                    <th scope="col">경매구분</th>
                                                    <th scope="col">경매번호</th>
                                                    {/*<th scope="col">경매일시</th>*/}
                                                    <th scope="col">회차</th>
                                                    <th scope="col">경매상태</th>
                                                    <th scope="col">경매시작일시</th>
                                                    <th scope="col">경매종료일시</th>
                                                    <th scope="col">경매수량</th>
                                                    <th scope="col">낙찰수량</th>
                                                    <th scope="col">유찰수량</th>
                                                    <th scope="col">비고</th>
                                                    <th scope="col">중량</th>
                                                    <th scope="col">최종수정자</th>
                                                    <th scope="col">최종수정일시</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {list.map((item, index) => (<tr key={index}>
                                                    <td>
                                                        <input type="checkbox" id={`chk${index}-1`}
                                                               name="chk1Group"
                                                               checked={item.is_checked}
                                                               onChange={(e) => selectItem(e.target.checked, item)}/>
                                                        <label htmlFor={`chk${index}-1`} className="lb-chk">
                                                            <span className="a11y">선택</span>
                                                        </label>
                                                    </td>
                                                    <td>{list.length - index}</td>
                                                    <td>{item.sale_div_cd_nm}</td>
                                                    <td>{item.auction_div_cd_nm}</td>
                                                    <td><a style={{cursor: "pointer"}}
                                                           onClick={() => selectAuction(item)}>{item.auction_no}</a>
                                                    </td>
                                                    {/*<td><a href>{formatUnixTime(item.reg_date)}</a></td>*/}
                                                    <td>{item.auction_no.substring(item.auction_no.length - 2)}</td>
                                                    <td><a href>{item.auction_stat_cd_nm}</a></td>
                                                    <td>{formatUnixTime(item.auction_start_dt)}</td>
                                                    <td>{formatUnixTime(item.auction_end_dt)}</td>
                                                    <td>{item.auction_goods_cnt}</td>
                                                    <td><a href>{item.order_goods_cnt}</a></td>
                                                    <td><a href>{item.failed_goods_cnt}</a></td>
                                                    <td>{item.memo}</td>
                                                    <td>{addComma(item.goods_wt)}</td>
                                                    <td>{item.mod_id}</td>
                                                    <td>{formatUnixTime(item.mod_date)}</td>
                                                </tr>))}

                                                </tbody>
                                            </table>

                                        </div>

                                    </div>

                                    <div className="tbl-footer">
                                        <div className="tbl-footer__cont">
                                            <strong className="tbl-footer__tit">총 {list.length}건</strong>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <Pagination pageCount={auctionTotalPage}
                                        paginate={(p) => setPageAuction(p.selected + 1)}></Pagination>
                            {selectedAuction !== null && (<>
                                <article>
                                    <div className="article-header">
                                        <h3 className="sub-title">{selectedAuction.auction_no}경매 제품조회</h3>
                                        <a href={'/kas-0502-edit/' + selectedAuction.auction_no} type="button"
                                           className="btn-round-util">
                                            <span>경매수정</span>
                                        </a>
                                    </div>
                                    <div className="article-content">
                                        <div className="tbl-area">
                                            <div className="table-wrap">
                                                <table>
                                                    <colgroup>
                                                        <col style={{width: '120px'}}/>
                                                        <col/>
                                                        <col style={{width: '120px'}}/>
                                                        <col/>
                                                        <col style={{width: '120px'}}/>
                                                        <col/>
                                                        <col style={{width: '120px'}}/>
                                                        <col/>
                                                    </colgroup>
                                                    <tbody>
                                                    <tr>
                                                        <th>창고구분</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item">
                                                                    <select className="selectbox"
                                                                            onChange={(e) => setFilterGoods({
                                                                                ...filterGoods,
                                                                                storage_div_cd: e.target.value
                                                                            })}>
                                                                        <option value=''>전체</option>
                                                                        <option value='G101'>우성</option>
                                                                        <option value='G041'>삼우</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>제품상태</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item">
                                                                    <select className="selectbox"
                                                                            onChange={(e) => setFilterGoods({
                                                                                ...filterGoods,
                                                                                goods_stat_cd: e.target.value
                                                                            })}>
                                                                        <option value=''>전체</option>
                                                                        <option value='GS01'>등록대기</option>
                                                                        <option value='GS03'>판매대기</option>
                                                                        <option value='GS05'>판매중</option>
                                                                        <option value='GS05'>판매중</option>
                                                                        <option value='GS07'>판매완료</option>
                                                                        <option value='GS09'>출하대기</option>
                                                                        <option value='GS11'>운송대기</option>
                                                                        <option value='GS13'>인도완료</option>
                                                                        <option value='GS90'>할당취소</option>
                                                                        <option value='GS91'>반품</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <th>제품등급</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="check-box">
                                                                    <input type="checkbox" id="check5"
                                                                           checked={grade1Checked}
                                                                           onChange={(e) => checkGradeCd1(e)}/>
                                                                    <label htmlFor="check5" className="lb-chk">
                                                                        <span>1등급</span>
                                                                    </label>
                                                                    <input type="checkbox" id="check6"
                                                                           checked={grade2Checked}
                                                                           onChange={(e) => checkGradeCd2(e)}/>
                                                                    <label htmlFor="check6" className="lb-chk">
                                                                        <span>2등급</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </td>


                                                        <th>재고상태</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item">
                                                                    <select className="selectbox"
                                                                            onChange={(e) => setFilterGoods({
                                                                                ...filterGoods,
                                                                                stock_stat_cd: e.target.value
                                                                            })}>
                                                                        <option value=''>전체</option>
                                                                        <option value='SSC01'>자사재고</option>
                                                                        <option value='SSC02'>타사재고</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <th>정척여부</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="check-box">
                                                                    <input type="checkbox" id="check55"
                                                                           checked={preferTGb1Checked}
                                                                           onChange={(e) => checkPreferTGbCd1(e)}/>
                                                                    <label htmlFor="check55" className="lb-chk">
                                                                        <span>Y</span>
                                                                    </label>
                                                                    <input type="checkbox" id="check66"
                                                                           checked={preferTGb2Checked}
                                                                           onChange={(e) => checkPreferTGbCd2(e)}/>
                                                                    <label htmlFor="check66" className="lb-chk">
                                                                        <span>N</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>규격약호</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item">
                                                                    <input type="text" className="inp"
                                                                           onChange={(e) => setFilterGoods({
                                                                               ...filterGoods,
                                                                               spec_abbsym: e.target.value
                                                                           })}/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>유찰회수</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item type-range">
                                                                    <input type="text" className="inp"
                                                                           onChange={(e) => setFilterGoods({
                                                                               ...filterGoods,
                                                                               fail_bidding_cnt_from: e.target.value
                                                                           })}
                                                                    />
                                                                    <span className="form-division">~</span>
                                                                    <input type="text" className="inp"
                                                                           onChange={(e) => setFilterGoods({
                                                                               ...filterGoods,
                                                                               fail_bidding_cnt_to: e.target.value
                                                                           })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <th rowSpan={3}>제품번호</th>
                                                        <td rowSpan={3}>
                                                            <div className="product-num">
                                                                <div className="item">
                                                                    <div className="form-area">
                                                                        <div className="form-item">
                                                                                <textarea className="inp"
                                                                                          defaultValue={""}
                                                                                          onChange={(e) => {
                                                                                              onChangeGoodsNo(e.target.value)
                                                                                          }}

                                                                                />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="summary">
                                                                    <p>
                                                                        *제품번호, 패키지번호 구분은 , 또는 엔터로 구분<br/><br/>
                                                                        예) A3022,A0033
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>두께</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item type-range">
                                                                    <input type="text" className="inp"
                                                                           onChange={(e) => setFilterGoods({
                                                                               ...filterGoods,
                                                                               goods_t_mm_from: e.target.value
                                                                           })}
                                                                    />
                                                                    <span className="form-division">~</span>
                                                                    <input type="text" className="inp"
                                                                           onChange={(e) => setFilterGoods({
                                                                               ...filterGoods,
                                                                               goods_t_mm_to: e.target.value
                                                                           })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>폭</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item type-range">
                                                                    <input type="text" className="inp"
                                                                           onChange={(e) => setFilterGoods({
                                                                               ...filterGoods,
                                                                               goods_w_mm_from: e.target.value
                                                                           })}
                                                                    />
                                                                    <span className="form-division">~</span>
                                                                    <input type="text" className="inp"
                                                                           onChange={(e) => setFilterGoods({
                                                                               ...filterGoods,
                                                                               goods_w_mm_to: e.target.value
                                                                           })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>길이</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item type-range">
                                                                    <input type="text" className="inp"
                                                                           onChange={(e) => setFilterGoods({
                                                                               ...filterGoods,
                                                                               goods_len_mm_from: e.target.value
                                                                           })}
                                                                    />
                                                                    <span className="form-division">~</span>
                                                                    <input type="text" className="inp"
                                                                           onChange={(e) => setFilterGoods({
                                                                               ...filterGoods,
                                                                               goods_len_mm_to: e.target.value
                                                                           })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                <div className="btn-area gap-x40">
                                    <button className="btn-base type-secondary size-m" onClick={() => clearGoodsList()}
                                    >
                                        <span>초기화</span>
                                    </button>
                                    <button className="btn-base type-primary size-m grid-m"
                                            onClick={() => getAuctionGoodsList()}>
                                        <i className="icon24 icon-search-light"/>
                                        <span>
                      조회
                    </span>
                                    </button>
                                </div>
                                <article className="space">
                                    <div className="article-content">
                                        <div className="tbl-area">
                                            <div className="tbl-upper">
                                                <div className="tbl-upper__cont">
                                                    <div className="form-group">
                                                        <dl>
                                                            <dt>시작단가</dt>
                                                            <dd>
                                                                <input type="text" className="inp"
                                                                       value={auctionGoodsStartPrice}
                                                                       onChange={(e) => setAuctionGoodsStartPrice(e.target.value)}/>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                    <button type="button" className="btn-round-util"
                                                            onClick={() => updateAuctionGoodStartPrice()}>
                                                        <span>시작단가일괄 변경</span>
                                                    </button>
                                                    <button type="button" className="btn-round-util"
                                                            onClick={() => setShowAuctionGoodsDelete(true)}>
                                                        <span>경매제품삭제</span>
                                                    </button>
                                                </div>
                                                <div className="tbl-upper__util">
                                                    <strong className="tbl-area__tit">
                                                        선택중량(KG) {addComma(totalAmountGoods)}
                                                    </strong>
                                                    <DownloadTableExcel filename="kasco" sheet="users"
                                                                        currentTableRef={tableRef1.current}>
                                                        <a href="#" className="btn-base type-dark">
                                                            <span>다운로드</span>
                                                        </a>

                                                    </DownloadTableExcel>
                                                    <select className="selectbox type-light"
                                                            onChange={(e) => setSizeGoods(e.target.value)}>
                                                        <option value='50'>50개씩</option>
                                                        <option value='100'>100개씩</option>
                                                        <option value='200'>200개씩</option>
                                                        <option value='500'>500개씩</option>
                                                        <option value='1000'>1000개씩</option>
                                                        <option value='5000'>5000개씩</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="tbl-base type-hover scroll-warp">
                                                <table className="table-product" ref={tableRef1}>
                                                    <caption>
                                                        정보 테이블
                                                    </caption>
                                                    <colgroup/>
                                                    <thead>
                                                    <tr>
                                                        <th scope="col">
                                                            <input type="checkbox" id="chk2-all"
                                                                   name="chk2Group"
                                                                   onChange={(e) => checkAllGoods(e.target.checked)}
                                                            />
                                                            <label htmlFor="chk2-all" className="lb-chk">
                                                                <span className="a11y">전체선택</span></label>
                                                        </th>
                                                        <th scope="col">번호</th>
                                                        <th scope="col">제품번호</th>
                                                        <th scope="col">입고상태</th>
                                                        <th scope="col">매입구분</th>
                                                        <th scope="col">매입처</th>
                                                        <th scope="col">제조사</th>
                                                        <th scope="col">판매구분</th>
                                                        <th scope="col">정척여부</th>
                                                        <th scope="col">유찰횟수</th>
                                                        <th scope="col">상시유형</th>
                                                        <th scope="col">제품구분</th>
                                                        <th colSpan={2} scope="col">판매유형</th>
                                                        <th scope="col">제품상태</th>
                                                        <th scope="col">창고</th>
                                                        <th scope="col">메모</th>
                                                        <th scope="col">입고일자</th>
                                                        <th scope="col">입고차수</th>
                                                        <th scope="col">매입가</th>
                                                        <th scope="col">판매가</th>
                                                        <th scope="col">경매시작단가</th>
                                                        <th scope="col">제품군</th>
                                                        <th scope="col">대표품명</th>
                                                        <th scope="col">제품등급</th>
                                                        <th scope="col">두께</th>
                                                        <th scope="col">폭</th>
                                                        <th scope="col">길이</th>
                                                        <th scope="col">중량</th>
                                                        <th scope="col">YP</th>
                                                        <th scope="col">TS</th>
                                                        <th scope="col">EL</th>
                                                        <th scope="col">C%</th>
                                                        <th scope="col">SI</th>
                                                        <th scope="col">MN</th>
                                                        <th scope="col">P</th>
                                                        <th scope="col">S</th>
                                                        {/*<th scope="col">야드구분</th>*/}
                                                        {/*<th scope="col">매수</th>*/}
                                                        {/*<th scope="col">인장시험</th>*/}
                                                        {/*<th scope="col">화학성분1</th>*/}
                                                        {/*<th scope="col">화학성분2</th>*/}
                                                        <th scope="col">여재원인</th>
                                                        <th scope="col">여제원인명1</th>
                                                        {/*<th scope="col">여제원인명2</th>*/}
                                                        {/*<th scope="col">여제원인명3</th>*/}
                                                        <th scope="col">용도코드</th>
                                                        <th scope="col">용도명</th>
                                                        {/*<th scope="col">정척여부</th>*/}
                                                        <th scope="col">규격약호</th>
                                                        <th scope="col">제품사양</th>
                                                        <th scope="col">출고일자</th>
                                                        <th scope="col">순번</th>
                                                        <th scope="col">차량번호</th>
                                                        {/*<th scope="col">유찰횟수</th>*/}
                                                        <th scope="col">재고상태</th>
                                                        <th scope="col">최종수정자</th>
                                                        <th scope="col">최종수정일시</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {goodsList.map((item, index) => (<tr>
                                                            <td>
                                                                <input type="checkbox"
                                                                       checked={item.is_checked}
                                                                       id={`chkGoods${index}-1`}
                                                                       name="chkGoodsGroup"
                                                                       onChange={(e) => selectGoodsItem(e.target.checked, item)}/>
                                                                <label htmlFor={`chkGoods${index}-1`}
                                                                       className="lb-chk">
                                                                    <span className="a11y">선택</span>
                                                                </label>
                                                            </td>
                                                            <td>{goodsList.length - index}</td>
                                                            <td><a href>{item.goods_no}</a></td>
                                                            <td>{item.receipt_stat_cd_nm}</td>
                                                            <td>{item.purchase_div_cd_nm}</td>
                                                            <td>{item.supplier_div_cd_nm}</td>
                                                            <td>{item.maker_cd_nm}</td>
                                                            <td>{item.sale_div_cd_nm}</td>
                                                            <td>{item.prefer_t_gb}</td>
                                                            <td>{item.fail_bidding_cnt}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>{item.sale_type_cd_nm}</td>
                                                            <td>&nbsp;</td>
                                                            <td>{item.goods_stat_cd_nm}</td>
                                                            <td>{item.storage_div_cd_nm}</td>
                                                            <td>{item.memo}</td>
                                                            <td>{item.lwedt}</td>
                                                            <td></td>
                                                            <td>{item.purchase_price}</td>
                                                            <td>{item.sale_price}</td>
                                                            <td>{item.auction_start_price}</td>
                                                            <td>{item.spartetext}</td>
                                                            <td>{item.itemname_cd}</td>
                                                            <td>{item.goods_grade_cd_nm}</td>
                                                            <td>{item.goods_t_mm}</td>
                                                            <td>{item.goods_w_mm}</td>
                                                            <td>{item.goods_len_mm}</td>
                                                            <td>{addComma(item.goods_wt)}</td>
                                                            <td>{item.yp_wrslt}</td>
                                                            <td>{item.ts_wrslt}</td>
                                                            <td>{item.el_wrslt}</td>
                                                            <td>{item.c_wrslt}</td>
                                                            <td>{item.si_wrslt}</td>
                                                            <td>{item.mn_wrslt}</td>
                                                            <td>{item.p_wrslt}</td>
                                                            <td>{item.s_wrslt}</td>
                                                            {/*<td></td>*/}
                                                            {/*<td></td>*/}
                                                            {/*<td></td>*/}
                                                            {/*<td></td>*/}
                                                            {/*<td></td>*/}
                                                            <td>{item.yeojae_cause_cd}</td>
                                                            <td>{item.yeojae_cause_cd_nm}</td>
                                                            {/*<td></td>*/}
                                                            {/*<td></td>*/}
                                                            <td>{item.usage_cd}</td>
                                                            <td>{item.usage_cd_nm}</td>
                                                            {/*<td></td>*/}
                                                            <td>{item.spec_abbsym}</td>
                                                            <td>{item.goods_wdh}</td>
                                                            <td>{item.release_dt}</td>
                                                            <td>{item.turn}</td>
                                                            <td>{item.car_no}</td>
                                                            {/*<td></td>*/}
                                                            <td>{item.stock_stat_cd_nm}</td>
                                                            <td>{item.mode_id}</td>
                                                            <td>{item.mod_date}</td>
                                                        </tr>

                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="tbl-footer">
                                            <div className="tbl-footer__cont">
                                                <strong
                                                    className="tbl-footer__tit">총 {goodsList.length}건</strong>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </>)}
                            <Pagination pageCount={goodsTotalPage}
                                        paginate={(p) => setPageGoods(p.selected + 1)}></Pagination>
                        </div>
                    </div>
                </div>

                <Modal show={showAuctionDelete} onHide={() => setShowAuctionDelete(false)}
                       aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>삭제</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        삭제하시겠습니까?
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn-base type-secondary size-m me-3"
                                onClick={() => setShowAuctionDelete(false)}>
                            <span>NO</span>
                        </button>
                        <button className="btn-base type-primary size-m grid-m" onClick={() => deleteAuction()}>
                            <span>
                              YES
                            </span>
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showAuctionGoodsDelete} onHide={() => setShowAuctionGoodsDelete(false)}
                       aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>삭제</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        삭제하시겠습니까?
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn-base type-secondary size-m me-3"
                                onClick={() => setShowAuctionGoodsDelete(false)}>
                            <span>NO</span>
                        </button>
                        <button className="btn-base type-primary size-m grid-m"
                                onClick={() => deleteAuctionGoods()}>
                            <span>
                              YES
                            </span>
                        </button>
                    </Modal.Footer>
                </Modal>

            </section>
            {/* // [End] main-area */}
            <Footer/>


        </div>
    </>)
}

export default Kas0500
import React, {useEffect, useState, useRef} from 'react'
import {DownloadTableExcel, useDownloadExcel} from 'react-export-table-to-excel';
import Header from '../../containers/header/Header1'
import Footer from '../../containers/footer/Footer'

import Background from '../../assets/img/menu-visual1.jpg'
import Modal from "react-bootstrap/Modal";
import Pagination from "../pagination/pagination";
import useDidMountEffect from "../../helpers/useDidMountEffect";
import {formatUnixTime, getPath} from "../../helpers/helpers";
import {store} from "../../store";
import {hideLoader, showLoader} from "../../store/loader/actions";
import axiosInstance from "../../helpers/axiosInstance";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

function Kas1901() {
    const tableRef = useRef(null);
    const {token, user} = useSelector((store) => store.auth);
    const [queryDestination, setQueryDestination] = useState({
        cust_corp_code: '',
        cust_corp_no: '',
        corp_nm: '',
        dest_sigun_cd: '',
        dest_nm: '',
        use_yn: '',
        dest_memo: '',
        addr: '',
    });

    const [use1Checked, setUse1Checked] = useState(false);
    const [use2Checked, setUse2Checked] = useState(false);
    const useYn = useRef('');

    const [queryResult, setQueryResult] = useState([]);
    const [queryPage, setQueryPage] = useState(1);
    const [querySize, setQuerySize] = useState(50);
    const [queryTotalPage, setQueryTotalPage] = useState(0);
    const [queryData, setQueryData] = useState({
        corp_nm: '',
        business_number: ''
    });
    const [showFindCustomer, setShowFindCustomer] = useState(false);
    const [showModalDestination, setShowModalDestination] = useState(false);
    const [queryDestSignCd, setQueryDestSignCd] = useState(null);
    const [queryDestNm, setQueryDestNm] = useState(null);
    const [queryDestPage, setQueryDestPage] = useState(1);
    const [queryDestSize, setQueryDestSize] = useState(50);
    const [queryDestTotalPage, setQueryDestTotalPage] = useState(0);
    const [queryDestList, setQueryDestList] = useState([]);
    const [destList, setDestList] = useState([]);
    const [destPage, setDestPage] = useState(1);
    const [destSize, setDestSize] = useState(50);
    const [destTotalPage, setDestTotalPage] = useState(0);

    useEffect(()=>{
        if (user.role === 'CUSTOMER') {
            getCustomerInfo()
        }
    },[]);

    useDidMountEffect(() => {
        searchCustomer();
    }, [querySize, queryPage]);

    useDidMountEffect(() => {
        searchDestination();
    }, [destPage, destSize]);

    useDidMountEffect(() => {
        getDestList();
    }, [queryDestPage, queryDestSize]);

    const searchCustomer = async () => {
        let params;
        let filterParams = queryData;
        queryData.size = querySize;
        queryData.page = queryPage;
        params = getPath(filterParams);
        try {
            store.dispatch(showLoader());
            const response = await axiosInstance.get('/auth/searchCustomer' + params);
            console.log(response);
            if (response.code === 200) {
                toast.dismiss();
                toast.success(('Success'))
                setQueryResult(response.data.items);
                setQueryTotalPage(Number.parseInt(response.data.totalPage));
                store.dispatch(hideLoader());
            } else {
                store.dispatch(hideLoader());
                alert(response.message);
            }
        } catch (e) {
            store.dispatch(hideLoader());
        }
    }


    const getCustomerInfo = async () => {
        try {
            store.dispatch(showLoader());
            const custCorpCode = user.cust_corp_code;
            const res1 = await axiosInstance.get('/dest/getCustInfo?custCorpCode=' + custCorpCode);
            console.log(res1);
            if (res1.code === 200) {
                console.log(res1.data);
                try {
                    store.dispatch(showLoader());
                    const custCorpCode = user.cust_corp_code;
                    const res = await axiosInstance.get('/dest/getDestInfo?custCorpCode=' + custCorpCode);
                    console.log(res);
                    if (res.code === 200) {
                        setQueryDestination({
                            ...queryDestination,
                            dest_sigun_cd: res.data.dest_sigun_cd,
                            dest_sigun_cd_nm: res.data.dest_sigun_cd_nm,
                            cust_dest_seq: res.data.cust_dest_seq,
                            dest_addr: res.data.addr,
                            dest_nm: res.data.dest_nm,
                            phone1: res.data.phone1,
                            cust_corp_no: res1.data.cust_corp_no,
                            cust_corp_code: res1.data.cust_corp_code,
                            corp_nm: res1.data.corp_nm
                        });
                    }
                    store.dispatch(hideLoader());
                } catch (e) {
                    store.dispatch(hideLoader());
                }
            }
            console.log(queryDestination);
            store.dispatch(hideLoader());
        } catch (e) {
            store.dispatch(hideLoader());
        }
    }
    const selectCustomer = (item) => {
        setQueryDestination({
            ...queryDestination,
            cust_corp_code: item.cust_corp_code,
            cust_corp_no: item.cust_corp_no,
            corp_nm: item.corp_nm
        })
        setShowFindCustomer(false);
    }

    async function clearDestInfo() {
        setQueryDestList([]);
        setQueryDestNm(null);
        setQueryDestSignCd(null);
    }

    async function selectDest(item) {
        setQueryDestination({
            ...queryDestination,
            dest_sigun_cd: item.dest_sigun_cd,
            dest_seq: item.dest_seq,
            dest_nm: item.dest_nm,
        })
        setShowModalDestination(false);
    }

    async function getDestList() {
        let params;
        let filterParams = {
            dest_sigun_cd: queryDestSignCd,
            dest_nm: queryDestNm,
        }
        filterParams.size = queryDestSize;
        filterParams.page = queryDestPage;
        params = getPath(filterParams);
        store.dispatch(showLoader());
        try {
            const res = await axiosInstance.get('/dest/destList' + params);
            store.dispatch(hideLoader());
            if (res.code === 200) {
                setQueryDestList(res.data.items);
                setQueryDestTotalPage(Number.parseInt(res.data.totalPage));
            } else {
                alert(res.message);
            }
        } catch (e) {
            store.dispatch(hideLoader());
        }
    }

    const checkUse1 = (e) => {
        if (e.target.checked) {
            setUse1Checked(true);
            setQueryDestination({
                ...queryDestination,
                use_yn: 'Y',
            })
            if (use2Checked) {
                setUse2Checked(false);
            }
        } else {
            setUse1Checked(false);
            setQueryDestination({
                ...queryDestination,
                use_yn: '',
            })
        }
    }
    const checkUse2 = (e) => {
        if (e.target.checked) {
            setUse2Checked(true);
            setQueryDestination({
                ...queryDestination,
                use_yn: 'N',
            })
            if (use1Checked) {
                setUse1Checked(false);
            }
        } else {
            setUse2Checked(false);
            setQueryDestination({
                ...queryDestination,
                use_yn: '',
            })
        }
    }

    const searchDestination = async () => {
        let params;
        queryDestination.page = destPage;
        queryDestination.size = destSize;
        params = getPath(queryDestination);
        store.dispatch(showLoader());
        try {
            const res = await axiosInstance.get('/dest/customerDestList' + params);
            console.log(res);
            store.dispatch(hideLoader());
            if (res.code === 200) {
                setDestList(res.data.items);
                setDestTotalPage(Number.parseInt(res.data.totalPage));
            } else {
                alert(res.message);
            }
        } catch (e) {
            store.dispatch(hideLoader());
        }
    }

    return (
        <>
            <div id="wrap">
                <Header/>
                {/* [Start] main-area */}
                <section className="main-area" id="main">
                    <div className="menu-visual" style={{backgroundImage: `url(${Background})`}}>
                        <h2 className="menu-visual__tit">
                            고객 목적지관리
                        </h2>
                        <div className="breadcrumbs-area">
                            <ol>
                                <li className="breadcrumbs-area__step breadcrumbs-area__step--root">
                                    <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                                        <i className="icon24 icon-home"/>
                                        <span className="a11y">홈으로</span>
                                    </a>
                                </li>
                                <li className="breadcrumbs-area__step breadcrumbs-area__step--more"> {/* [D] sub menu 있을 시, breadcrumbs-area__step--more 클래스 추가 */}
                                    <a href="javascript:void(0);" className="breadcrumbs-area__link">
                                        <span>기준정보관리</span>
                                    </a>
                                    <ul className="breadcrumbs-area__sub-menu">
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 1</a>
                                        </li>
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 2</a>
                                        </li>
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 3</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="breadcrumbs-area__step">
                                    <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                                        <span>고객 목적지관리</span>
                                    </a>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className="cont-area">
                        <div className="cont-area__grid">
                            <div className="cont-area__section">
                                <div className="cont-area__header">
                                    <div className="btn-area right">
                                        <a href="/kas-1902" className="btn-base type-dark">
                      <span>
                        목적지 등록
                      </span>
                                            <i className="icon20 icon-round-arrow-right"/>
                                        </a>
                                    </div>
                                </div>
                                <article>
                                    <div className="article-content">
                                        <div className="tbl-area">
                                            <div className="table-wrap">
                                                <table>
                                                    <colgroup>
                                                        <col style={{width: '160px'}}/>
                                                        <col/>
                                                        <col style={{width: '160px'}}/>
                                                        <col/>
                                                        <col style={{width: '160px'}}/>
                                                        <col/>
                                                    </colgroup>
                                                    <tbody>
                                                    <tr>
                                                        <th>고객명</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item overlap-check">
                                                                    <input type="text" className="inp" disabled
                                                                           value={queryDestination.corp_nm}/>
                                                                    {user.role === 'ADMIN' && (
                                                                        <button type="button" className="btn-check_001"
                                                                                onClick={() => {
                                                                                    setQueryData({
                                                                                        corp_nm: '',
                                                                                        business_number: ''
                                                                                    });
                                                                                    setQueryResult([]);
                                                                                    setQuerySize(50);
                                                                                    setQueryTotalPage(0);
                                                                                    setShowFindCustomer(true);
                                                                                }}>찾기
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>사용여부</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="check-box">
                                                                    <input type="checkbox" id="check1"
                                                                           checked={use1Checked}
                                                                           onChange={(e) => checkUse1(e)}/>
                                                                    <label htmlFor="check1" className="lb-chk">
                                                                        <span>Y</span>
                                                                    </label>
                                                                    <input type="checkbox" id="check2"
                                                                           checked={use2Checked}
                                                                           onChange={(e) => checkUse2(e)}/>
                                                                    <label htmlFor="check2" className="lb-chk">
                                                                        <span>N</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>목적지코드</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item overlap-check">
                                                                    <input type="text" className="inp" disabled value={queryDestination.dest_nm}/>
                                                                    <button type="button" className="btn-check_001"
                                                                            onClick={() => {
                                                                                setQueryDestList([]);
                                                                                setQueryDestSize(50);
                                                                                setQueryDestTotalPage(0);
                                                                                setShowModalDestination(true)
                                                                            }}>찾기
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>하차지명</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item">
                                                                    <input type="text" className="inp"
                                                                           onChange={(e) => {
                                                                               setQueryDestination({
                                                                                   ...queryDestination,
                                                                                   dest_nm: e.target.value,
                                                                               })
                                                                           }}/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>하차지특이사항</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item">
                                                                    <input type="text" className="inp"
                                                                           onChange={(e) => {
                                                                               setQueryDestination({
                                                                                   ...queryDestination,
                                                                                   dest_memo: e.target.value,
                                                                               })
                                                                           }}/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>목적지주소</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item">
                                                                    <input type="text" className="inp"
                                                                           onChange={(e) => {
                                                                               setQueryDestination({
                                                                                   ...queryDestination,
                                                                                   addr: e.target.value,
                                                                               })
                                                                           }}/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                <div className="btn-area gap-x40">
                                    <a href="#" className="btn-base type-secondary size-m"
                                    >
                                        <span>초기화</span>
                                    </a>
                                    <button onClick={() => searchDestination()}
                                            className="btn-base type-primary size-m grid-m"
                                    >
                                        <i className="icon24 icon-search-light"/>
                                        <span>
                      조회
                    </span>
                                    </button>
                                </div>
                                <article className="space">
                                    <div className="article-content">
                                        <div className="tbl-area">
                                            <div className="tbl-upper">
                                                {/*<div className="tbl-upper__cont">*/}
                                                {/*    <button type="button" className="btn-round-util">*/}
                                                {/*        <span>항목삭제</span>*/}
                                                {/*    </button>*/}
                                                {/*    <button type="button" className="btn-round-util">*/}
                                                {/*        <span>저장</span>*/}
                                                {/*    </button>*/}
                                                {/*</div>*/}
                                                <div className="tbl-upper__util">
                                                    <DownloadTableExcel filename="kasco" sheet="users"
                                                                        currentTableRef={tableRef.current}>
                                                        <a href="#" className="btn-base type-dark">
                                                            <span>다운로드</span>
                                                        </a>
                                                    </DownloadTableExcel>
                                                    <select className="selectbox type-light"
                                                            onChange={(e) => setDestSize(e.target.value)}>
                                                        <option value='50'>50개씩</option>
                                                        <option value='100'>100개씩</option>
                                                        <option value='200'>200개씩</option>
                                                        <option value='500'>500개씩</option>
                                                        <option value='1000'>1000개씩</option>
                                                        <option value='5000'>5000개씩</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="tbl-base type-hover">
                                                <table ref={tableRef}>
                                                    <colgroup>
                                                        <col style={{width: '80px'}}/>
                                                        <col style={{width: '80px'}}/>
                                                        <col style={{width: '80px'}}/>
                                                        <col style={{width: '80px'}}/>
                                                        <col/>
                                                        <col/>
                                                        <col style={{width: '80px'}}/>
                                                        <col/>
                                                        <col style={{width: '200px'}}/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                    </colgroup>
                                                    <thead>
                                                    <tr>
                                                        <th>
                                                            <input type="checkbox" id="chk1-all" name="chk1Group"/>
                                                            <label htmlFor="chk1-all" className="lb-chk">
                                                                <span className="a11y">전체선택</span>
                                                            </label>
                                                        </th>
                                                        <th>번호</th>
                                                        <th>대표여부</th>
                                                        <th>사용여부</th>
                                                        <th>고객코드</th>
                                                        <th>고객명</th>
                                                        <th>순번</th>
                                                        <th>목적지코드</th>
                                                        <th>목적지주소</th>
                                                        <th>전화번호1</th>
                                                        <th>전화번호2</th>
                                                        <th>하차지명</th>
                                                        <th>하차지특이사항</th>
                                                        <th>최종수정자</th>
                                                        <th>최종수정일시</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>

                                                    {
                                                        destList.map((item, index) => (
                                                            <tr>
                                                                <td>
                                                                    <input type="checkbox" id="chk1-0"
                                                                           name="chk1Group"/>
                                                                    <label htmlFor="chk1-0" className="lb-chk">
                                                                        <span className="a11y">선택</span>
                                                                    </label>
                                                                </td>
                                                                <td>{destList.length - index}</td>
                                                                <td>
                                                                    <input type="checkbox" id="chk2-0"
                                                                           name="chk1Group"
                                                                           checked={item.reps_yn === 'Y'}/>
                                                                    <label htmlFor="chk2-0" className="lb-chk">
                                                                        <span className="a11y">선택</span>
                                                                    </label>
                                                                </td>
                                                                <td>{item.use_yn}</td>
                                                                <td><Link to={'/kas-1902/' + item.cust_dest_seq}>
                                                                    {item.cust_corp_code}
                                                                </Link></td>
                                                                <td>{item.corp_nm}</td>
                                                                <td>{item.turn}</td>
                                                                <td>{item.dest_sigun_cd}</td>
                                                                <td>{item.addr}</td>
                                                                <td>{item.phone1}</td>
                                                                <td>{item.phone2}</td>
                                                                <td>{item.dest_nm}</td>
                                                                <td>{item.dest_memo}</td>
                                                                <td>{item.mod_mid}</td>
                                                                <td>{formatUnixTime(item.mod_date)}</td>
                                                            </tr>

                                                        ))
                                                    }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="tbl-footer">
                                            <div className="tbl-footer__cont">
                                                <strong className="tbl-footer__tit">총 {destList.length}건</strong>
                                            </div>
                                        </div>
                                        <div className="paging-area">
                                            <Pagination pageCount={destTotalPage}
                                                        paginate={(p) => setDestPage(p.selected + 1)}></Pagination>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </section>
                {/* // [End] main-area */}
                <Footer/>

                <Modal
                    dialogClassName="justify-content-center"
                    show={showFindCustomer}
                    size="lg"
                    onHide={() => setShowFindCustomer(false)}
                >
                    <Modal.Body style={{background: '#fff'}}>
                        <section className="main-area">
                            <article className="small-pop">
                                <div className="article-header">
                                    <h1 className="popup-header__tit"> 고객사 찾기</h1>
                                    <button type="button" className="btn-close"
                                            onClick={() => setShowFindCustomer(false)}><span
                                        className="a11y">팝업 닫기</span></button>
                                </div>
                                <div className="article-content">
                                    <div className="cont-area">
                                        <div className="cont-area__section">
                                            <article>
                                                <div className="article-content">
                                                    <div className="tbl-area">
                                                        <div className="table-wrap">
                                                            <table>
                                                                <colgroup>
                                                                    <col style={{width: '160px'}}/>
                                                                    <col/>
                                                                    <col style={{width: '160px'}}/>
                                                                    <col/>
                                                                </colgroup>
                                                                <tbody>
                                                                <tr>
                                                                    <th>고객사명</th>
                                                                    <td>
                                                                        <div className="form-area">
                                                                            <div className="form-item">
                                                                                <input type="text" className="inp"
                                                                                       value={queryData.corp_nm}
                                                                                       onChange={(e) => {
                                                                                           setQueryData({
                                                                                               ...queryData,
                                                                                               corp_nm: e.target.value
                                                                                           })
                                                                                       }}/>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <th>사업자번호</th>
                                                                    <td>
                                                                        <div className="form-area">
                                                                            <div className="form-item">
                                                                                <input type="text" className="inp"
                                                                                       value={queryData.business_number}
                                                                                       onChange={(e) => {
                                                                                           setQueryData({
                                                                                               ...queryData,
                                                                                               business_number: e.target.value
                                                                                           })
                                                                                       }}/>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                            <div className="btn-area gap-x40">
                                                <button onClick={() => {
                                                    setQueryData({
                                                        ...queryData,
                                                        corp_nm: '',
                                                        business_number: ''
                                                    });
                                                    setQueryResult([]);
                                                    setQuerySize(10);
                                                    setQueryTotalPage(0);
                                                }} className="btn-base type-secondary size-m">
                                                    <span>초기화</span>
                                                </button>
                                                <button onClick={() => searchCustomer()}
                                                        className="btn-base type-primary size-m grid-m">
                                                    <i className="icon24 icon-search-light"/>
                                                    <span>
                        검색
                      </span>
                                                </button>
                                            </div>
                                            <article className="space">
                                                <div className="article-content">
                                                    <div className="tbl-area">
                                                        <div className="tbl-upper">
                                                            <div className="tbl-upper__util">
                                                                <select className="selectbox type-light"
                                                                        onChange={(e) => setQuerySize(e.target.value)}>
                                                                    <option value='50'>50개씩</option>
                                                                    <option value='100'>100개씩</option>
                                                                    <option value='200'>200개씩</option>
                                                                    <option value='500'>500개씩</option>
                                                                    <option value='1000'>1000개씩</option>
                                                                    <option value='5000'>5000개씩</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="tbl-base type-hover">
                                                            <table>
                                                                <colgroup>
                                                                    <col style={{width: '100px'}}/>
                                                                    <col/>
                                                                    <col/>
                                                                </colgroup>
                                                                <thead>
                                                                <tr>
                                                                    <th>고객코드</th>
                                                                    <th>고객사명</th>
                                                                    <th>사업자번호</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    queryResult.map((item, index) => (
                                                                        <tr onClick={() => selectCustomer(item)}>
                                                                            <td>{item.cust_corp_code}</td>
                                                                            <td>{item.corp_nm}</td>
                                                                            <td>{item.business_num}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <Pagination pageCount={queryTotalPage}
                                                                paginate={(p) => setQueryPage(p.selected + 1)}></Pagination>
                                                </div>
                                            </article>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </section>
                    </Modal.Body>
                </Modal>

                <Modal
                    dialogClassName="justify-content-center"
                    show={showModalDestination}
                    size="lg"
                    onHide={() => setShowModalDestination(false)}
                >
                    <Modal.Body style={{background: '#fff'}}>
                        <div id="wrap" className="popup-area">
                            <section className="main-area">
                                <article className="small-pop">
                                    <div className="article-header">
                                        <h1 className="popup-header__tit">목적지 조회</h1>
                                        <button type="button" className="btn-close"
                                                onClick={() => setShowModalDestination(false)}><span
                                            className="a11y">팝업 닫기</span></button>
                                    </div>
                                    <div className="article-content">
                                        <div className="cont-area">
                                            <div className="cont-area__section">
                                                <article>
                                                    <div className="article-content">
                                                        <div className="tbl-area">
                                                            <div className="table-wrap">
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{width: '160px'}}/>
                                                                        <col/>
                                                                        <col style={{width: '160px'}}/>
                                                                        <col/>
                                                                    </colgroup>
                                                                    <tbody>
                                                                    <tr>
                                                                        <th>목적지코드</th>
                                                                        <td>
                                                                            <div className="form-area">
                                                                                <div className="form-item">
                                                                                    <input type="text" className="inp"
                                                                                           onChange={(e) => setQueryDestSignCd(e.target.value)}/>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <th>목적지명</th>
                                                                        <td>
                                                                            <div className="form-area">
                                                                                <div className="form-item">
                                                                                    <input type="text" className="inp"
                                                                                           onChange={(e) => setQueryDestNm(e.target.value)}/>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                                <div className="btn-area gap-x40">
                                                    <button className="btn-base type-secondary size-m"
                                                            onClick={() => clearDestInfo()}>
                                                        <span>초기화</span>
                                                    </button>
                                                    <button className="btn-base type-primary size-m grid-m"
                                                            onClick={() => getDestList()}>
                                                        <i className="icon24 icon-search-light"/>
                                                        <span>
                        검색
                      </span>
                                                    </button>
                                                </div>
                                                <article className="space">
                                                    <div className="article-content">
                                                        <div className="tbl-area">
                                                            <div className="tbl-upper">
                                                                <div className="tbl-upper__util">
                                                                    <select className="selectbox type-light"
                                                                            onChange={(e) => setQueryDestSize(e.target.value)}>
                                                                        <option value='50'>50개씩</option>
                                                                        <option value='100'>100개씩</option>
                                                                        <option value='200'>200개씩</option>
                                                                        <option value='500'>500개씩</option>
                                                                        <option value='1000'>1000개씩</option>
                                                                        <option value='5000'>5000개씩</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="tbl-base type-hover">
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{width: '100px'}}/>
                                                                        <col/>
                                                                    </colgroup>
                                                                    <thead>
                                                                    <tr>
                                                                        <th>목적자코드</th>
                                                                        <th>목적지</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                    {
                                                                        queryDestList.map((item, index) => (
                                                                                <tr onClick={() => selectDest(item)}>
                                                                                    <td>{item.dest_sigun_cd}</td>
                                                                                    <td>{item.dest_nm}</td>
                                                                                </tr>
                                                                            )
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <Pagination pageCount={queryDestTotalPage}
                                                                    paginate={(p) => setQueryDestPage(p.selected + 1)}></Pagination>
                                                    </div>
                                                </article>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </section>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default Kas1901
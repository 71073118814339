import React, {useEffect, useState, useRef} from 'react'
import { DownloadTableExcel, useDownloadExcel} from 'react-export-table-to-excel';
import Header from '../../containers/header/Header1'
import Footer from '../../containers/footer/Footer'

import Background from '../../assets/img/menu-visual1.jpg'
import {formatUnixTime, getPath} from "../../helpers/helpers";
import {store} from "../../store";
import {hideLoader, showLoader} from "../../store/loader/actions";
import axiosInstance from "../../helpers/axiosInstance";
import {addComma} from "../../Utils";
import useDidMountEffect from "../../helpers/useDidMountEffect";

function Kas0506() {
    const tableRef = useRef(null);
    const [filter, setFilter] = useState({
        start_date: '',
        end_date: '',
        supplier_div_cd: '',
        storage_div_cd: '',
        arr_goods_grade: '',
        arr_prifer_tgb: '',
        spart_cd: '',
        from_fail_cnt: '',
        to_fail_cnt: '',
        effect_previous_price: '',
        effect_price: '',
    });

    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(50);

    useDidMountEffect(() => {
        console.log(size);
        getList();
    }, [size]);

    async function getList() {
        let params = "";
        let filterParams = filter;
        filterParams.page = page;
        filterParams.size = size;
        if (Object.entries(filterParams).length > 0) {
            params = getPath(filterParams);
        }
        try {
            store.dispatch(showLoader());
            const response = await axiosInstance.get('/auction/costList' + params);
            if (response.code === 200) {
                let listOfObjs = [];
                for (let i = 0; i < response.data.items.length; i++) {
                    let item = response.data.items[i];
                    item.is_checked = false;
                    listOfObjs.push(item);
                }
                setList(listOfObjs);
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }


    function selectItem(e, item) {
        let listItems = [...list];
        // if (e) {
        //     setTotalAmount(totalAmount + Number(item.goods_wt))
        // }
        // if (!e) {
        //     setTotalAmount(totalAmount - Number(item.goods_wt))
        // }
        for (let i = 0; i < listItems.length; i++) {
            if (listItems[i].auction_no === item.auction_no) {
                listItems[i].is_checked = e;
            }
        }
        setList(listItems);
    }

    function checkAll(e) {
        console.log(e);
        let listItems = [...list];
        // let totalAmount = 0
        // for (let i = 0; i < list.length; i++) {
        //     let item = list[i];
        //     totalAmount += Number(item.goods_wt);
        // }
        // setTotalAmount(e ? totalAmount : 0);
        for (let i = 0; i < listItems.length; i++) {
            listItems[i].is_checked = e;
        }
        setList(listItems);
    }


    return (
        <>
            <div id="wrap">
                <Header/>
                {/* [Start] main-area */}
                <section className="main-area" id="main">
                    <div className="menu-visual" style={{backgroundImage: `url(${Background})`}}>
                        <h2 className="menu-visual__tit">
                            경매시작단가관리
                        </h2>
                        <div className="breadcrumbs-area">
                            <ol>
                                <li className="breadcrumbs-area__step breadcrumbs-area__step--root">
                                    <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                                        <i className="icon24 icon-home"/>
                                        <span className="a11y">홈으로</span>
                                    </a>
                                </li>
                                <li className="breadcrumbs-area__step breadcrumbs-area__step--more"> {/* [D] sub menu 있을 시, breadcrumbs-area__step--more 클래스 추가 */}
                                    <a href="#!" className="breadcrumbs-area__link">
                                        <span>시스템관리</span>
                                    </a>
                                    <ul className="breadcrumbs-area__sub-menu">
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 1</a>
                                        </li>
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 2</a>
                                        </li>
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 3</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="breadcrumbs-area__step">
                                    <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                                        <span>경매시작단가관리</span>
                                    </a>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className="cont-area">
                        <div className="cont-area__grid">
                            <div className="cont-area__section">
                                <div className="cont-area__header">
                                    <div className="btn-area right">
                                        <a href="#" className="btn-base type-dark">
                      <span>
                        경매시작단가등록
                      </span>
                                            <i className="icon20 icon-round-arrow-right"/>
                                        </a>
                                    </div>
                                </div>
                                <article>
                                    <div className="article-content">
                                        <div className="tbl-area">
                                            <div className="table-wrap">
                                                <table>
                                                    <colgroup>
                                                        <col style={{width: '160px'}}/>
                                                        <col/>
                                                        <col style={{width: '160px'}}/>
                                                        <col/>
                                                        <col style={{width: '160px'}}/>
                                                        <col/>
                                                    </colgroup>
                                                    <tbody>
                                                    <tr>
                                                        <th>적용일자</th>
                                                        <td>
                                                            <div className="form-item type-range">
                                                                <input type="date" id className="inp type-date"
                                                                       defaultValue="2022-11-01"
                                                                       onChange={(e) => setFilter({
                                                                           ...filter,
                                                                           start_date: e.target.value
                                                                       })}/>
                                                                <span className="form-division">~</span>
                                                                <input type="date" id className="inp type-date"
                                                                       defaultValue="2022-11-01"
                                                                       onChange={(e) => setFilter({
                                                                           ...filter,
                                                                           end_date: e.target.value
                                                                       })}/>
                                                            </div>
                                                        </td>
                                                        <th>매입처</th>
                                                        <td>
                                                            <div className="form-item">
                                                                <select className="selectbox" id="selectStore1"
                                                                        onChange={(e) => setFilter({
                                                                            ...filter,
                                                                            supplier_div_cd: e.target.value
                                                                        })}>
                                                                    <option value=''>전체</option>
                                                                    <option value='SDC01'>현대제철</option>
                                                                    <option value='SDC02'>카스코철강</option>
                                                                </select>
                                                            </div>
                                                        </td>
                                                        <th>창고구분</th>
                                                        <td>
                                                            <div className="form-item">
                                                                <select className="selectbox" id="selectStore1"
                                                                        onChange={(e) => setFilter({
                                                                            ...filter,
                                                                            storage_div_cd: e.target.value
                                                                        })}>
                                                                    <option value hidden>전체</option>
                                                                    <option value='G101'>우성</option>
                                                                    <option value='G041'>삼우</option>

                                                                </select>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>제품등급</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="check-box">
                                                                    <input type="checkbox" id="check1"/>
                                                                    <label htmlFor="check1" className="lb-chk">
                                                                        <span>1등급</span>
                                                                    </label>
                                                                    <input type="checkbox" id="check2"/>
                                                                    <label htmlFor="check2" className="lb-chk">
                                                                        <span>2등급</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>제품군</th>
                                                        <td>
                                                            <div className="form-item">
                                                                <select className="selectbox" id="selectStore1"
                                                                        onChange={(e) => setFilter({
                                                                            ...filter,
                                                                            spart_cd: e.target.value
                                                                        })}>
                                                                    <option value hidden>전체</option>
                                                                    <option value='11'>철근</option>
                                                                    <option value='12'>형강</option>
                                                                    <option value='13'>원형강</option>
                                                                    <option value='21'>STS</option>
                                                                    <option value='31'>주강</option>
                                                                    <option value='32'>단강</option>
                                                                    <option value='41'>중기</option>
                                                                    <option value='42'>롤</option>
                                                                    <option value='51'>열연</option>
                                                                    <option value='61'>후판</option>
                                                                </select>
                                                            </div>
                                                        </td>
                                                        <th>정척여부</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="check-box">
                                                                    <input type="checkbox" id="check3"/>
                                                                    <label htmlFor="check3" className="lb-chk">
                                                                        <span>정척</span>
                                                                    </label>
                                                                    <input type="checkbox" id="check4"/>
                                                                    <label htmlFor="check4" className="lb-chk">
                                                                        <span>비정척</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>유찰횟수</th>
                                                        <td>
                                                            <div className="form-item type-range">
                                                                <input type="text" className="inp"
                                                                       onChange={(e) => setFilter({
                                                                           ...filter,
                                                                           from_fail_cnt: e.target.value
                                                                       })}/>
                                                                <span className="form-division">~</span>
                                                                <input type="text" className="inp"
                                                                       onChange={(e) => setFilter({
                                                                           ...filter,
                                                                           to_fail_cnt: e.target.value
                                                                       })}/>
                                                            </div>
                                                        </td>
                                                        <th>적용직전단가</th>
                                                        <td>
                                                            <div className="form-item">
                                                                <input type="text" className="inp"
                                                                       onChange={(e) => setFilter({
                                                                           ...filter,
                                                                           effect_previous_price: e.target.value
                                                                       })}/>
                                                            </div>
                                                        </td>
                                                        <th>적용단가</th>
                                                        <td>
                                                            <div className="form-item">
                                                                <input type="text" className="inp"
                                                                       onChange={(e) => setFilter({
                                                                           ...filter,
                                                                           effect_price: e.target.value
                                                                       })}/>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                <div className="btn-area gap-x40">
                                    <a href="#" className="btn-base type-secondary size-m">
                                        <span>취소</span>
                                    </a>
                                    <a href="#" className="btn-base type-primary size-m grid-m"
                                       onClick={() => getList()}>
                                        <i className="icon24 icon-search-light"/>
                                        <span>
                      조회
                    </span>
                                    </a>
                                </div>
                                <article className="space">
                                    <div className="article-content">
                                        <div className="tbl-area">
                                            <div className="tbl-upper">
                                                <div className="tbl-upper__cont">
                                                    <button type="button" className="btn-round-util">
                                                        <span>항목삭제</span>
                                                    </button>
                                                    <button type="button" className="btn-round-util">
                                                        <span>대량업로드</span>
                                                    </button>
                                                    <div className="form-group">
                                                        <dl>
                                                            <dt>경매 시작단가</dt>
                                                            <dd>
                                                                <div className="form-item">
                                                                    <input type="text" className="inp"/>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                    <button type="button" className="btn-round-util">
                                                        <span>일괄 변경</span>
                                                    </button>
                                                </div>
                                                <div className="tbl-upper__util">
                                                <DownloadTableExcel filename="kasco" sheet="users"
                                                                        currentTableRef={tableRef.current}>
                                                        <a href="#" className="btn-base type-dark">
                                                            <span>다운로드</span>
                                                        </a>
                                                    </DownloadTableExcel>
                                                    <select className="selectbox type-light"
                                                            onChange={(e) => setSize(e.target.value)}>
                                                        <option value='50'>50개씩</option>
                                                        <option value='100'>100개씩</option>
                                                        <option value='200'>200개씩</option>
                                                        <option value='500'>500개씩</option>
                                                        <option value='1000'>1000개씩</option>
                                                        <option value='5000'>5000개씩</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="tbl-base type-hover">
                                                <table ref={tableRef}>
                                                    <colgroup>
                                                        <col style={{width: '80px'}}/>
                                                        <col style={{width: '80px'}}/>
                                                        <col/>
                                                        <col/>
                                                        <col style={{width: '80px'}}/>
                                                        <col/>
                                                        <col style={{width: '80px'}}/>
                                                        <col style={{width: '80px'}}/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                    </colgroup>
                                                    <thead>
                                                    <tr>
                                                        <th>
                                                            <input type="checkbox" id="chk1-all" name="chk1Group"
                                                                   onChange={(e) => checkAll(e.target.checked)}/>
                                                            <label htmlFor="chk1-all" className="lb-chk">
                                                                <span className="a11y">전체선택</span>
                                                            </label>
                                                        </th>
                                                        <th>번호</th>
                                                        <th>적용일자</th>
                                                        <th>매입처</th>
                                                        <th>제품등급</th>
                                                        <th>제품군</th>
                                                        <th>정척여부</th>
                                                        <th>유찰횟수</th>
                                                        <th>적용직전단가</th>
                                                        <th>적용단가</th>
                                                        <th>최종수정자</th>
                                                        <th>최종수정일시</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        list.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    <input type="checkbox" id={`chk${index}-1`}
                                                                           name="chk1Group" checked={item.is_checked}
                                                                           onChange={(e) => selectItem(e.target.checked, item)}/>
                                                                    <label htmlFor="chk1-0" className="lb-chk">
                                                                        <span className="a11y">선택</span>
                                                                    </label>
                                                                </td>
                                                                <td>{list.length - index}</td>
                                                                <td>{item.price_effect_dt}</td>
                                                                <td>{item.supplier_div_cd_nm}</td>
                                                                <td></td>
                                                                <td>{item.spart_cd_nm}</td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        ))
                                                    }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="tbl-footer">
                                            <div className="tbl-footer__cont">
                                                <strong className="tbl-footer__tit">총 {list.length}건</strong>
                                            </div>
                                        </div>
                                        <div className="paging-area">
                                            <button type="button" className="btn-jump first" disabled/>
                                            <button type="button" className="btn-jump prev" disabled/>
                                            <div className="paging-link">
                                                <a href="#" className="current">1</a> {/* [D] 현재 페이지에 current 클래스 추가 */}
                                                <a href="#">2</a>
                                                <a href="#">3</a>
                                                <a href="#">4</a>
                                                <a href="#">5</a>
                                                <a href="#">6</a>
                                                <a href="#">7</a>
                                                <a href="#">8</a>
                                                <a href="#">9</a>
                                                <a href="#">10</a>
                                            </div>
                                            <button type="button" className="btn-jump next"/>
                                            <button type="button" className="btn-jump last"/>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </section>
                {/* // [End] main-area */}
                <Footer/>
            </div>
        </>
    )
}

export default Kas0506
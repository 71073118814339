import axios from 'axios';
import {store} from "../store/index";
import {logout, setLogin, setToken, setUser} from "../store/auth/auth.slice";

const baseURL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
    baseURL,
    timeout: 100000,
});

const errorHandler = (error, hooks) => {
    console.log(error);
    if (error.response.status === 401) {
        store.dispatch(setToken(null));
        store.dispatch(setUser(null));
        store.dispatch(setLogin(false));
        store.dispatch(logout());
    }
    return Promise.reject(error.response)
}

axiosInstance.interceptors.request.use(
    config => {
        const token = store.getState().auth.token;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    error => errorHandler(error)
)

axiosInstance.interceptors.response.use(response => {
    if (response.status === 401) {
        store.dispatch(setToken(null));
        store.dispatch(setUser(null));
        store.dispatch(setLogin(false));
        store.dispatch(logout());
    }
    return response.data;
}, error => errorHandler(error));


export default axiosInstance;

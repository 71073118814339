import React, { useState, useEffect, useRef } from "react";

import {
  formatMoney,
  formatUnixTime,
  getPath,
  getUnixTimeByDate,
  todayDate,
} from "../../helpers/helpers";
import axiosInstance from "../../helpers/axiosInstance";
import { DownloadTableExcel } from "react-export-table-to-excel";

import Header from "../../containers/header/Header1";
import Footer from "../../containers/footer/Footer";

import Background from "../../assets/img/menu-visual1.jpg";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { store } from "../../store";
import { hideLoader, showLoader } from "../../store/loader/actions";
import { toast } from "react-toastify";
import { addComma } from "../../Utils";
import useDidMountEffect from "../../helpers/useDidMountEffect";
import Pagination from "../pagination/pagination";
import Modal from "react-bootstrap/Modal";

function SignUp() {
  return (
      <>
        <div id="wrap">
          {/*<Header />*/}
          <header className="header-area" />
          {/* [Start] main-area */}
          <section className="main-area" id="main">
            <div
                className="menu-visual"
                style={{ backgroundImage: `url(${Background})` }}
            >
              <h2 className="menu-visual__tit">회원가입</h2>
              <div className="breadcrumbs-area">
                <ol>
                  <li className="breadcrumbs-area__step breadcrumbs-area__step--root">
                    <a
                        href="/manageProduct1.html"
                        className="breadcrumbs-area__link"
                    >
                      <i className="icon24 icon-home" />
                      <span className="a11y">홈으로</span>
                    </a>
                  </li>
                  <li className="breadcrumbs-area__step">
                    <a href="javascript:;" className="breadcrumbs-area__link">
                      회원가입
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            <div className="cont-area">
              <div className="cont-area__grid">
                <div className="cont-area__section">
                  <article>
                    <div className="article-content">
                      <div className="tbl-area">
                        <div className="table-wrap">
                          <table>
                            <colgroup>
                              <col style={{ width: 160 }} />
                              <col />
                              <col style={{ width: 160 }} />
                              <col />
                            </colgroup>
                            <tbody>
                            <tr>
                              <th>고객구분</th>
                              <td>
                                <div className="form-area colspan2">
                                  <div className="radio-box">
                                    <input
                                        type="radio"
                                        name="radioCheck1"
                                        id="radio1"
                                        defaultChecked
                                    />
                                    <label htmlFor="radio1" className="rdo-txt">
                                      신규고객
                                    </label>
                                    <input
                                        type="radio"
                                        name="radioCheck1"
                                        id="radio2"
                                    />
                                    <label htmlFor="radio2" className="rdo-txt">
                                      기존고객 사용자등록
                                    </label>
                                  </div>
                                </div>
                              </td>
                              <th>가입경로</th>
                              <td>
                                <div className="form-area colspan2">
                                  <div className="form-item col2">
                                    <select className="selectbox">
                                      <option value>선택</option>
                                      <option value>옵션1</option>
                                      <option value>옵션2</option>
                                      <option value>옵션3</option>
                                    </select>
                                    <input type="text" className="inp" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th className="necessary">사업자구분</th>
                              <td>
                                <div className="form-area colspan2">
                                  <div className="radio-box">
                                    <input
                                        type="radio"
                                        name="radioCheck2"
                                        id="radio3"
                                    />
                                    <label htmlFor="radio3" className="rdo-txt">
                                      개인사업자
                                    </label>
                                    <input
                                        type="radio"
                                        name="radioCheck2"
                                        id="radio4"
                                        defaultChecked
                                    />
                                    <label htmlFor="radio4" className="rdo-txt">
                                      법인사업자(주식회사)
                                    </label>
                                    <input
                                        type="radio"
                                        name="radioCheck2"
                                        id="radio5"
                                    />
                                    <label htmlFor="radio5" className="rdo-txt">
                                      법인사업자(유한회사)
                                    </label>
                                  </div>
                                </div>
                              </td>
                              <th className="necessary">고객사명</th>
                              <td>
                                <div className="form-area colspan2">
                                  <div className="form-item">
                                    <input type="text" className="inp" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th className="necessary">사업자번호</th>
                              <td>
                                <div className="form-area colspan2">
                                  <div className="form-item overlap-check">
                                    <input type="text" className="inp" />
                                    <button type="button" className="btn-check">
                                      중복확인
                                    </button>
                                  </div>
                                </div>
                              </td>
                              <th className="necessary">사업자등록증</th>
                              <td>
                                <div className="form-area colspan2">
                                  <div className="form-item file-area">
                                    <label
                                        htmlFor="fileUpload"
                                        className="file-upload"
                                    >
                                      파일등록
                                    </label>
                                    <input type="file" id="fileUpload" />
                                    <p className="inp">
                                      * pdf, gif, jpg, png 가능/ 5M 이하
                                    </p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th className="necessary">법인등록번호</th>
                              <td>
                                <div className="form-area colspan2">
                                  <div className="form-item">
                                    <input type="text" className="inp" />
                                  </div>
                                </div>
                              </td>
                              <th className="necessary">개업연월일</th>
                              <td>
                                <div className="form-area colspan2">
                                  <div className="form-item">
                                    <input type="text" className="inp" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th className="necessary">전화번호</th>
                              <td>
                                <div className="form-area colspan2">
                                  <div className="form-item">
                                    <input type="text" className="inp" />
                                  </div>
                                </div>
                              </td>
                              <th className="necessary">팩스번호</th>
                              <td>
                                <div className="form-area colspan2">
                                  <div className="form-item">
                                    <input type="text" className="inp" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th className="necessary">업태</th>
                              <td>
                                <div className="form-area colspan2">
                                  <div className="form-item">
                                    <input type="text" className="inp" />
                                  </div>
                                </div>
                              </td>
                              <th className="necessary">업종</th>
                              <td>
                                <div className="form-area colspan2">
                                  <div className="form-item">
                                    <input type="text" className="inp" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th className="necessary">주소</th>
                              <td colSpan={3}>
                                <div className="form-item addr">
                                  <button type="button" className="btn-addr">
                                    우편번호찾기
                                  </button>
                                  <input type="text" className="inp" disabled />
                                  <input type="text" className="inp" />
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </article>
                  <article>
                    <div className="article-header">
                      <h3 className="sub-title">대표계정</h3>
                    </div>
                    <div className="article-content">
                      <div className="tbl-area">
                        <div className="table-wrap">
                          <table>
                            <colgroup>
                              <col style={{ width: 160 }} />
                              <col />
                              <col style={{ width: 160 }} />
                              <col />
                            </colgroup>
                            <tbody>
                            <tr>
                              <th className="necessary">회원아이디</th>
                              <td>
                                <div className="form-area colspan2">
                                  <div className="form-item overlap-check">
                                    <input type="text" className="inp" />
                                    <button type="button" className="btn-check">
                                      중복확인
                                    </button>
                                  </div>
                                </div>
                              </td>
                              <th className="necessary">담당자 성명</th>
                              <td>
                                <div className="form-area colspan2">
                                  <div className="form-item">
                                    <input type="text" className="inp" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th className="necessary">비밀번호</th>
                              <td>
                                <div className="form-area colspan2">
                                  <div className="form-item">
                                    <input type="password" className="inp" />
                                  </div>
                                </div>
                              </td>
                              <th className="necessary">비밀번호 재입력</th>
                              <td>
                                <div className="form-area colspan2">
                                  <div className="form-item">
                                    <input type="password" className="inp" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>부서명</th>
                              <td>
                                <div className="form-area colspan2">
                                  <div className="form-item">
                                    <input type="text" className="inp" />
                                  </div>
                                </div>
                              </td>
                              <th>담당자 직급</th>
                              <td>
                                <div className="form-area colspan2">
                                  <div className="form-item">
                                    <input type="text" className="inp" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th className="necessary">휴대폰번호</th>
                              <td>
                                <div className="form-area colspan2">
                                  <div className="form-item">
                                    <input type="text" className="inp" />
                                  </div>
                                </div>
                              </td>
                              <th className="necessary">전화번호</th>
                              <td>
                                <div className="form-area colspan2">
                                  <div className="form-item">
                                    <input type="text" className="inp" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th className="necessary">이메일</th>
                              <td colSpan={3}>
                                <div className="form-area colspan2">
                                  <div className="form-item">
                                    <input type="text" className="inp" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </article>
                  <div className="btn-area gap-x40">
                    <a href="#" className="btn-base type-secondary size-m">
                      <span>취소</span>
                    </a>
                    <a href="#" className="btn-base type-primary size-m grid-m">
                      <span>등록</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* // [End] main-area */}
          <footer className="footer-area" />
        </div>
      </>
  );
}

export default SignUp;

import React, {useEffect, useRef, useState} from 'react'

import Header from '../../containers/header/Header1'
import Footer from '../../containers/footer/Footer'
import {downloadExcel, DownloadTableExcel, useDownloadExcel} from 'react-export-table-to-excel';
import Background from '../../assets/img/menu-visual1.jpg'
import {formatUnixTime, getCurrentDay, getNextMonthDay, getPath, todayDate} from "../../helpers/helpers";
import {store} from "../../store";
import {hideLoader, showLoader} from "../../store/loader/actions";
import axiosInstance from "../../helpers/axiosInstance";
import {addComma} from "../../Utils";
import {toast} from "react-toastify";
import useDidMountEffect from "../../helpers/useDidMountEffect";
import {useNavigate, useParams} from "react-router-dom";
import {format} from "date-fns";
import Modal from "react-bootstrap/Modal";
import Pagination from "../pagination/pagination";

function Kas0502Edit() {

    let {auction_no} = useParams();
    const navigate = useNavigate();

    const tableRef = useRef(null);
    const [auctionData, setAuctionData] = useState({
        auction_no: auction_no,
        auction_stat_cd: '',
        auction_stat_cd_nm: '',
        sale_div_cd: '',
        display_yn: '',
        auction_div_cd: '',
        memo: '',
    });

    const [dateInfo, setDateInfo] = useState({
        start_date: getCurrentDay(),
        start_hour: '00',
        start_minute: '00',
        end_date: getCurrentDay(),
        end_hour: '00',
        end_minute: '00',
    })

    const [filterGoods, setFilterGoods] = useState({
        storage_div_cd: '',
        goods_stat_cd: '',
        goods_grade_cd: '',
        prefer_t_gb: '',
        spec_abbsym: '',
        fail_bidding_cnt_from: '',
        fail_bidding_cnt_to: '',
        goods_t_mm_from: '',
        goods_t_mm_to: '',
        goods_w_mm_from: '',
        goods_w_mm_to: '',
        goods_len_mm_from: '',
        goods_len_mm_to: '',
        goods_no_arr: '',
        stock_stat_cd: '',
    });

    const [auctionDetail, setAuctionDetail] = useState({});

    let hourList = [];
    for (let i = 0; i <= 24; i++) {
        if (i < 10) {
            hourList.push('0' + i);
        } else {
            hourList.push(i);
        }
    }
    let minuteList = [];
    for (let i = 0; i <= 60; i++) {
        if (i < 10) {
            minuteList.push('0' + i);
        } else {
            minuteList.push(i);
        }
    }

    const [showTr, setShowTr] = useState(false)
    const [dataOpenedSection, setDataOpenedSection] = useState('')
    const [totalAmountGoods, setTotalAmountGoods] = useState(0);
    const [totalAmountAuctionGoods, setTotalAmountAuctionGoods] = useState(0);
    const [goodsList, setGoodsList] = useState([]);

    const [auctionGoods, setAuctionGoods] = useState([]);


    const currentAuctionGoods = useRef([]);
    const [showAuctionGoodsDelete, setShowAuctionGoodsDelete] = useState(false);
    const [pageGoods, setPageGoods] = useState(1);
    const [totalPageGoods, setTotalPageGoods] = useState(0);
    const [sizeGoods, setSizeGoods] = useState(50);

    const [isAllGoodsSelected, setAllGoodsSelected] = useState(false);
    const [isAllAuctionGoodsSelected, setAllAuctionGoodsSelected] = useState(false);


    useDidMountEffect(() => {
        searchGoods();
    }, [sizeGoods, pageGoods]);

    // useDidMountEffect(() => {
    //     getAuctionGoods();
    // }, [sizeSelectedGoods, pageSelectedGoods]);

    useEffect(() => {
        getAuctionDetail();
    }, []);

    const formatDates = (startDate, endDate) => {

        let startDateFm = format(new Date(startDate), 'yyyy-MM-dd HH:mm').toString();
        let endDateFm = format(new Date(endDate), 'yyyy-MM-dd HH:mm').toString();
        let startTimes = startDateFm.split(' ');
        let endTimes = endDateFm.split(' ');
        let startHours = startTimes[1].split(":");
        let endHours = endTimes[1].split(":");

        setDateInfo({
            ...dateInfo,
            start_date: startTimes[0],
            start_hour: startHours[0],
            start_minute: startHours[1],
            end_date: endTimes[0],
            end_hour: endHours[0],
            end_minute: endHours[1],
        })

    }

    const getAuctionDetail = async () => {
        const response = await axiosInstance.get('/auction/detail?auctionNo=' + auction_no);
        console.log(response);
        setAuctionDetail(response.data);
        setAuctionData({
            ...auctionData,
            auction_no: auction_no,
            auction_stat_cd: response.data.auction_stat_cd,
            auction_stat_cd_nm: response.data.auction_stat_cd_nm,
            sale_div_cd: response.data.sale_div_cd,
            display_yn: response.data.display_yn,
            auction_div_cd: response.data.auction_div_cd,
            memo: response.data.memo,

        })
        formatDates(response.data.auction_start_dt, response.data.auction_end_dt);
        await getAuctionGoods()
    }

    const getAuctionGoods = async () => {
        let params = "";
        let filterParams = filterGoods;
        filterParams.auction_no = auction_no;
        filterParams.page = pageGoods;
        filterParams.size = sizeGoods;
        if (Object.entries(filterParams).length > 0) {
            params = getPath(filterParams);
        }
        store.dispatch(showLoader());
        try {
            const response = await axiosInstance.get('/auction/getGoodsListByAuction' + params);
            console.log(response);
            if (response.code === 200) {
                let listOfObjs = [];
                for (let i = 0; i < response.data.items.length; i++) {
                    let item = response.data.items[i];
                    item.is_checked = false;
                    item.is_exist = true;
                    listOfObjs.push(item);
                }
                setAuctionGoods(listOfObjs);
                currentAuctionGoods.current = listOfObjs;
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }


    const searchGoods = async () => {
        setTotalAmountGoods(0);
        let params = "";
        let filterParams = filterGoods;
        filterParams.size = sizeGoods;
        filterParams.page = pageGoods;
        if (Object.entries(filterParams).length > 0) {
            params = getPath(filterParams);
        }
        console.log(params)
        store.dispatch(showLoader());
        try {
            const response = await axiosInstance.get('/goods/list' + params);
            if (response.code === 200) {
                let listOfObjs = [];
                for (let i = 0; i < response.data.items.length; i++) {
                    let item = response.data.items[i];
                    item.is_checked = false;
                    listOfObjs.push(item);
                }
                setGoodsList(listOfObjs);
                setTotalPageGoods(Number.parseInt(response.data.totalPage));
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }

    function checkAllGoods(e) {
        console.log(e);
        let listItems = [...goodsList];
        let totalAmount = 0
        for (let i = 0; i < goodsList.length; i++) {
            let item = goodsList[i];
            totalAmount += Number(item.goods_wt);
        }
        setTotalAmountGoods(e ? totalAmount : 0);
        for (let i = 0; i < listItems.length; i++) {
            listItems[i].is_checked = e;
        }
        setGoodsList(listItems);
        setAllGoodsSelected(e);
    }

    function checkAllAuctionGoods(e) {
        console.log(e);
        let listItems = [...auctionGoods];
        let totalAmount = 0
        for (let i = 0; i < auctionGoods.length; i++) {
            let item = auctionGoods[i];
            totalAmount += Number(item.goods_wt);
        }
        setTotalAmountAuctionGoods(e ? totalAmount : 0);
        for (let i = 0; i < listItems.length; i++) {
            listItems[i].is_checked = e;
        }
        setAuctionGoods(listItems);
        setAllAuctionGoodsSelected(e);
    }

    function selectGoodsItem(e, item) {
        console.log(item);
        let listItems = [...goodsList];
        if (e) {
            setTotalAmountGoods(totalAmountGoods + Number(item.goods_wt))
        }
        if (!e) {
            setTotalAmountGoods(totalAmountGoods - Number(item.goods_wt))
        }
        for (let i = 0; i < listItems.length; i++) {
            if (listItems[i].goods_no === item.goods_no) {
                listItems[i].is_checked = e;
            }
        }
        setGoodsList(listItems);
    }

    function selectAuctionGoodsItem(e, item) {
        console.log(item);
        let listItems = [...auctionGoods];
        let demo = JSON.parse(JSON.stringify(listItems));
        if (e) {
            setTotalAmountAuctionGoods(totalAmountGoods + Number(item.goods_wt))
        }
        if (!e) {
            setTotalAmountAuctionGoods(totalAmountGoods - Number(item.goods_wt))
        }
        for (let i = 0; i < demo.length; i++) {
            if (demo[i].goods_no === item.goods_no) {
                demo[i].is_checked = e;
            }
        }
        setAuctionGoods(demo);
    }

    const moveToAuctionList = async () => {
        let oldGoodsList = [...goodsList];
        let newAuctionGoodsList = [...auctionGoods];
        for (let i = 0; i < oldGoodsList.length; i++) {
            if (oldGoodsList[i].is_checked) {
                let isExist = false;
                for (let j = 0; j < newAuctionGoodsList.length; j++) {
                    if (newAuctionGoodsList[j].goods_no === oldGoodsList[i].goods_no) {
                        isExist = true;
                    }
                }
                if (!isExist) {
                    oldGoodsList[i].is_checked = false;
                    newAuctionGoodsList.push(oldGoodsList[i])
                }
            }
        }
        setGoodsList(oldGoodsList);
        setAuctionGoods(newAuctionGoodsList);
        setAllGoodsSelected(false);
        await checkAllGoods(false);
    }

    const updateAuction = async () => {
        console.log(auctionData)
        console.log(dateInfo)
        console.log(auctionGoods)
        const startDate = dateInfo.start_date + ' ' + dateInfo.start_hour + ':' + dateInfo.start_minute;
        const endDate = dateInfo.end_date + ' ' + dateInfo.end_hour + ':' + dateInfo.end_minute;
        const startDateUpdated = new Date(startDate.replace(/-/g, '/'));
        console.log("StartDate : " + startDateUpdated)
        const currentDate = new Date();
        console.log("currentDate : " + currentDate)
        const endDateUpdated = new Date(endDate.replace(/-/g, '/'));
        console.log("endDateUpdated : " + endDateUpdated)
        // check end date must be higher than start date
        if (auctionGoods.length === 0) {
            alert("Please select Goods")
            return;
        }
        const newArrays = checkNewOrDeletedItems();
        console.log(newArrays);
        const payload = {
            ...auctionData,
            auction_start_date: dateInfo.start_date + ' ' + dateInfo.start_hour + ':' + dateInfo.start_minute,
            auction_end_date: dateInfo.end_date + ' ' + dateInfo.end_hour + ':' + dateInfo.end_minute,
            auction_new_goods_ids: newArrays.added_list,
            auction_delete_goods_ids: newArrays.deleted_list,
        }
        store.dispatch(showLoader());
        try {
            const response = await axiosInstance.post('/auction/update', payload);
            if (response.code === 200) {
                toast.dismiss();
                toast.success(('Success'))
                await getAuctionGoods();
                await searchGoods();
                navigate('/kas-0500');
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }

        // checkNewOrDeletedItems();
    }

    const removeFromAuctionList = async () => {
        let list = [...auctionGoods];
        list = JSON.parse(JSON.stringify(list));
        let finalList = [];
        for (let i = 0; i < list.length; i++) {
            console.log(list[i].is_checked)
            if (!list[i].is_checked) {
                finalList.push(list[i]);
            }
        }

        setTotalAmountAuctionGoods(0);
        console.log(finalList)
        setAuctionGoods(finalList);
        setAllAuctionGoodsSelected(false);
        // await checkAllAuctionGoods(false)
    };


    const deleteAuctionGoods = async () => {
        let listOfObj = [];
        for (let i = 0; i < auctionGoods.length; i++) {
            if (auctionGoods[i].is_checked) {
                listOfObj.push(auctionGoods[i].goods_no);
            }
        }

        if (listOfObj.length === 0) {
            alert("Please select items")
            return;
        }

        const payload = {
            auction_no: auction_no,
            auction_goods_ids: listOfObj
        }
        store.dispatch(showLoader());
        try {
            const res = await axiosInstance.post('/auction/deleteGoods', payload);
            console.log(res);
            if (res.code === 200) {
                await getAuctionGoods();
                setShowAuctionGoodsDelete(false)
            } else {
                alert(res.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }

    const checkNewOrDeletedItems = () => {
        const intersection = (set1, set2) => new Set([...set1].filter(x => set2.has(x)))
        const difference = (set1, set2) => new Set([...set1].filter(x => !set2.has(x)))
        const intersectionA = (arr1, arr2) => Array.from(intersection(new Set(arr1), new Set(arr2)))
        const differenceA = (arr1, arr2) => Array.from(difference(new Set(arr1), new Set(arr2)))
        const breakdown = (prev, curr) => ({
            delete_id: differenceA(prev, curr),
            new_id: differenceA(curr, prev),
            existing_id: intersectionA(prev, curr)
        })

        let oldArray = [];
        let newArray = [];

        for (let i = 0; i < currentAuctionGoods.current.length; i++) {
            oldArray.push(currentAuctionGoods.current[i].goods_no);
        }

        for (let i = 0; i < auctionGoods.length; i++) {
            newArray.push(auctionGoods[i].goods_no);
        }


        const result = breakdown(oldArray, newArray)

        return {
            'added_list': result.new_id,
            'deleted_list': result.delete_id
        }
    }

    function onChangeGoodsNo(e) {
        const lines = e.split(/\n/);
        let arrays = [];
        for (let i = 0; i < lines.length; i++) {
            if (/\S/.test(lines[i])) {
                arrays.push(lines[i].trim());
            }
        }
        setFilterGoods({
            ...filterGoods,
            goods_no_arr: arrays
        })
    }


    return (
        <>
            <div id="wrap">
                <Header/>
                {/* [Start] main-area */}
                <section className="main-area" id="main">
                    <div className="menu-visual" style={{backgroundImage: `url(${Background})`}}>
                        <h2 className="menu-visual__tit">
                            경매수정
                        </h2>
                        <div className="breadcrumbs-area">
                            <ol>
                                <li className="breadcrumbs-area__step breadcrumbs-area__step--root">
                                    <a href="/writeAuction1.html" className="breadcrumbs-area__link">
                                        <i className="icon24 icon-home"/>
                                        <span className="a11y">홈으로</span>
                                    </a>
                                </li>
                                <li className="breadcrumbs-area__step breadcrumbs-area__step--more">
                                    {/* [D] sub menu 있을 시, breadcrumbs-area__step--more 클래스 추가 */}
                                    <a href="#!" className="breadcrumbs-area__link">
                                        <span>경매관리</span>
                                    </a>
                                    <ul className="breadcrumbs-area__sub-menu">
                                        <li>
                                            <a href="/writeAuction1.html">경매메뉴 1</a>
                                        </li>
                                        <li>
                                            <a href="/writeAuction1.html">경매메뉴 2</a>
                                        </li>
                                        <li>
                                            <a href="/writeAuction1.html">경매메뉴 3</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="breadcrumbs-area__step">
                                    <a href="#!" className="breadcrumbs-area__link">
                                        <span>경매수정</span>
                                    </a>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className="cont-area cont-area--bg">
                        {/* 배경 색 있을 시, cont-area--bg 클래스 추가 */}
                        <div className="cont-area__grid">
                            <div className="cont-area__body">
                                <div className="tbl-area">
                                    <div className="table-wrap">
                                        <table>
                                            <colgroup>
                                                <col style={{width: '160px'}}/>
                                                <col/>
                                                <col style={{width: '160px'}}/>
                                                <col/>
                                            </colgroup>
                                            <tbody>
                                            <tr>
                                                <th>경매번호</th>
                                                <td>
                                                    <div className="form-area">
                                                        <div className="form-item">
                                                            <input type="text" className="inp" value={auction_no}
                                                                   disabled/>
                                                        </div>
                                                    </div>
                                                </td>
                                                <th className="necessary">판매구분</th>
                                                <td>
                                                    <div className="form-area">
                                                        <div className="radio-box">

                                                            <input type="radio" name="radioCheck2" id="radio3"
                                                                   checked={auctionData.sale_div_cd === 'SD01'}
                                                                   disabled/>
                                                            <label htmlFor="radio3" className="rdo-txt">일반</label>
                                                            <input type="radio" name="radioCheck2" id="radio4"
                                                                   checked={auctionData.sale_div_cd === 'SD02'}
                                                                   disabled/>
                                                            <label htmlFor="radio4" className="rdo-txt">패키지</label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>경매상태</th>
                                                <td>
                                                    <div className="form-area">
                                                        <div className="form-item">
                                                            <input type="text" className="inp"
                                                                   value={auctionData.auction_stat_cd_nm}
                                                                   disabled/>

                                                        </div>
                                                    </div>
                                                </td>
                                                <th className="necessary">경매구분</th>
                                                <td>
                                                    <div className="form-area">
                                                        <div className="radio-box">
                                                            <input type="radio" name="radioCheck4" id="radio5"
                                                                   defaultChecked/>
                                                            <label htmlFor="radio5" className="rdo-txt">추가경매</label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>경매시작일시</th>
                                                <td>
                                                    <div className="form-area">
                                                        <div className="form-item cols3">
                                                            <input type="date" id className="inp type-date"
                                                                   value={dateInfo.start_date}
                                                                   onChange={(e) => setDateInfo({
                                                                       ...dateInfo,
                                                                       start_date: e.target.value
                                                                   })}
                                                            />
                                                            <select className="selectbox mr-10"
                                                                    value={dateInfo.start_hour}
                                                                    onChange={(e) => setDateInfo({
                                                                        ...dateInfo,
                                                                        start_hour: e.target.value
                                                                    })}>
                                                                {
                                                                    hourList.map((x, y) =>
                                                                        <option value={x}>{x}시</option>)
                                                                }
                                                            </select>
                                                            <select className="selectbox mr-10"
                                                                    value={dateInfo.start_minute}
                                                                    onChange={(e) => setDateInfo({
                                                                        ...dateInfo,
                                                                        start_minute: e.target.value
                                                                    })}>
                                                                {
                                                                    minuteList.map((x, y) =>
                                                                        <option value={x}>{x}분</option>)
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </td>
                                                <th className="necessary">노출여부</th>
                                                <td>
                                                    <div className="form-area">
                                                        <div className="radio-box">
                                                            <input type="radio" name="radioCheck71" id="radio71"
                                                                   defaultValue={auctionData.display_yn}
                                                                   value={auctionData.display_yn}
                                                                   checked={auctionData.display_yn === 'Y'}
                                                                   onChange={() => setAuctionData({
                                                                       ...auctionData,
                                                                       display_yn: 'Y'
                                                                   })}/>
                                                            <label htmlFor="radio71" className="rdo-txt">Y</label>
                                                            <input type="radio" name="radioCheck72" id="radio82"
                                                                   defaultValue={auctionData.display_yn}
                                                                   checked={auctionData.display_yn === 'N'}
                                                                   onChange={() => setAuctionData({
                                                                       ...auctionData,
                                                                       display_yn: 'N'
                                                                   })}/>
                                                            <label htmlFor="radio82" className="rdo-txt">N</label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>경매종료일시</th>
                                                <td>
                                                    <div className="form-area">
                                                        <div className="form-item cols3">
                                                            <input type="date" id className="inp type-date"
                                                                   value={dateInfo.end_date}
                                                                   onChange={(e) => setDateInfo({
                                                                       ...dateInfo,
                                                                       end_date: e.target.value
                                                                   })}
                                                            />
                                                            <select className="selectbox mr-10"
                                                                    value={dateInfo.end_hour}
                                                                    onChange={(e) => setDateInfo({
                                                                        ...dateInfo,
                                                                        end_hour: e.target.value
                                                                    })}>
                                                                {
                                                                    hourList.map((x, y) =>
                                                                        <option value={x}>{x}시</option>)
                                                                }
                                                            </select>
                                                            <select className="selectbox mr-10"
                                                                    value={dateInfo.end_minute}
                                                                    onChange={(e) => setDateInfo({
                                                                        ...dateInfo,
                                                                        end_minute: e.target.value
                                                                    })}>
                                                                {
                                                                    minuteList.map((x, y) =>
                                                                        <option value={x}>{x}분</option>)
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </td>
                                                <th>비고</th>
                                                <td>
                                                    <div className="form-area">
                                                        <div className="form-item">
                                                            <input type="text" className="inp"
                                                                   defaultValue={auctionData.memo}
                                                                   onChange={(e) => setAuctionData({
                                                                       ...auctionData,
                                                                       memo: e.target.value
                                                                   })}/>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>경매입찰대상목록</th>
                                                <td colSpan={3}>
                                                    <div className="form-area">
                                                        <div className="form-item">
                                                            <input type="text" className="inp"/>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            {
                                                showTr ?
                                                    <tr>
                                                        <th>대량 파일 업로드</th>
                                                        <td colSpan={3}>
                                                            <div className="file-upload">
                                                                <label htmlFor="file1" className="btn-file">엑셀
                                                                    업로드</label>
                                                                {/* <!--<input type="file" id="file1" className="file">--> */}
                                                                <div className="file-list">
                                                                    <p>20220123_Product.xlsx</p>
                                                                </div>
                                                            </div>
                                                            <ul className="dott-list gap-x20">
                                                                <li>대량등록의 경우 정상 등록 가능 확인이 <span>최대 30분 시간이</span> 소요됩니다.
                                                                </li>
                                                                <li>등록가능한 확장자 : xlsx, csv, xls</li>
                                                            </ul>
                                                            <ul className="dott-list gap-x20">
                                                                <li><span>일반 등록시 제품 [판매구분 = 일반] 상품만 등록 가능합니다.</span>
                                                                </li>
                                                                <li><span>패키지 등록시 제품의[판매구분 = 패키지] 상품만 등록 가능합니다.</span>
                                                                </li>
                                                            </ul>
                                                            <div className="format-area gap-x20">
                                                                <button type="button" className="btn-format">포멧 다운로드
                                                                </button>
                                                            </div>
                                                            <ul className="dott-list gap-x20">
                                                                <li>포멧에 맞춰서 파일을 업로드 해주세요.</li>
                                                                <li>최대 <span>3,000</span>건까지 가능합니다.</li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    : null
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="folding-area" data-open-section={dataOpenedSection}>
                        <div className="folding-area__section left">
                            <div className="cont-area">
                                <div className="cont-area__header">
                                    <div className="cont-area__header-inner">
                                        <h3>등록가능 제품</h3>
                                        <button type="button" className="btn-folding"
                                                onClick={() => dataOpenedSection == 'left' ? setDataOpenedSection('') : setDataOpenedSection('left')}/>
                                    </div>
                                </div>
                                <div className="folding-area__scroll">
                                    <div className="cont-area__grid">
                                        <div className="cont-area__section">
                                            <div className="tbl-area">
                                                <div className="table-wrap">
                                                    <table>
                                                        <colgroup>
                                                            <col style={{width: '160px'}}/>
                                                            <col/>
                                                        </colgroup>
                                                        <tbody>
                                                        <tr>
                                                            <th>총중량</th>
                                                            <td>
                                                                <div className="form-area">
                                                                    <div className="form-item">
                                                                        <input type="text" className="inp"/>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cont-area__section">
                                            <div className="tbl-area">
                                                <div className="table-wrap">
                                                    <table>
                                                        <colgroup>
                                                            <col style={{width: '120px'}}/>
                                                            <col/>
                                                            <col style={{width: '120px'}}/>
                                                            <col/>
                                                            <col style={{width: '120px'}}/>
                                                            <col/>
                                                            <col style={{width: '120px'}}/>
                                                            <col/>
                                                        </colgroup>
                                                        <tbody>
                                                        <tr>
                                                            <th>창고구분</th>
                                                            <td>
                                                                <div className="form-area">
                                                                    <div className="form-item">
                                                                        <select className="selectbox"
                                                                                onChange={(e) => setFilterGoods({
                                                                                    ...filterGoods,
                                                                                    storage_div_cd: e.target.value
                                                                                })}>
                                                                            <option value=''>전체</option>
                                                                            <option value='G101'>우성</option>
                                                                            <option value='G041'>삼우</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <th>제품상태</th>
                                                            <td>
                                                                <div className="form-area">
                                                                    <div className="form-item">
                                                                        <select className="selectbox"
                                                                                onChange={(e) => setFilterGoods({
                                                                                    ...filterGoods,
                                                                                    goods_stat_cd: e.target.value
                                                                                })}>
                                                                            <option value=''>전체</option>
                                                                            <option value='GS01'>등록대기</option>
                                                                            <option value='GS03'>판매대기</option>
                                                                            <option value='GS05'>판매중</option>
                                                                            <option value='GS05'>판매중</option>
                                                                            <option value='GS07'>판매완료</option>
                                                                            <option value='GS09'>출하대기</option>
                                                                            <option value='GS11'>운송대기</option>
                                                                            <option value='GS13'>인도완료</option>
                                                                            <option value='GS90'>할당취소</option>
                                                                            <option value='GS91'>반품</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <th>제품등급</th>
                                                            <td>
                                                                <div className="form-area">
                                                                    <div className="check-box">
                                                                        <input type="checkbox" id="check1"/>
                                                                        <label htmlFor="check1" className="lb-chk">
                                                                            <span>1등급</span>
                                                                        </label>
                                                                        <input type="checkbox" id="check2"/>
                                                                        <label htmlFor="check2" className="lb-chk">
                                                                            <span>2등급</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </td>


                                                            <th>재고상태</th>
                                                            <td>
                                                                <div className="form-area">
                                                                    <div className="form-item">
                                                                        <select className="selectbox"
                                                                                onChange={(e) => setFilterGoods({
                                                                                    ...filterGoods,
                                                                                    stock_stat_cd: e.target.value
                                                                                })}>
                                                                            <option value=''>전체</option>
                                                                            <option value='SSC01'>자사재고</option>
                                                                            <option value='SSC02'>타사재고</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <th>정척여부</th>
                                                            <td>
                                                                <div className="form-area">
                                                                    <div className="form-item">
                                                                        <input type="text" className="inp"
                                                                               onChange={(e) => setFilterGoods({
                                                                                   ...filterGoods,
                                                                                   prefer_t_gb: e.target.value
                                                                               })}/>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <th>규격약호</th>
                                                            <td>
                                                                <div className="form-area">
                                                                    <div className="form-item">
                                                                        <input type="text" className="inp"
                                                                               onChange={(e) => setFilterGoods({
                                                                                   ...filterGoods,
                                                                                   spec_abbsym: e.target.value
                                                                               })}/>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <th>유찰회수</th>
                                                            <td>
                                                                <div className="form-area">
                                                                    <div className="form-item type-range">
                                                                        <input type="text" className="inp"
                                                                               onChange={(e) => setFilterGoods({
                                                                                   ...filterGoods,
                                                                                   fail_bidding_cnt_from: e.target.value
                                                                               })}
                                                                        />
                                                                        <span className="form-division">~</span>
                                                                        <input type="text" className="inp"
                                                                               onChange={(e) => setFilterGoods({
                                                                                   ...filterGoods,
                                                                                   fail_bidding_cnt_to: e.target.value
                                                                               })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <th rowSpan={3}>제품번호</th>
                                                            <td rowSpan={3}>
                                                                <div className="product-num">
                                                                    <div className="item">
                                                                        <div className="form-area">
                                                                            <div className="form-item">
                                                                             <textarea className="inp"
                                                                                       defaultValue={""}
                                                                                       onChange={(e) => onChangeGoodsNo(e.target.value)}
                                                                             />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="summary">
                                                                        <p>
                                                                            *제품번호, 패키지번호 구분은 , 또는 엔터로 구분<br/><br/>
                                                                            예) A3022,A0033
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>두께</th>
                                                            <td>
                                                                <div className="form-area">
                                                                    <div className="form-item type-range">
                                                                        <input type="text" className="inp"
                                                                               onChange={(e) => setFilterGoods({
                                                                                   ...filterGoods,
                                                                                   goods_t_mm_from: e.target.value
                                                                               })}
                                                                        />
                                                                        <span className="form-division">~</span>
                                                                        <input type="text" className="inp"
                                                                               onChange={(e) => setFilterGoods({
                                                                                   ...filterGoods,
                                                                                   goods_t_mm_to: e.target.value
                                                                               })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <th>폭</th>
                                                            <td>
                                                                <div className="form-area">
                                                                    <div className="form-item type-range">
                                                                        <input type="text" className="inp"
                                                                               onChange={(e) => setFilterGoods({
                                                                                   ...filterGoods,
                                                                                   goods_w_mm_from: e.target.value
                                                                               })}
                                                                        />
                                                                        <span className="form-division">~</span>
                                                                        <input type="text" className="inp"
                                                                               onChange={(e) => setFilterGoods({
                                                                                   ...filterGoods,
                                                                                   goods_w_mm_to: e.target.value
                                                                               })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <th>길이</th>
                                                            <td>
                                                                <div className="form-area">
                                                                    <div className="form-item type-range">
                                                                        <input type="text" className="inp"
                                                                               onChange={(e) => setFilterGoods({
                                                                                   ...filterGoods,
                                                                                   goods_len_mm_from: e.target.value
                                                                               })}
                                                                        />
                                                                        <span className="form-division">~</span>
                                                                        <input type="text" className="inp"
                                                                               onChange={(e) => setFilterGoods({
                                                                                   ...filterGoods,
                                                                                   goods_len_mm_to: e.target.value
                                                                               })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </td>

                                                        </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="btn-area gap-x40">
                                            <a href="#" className="btn-base type-secondary size-m">
                                                <span>초기화</span>
                                            </a>
                                            <a href="#!" className="btn-base type-primary size-m grid-m"
                                               onClick={() => searchGoods()}>
                                                <i className="icon24 icon-search-light"/>
                                                <span>조회</span>
                                            </a>
                                        </div>
                                        <div className="cont-area__section gap-x40">
                                            <div className="tbl-area">
                                                <div className="tbl-upper">
                                                    <div className="tbl-upper__util">
                                                        <strong className="tbl-area__tit">
                                                            선택중량(KG) {addComma(totalAmountGoods)}
                                                        </strong>
                                                        <DownloadTableExcel filename="kasco" sheet="users"
                                                                            currentTableRef={tableRef.current}>
                                                            <a href="#" className="btn-base type-dark">
                                                                <span>다운로드</span>
                                                            </a>

                                                        </DownloadTableExcel>
                                                        <select className="selectbox type-light"
                                                                onChange={(e) => setSizeGoods(e.target.value)}>
                                                            <option value='50'>50개씩</option>
                                                            <option value='100'>100개씩</option>
                                                            <option value='200'>200개씩</option>
                                                            <option value='500'>500개씩</option>
                                                            <option value='1000'>1000개씩</option>
                                                            <option value='5000'>5000개씩</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="tbl-base type-hover scroll-warp">
                                                    <table className="table-product" ref={tableRef}>
                                                        <caption>
                                                            정보 테이블
                                                        </caption>
                                                        <colgroup/>
                                                        <thead>
                                                        <tr>
                                                            <th scope="col">
                                                                <input type="checkbox" id="chk2-all"
                                                                       name="chk2Group"
                                                                       onChange={(e) => checkAllGoods(e.target.checked)}
                                                                />
                                                                <label htmlFor="chk2-all" className="lb-chk">
                                                                    <span className="a11y">전체선택</span></label>
                                                            </th>
                                                            <th scope="col">번호</th>
                                                            <th scope="col">ProNO</th>
                                                            <th scope="col">제품번호</th>
                                                            <th scope="col">입고상태</th>
                                                            <th scope="col">매입구분</th>
                                                            <th scope="col">매입처</th>
                                                            <th scope="col">제조사</th>
                                                            <th scope="col">판매구분</th>
                                                            <th scope="col">상시유형</th>
                                                            <th scope="col">제품구분</th>
                                                            <th colSpan={2} scope="col">판매유형</th>
                                                            <th scope="col">제품상태</th>
                                                            <th scope="col">창고</th>
                                                            <th scope="col">메모</th>
                                                            <th scope="col">입고일자</th>
                                                            <th scope="col">입고차수</th>
                                                            <th scope="col">매입가</th>
                                                            <th scope="col">판매가</th>
                                                            <th scope="col">경매시작단가</th>
                                                            <th scope="col">제품군</th>
                                                            <th scope="col">대표품명</th>
                                                            <th scope="col">제품등급</th>
                                                            <th scope="col">두께</th>
                                                            <th scope="col">폭</th>
                                                            <th scope="col">길이</th>
                                                            <th scope="col">중량</th>
                                                            <th scope="col">YP</th>
                                                            <th scope="col">TS</th>
                                                            <th scope="col">EL</th>
                                                            <th scope="col">C%</th>
                                                            <th scope="col">SI</th>
                                                            <th scope="col">MN</th>
                                                            <th scope="col">P</th>
                                                            <th scope="col">S</th>
                                                            {/*<th scope="col">야드구분</th>*/}
                                                            {/*<th scope="col">매수</th>*/}
                                                            {/*<th scope="col">인장시험</th>*/}
                                                            {/*<th scope="col">화학성분1</th>*/}
                                                            {/*<th scope="col">화학성분2</th>*/}
                                                            <th scope="col">여재원인</th>
                                                            <th scope="col">여제원인명1</th>
                                                            {/*<th scope="col">여제원인명2</th>*/}
                                                            {/*<th scope="col">여제원인명3</th>*/}
                                                            <th scope="col">용도코드</th>
                                                            <th scope="col">용도명</th>
                                                            {/*<th scope="col">정척여부</th>*/}
                                                            <th scope="col">규격약호</th>
                                                            <th scope="col">제품사양</th>
                                                            <th scope="col">출고일자</th>
                                                            <th scope="col">순번</th>
                                                            <th scope="col">차량번호</th>
                                                            {/*<th scope="col">유찰횟수</th>*/}
                                                            <th scope="col">재고상태</th>
                                                            <th scope="col">최종수정자</th>
                                                            <th scope="col">최종수정일시</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            goodsList.map((item, index) => (
                                                                <tr>
                                                                    <td>
                                                                        <input type="checkbox"
                                                                               checked={item.is_checked}
                                                                               id={`chkGoods${index}-1`}
                                                                               name="chkGoodsGroup"
                                                                               onChange={(e) => selectGoodsItem(e.target.checked, item)}/>
                                                                        <label htmlFor={`chkGoods${index}-1`}
                                                                               className="lb-chk">
                                                                            <span className="a11y">선택</span>
                                                                        </label>
                                                                    </td>
                                                                    <td>{goodsList.length - index}</td>
                                                                    <td><a href></a></td>
                                                                    <td><a href>{item.goods_no}</a></td>
                                                                    <td>{item.receipt_stat_cd_nm}</td>
                                                                    <td>{item.purchase_div_cd_nm}</td>
                                                                    <td>{item.supplier_div_cd_nm}</td>
                                                                    <td>{item.maker_cd_nm}</td>
                                                                    <td>{item.sale_div_cd_nm}</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>{item.sale_type_cd_nm}</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>{item.goods_stat_cd_nm}</td>
                                                                    <td>{item.storage_div_cd_nm}</td>
                                                                    <td>{item.memo}</td>
                                                                    <td>{item.lwedt}</td>
                                                                    <td></td>
                                                                    <td>{item.purchase_price}</td>
                                                                    <td>{item.sale_price}</td>
                                                                    <td>{item.auction_start_price}</td>
                                                                    <td>{item.spartetext}</td>
                                                                    <td>{item.itemname_cd}</td>
                                                                    <td>{item.goods_grade_cd_nm}</td>
                                                                    <td>{item.goods_t_mm}</td>
                                                                    <td>{item.goods_w_mm}</td>
                                                                    <td>{item.goods_len_mm}</td>
                                                                    <td>{addComma(item.goods_wt)}</td>
                                                                    <td>{item.yp_wrslt}</td>
                                                                    <td>{item.ts_wrslt}</td>
                                                                    <td>{item.el_wrslt}</td>
                                                                    <td>{item.c_wrslt}</td>
                                                                    <td>{item.si_wrslt}</td>
                                                                    <td>{item.mn_wrslt}</td>
                                                                    <td>{item.p_wrslt}</td>
                                                                    <td>{item.s_wrslt}</td>
                                                                    {/*<td></td>*/}
                                                                    {/*<td></td>*/}
                                                                    {/*<td></td>*/}
                                                                    {/*<td></td>*/}
                                                                    {/*<td></td>*/}
                                                                    <td>{item.yeojae_cause_cd}</td>
                                                                    <td>{item.yeojae_cause_cd_nm}</td>
                                                                    {/*<td></td>*/}
                                                                    {/*<td></td>*/}
                                                                    <td>{item.usage_cd}</td>
                                                                    <td>{item.usage_cd_nm}</td>
                                                                    {/*<td></td>*/}
                                                                    <td>{item.spec_abbsym}</td>
                                                                    <td>{item.goods_wdh}</td>
                                                                    <td>{item.release_dt}</td>
                                                                    <td>{item.turn}</td>
                                                                    <td>{item.car_no}</td>
                                                                    {/*<td></td>*/}
                                                                    <td>{item.stock_stat_cd_nm}</td>
                                                                    <td>{item.mode_id}</td>
                                                                    <td>{format(new Date(item.mod_date), 'yyyy-MM-dd HH:mm')}</td>
                                                                </tr>

                                                            ))
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <Pagination pageCount={totalPageGoods}
                                                            paginate={(p) => setPageGoods(p.selected + 1)}></Pagination>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="folding-area__btn-area">
                            <button type="button" className="folding-equal-btn"
                                    onClick={() => setDataOpenedSection('')}>
                                <span className="a11y">원상태로</span>
                            </button>
                            <div className="move-btn">
                                <button type="button" className="move-btn__btn move-btn__btn--right"
                                        onClick={() => moveToAuctionList()}>
                                    <span className="a11y">우측으로 보내기</span>
                                </button>
                                <button type="button" className="move-btn__btn move-btn__btn--left"
                                        onClick={() => removeFromAuctionList()}>
                                    <span className="a11y">좌측으로 보내기</span>
                                </button>
                            </div>
                        </div>
                        <div className="folding-area__section right">
                            <div className="cont-area">
                                <div className="cont-area__header">
                                    <div className="cont-area__header-inner">
                                        <h3>대상 제품</h3>
                                        <button type="button" className="btn-folding"
                                                onClick={() => dataOpenedSection == 'right' ? setDataOpenedSection('') : setDataOpenedSection('right')}/>
                                    </div>
                                </div>
                                <div className="folding-area__scroll">
                                    <div className="cont-area__grid">
                                        <div className="cont-area__section gap-x40">
                                            <div className="tbl-area">
                                                <div className="tbl-upper">
                                                    <div className="tbl-upper__cont">
                                                        <button type="button" className="btn-round-util"
                                                                onClick={() => removeFromAuctionList()}>
                                                            <span>삭제</span>
                                                        </button>
                                                    </div>
                                                    <div className="tbl-upper__util">
                                                        <strong className="tbl-area__tit">
                                                            선택중량(KG) {addComma(totalAmountAuctionGoods)}
                                                        </strong>
                                                        <a href="#" className="btn-base type-dark">
                                                            <span>다운로드</span>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="tbl-base type-hover scroll-warp">
                                                    <table className="table-product">
                                                        <caption>
                                                            정보 테이블
                                                        </caption>
                                                        <colgroup/>
                                                        <thead>
                                                        <tr>
                                                            <th scope="col">
                                                                <input type="checkbox" id="chkAuction-all"
                                                                       name="chkAuctionGroup" onChange={(e) => {
                                                                    checkAllAuctionGoods(e.target.checked)
                                                                }}/>
                                                                <label htmlFor="chkAuction-all" className="lb-chk">
                                                                    <span className="a11y">전체선택</span></label>
                                                            </th>
                                                            <th scope="col">번호</th>
                                                            <th scope="col">ProNO</th>
                                                            <th scope="col">제품번호</th>
                                                            <th scope="col">입고상태</th>
                                                            <th scope="col">매입구분</th>
                                                            <th scope="col">매입처</th>
                                                            <th scope="col">제조사</th>
                                                            <th scope="col">판매구분</th>
                                                            <th scope="col">상시유형</th>
                                                            <th scope="col">제품구분</th>
                                                            <th colSpan={2} scope="col">판매유형</th>
                                                            <th scope="col">제품상태</th>
                                                            <th scope="col">창고</th>
                                                            <th scope="col">메모</th>
                                                            <th scope="col">입고일자</th>
                                                            <th scope="col">입고차수</th>
                                                            <th scope="col">매입가</th>
                                                            <th scope="col">판매가</th>
                                                            <th scope="col">경매시작단가</th>
                                                            <th scope="col">제품군</th>
                                                            <th scope="col">대표품명</th>
                                                            <th scope="col">품명</th>
                                                            <th scope="col">진도코드</th>
                                                            <th scope="col">제품등급</th>
                                                            <th scope="col">두께</th>
                                                            <th scope="col">폭</th>
                                                            <th scope="col">길이</th>
                                                            <th scope="col">중량</th>
                                                            <th scope="col">YP</th>
                                                            <th scope="col">TS</th>
                                                            <th scope="col">EL</th>
                                                            <th scope="col">C%</th>
                                                            <th scope="col">SI</th>
                                                            <th scope="col">MN</th>
                                                            <th scope="col">P</th>
                                                            <th scope="col">S</th>
                                                            <th scope="col">야드구분</th>
                                                            <th scope="col">매수</th>
                                                            <th scope="col">인장시험</th>
                                                            <th scope="col">화학성분1</th>
                                                            <th scope="col">화학성분2</th>
                                                            <th scope="col">여재원인</th>
                                                            <th scope="col">여제원인명1</th>
                                                            <th scope="col">여제원인명2</th>
                                                            <th scope="col">여제원인명3</th>
                                                            <th scope="col">용도코드</th>
                                                            <th scope="col">용도명</th>
                                                            <th scope="col">정척여부</th>
                                                            <th scope="col">규격약호</th>
                                                            <th scope="col">제품사양</th>
                                                            <th scope="col">출고일자</th>
                                                            <th scope="col">순번</th>
                                                            <th scope="col">차량번호</th>
                                                            <th scope="col">유찰횟수</th>
                                                            <th scope="col">재고상태</th>
                                                            <th scope="col">최종수정자</th>
                                                            <th scope="col">최종수정일시</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            auctionGoods.map((item, index) => (
                                                                <tr>
                                                                    <td>
                                                                        <input type="checkbox"
                                                                               checked={item.is_checked}
                                                                               id={`chkAuctionGoods${index}-1`}
                                                                               name="chkAuctionGoodsGroup"
                                                                               onChange={(e) => {
                                                                                   selectAuctionGoodsItem(e.target.checked, item)
                                                                               }}
                                                                        />
                                                                        <label htmlFor={`chkAuctionGoods${index}-1`}
                                                                               className="lb-chk">
                                                                            <span className="a11y">선택</span>
                                                                        </label>
                                                                    </td>
                                                                    <td>{auctionGoods.length - index}</td>
                                                                    <td><a href></a></td>
                                                                    <td><a href>{item.goods_no}</a></td>
                                                                    <td>{item.receipt_stat_cd_nm}</td>
                                                                    <td>{item.purchase_div_cd_nm}</td>
                                                                    <td>{item.supplier_div_cd_nm}</td>
                                                                    <td>{item.maker_cd_nm}</td>
                                                                    <td>{item.sale_div_cd_nm}</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>{item.sale_type_cd_nm}</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>{item.goods_stat_cd_nm}</td>
                                                                    <td>{item.storage_div_cd_nm}</td>
                                                                    <td>{item.memo}</td>
                                                                    <td>{item.lwedt}</td>
                                                                    <td></td>
                                                                    <td>{item.purchase_price}</td>
                                                                    <td>{item.sale_price}</td>
                                                                    <td>{item.auction_start_price}</td>
                                                                    <td>{item.spartetext}</td>
                                                                    <td>{item.itemname_cd}</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>{item.goods_grade_cd_nm}</td>
                                                                    <td>{item.goods_t_mm}</td>
                                                                    <td>{item.goods_w_mm}</td>
                                                                    <td>{item.goods_len_mm}</td>
                                                                    <td>{addComma(item.goods_wt)}</td>
                                                                    <td>{item.yp_wrslt}</td>
                                                                    <td>{item.ts_wrslt}</td>
                                                                    <td>{item.el_wrslt}</td>
                                                                    <td>{item.c_wrslt}</td>
                                                                    <td>{item.si_wrslt}</td>
                                                                    <td>{item.mn_wrslt}</td>
                                                                    <td>{item.p_wrslt}</td>
                                                                    <td>{item.s_wrslt}</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>{item.usage_cd}</td>
                                                                    <td>{item.usage_cd_nm}</td>
                                                                    <td></td>
                                                                    <td>{item.spec_abbsym}</td>
                                                                    <td>{item.goods_wdh}</td>
                                                                    <td>{item.release_dt}</td>
                                                                    <td>{item.turn}</td>
                                                                    <td>{item.car_no}</td>
                                                                    <td></td>
                                                                    <td>{item.stock_stat_cd_nm}</td>
                                                                    <td>{item.mode_id}</td>
                                                                    <td>{format(new Date(item.mod_date), 'yyyy-MM-dd HH:mm')}</td>
                                                                </tr>

                                                            ))
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cont-area">
                        <div className="cont-area__grid">
                            <div className="cont-area__section">
                                <div className="btn-area">
                                    <a href="#" className="btn-base type-secondary size-m">
                                        <span>취소</span>
                                    </a>
                                    <a href="#!" className="btn-base type-primary size-m grid-m"
                                       onClick={() => updateAuction()}>
                                        <span>수정</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={showAuctionGoodsDelete} onHide={() => setShowAuctionGoodsDelete(false)}
                           aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Header closeButton>
                            <Modal.Title>삭제</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            삭제하시겠습니까?
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn-base type-secondary size-m me-3"
                                    onClick={() => setShowAuctionGoodsDelete(false)}>
                                <span>NO</span>
                            </button>
                            <button className="btn-base type-primary size-m grid-m"
                                    onClick={() => deleteAuctionGoods()}>
                            <span>
                              YES
                            </span>
                            </button>
                        </Modal.Footer>
                    </Modal>

                </section>
                {/* // [End] main-area */}
                <Footer/>
            </div>
        </>
    )
}

export default Kas0502Edit
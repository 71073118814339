import React, {useEffect, useRef, useState} from 'react'

import Header from '../../containers/header/Header1'
import Footer from '../../containers/footer/Footer'

import Background from '../../assets/img/menu-visual1.jpg'
import {Link} from "react-router-dom";
import {getPath, getUnixTimeByDate} from "../../helpers/helpers";
import {useSelector} from "react-redux";
import {format} from "date-fns";
import useDidMountEffect from "../../helpers/useDidMountEffect";
import {store} from "../../store";
import {hideLoader, showLoader} from "../../store/loader/actions";
import axiosInstance from "../../helpers/axiosInstance";
import {toast} from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Pagination from "../pagination/pagination";
import {DownloadTableExcel} from "react-export-table-to-excel";
import {addComma} from "../../Utils";

function Kas0858() {
    const {token, user} = useSelector((store) => store.auth);

    const [query, setQuery] = useState({
        start_confirm_dt: '',
        end_confirm_dt: '',
        start_send_dt: '',
        end_send_dt: '',
        storage_div_cd: '',
        cust_corp_nm: '',
        cust_corp_code: '',
        cust_corp_no: '',
        order_no_arr: '',
        shipment_yn: '',
        dest_sigun_cd: '',
        dest_nm: '',
        dest_seq: ''
    });
    const isMounted = useRef(false);

    const [startDateFm, setStartDateFm] = useState(null);
    const [endDateFm, setEndDateFm] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [page, setPage] = useState(1);
    const [size, setSize] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [list, setList] = useState([]);

    const [use1Checked, setUse1Checked] = useState(false);
    const [use2Checked, setUse2Checked] = useState(false);


    useEffect(() => {
        if (!isMounted.current) {
            let currentDate = new Date();
            let startOfDayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);
            setStartDateFm(format(startOfDayDate, 'yyyy-MM-dd'));
            setStartDate(getUnixTimeByDate(startOfDayDate));

            let endOfDayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);
            setEndDateFm(format(endOfDayDate, 'yyyy-MM-dd'))
            setEndDate(getUnixTimeByDate(endOfDayDate))
            isMounted.current = true;
        }
    }, []);


    const tableRef = useRef(null);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const [queryData, setQueryData] = useState({
        corp_nm: '', business_number: ''
    });
    const [queryResult, setQueryResult] = useState([]);
    const [queryPage, setQueryPage] = useState(1);
    const [querySize, setQuerySize] = useState(10);
    const [queryTotalPage, setQueryTotalPage] = useState(0);
    const [showFindCustomer, setShowFindCustomer] = useState(false);

    const [showModalDestination, setShowModalDestination] = useState(false);
    const [queryDestSignCd, setQueryDestSignCd] = useState(null);
    const [queryDestNm, setQueryDestNm] = useState(null);
    const [queryDestPage, setQueryDestPage] = useState(1);
    const [queryDestSize, setQueryDestSize] = useState(10);
    const [queryDestTotalPage, setQueryDestTotalPage] = useState(0);
    const [queryDestList, setQueryDestList] = useState([]);


    useDidMountEffect(() => {
        searchQuery();
    }, [size, page]);

    useDidMountEffect(() => {
        searchCustomer();
    }, [querySize, queryPage]);

    useDidMountEffect(() => {
        getDestList();
    }, [queryDestPage, queryDestSize]);

    async function clearDestInfo() {
        setQueryDestList([]);
        setQueryDestNm(null);
        setQueryDestSignCd(null);
    }

    async function selectDest(item) {
        console.log(item);
        setQuery({
            ...query,
            dest_sigun_cd: item.dest_sigun_cd,
            dest_seq: item.dest_seq,
            dest_nm: item.dest_nm,
        })
        setShowModalDestination(false);
    }

    async function getDestList() {
        let params;
        let filterParams = {
            dest_sigun_cd: queryDestSignCd,
            dest_nm: queryDestNm,
        }
        filterParams.size = queryDestSize;
        filterParams.page = queryDestPage;
        params = getPath(filterParams);
        store.dispatch(showLoader());
        try {
            const res = await axiosInstance.get('/dest/destList' + params);
            store.dispatch(hideLoader());
            if (res.code === 200) {
                setQueryDestList(res.data.items);
                setQueryDestTotalPage(Number.parseInt(res.data.totalPage));
            } else {
                alert(res.message);
            }
        } catch (e) {
            store.dispatch(hideLoader());
        }
    }

    const searchCustomer = async () => {
        let params;
        let filterParams = queryData;
        queryData.size = querySize;
        queryData.page = queryPage;
        params = getPath(filterParams);
        try {
            store.dispatch(showLoader());
            const response = await axiosInstance.get('/auth/searchCustomer' + params);
            console.log(response);
            if (response.code === 200) {
                toast.dismiss();
                toast.success(('Success'))
                setQueryResult(response.data.items);
                setQueryTotalPage(Number.parseInt(response.data.totalPage));
                store.dispatch(hideLoader());
            } else {
                store.dispatch(hideLoader());
                alert(response.message);
            }
        } catch (e) {
            store.dispatch(hideLoader());
        }
    }

    const selectCustomer = (item) => {
        console.log(item);
        setQuery({
            ...query,
            cust_corp_code: item.cust_corp_code,
            business_num: item.business_num,
            cust_corp_no: item.cust_corp_no,
            cust_corp_nm: item.corp_nm
        })
        setShowFindCustomer(false);
    }

    function onChangeOrderNo(e) {
        const lines = e.split(/\n/);
        let arrays = [];
        for (let i = 0; i < lines.length; i++) {
            if (/\S/.test(lines[i])) {
                arrays.push(lines[i].trim());
            }
        }
        setQuery({
            ...query,
            order_no_arr: arrays
        })
    }

    const searchQuery = async () => {
        setTotalAmount(0);
        let params = "";
        let filterParams = query;
        filterParams.start_dt = startDate;
        filterParams.end_dt = endDate;
        filterParams.page = page;
        filterParams.size = size;
        if (Object.entries(filterParams).length > 0) {
            params = getPath(filterParams);
        }
        try {
            store.dispatch(showLoader());
            const response = await axiosInstance.get('/shipment/shippingPerformanceList' + params);
            if (response.code === 200) {
                let listOfObjs = [];
                for (let i = 0; i < response.data.items.length; i++) {
                    let item = response.data.items[i];
                    item.is_checked = false;
                    listOfObjs.push(item);
                }
                setList(listOfObjs);
                setTotalPage(Number.parseInt(response.data.totalPage));
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }

    function checkAllOrders(e) {
        let listItems = [...list];
        let totalAmount = 0
        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            totalAmount += Number(item.goods_wt);
        }
        setTotalAmount(e ? totalAmount : 0);
        for (let i = 0; i < listItems.length; i++) {
            listItems[i].is_checked = e;
        }
        setList(listItems);
    }

    function selectOrderItem(e, item) {
        let listItems = [...list];
        if (e) {
            setTotalAmount(totalAmount + Number(item.goods_wt))
        }
        if (!e) {
            setTotalAmount(totalAmount - Number(item.goods_wt))
        }
        for (let i = 0; i < listItems.length; i++) {
            if (listItems[i].goods_no === item.goods_no) {
                listItems[i].is_checked = e;
            }
        }
        setList(listItems);
    }

    const checkUse1 = (e) => {
        if (e.target.checked) {
            setUse1Checked(true);
            setQuery({
                ...query,
                shipment_yn: 'Y',
            })
            if (use2Checked) {
                setUse2Checked(false);
            }
        } else {
            setUse1Checked(false);
            setQuery({
                ...query,
                shipment_yn: '',
            })
        }
    }
    const checkUse2 = (e) => {
        if (e.target.checked) {
            setUse2Checked(true);
            setQuery({
                ...query,
                use_yn: 'N',
            })
            if (use1Checked) {
                setUse1Checked(false);
            }
        } else {
            setUse2Checked(false);
            setQuery({
                ...query,
                use_yn: '',
            })
        }
    }


    const cancelOrderShipment = async () => {
        let listOfObjs = [];
        for (let i = 0; i < list.length; i++) {
            if (list[i].is_checked) {
                listOfObjs.push(list[i].ord_no);
            }
        }
        if (listOfObjs.length === 0) {
            alert("Please select items")
            return;
        }
        console.log(listOfObjs);
        const payload = {
            order_no_arr: listOfObjs
        }
        store.dispatch(showLoader());
        try {
            const response = await axiosInstance.post('/shipment/cancelOrderShipment', payload);
            if (response.code === 200) {
                toast.dismiss();
                toast.success(('Success'));
                await searchQuery();
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }
    return (
        <>
            <div id="wrap">
                <Header/>
                {/* [Start] main-area */}
                <section className="main-area" id="main">
                    <div className="menu-visual" style={{backgroundImage: `url(${Background})`}}>
                        <h2 className="menu-visual__tit">
                            출고관리
                        </h2>
                        <div className="breadcrumbs-area">
                            <ol>
                                <li className="breadcrumbs-area__step breadcrumbs-area__step--root">
                                    <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                                        <i className="icon24 icon-home"/>
                                        <span className="a11y">홈으로</span>
                                    </a>
                                </li>
                                <li className="breadcrumbs-area__step breadcrumbs-area__step--more"> {/* [D] sub menu 있을 시, breadcrumbs-area__step--more 클래스 추가 */}
                                    <a href="#!" className="breadcrumbs-area__link">
                                        <span>시스템관리</span>
                                    </a>
                                    <ul className="breadcrumbs-area__sub-menu">
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 1</a>
                                        </li>
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 2</a>
                                        </li>
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 3</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="breadcrumbs-area__step">
                                    <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                                        <span>출고관리</span>
                                    </a>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className="cont-area">
                        <div className="cont-area__grid">
                            <div className="cont-area__header">
                                <div className="cont-area__header-inner">
                                    <div className="tab-area">
                                        <Link to={'/kas-0851'}
                                              className="tab-area__btn "> {/* [D] 활성화 탭 버튼에 tab-area__btn--active 클래스 추가 */}
                                            <span>출고요청</span>
                                        </Link>
                                        <Link to={'/kas-0852'} className="tab-area__btn ">
                                            <span>배차/출고등록</span>
                                        </Link>
                                        <Link to={'/kas-0856_01'} className="tab-area__btn">
                                            <span>출고현황</span>
                                        </Link>
                                        <Link to={'/kas-0858'} className="tab-area__btn tab-area__btn--active">
                                            <span>출고실적</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="cont-area__section">
                                <article>
                                    <div className="article-content">
                                        <div className="tbl-area">
                                            <div className="table-wrap">
                                                <table>
                                                    <colgroup>
                                                        <col style={{width: '160px'}}/>
                                                        <col/>
                                                        <col style={{width: '160px'}}/>
                                                        <col/>
                                                        <col style={{width: '160px'}}/>
                                                        <col/>
                                                    </colgroup>
                                                    <tbody>
                                                    <tr>
                                                        <th>출고일자</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item type-range">
                                                                    <input type="date" id className="inp type-date"
                                                                           defaultValue={startDateFm}
                                                                           onChange={(e) => setStartDate(getUnixTimeByDate(new Date(e.target.value)))}/>
                                                                    <span className="form-division">~</span>
                                                                    <input type="date" id className="inp type-date"
                                                                           defaultValue={endDateFm}
                                                                           onChange={(e) => setEndDate(getUnixTimeByDate(new Date(e.target.value)))}/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>창고</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item">
                                                                    <select className="selectbox"
                                                                            onChange={(e) => setQuery({
                                                                                ...query,
                                                                                storage_div_cd: e.target.value
                                                                            })}>
                                                                        <option value=''>전체</option>
                                                                        <option value='G101'>우성</option>
                                                                        <option value='G041'>삼우</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>고객코드/고객명</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item col2 overlap-check">
                                                                    <input
                                                                        type="text"
                                                                        className="inp"
                                                                        value={query.cust_corp_code}
                                                                        disabled
                                                                    />
                                                                    <input
                                                                        type="text"
                                                                        className="inp"
                                                                        value={query.cust_corp_nm}
                                                                        disabled
                                                                    />
                                                                    <button onClick={() => setShowFindCustomer(true)}
                                                                            type="button" className="btn-check_001">
                                                                        찾기
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>

                                                        <th>목적지코드/목적지명</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item col2 overlap-check">
                                                                    <input id className="inp" disabled
                                                                           value={query.dest_sigun_cd}/>
                                                                    <input id className="inp" disabled
                                                                           value={query.dest_nm}/>
                                                                    <button type="button" className="btn-check_001"
                                                                            onClick={() => setShowModalDestination(true)}
                                                                    >찾기
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th rowSpan={3} className="border-0">주문번호</th>
                                                        <td rowSpan={3} className="border-0">
                                                            <div className="product-num">
                                                                <div className="item">
                                                                    <div className="form-area">
                                                                        <div className="form-item">
                                                                            <textarea className="inp"
                                                                                      defaultValue={""}
                                                                                      onChange={(e) => {
                                                                                          onChangeOrderNo(e.target.value)
                                                                                      }}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="summary">
                                                                    <p>
                                                                        *제품번호, 패키지번호 구분은 , 또는 엔터로 구분<br/><br/>
                                                                        예) A3022,A0033
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                <div className="btn-area gap-x40">
                                    <a href="#!" className="btn-base type-secondary size-m">
                                        <span>초기화</span>
                                    </a>
                                    <button onClick={() => searchQuery()}
                                            className="btn-base type-primary size-m grid-m">
                                        <i className="icon24 icon-search-light"/>
                                        <span>
                      조회
                    </span>
                                    </button>
                                </div>
                                <article className="space">
                                    <div className="article-content">
                                        <div className="tbl-area">
                                            <div className="tbl-upper">
                                                <div className="tbl-upper__cont">
                                                    <button type="button" className="btn-round-util">
                                                        <span>저장</span>
                                                    </button>
                                                    <button type="button" className="btn-round-util">
                                                        <span>반품등록</span>
                                                    </button>
                                                </div>
                                                <div className="tbl-upper__util">
                                                    <a href="#" className="btn-base type-dark">
                                                        <span>거래명세서 출력</span>
                                                    </a>
                                                    <strong className="tbl-area__tit">
                                                        선택 중량(KG) {addComma(totalAmount)}
                                                    </strong>
                                                    <DownloadTableExcel filename="kasco" sheet="users"
                                                                        currentTableRef={tableRef.current}>
                                                        <a href="#" className="btn-base type-dark">
                                                            <span>다운로드</span>
                                                        </a>
                                                    </DownloadTableExcel>
                                                    <select className="selectbox type-light">
                                                        <option value='50'>50개씩</option>
                                                        <option value='100'>100개씩</option>
                                                        <option value='200'>200개씩</option>
                                                        <option value='500'>500개씩</option>
                                                        <option value='1000'>1000개씩</option>
                                                        <option value='5000'>5000개씩</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="tbl-base type-hover">
                                                <table className="table-product" ref={tableRef}>
                                                    <caption>
                                                        정보 테이블
                                                    </caption>
                                                    <colgroup>
                                                        <col style={{width: '90px'}}/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        {user.role === 'ADMIN' && (
                                                            <>
                                                                <col/>
                                                                <col/>
                                                            </>
                                                        )}
                                                    </colgroup>
                                                    <thead>
                                                    <tr>
                                                        <th scope="col">
                                                            <input type="checkbox" id="chk2-all"
                                                                   name="chk2Group"
                                                                   onChange={(e) => checkAllOrders(e.target.checked)}
                                                            />
                                                            <label htmlFor="chk2-all" className="lb-chk">
                                                                <span className="a11y">전체선택</span></label>
                                                        </th>
                                                        <th scope="col">제품번호</th>
                                                        <th scope="col">두께</th>
                                                        <th scope="col">폭</th>
                                                        <th scope="col">길이</th>
                                                        <th scope="col">중량</th>
                                                        <th scope="col">규격약호</th>
                                                        <th scope="col">고객코드</th>
                                                        <th scope="col">고객사</th>
                                                        <th scope="col">목적지</th>
                                                        <th scope="col">도착지전화번호</th>
                                                        <th scope="col">경매번호</th>
                                                        <th scope="col">출하지시일자</th>
                                                        <th scope="col">출하요청일자</th>
                                                        {user.role === 'ADMIN' && (
                                                            <>
                                                                <th scope="col">매출운임비</th>
                                                                <th scope="col">매입운임비</th>
                                                            </>
                                                        )}

                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        list.map((item, index) => (
                                                            <tr>
                                                                <td>
                                                                    <input type="checkbox"
                                                                           checked={item.is_checked}
                                                                           id={`chkGoods${index}-1`}
                                                                           name="chkGoodsGroup"
                                                                           onChange={(e) => selectOrderItem(e.target.checked, item)}/>
                                                                    <label htmlFor={`chkGoods${index}-1`}
                                                                           className="lb-chk">
                                                                        <span className="a11y">선택</span>
                                                                    </label>
                                                                </td>
                                                                <td>{item.goods_no}</td>
                                                                <td>{item.goods_t_mm}</td>
                                                                <td>{item.goods_w_mm}</td>
                                                                <td>{item.goods_len_mm}</td>
                                                                <td>{item.goods_wt}</td>
                                                                <td>{item.spec_abbsym}</td>
                                                                <td>{item.cust_corp_code}</td>
                                                                <td>{item.corp_nm}</td>
                                                                <td>{item.dest_sigun_nm}</td>
                                                                <td>{item.dest_phone}</td>
                                                                <td>{item.auction_no}</td>
                                                                <td>{item.out_order_dt}</td>
                                                                {user.role === 'ADMIN' && (
                                                                    <>
                                                                        <td>{addComma(item.freight_amount)}</td>
                                                                        <td>{addComma(item.inbound_feright_amount)}</td>
                                                                    </>
                                                                )}

                                                            </tr>
                                                        ))
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </section>
                <Modal
                    dialogClassName="justify-content-center"
                    show={showFindCustomer}
                    size="lg"
                    onHide={() => setShowFindCustomer(false)}
                >
                    <Modal.Body style={{background: '#fff'}}>
                        <section className="main-area">
                            <article className="small-pop">
                                <div className="article-header">
                                    <h1 className="popup-header__tit"> 고객사 찾기</h1>
                                    <button type="button" className="btn-close"
                                            onClick={() => setShowFindCustomer(false)}><span
                                        className="a11y">팝업 닫기</span></button>
                                </div>
                                <div className="article-content">
                                    <div className="cont-area">
                                        <div className="cont-area__section">
                                            <article>
                                                <div className="article-content">
                                                    <div className="tbl-area">
                                                        <div className="table-wrap">
                                                            <table>
                                                                <colgroup>
                                                                    <col style={{width: '160px'}}/>
                                                                    <col/>
                                                                    <col style={{width: '160px'}}/>
                                                                    <col/>
                                                                </colgroup>
                                                                <tbody>
                                                                <tr>
                                                                    <th>고객사명</th>
                                                                    <td>
                                                                        <div className="form-area">
                                                                            <div className="form-item">
                                                                                <input type="text" className="inp"
                                                                                       value={queryData.corp_nm}
                                                                                       onChange={(e) => {
                                                                                           setQueryData({
                                                                                               ...queryData,
                                                                                               corp_nm: e.target.value
                                                                                           })
                                                                                       }}/>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <th>사업자번호</th>
                                                                    <td>
                                                                        <div className="form-area">
                                                                            <div className="form-item">
                                                                                <input type="text" className="inp"
                                                                                       value={queryData.business_number}
                                                                                       onChange={(e) => {
                                                                                           setQueryData({
                                                                                               ...queryData,
                                                                                               business_number: e.target.value
                                                                                           })
                                                                                       }}/>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                            <div className="btn-area gap-x40">
                                                <button onClick={() => {
                                                    setQueryData({
                                                        ...queryData,
                                                        corp_nm: '',
                                                        business_number: ''
                                                    });
                                                    setQueryResult([]);
                                                    setQuerySize(10);
                                                    setQueryTotalPage(0);
                                                }} className="btn-base type-secondary size-m">
                                                    <span>초기화</span>
                                                </button>
                                                <button onClick={() => searchCustomer()}
                                                        className="btn-base type-primary size-m grid-m">
                                                    <i className="icon24 icon-search-light"/>
                                                    <span>
                        검색
                      </span>
                                                </button>
                                            </div>
                                            <article className="space">
                                                <div className="article-content">
                                                    <div className="tbl-area">
                                                        <div className="tbl-upper">
                                                            <div className="tbl-upper__util">
                                                                <select className="selectbox type-light"
                                                                        onChange={(e) => setQuerySize(e.target.value)}>
                                                                    <option value='50'>50개씩</option>
                                                                    <option value='100'>100개씩</option>
                                                                    <option value='200'>200개씩</option>
                                                                    <option value='500'>500개씩</option>
                                                                    <option value='1000'>1000개씩</option>
                                                                    <option value='5000'>5000개씩</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="tbl-base type-hover">
                                                            <table>
                                                                <colgroup>
                                                                    <col style={{width: '100px'}}/>
                                                                    <col/>
                                                                    <col/>
                                                                </colgroup>
                                                                <thead>
                                                                <tr>
                                                                    <th>고객코드</th>
                                                                    <th>고객사명</th>
                                                                    <th>사업자번호</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    queryResult.map((item, index) => (
                                                                        <tr onClick={() => selectCustomer(item)}>
                                                                            <td>{item.cust_corp_code}</td>
                                                                            <td>{item.corp_nm}</td>
                                                                            <td>{item.business_num}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <Pagination pageCount={queryTotalPage}
                                                                paginate={(p) => setQueryPage(p.selected + 1)}></Pagination>
                                                </div>
                                            </article>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </section>
                    </Modal.Body>
                </Modal>

                <Modal
                    dialogClassName="justify-content-center"
                    show={showModalDestination}
                    size="lg"
                    onHide={() => setShowModalDestination(false)}
                >
                    <Modal.Body style={{background: '#fff'}}>
                        <div id="wrap" className="popup-area">
                            <section className="main-area">
                                <article className="small-pop">
                                    <div className="article-header">
                                        <h1 className="popup-header__tit">목적지 조회</h1>
                                        <button type="button" className="btn-close"
                                                onClick={() => setShowModalDestination(false)}><span
                                            className="a11y">팝업 닫기</span></button>
                                    </div>
                                    <div className="article-content">
                                        <div className="cont-area">
                                            <div className="cont-area__section">
                                                <article>
                                                    <div className="article-content">
                                                        <div className="tbl-area">
                                                            <div className="table-wrap">
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{width: '160px'}}/>
                                                                        <col/>
                                                                        <col style={{width: '160px'}}/>
                                                                        <col/>
                                                                    </colgroup>
                                                                    <tbody>
                                                                    <tr>
                                                                        <th>목적지코드</th>
                                                                        <td>
                                                                            <div className="form-area">
                                                                                <div className="form-item">
                                                                                    <input type="text" className="inp"
                                                                                           onChange={(e) => setQueryDestSignCd(e.target.value)}/>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <th>목적지명</th>
                                                                        <td>
                                                                            <div className="form-area">
                                                                                <div className="form-item">
                                                                                    <input type="text" className="inp"
                                                                                           onChange={(e) => setQueryDestNm(e.target.value)}/>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                                <div className="btn-area gap-x40">
                                                    <button className="btn-base type-secondary size-m"
                                                            onClick={() => clearDestInfo()}>
                                                        <span>초기화</span>
                                                    </button>
                                                    <button className="btn-base type-primary size-m grid-m"
                                                            onClick={() => getDestList()}>
                                                        <i className="icon24 icon-search-light"/>
                                                        <span>
                        검색
                      </span>
                                                    </button>
                                                </div>
                                                <article className="space">
                                                    <div className="article-content">
                                                        <div className="tbl-area">
                                                            <div className="tbl-upper">
                                                                <div className="tbl-upper__util">
                                                                    <select className="selectbox type-light"
                                                                            onChange={(e) => setQueryDestSize(e.target.value)}>
                                                                        <option value='50'>50개씩</option>
                                                                        <option value='100'>100개씩</option>
                                                                        <option value='200'>200개씩</option>
                                                                        <option value='500'>500개씩</option>
                                                                        <option value='1000'>1000개씩</option>
                                                                        <option value='5000'>5000개씩</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="tbl-base type-hover">
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{width: '100px'}}/>
                                                                        <col/>
                                                                    </colgroup>
                                                                    <thead>
                                                                    <tr>
                                                                        <th>목적자코드</th>
                                                                        <th>목적지</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                    {
                                                                        queryDestList.map((item, index) => (
                                                                                <tr onClick={() => selectDest(item)}>
                                                                                    <td>{item.dest_sigun_cd}</td>
                                                                                    <td>{item.dest_nm}</td>
                                                                                </tr>
                                                                            )
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <Pagination pageCount={queryDestTotalPage}
                                                                    paginate={(p) => setQueryDestPage(p.selected + 1)}></Pagination>
                                                    </div>
                                                </article>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </section>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* // [End] main-area */}
                <Footer/>
            </div>
        </>
    )
}

export default Kas0858
import React, {useEffect, useState, useRef} from 'react'
import {DownloadTableExcel, useDownloadExcel} from 'react-export-table-to-excel';

import Header from '../../containers/header/Header1'
import Footer from '../../containers/footer/Footer'

import Background from '../../assets/img/menu-visual1.jpg'
import {format} from "date-fns";
import {formatUnixTime, getPath, getRemainingTimeToFinish, getUnixTimeByDate} from "../../helpers/helpers";
import useDidMountEffect from "../../helpers/useDidMountEffect";
import {store} from "../../store";
import {hideLoader, showLoader} from "../../store/loader/actions";
import axiosInstance from "../../helpers/axiosInstance";
import {addComma, isValidValue, isValidValueOrEmpty} from "../../Utils";
import {useSelector} from "react-redux";
import {setPendingAuctions} from "../../store/common/common.slice";
import {toast} from "react-toastify";
import Modal from "react-bootstrap/Modal";
import {Link} from "react-router-dom";
import Pagination from "../pagination/pagination";

function Kas0601() {
    const tableRef = useRef(null);
    const [startDateFm, setStartDateFm] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [grade1Checked, setGrade1Checked] = useState(false);
    const [grade2Checked, setGrade2Checked] = useState(false);
    const [totalWeightAmount, setTotalWeightAmount] = useState(0);
    const [hasOngoingAuction, setHasOngoingAuction] = useState(false);
    const [hasPendingAuction, setHasPendingAuction] = useState(false);
    const goodsGradeCd = useRef('');
    const [ongoingAuctionDate, setOngoingAuctionDate] = useState({});
    const [pendingAuctionDate, setPendingAuctionDate] = useState({});
    const [pendingAuction, setPendingAuction] = useState([]);

    const {ongoing_auctions} = useSelector((store) => store.common);
    const {user} = useSelector((store) => store.auth);
    const [showModalDestination, setShowModalDestination] = useState(false);

    const [useYn1, setUseYn1] = useState(false);
    const [useYn2, setUseYn2] = useState(false);
    const useYn = useRef('');


    const [filterGoods, setFilterGoods] = useState({
        auction_no: '',
        storage_div_cd: '',
        goods_stat_cd: '',
        goods_grade_cd: '',
        spec_abbsym: '',
        goods_t_mm_from: '',
        goods_t_mm_to: '',
        goods_w_mm_from: '',
        goods_w_mm_to: '',
        goods_len_mm_from: '',
        goods_len_mm_to: '',
        goods_no_arr: '',
        stock_stat_cd: '',
    });

    const [pageGoods, setPageGoods] = useState(1);
    const [totalGoods, setTotalGoods] = useState(0);
    const [sizeGoods, setSizeGoods] = useState(50);
    const [goodList, setGoodList] = useState([]);
    const [onGoingAuctionNo, setOngoingAuctionNo] = useState(null);
    const [onPendingAuctionNo, setPendingAuctionNo] = useState(null);
    const [biddingCost, setBiddingCost] = useState(0);
    const [proceedBidInfo, setProceedBidInfo] = useState({});
    const [totalBiddingList, setTotalBiddingList] = useState([]);
    const [totalWeight, setTotalWeight] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const ongoingAuctionStartDateReg = useRef(null);

    const [queryDestSignCd, setQueryDestSignCd] = useState(null);
    const [queryDestNm, setQueryDestNm] = useState(null);
    const [destList, setDesitList] = useState([]);
    const [destSize, setDestSize] = useState(50);
    const [destPage, setDestPage] = useState(1);
    const [destTotalPage, setDestTotalPage] = useState(0);
    const [selectedDest, setSelectedDest] = useState({
        dest_sigun_cd: '',
        cust_dest_seq: '',
        dest_nm: '',
        dest_addr: '',
        phone1: '',
    });


    let interval;

    useDidMountEffect(() => {
        getDestList();
    }, [destSize, destPage]);


    useEffect(() => {
        console.log(process.env.REACT_APP_ENV)
        let currentDate = new Date();
        let startOfDayDate = new Date(currentDate.getFullYear()
            , currentDate.getMonth()
            , currentDate.getDate()
            , 0, 0, 0);
        setStartDateFm(format(startOfDayDate, 'yyyy-MM-dd'))
        setStartDate(getUnixTimeByDate(startOfDayDate))
        getOngoingAuctionList();

        if (user.role === 'CUSTOMER') {
            getCustomerInfo()
        }

    }, []);

    useEffect(() => {
        interval = setInterval(() => {
            const currentDate = new Date();
            if (ongoingAuctionStartDateReg.current !== null) {
                const data = getRemainingTimeToFinish(currentDate, ongoingAuctionStartDateReg.current);
                setPendingAuctionDate(data);
            }
            if (ongoingAuctionStartDateReg.current !== null && (currentDate > ongoingAuctionStartDateReg.current)) {
                // setPendingAuctions([]);
                getOngoingAuctionList();
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const getOngoingAuctionList = async () => {
        const res = await axiosInstance.get('/auction/ongoingAuction');
        console.log(res);
        if (res.code === 200) {
            if (res.data.ongoing_auctions.length > 0) {
                const currentDate = new Date();
                const startDate = new Date(res.data.ongoing_auctions[0].auction_start_dt);
                const endDate = new Date(res.data.ongoing_auctions[0].auction_end_dt);
                if (currentDate < endDate) {
                    setHasOngoingAuction(true);
                    setOngoingAuctionNo(res.data.ongoing_auctions[0]);
                }
                const data = getRemainingTimeToFinish(new Date(), endDate);
                setOngoingAuctionDate(data);
            } else {
                if (res.data.pending_auctions.length > 0) {
                    console.log(res.data.pending_auctions)
                    setPendingAuctions(res.data.pending_auctions[0]);
                    setPendingAuctionNo(res.data.pending_auctions[0].auction_no);
                    const startDate = new Date(res.data.pending_auctions[0].auction_start_dt);
                    const endDate = new Date(res.data.pending_auctions[0].auction_end_dt);
                    const data = getRemainingTimeToFinish(new Date(), startDate);
                    setHasPendingAuction(true);
                    setPendingAuctionDate(data);
                    ongoingAuctionStartDateReg.current = startDate;
                }
            }

        }
    }

    useEffect(() => {
        if (onGoingAuctionNo !== null) {
            console.log(onGoingAuctionNo);
            searchGoods();
            getAuctionTotalInfo();
        }
    }, [onGoingAuctionNo]);

    useDidMountEffect(() => {
        console.log(sizeGoods);
        searchGoods();
    }, [sizeGoods, pageGoods]);

    const searchGoods = async () => {
        let params = "";
        let filterParams = filterGoods;
        if (hasOngoingAuction) {
            filterParams.auction_no = onGoingAuctionNo.auction_no;
        } else if (hasPendingAuction) {
            filterParams.auction_no = onPendingAuctionNo.auction_no;
        }
        filterParams.goods_grade_cd = goodsGradeCd.current;
        filterParams.use_yn = useYn.current;
        filterParams.start_date = startDate;
        filterParams.size = sizeGoods;
        filterParams.page = pageGoods;
        if (Object.entries(filterParams).length > 0) {
            params = getPath(filterParams);
        }
        console.log(params)
        store.dispatch(showLoader());
        try {
            const response = await axiosInstance.get('/auction/biddingList' + params);
            if (response.code === 200) {
                let listOfObjs = [];
                for (let i = 0; i < response.data.items.length; i++) {
                    let item = response.data.items[i];
                    item.highist_price = item.highist_price || 0;
                    item.bidding_price = item.bidding_price || 0;
                    if (Number.parseFloat(item.highist_price) === Number.parseFloat(item.bidding_price)) {
                        item.isYnBidding = 'Y';
                    } else {
                        item.isYnBidding = 'N';
                    }
                    if (user.role === 'CUSTOMER') {
                        item.cust_dest_seq = selectedDest.cust_dest_seq;
                        item.dest_sigun_cd = selectedDest.dest_sigun_cd;
                        item.dest_addr = selectedDest.dest_addr;
                        item.dest_phone = selectedDest.phone1;
                        item.is_changed = true;
                    }
                    item.is_checked = false;
                    listOfObjs.push(item);
                }
                setTotalGoods(Number.parseInt(response.data.totalPage));
                setGoodList(listOfObjs);
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }

    const checkGradeCd1 = (e) => {
        if (e.target.checked) {
            setGrade1Checked(true);
            goodsGradeCd.current = '1';
            if (grade2Checked) {
                setGrade2Checked(false);
            }
        } else {
            setGrade1Checked(false);
            goodsGradeCd.current = '';
        }
    }
    const checkGradeCd2 = (e) => {
        if (e.target.checked) {
            setGrade2Checked(true);
            goodsGradeCd.current = '2';
            if (grade1Checked) {
                setGrade1Checked(false);
            }
        } else {
            setGrade2Checked(false);
            goodsGradeCd.current = '';
        }
    }


    const checkUseYn1 = (e) => {
        if (e.target.checked) {
            setUseYn1(true);
            useYn.current = 'Y';
            if (useYn2) {
                setUseYn2(false);
            }
        } else {
            setUseYn1(false);
            useYn.current = '';
        }
    }
    const checkUseYn2 = (e) => {
        if (e.target.checked) {
            setUseYn2(true);
            useYn.current = 'N';
            if (useYn1) {
                setUseYn1(false);
            }
        } else {
            setUseYn2(false);
            useYn.current = '';
        }
    }


    function selectGoodsItem(e, item) {
        let listItems = [...goodList];
        if (e) {
            setTotalWeightAmount(totalWeightAmount + Number(item.goods_wt))
        }
        if (!e) {
            setTotalWeightAmount(totalWeightAmount - Number(item.goods_wt))
        }
        for (let i = 0; i < listItems.length; i++) {
            if (listItems[i].goods_no === item.goods_no) {
                listItems[i].is_checked = e;
            }
        }
        setGoodList(listItems);
    }

    function checkAllGoods(e) {
        let listItems = [...goodList];
        let totalAmount = 0
        for (let i = 0; i < goodList.length; i++) {
            let item = goodList[i];
            totalAmount += Number(item.goods_wt);
        }
        setTotalWeightAmount(e ? totalAmount : 0);
        for (let i = 0; i < listItems.length; i++) {
            listItems[i].is_checked = e;
        }
        setGoodList(listItems);
    }

    async function auctionBid() {
        if (biddingCost === 0) {
            alert("Please enter price")
            return;
        }
        if (Object.keys(selectedDest).length === 0) {
            alert("Please select destination")
            return;
        }

        let listOfObj = [];
        let isBrokenList = false;
        for (let i = 0; i < goodList.length; i++) {
            if (goodList[i].is_changed) {
                if (!isValidValueOrEmpty(goodList[i].dest_sigun_cd) ||
                    !isValidValueOrEmpty(goodList[i].cust_dest_seq) || !isValidValueOrEmpty(goodList[i].dest_addr) || !isValidValueOrEmpty(goodList[i].dest_phone)) {
                    isBrokenList = true;
                    break;
                }
                listOfObj.push({
                    goods_no: goodList[i].goods_no,
                    bidding_price: goodList[i].bidding_price,
                    dest_sigun_cd: goodList[i].dest_sigun_cd,
                    dest_nm: goodList[i].dest_nm,
                    cust_dest_seq: goodList[i].cust_dest_seq,
                    dest_addr: goodList[i].dest_addr,
                    dest_phone: goodList[i].dest_phone,
                });
            }
        }

        if (isBrokenList) {
            alert("Please Modify Destination info");
            return;
        }

        console.log(listOfObj);

        if (listOfObj.length === 0) {
            alert("Please select GOODS")
            return;
        }

        const payload = {
            goods_ids: listOfObj,
            auction_no: onGoingAuctionNo.auction_no,
            auction_start_date: onGoingAuctionNo.auction_start_dt,
            bidding_time: formatUnixTime(new Date().getTime()),
            bidding_price: biddingCost
        }
        store.dispatch(showLoader());
        try {
            const res = await axiosInstance.post('/auction/bid', payload);
            console.log(res);
            if (res.code === 200) {
                checkAllGoods(false);
                await searchGoods();
                await getAuctionTotalInfo();
                setBiddingCost(0);
                toast.dismiss();
                toast.success(('Success'));
            } else {
                alert(res.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }


    }

    async function getAuctionTotalInfo() {
        const res = await axiosInstance.get('/auction/totalBiddingList?auctionNo=' + onGoingAuctionNo.auction_no);
        console.log(res);
        if (res.code === 200) {
            let totalWeightI = 0;
            let totalPriceI = 0;
            for (let i = 0; i < res.data.length; i++) {
                const item = res.data[i];
                totalWeightI += item.goods_wt;
                totalPriceI += item.total_amount;
            }
            setTotalPrice(totalPriceI);
            setTotalWeight(totalWeightI);
            setTotalBiddingList(res.data);
        } else {
            alert(res.message);
        }
    }

    function onChangeGoodsNo(e) {
        const lines = e.split(/\n/);
        let arrays = [];
        for (let i = 0; i < lines.length; i++) {
            if (/\S/.test(lines[i])) {
                arrays.push(lines[i].trim());
            }
        }

        setFilterGoods({
            ...filterGoods,
            goods_no_arr: arrays
        })

    }

    async function getDestList() {
        let params;
        let filterParams = {
            dest_sigun_cd: queryDestSignCd,
            dest_nm: queryDestNm,
            page: destPage,
            size: destSize,
        }
        if (user.role === 'CUSTOMER') {
            filterParams.cust_corp_code = user.cust_corp_code;
        }
        params = getPath(filterParams);
        store.dispatch(showLoader());
        try {
            const res = await axiosInstance.get('/dest/customerDestList' + params);
            console.log(res);
            store.dispatch(hideLoader());
            if (res.code === 200) {
                setDesitList(res.data.items);
                setDestTotalPage(Number.parseInt(res.data.totalPage));
            } else {
                alert(res.message);
            }
        } catch (e) {
            store.dispatch(hideLoader());
        }
    }

    async function clearDestInfo() {
        setDesitList([]);
        setQueryDestNm(null);
        setQueryDestSignCd(null);
        setDestPage(1);
        setDestTotalPage(0);
        setDestSize(50);
    }

    async function selectDest(item) {
        setSelectedDest({
            ...selectedDest,
            dest_sigun_cd: item.dest_sigun_cd,
            cust_dest_seq: item.cust_dest_seq,
            dest_addr: item.addr,
            dest_nm: item.dest_nm,
            phone1: item.phone1,
        });
        console.log(item);
        setShowModalDestination(false);
    }

    const getCustomerInfo = async () => {
        try {
            store.dispatch(showLoader());
            const custCorpCode = user.cust_corp_code;
            const res = await axiosInstance.get('/dest/getDestInfo?custCorpCode=' + custCorpCode);
            console.log(res);
            if (res.code === 200) {
                setSelectedDest({
                    ...selectedDest,
                    dest_sigun_cd: res.data.dest_sigun_cd,
                    cust_dest_seq: res.data.cust_dest_seq,
                    dest_addr: res.data.addr,
                    dest_nm: res.data.dest_nm,
                    phone1: res.data.phone1,
                });
            }
            store.dispatch(hideLoader());
        } catch (e) {
            store.dispatch(hideLoader());
        }
    }

    async function updateBiddingPrice() {
        let tempList = [...goodList];
        if (biddingCost === 0 || isNaN(biddingCost)) {
            alert("Price must be higher than 0")
            return;
        }
        let listOfObj = [];
        for (let i = 0; i < tempList.length; i++) {
            if (tempList[i].is_checked) {
                listOfObj.push({
                    goods_no: tempList[i].goods_no,
                });
            }
        }
        if (listOfObj.length === 0) {
            alert("Please select GOODS")
            return;
        }

        for (let i = 0; i < listOfObj.length; i++) {
            var index = tempList.findIndex(x => x.goods_no === listOfObj[i].goods_no);
            console.log(index);
            const highestPrice = tempList[index]['highist_price'];
            const startPrice = tempList[index]['auction_start_price'];
            console.log(highestPrice);
            if (Number.parseFloat(highestPrice) === 0) {
                tempList[index]['bidding_price'] = Number.parseFloat(startPrice) + Number.parseFloat(biddingCost);
            } else {
                tempList[index]['bidding_price'] = Number.parseFloat(highestPrice) + Number.parseFloat(biddingCost);
            }
            tempList[index]['is_changed'] = true;
        }
        setGoodList(tempList);
    }

    async function updateDest() {
        let tempList = [...goodList];
        if (Object.keys(selectedDest).length === 0) {
            alert("Please select destination")
            return;
        }
        let listOfObj = [];
        for (let i = 0; i < tempList.length; i++) {
            if (tempList[i].is_checked) {
                listOfObj.push({
                    goods_no: tempList[i].goods_no,
                });
            }
        }
        if (listOfObj.length === 0) {
            alert("Please select GOODS")
            return;
        }
        for (let i = 0; i < listOfObj.length; i++) {
            var index = tempList.findIndex(x => x.goods_no === listOfObj[i].goods_no);
            console.log(index);
            console.log(selectedDest);
            tempList[index]['cust_dest_seq'] = selectedDest.cust_dest_seq;
            tempList[index]['dest_sigun_cd'] = selectedDest.dest_sigun_cd;
            tempList[index]['dest_addr'] = selectedDest.dest_addr;
            tempList[index]['dest_phone'] = selectedDest.phone1;
            tempList[index]['is_changed'] = true;
            console.log(selectedDest);
            console.log(tempList[index]);
        }
        console.log(tempList);
        setGoodList(tempList);
    }

    return (
        <>
            <div id="wrap">
                <Header/>
                {/* [Start] main-area */}
                <section className="main-area" id="main">
                    <div
                        className="menu-visual"
                        style={{backgroundImage: `url(${Background})`}}
                    >
                        <h2 className="menu-visual__tit">경매몰</h2>
                        <div className="breadcrumbs-area">
                            <ol>
                                <li className="breadcrumbs-area__step breadcrumbs-area__step--root">
                                    <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                                        <i className="icon24 icon-home"/>
                                        <span className="a11y">홈으로</span>
                                    </a>
                                </li>
                                <li className="breadcrumbs-area__step breadcrumbs-area__step--more">
                                    {/* [D] sub menu 있을 시, breadcrumbs-area__step--more 클래스 추가 */}
                                    <a href="javascript:void(0);" className="breadcrumbs-area__link">
                                        <span>경매몰관리</span>
                                    </a>
                                    <ul className="breadcrumbs-area__sub-menu">
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 1</a>
                                        </li>
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 2</a>
                                        </li>
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 3</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="breadcrumbs-area__step">
                                    <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                                        <span>경매몰</span>
                                    </a>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className="cont-area">
                        <div className="cont-area__grid">
                            <div className="cont-area__header">
                                <div className="cont-area__header-inner">
                                    <div className="tab-area">
                                        <Link to="#!" className="tab-area__btn tab-area__btn--active">
                                            {/* [D] 활성화 탭 버튼에 tab-area__btn--active 클래스 추가 */}
                                            <span>경매응찰</span>
                                        </Link>
                                        {(user.role === 'ADMIN') && (
                                            <>
                                                <a href="#" className="tab-area__btn">
                                                    <span>경매진행조회</span>
                                                </a>
                                                <Link to="/kas-0607_20" className="tab-area__btn">
                                                    <span>경매진행상세조회</span>
                                                </Link>

                                            </>
                                        )}
                                        <Link to="/kas-0607" className="tab-area__btn">
                                            <span>경매낙찰</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="cont-area__section">
                                {hasOngoingAuction ? (
                                    <div className="board-frame">
                                        {/*<div>*/}
                                        {/*    [낙찰예상 중량 <span>8</span>톤] - [최소낙찰 <span>10</span>톤] -*/}
                                        {/*    = [<span>-2톤 상태입니다.</span>]*/}
                                        {/*</div>*/}
                                        {/*<div>*/}
                                        {/*    {ongoingAuctionDate.start_date_fm + " " + ongoingAuctionDate.start_hours_fm} 경매*/}
                                        {/*    진행중{" "}*/}
                                        {/*    <span*/}
                                        {/*        className="under-txt">남은시간 {ongoingAuctionDate.remaining_hours + ':' + ongoingAuctionDate.remaining_minutes + ':' + ongoingAuctionDate.remaining_seconds}</span>*/}
                                        {/*</div>*/}
                                    </div>
                                ) : (
                                    ongoingAuctionStartDateReg.current !== null && (
                                        pendingAuctionDate.toDate < new Date() ? (
                                            <span
                                                className="under-txt"></span>
                                        ) : (
                                            <div className="board-frame">
                                                {pendingAuctionDate.start_date_fm + " " + pendingAuctionDate.start_hours_fm} 경매
                                                준비중으로 제품 조회만 가능합니다.
                                                (경매시간 갱신이 필요한 경우 화면을 재조회해주세요.)
                                                <span
                                                    className="under-txt">남은시간 {pendingAuctionDate.remaining_hours + ':' + pendingAuctionDate.remaining_minutes + ':' + pendingAuctionDate.remaining_seconds}</span>
                                            </div>
                                        )
                                    )
                                )}
                                <article className="gap-x40">
                                    <div className="article-content">
                                        <div className="tbl-area">
                                            <div className="table-wrap">
                                                <table>
                                                    <colgroup>
                                                        <col style={{width: 160}}/>
                                                        <col/>
                                                        <col style={{width: 160}}/>
                                                        <col/>
                                                        <col style={{width: 160}}/>
                                                        <col/>
                                                    </colgroup>
                                                    <tbody>
                                                    <tr>
                                                        {/*<th>경매시작일자/회차</th>*/}
                                                        {/*<td>*/}
                                                        {/*    <div className="form-area">*/}
                                                        {/*        <div className="form-item col2">*/}
                                                        {/*            <input*/}
                                                        {/*                type="date"*/}
                                                        {/*                defaultValue={startDateFm}*/}
                                                        {/*                onChange={(e) => setStartDate(getUnixTimeByDate(new Date(e.target.value)))}*/}
                                                        {/*                className="inp type-date"*/}
                                                        {/*            />*/}
                                                        {/*        </div>*/}
                                                        {/*    </div>*/}
                                                        {/*</td>*/}
                                                        <th>창고</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item">
                                                                    <select className="selectbox"
                                                                            onChange={(e) => setFilterGoods({
                                                                                ...filterGoods,
                                                                                storage_div_cd: e.target.value
                                                                            })}>
                                                                        <option value=''>전체</option>
                                                                        <option value='G101'>우성</option>
                                                                        <option value='G041'>삼우</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>제품등급</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="check-box">
                                                                    <input type="checkbox" id="check5"
                                                                           checked={grade1Checked}
                                                                           onChange={(e) => checkGradeCd1(e)}/>
                                                                    <label htmlFor="check5" className="lb-chk">
                                                                        <span>1등급</span>
                                                                    </label>
                                                                    <input type="checkbox" id="check6"
                                                                           checked={grade2Checked}
                                                                           onChange={(e) => checkGradeCd2(e)}/>
                                                                    <label htmlFor="check6" className="lb-chk">
                                                                        <span>2등급</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>


                                                    </tr>
                                                    <tr>
                                                        <th>두께</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item type-range">
                                                                    <input type="text" className="inp"
                                                                           onChange={(e) => setFilterGoods({
                                                                               ...filterGoods,
                                                                               goods_t_mm_from: e.target.value
                                                                           })}
                                                                    />
                                                                    <span className="form-division">~</span>
                                                                    <input type="text" className="inp"
                                                                           onChange={(e) => setFilterGoods({
                                                                               ...filterGoods,
                                                                               goods_t_mm_to: e.target.value
                                                                           })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>폭</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item type-range">
                                                                    <input type="text" className="inp"
                                                                           onChange={(e) => setFilterGoods({
                                                                               ...filterGoods,
                                                                               goods_w_mm_from: e.target.value
                                                                           })}
                                                                    />
                                                                    <span className="form-division">~</span>
                                                                    <input type="text" className="inp"
                                                                           onChange={(e) => setFilterGoods({
                                                                               ...filterGoods,
                                                                               goods_w_mm_to: e.target.value
                                                                           })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>길이</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item type-range">
                                                                    <input type="text" className="inp"
                                                                           onChange={(e) => setFilterGoods({
                                                                               ...filterGoods,
                                                                               goods_len_mm_from: e.target.value
                                                                           })}
                                                                    />
                                                                    <span className="form-division">~</span>
                                                                    <input type="text" className="inp"
                                                                           onChange={(e) => setFilterGoods({
                                                                               ...filterGoods,
                                                                               goods_len_mm_to: e.target.value
                                                                           })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th rowSpan={4} className="border-0">
                                                            제품번호
                                                        </th>
                                                        <td rowSpan={4} className="border-0">
                                                            <div className="product-num">
                                                                <div className="item">
                                                                    <div className="form-area">
                                                                        <div className="form-item">
                                                                             <textarea className="inp"
                                                                                       defaultValue={""}
                                                                                       onChange={(e) => onChangeGoodsNo(e.target.value)}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="summary">
                                                                    <p>
                                                                        *제품번호, 패키지번호 구분은 , 또는 엔터로 구분
                                                                        <br/>
                                                                        <br/>
                                                                        예) A3022,A0033
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {/*<th>경매번호</th>*/}
                                                        {/*<td>*/}
                                                        {/*    <div className="form-area">*/}
                                                        {/*        <div className="form-item">*/}
                                                        {/*            <input type="text" className="inp"*/}
                                                        {/*                   onChange={(e) => setFilterGoods({*/}
                                                        {/*                       ...filterGoods,*/}
                                                        {/*                       auction_no: e.target.value*/}
                                                        {/*                   })}/>*/}
                                                        {/*        </div>*/}
                                                        {/*    </div>*/}
                                                        {/*</td>*/}

                                                        <th>규격약호</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item overlap-check">
                                                                    <input type="text" className="inp"
                                                                           onChange={(e) => setFilterGoods({
                                                                               ...filterGoods,
                                                                               spec_abbsym: e.target.value
                                                                           })}/>
                                                                    <button type="button" className="btn-check"
                                                                    >
                                                                        찾기
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>응찰여부</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="check-box">
                                                                    <input type="checkbox" id="check55"
                                                                           checked={useYn1}
                                                                           onChange={(e) => checkUseYn1(e)}/>
                                                                    <label htmlFor="check55" className="lb-chk">
                                                                        <span>Y</span>
                                                                    </label>
                                                                    <input type="checkbox" id="check66"
                                                                           checked={useYn2}
                                                                           onChange={(e) => checkUseYn2(e)}/>
                                                                    <label htmlFor="check66" className="lb-chk">
                                                                        <span>N</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                <div className="btn-area gap-x40">
                                    <a href="#" className="btn-base type-secondary size-m">
                                        <span>초기화</span>
                                    </a>
                                    <button onClick={() => searchGoods()}
                                            className="btn-base type-primary size-m grid-m">
                                        <i className="icon24 icon-search-light"/>
                                        <span>조회</span>
                                    </button>
                                </div>
                                {hasOngoingAuction && (
                                    <>
                                        {totalBiddingList.length > 0 && (
                                            <article>
                                                <div className="article-header">
                                                    <h3 className="sub-title">경매응찰 제품</h3>
                                                </div>
                                                <div className="article-content">
                                                    <div className="tbl-area">
                                                        <div className="tbl-upper">
                                                            <div className="tbl-upper__util">
                                                                <strong className="tbl-area__tit">
                                                                    낙찰 예상
                                                                    중량 {addComma(totalWeight)} kg &nbsp;&nbsp; | &nbsp;&nbsp;{" "}
                                                                    <span>{addComma(totalPrice)}원</span>
                                                                </strong>
                                                            </div>
                                                        </div>
                                                        <div className="tbl-base type-hover">
                                                            <table>
                                                                <colgroup>
                                                                    <col/>
                                                                    <col/>
                                                                    <col/>
                                                                    <col/>
                                                                    <col/>
                                                                    <col/>
                                                                    <col/>
                                                                </colgroup>
                                                                <thead>
                                                                <tr>
                                                                    <th>출발지(상품 창고명)</th>
                                                                    <th>목적지(고객 목적지 명)</th>
                                                                    <th>수량</th>
                                                                    <th>중량</th>
                                                                    <th>제품공급가</th>
                                                                    <th>운반비공급가</th>
                                                                    <th>합계</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {totalBiddingList.map((item, index) => (
                                                                    <tr>
                                                                        <td>{item.storage_nm}</td>
                                                                        <td>{item.dest_nm}</td>
                                                                        <td>{item.count}</td>
                                                                        <td>{addComma(item.goods_wt)}</td>
                                                                        <td>{addComma(item.goods_amount)}</td>
                                                                        <td>{addComma(item.freight_total_cost)}</td>
                                                                        <td>{addComma(item.total_amount)}</td>
                                                                    </tr>
                                                                ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="tbl-footer">
                                                        <div className="tbl-footer__cont">
                                                            <strong
                                                                className="tbl-footer__tit">총 {totalBiddingList.length}건</strong>
                                                        </div>
                                                    </div>
                                                </div>

                                            </article>
                                        )}

                                        <article>
                                            <div className="article-header">
                                                <h3 className="sub-title">경매대상 제품</h3>
                                            </div>
                                            <div className="aticle-content">
                                                <div className="tbl-area">
                                                    <div className="table-wrap">
                                                        <table>
                                                            <colgroup>
                                                                <col style={{width: 120}}/>
                                                                <col/>
                                                                <col style={{width: 100}}/>
                                                                <col style={{width: 420}}/>
                                                                <col style={{width: 120}}/>
                                                                <col style={{width: 430}}/>
                                                            </colgroup>
                                                            <tbody>
                                                            <tr>
                                                                <th className="necessary">응찰단가적용</th>
                                                                <td>
                                                                    <div className="form-group">
                                                                        <dl>
                                                                            <dt>최고가+</dt>
                                                                            <dd>
                                                                                <div
                                                                                    className="form-item overlap-check">
                                                                                    <input className="inp"
                                                                                           value={biddingCost}
                                                                                           type="number"
                                                                                           pattern="[0-9]*"
                                                                                           onChange={(e) => setBiddingCost(Number.parseInt(e.target.value))}/>

                                                                                    <button type="button"
                                                                                            onClick={() => updateBiddingPrice()}
                                                                                            className="btn-check_001"
                                                                                    >
                                                                                        일괄적용
                                                                                    </button>
                                                                                </div>
                                                                            </dd>
                                                                        </dl>
                                                                    </div>
                                                                </td>
                                                                <th>목적지</th>
                                                                <td>
                                                                    <div className="form-area col2">
                                                                        <div className="form-item overlap-check">
                                                                            <input type="text" className="inp"
                                                                                   value={selectedDest.dest_sigun_cd}
                                                                                   disabled/>
                                                                            <button type="button"
                                                                                    className="btn-check_001"
                                                                                    onClick={() => setShowModalDestination(true)}>
                                                                                찾기
                                                                            </button>
                                                                        </div>
                                                                        <div className="form-item">
                                                                            <input type="text" className="inp"
                                                                                   value={selectedDest.dest_nm}
                                                                                   disabled/>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <th>착지전화번호</th>
                                                                <td>
                                                                    <div className="form-area col2">
                                                                        <div className="form-item">
                                                                            <input type="text" className="inp"
                                                                                   value={selectedDest.phone1}
                                                                                   disabled/>
                                                                        </div>
                                                                        <div className="form-item">
                                                                            <div className="btn-area d-flex">
                                                                                <button className="btn-default_01"
                                                                                        onClick={() => updateDest()}>
                                                                                    목적지 적용
                                                                                </button>
                                                                                <button className="btn-default_01"
                                                                                        onClick={() => auctionBid()}>
                                                                                    경매 응찰
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </>
                                )}
                                <article className="space">
                                    <div className="article-content">
                                        <div className="tbl-area">
                                            <div className="tbl-upper">
                                                <div className="tbl-upper__cont">
                                                    {/*<button type="button" className="btn-round-util">*/}
                                                    {/*    <span>저장</span>*/}
                                                    {/*</button>*/}
                                                </div>
                                                <div className="tbl-upper__util">
                                                    <strong className="tbl-area__tit">
                                                        선택중량(KG) {addComma(totalWeightAmount)}
                                                    </strong>
                                                    <DownloadTableExcel filename="kasco" sheet="users"
                                                                        currentTableRef={tableRef.current}>
                                                        <a href="#" className="btn-base type-dark">
                                                            <span>다운로드</span>
                                                        </a>

                                                    </DownloadTableExcel>
                                                    <select className="selectbox type-light"
                                                            onChange={(e) => setSizeGoods(e.target.value)}>
                                                        <option value='50'>50개씩</option>
                                                        <option value='100'>100개씩</option>
                                                        <option value='200'>200개씩</option>
                                                        <option value='500'>500개씩</option>
                                                        <option value='1000'>1000개씩</option>
                                                        <option value='5000'>5000개씩</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="tbl-base type-hover scroll-warp">
                                                <table ref={tableRef}>
                                                    <caption>정보 테이블</caption>
                                                    <colgroup/>
                                                    <thead>
                                                    <tr>
                                                        <th scope="col">
                                                            <input
                                                                type="checkbox"
                                                                id="chk1-all"
                                                                name="chk1Group"
                                                                onChange={(e) => checkAllGoods(e.target.checked)}
                                                            />
                                                            <label htmlFor="chk1-all" className="lb-chk">
                                                                <span className="a11y">전체선택</span>
                                                            </label>
                                                        </th>
                                                        <th scope="col">조회구분</th>
                                                        <th scope="col">경매번호</th>
                                                        <th scope="col">제품번호</th>
                                                        <th scope="col">창고</th>
                                                        <th scope="col">시작가</th>
                                                        <th scope="col">최고가</th>
                                                        <th scope="col">응찰가</th>
                                                        {/*<th scope="col">낙찰가능여부</th>*/}
                                                        <th scope="col">제품등급</th>
                                                        <th scope="col">두께</th>
                                                        <th scope="col">폭</th>
                                                        <th scope="col">길이</th>
                                                        <th scope="col">중량</th>
                                                        <th scope="col">규격약호</th>
                                                        <th scope="col">착지명</th>
                                                        <th scope="col">YP</th>
                                                        <th scope="col">TS</th>
                                                        <th scope="col">EL</th>
                                                        <th scope="col">C%</th>
                                                        <th scope="col">SI</th>
                                                        <th scope="col">MN</th>
                                                        <th scope="col">P</th>
                                                        <th scope="col">S</th>
                                                        <th scope="col">여재원인</th>
                                                        <th scope="col">용도코드</th>
                                                        <th scope="col">용도명</th>
                                                        <th scope="col">목적지코드</th>
                                                        <th scope="col">목적지주소</th>
                                                        <th scope="col">목적지전화번호</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        goodList.map((item, index) => (
                                                            <tr>
                                                                <td>
                                                                    <input type="checkbox"
                                                                           checked={item.is_checked}
                                                                           id={`chkGoods${index}-1`}
                                                                           onChange={(e) => selectGoodsItem(e.target.checked, item)}
                                                                           name="chk1Group"/>
                                                                    <label htmlFor={`chkGoods${index}-1`}
                                                                           className="lb-chk">
                                                                        <span className="a11y">선택</span>
                                                                    </label>
                                                                </td>
                                                                <td>{item.bid_flag}</td>
                                                                <td>{item.auction_no}</td>
                                                                <td>{item.goods_no}</td>
                                                                <td>{item.storage_div_cd_nm}</td>
                                                                <td>
                                                                    {addComma(item.auction_start_price)}
                                                                </td>
                                                                <td>{addComma(item.highist_price === 0 ? item.auction_start_price : item.highist_price)}</td>
                                                                <td>{addComma(item.bidding_price)}</td>
                                                                {/*<td>{item.isYnBidding}</td>*/}
                                                                <td>{item.goods_grade_cd_nm}</td>
                                                                <td>{item.goods_t_mm}</td>
                                                                <td>{item.goods_w_mm}</td>
                                                                <td>{item.goods_len_mm}</td>
                                                                <td>{addComma(item.goods_wt)}</td>
                                                                <td>{item.spec_abbsym_full_nm}</td>
                                                                <td>{item.dest_nm}</td>
                                                                <td>{item.yp_wrslt}</td>
                                                                <td>{item.ts_wrslt}</td>
                                                                <td>{item.el_wrslt}</td>
                                                                <td>{item.c_wrslt}</td>
                                                                <td>{item.si_wrslt}</td>
                                                                <td>{item.mn_wrslt}</td>
                                                                <td>{item.p_wrslt}</td>
                                                                <td>{item.s_wrslt}</td>
                                                                <td>{item.yeojae_cause_cd_nm}</td>
                                                                <td>{item.usage_cd}</td>
                                                                <td>{item.usage_cd_nm}</td>
                                                                <td>{item.dest_sigun_cd}</td>
                                                                <td>{item.dest_addr}</td>
                                                                <td>{item.dest_phone}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <div className="tbl-footer">
                                            <div className="tbl-footer__cont">
                                                <strong className="tbl-footer__tit">총 {goodList.length}건</strong>
                                            </div>
                                        </div>
                                        <Pagination pageCount={totalGoods}
                                                    paginate={(p) => setPageGoods(p.selected + 1)}></Pagination>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </section>
                <Modal
                    dialogClassName="justify-content-center"
                    show={showModalDestination}
                    size="lg"
                    onHide={() => setShowModalDestination(false)}
                >
                    <Modal.Body style={{background: '#fff'}}>
                        <div id="wrap" className="popup-area">
                            <section className="main-area">
                                <article className="small-pop">
                                    <div className="article-header">
                                        <h1 className="popup-header__tit">목적지 조회</h1>
                                        <button type="button" className="btn-close"
                                                onClick={() => setShowModalDestination(false)}><span
                                            className="a11y">팝업 닫기</span></button>
                                    </div>
                                    <div className="article-content">
                                        <div className="cont-area">
                                            <div className="cont-area__section">
                                                <article>
                                                    <div className="article-content">
                                                        <div className="tbl-area">
                                                            <div className="table-wrap">
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{width: '160px'}}/>
                                                                        <col/>
                                                                        <col style={{width: '160px'}}/>
                                                                        <col/>
                                                                    </colgroup>
                                                                    <tbody>
                                                                    <tr>
                                                                        <th>목적지코드</th>
                                                                        <td>
                                                                            <div className="form-area">
                                                                                <div className="form-item">
                                                                                    <input type="text" className="inp"
                                                                                           onChange={(e) => setQueryDestSignCd(e.target.value)}/>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <th>목적지명</th>
                                                                        <td>
                                                                            <div className="form-area">
                                                                                <div className="form-item">
                                                                                    <input type="text" className="inp"
                                                                                           onChange={(e) => setQueryDestNm(e.target.value)}/>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                                <div className="btn-area gap-x40">
                                                    <button className="btn-base type-secondary size-m"
                                                            onClick={() => clearDestInfo()}>
                                                        <span>초기화</span>
                                                    </button>
                                                    <button className="btn-base type-primary size-m grid-m"
                                                            onClick={() => getDestList()}>
                                                        <i className="icon24 icon-search-light"/>
                                                        <span>
                        검색
                      </span>
                                                    </button>
                                                </div>
                                                <article className="space">
                                                    <div className="article-content">
                                                        <div className="tbl-area">
                                                            <div className="tbl-upper">
                                                                <div className="tbl-upper__util">
                                                                    <select className="selectbox type-light"
                                                                            onChange={(e) => setDestSize(e.target.value)}>
                                                                        <option value='50'>50개씩</option>
                                                                        <option value='100'>100개씩</option>
                                                                        <option value='200'>200개씩</option>
                                                                        <option value='500'>500개씩</option>
                                                                        <option value='1000'>1000개씩</option>
                                                                        <option value='5000'>5000개씩</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="tbl-base type-hover">
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{width: '100px'}}/>
                                                                        <col/>
                                                                        <col/>
                                                                        <col/>
                                                                        <col/>
                                                                    </colgroup>
                                                                    <thead>
                                                                    <tr>
                                                                        <th>목적자코드</th>
                                                                        <th>목적지 2(시/군/구)</th>
                                                                        <th>전화번호</th>
                                                                        <th>주소</th>
                                                                        <th>하차지명</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                    {
                                                                        destList.map((item, index) => (
                                                                                <tr onClick={() => selectDest(item)}>
                                                                                    <td>{item.dest_sigun_cd}</td>
                                                                                    <td>{item.dest_nm}</td>
                                                                                    <td>{item.phone1}</td>
                                                                                    <td>{item.addr}</td>
                                                                                    <td>{item.dest_nm}</td>
                                                                                </tr>
                                                                            )
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                                <Pagination pageCount={destTotalPage}
                                                            paginate={(p) => setDestPage(p.selected + 1)}></Pagination>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </section>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* // [End] main-area */}
                <Footer/>
            </div>
        </>
    )
}

export default Kas0601
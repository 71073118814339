import React, {useEffect, useState} from 'react'

import Header from '../../containers/header/Header1'
import Footer from '../../containers/footer/Footer'

import Background from '../../assets/img/menu-visual1.jpg'
import useDidMountEffect from "../../helpers/useDidMountEffect";
import {getPath} from "../../helpers/helpers";
import {store} from "../../store";
import {hideLoader, showLoader} from "../../store/loader/actions";
import axiosInstance from "../../helpers/axiosInstance";
import {toast} from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Pagination from "../pagination/pagination";
import {Link, useParams} from "react-router-dom";

function Kas1902() {
    let {cust_dest_seq} = useParams();

    const [registerData, setRegisterData] = useState({
        cust_corp_code: '',
        cust_corp_no: '',
        corp_nm: '',

        dest_sigun_cd: '',
        dest_nm: '',
        dest_seq: '',

        car_desc: '',
        co_dest_nm: '',
        note: '',
        reps_yn: 'N',
        use_yn: 'N',
        turn: '',
        addr: '',
        phone1: '',
        phone2: '',
        dest_memo: '',
    })

    const [queryResult, setQueryResult] = useState([]);
    const [queryPage, setQueryPage] = useState(1);
    const [querySize, setQuerySize] = useState(50);
    const [queryTotalPage, setQueryTotalPage] = useState(0);
    const [queryData, setQueryData] = useState({
        corp_nm: '',
        business_number: ''
    });
    const [showFindCustomer, setShowFindCustomer] = useState(false);
    const [showModalDestination, setShowModalDestination] = useState(false);

    const [queryDestSignCd, setQueryDestSignCd] = useState(null);
    const [queryDestNm, setQueryDestNm] = useState(null);
    const [queryDestPage, setQueryDestPage] = useState(1);
    const [queryDestSize, setQueryDestSize] = useState(10);
    const [queryDestTotalPage, setQueryDestTotalPage] = useState(0);
    const [destList, setDesitList] = useState([]);
    const [selectedDest, setSelectedDest] = useState({})

    useEffect(() => {
        console.log(cust_dest_seq);
        if (cust_dest_seq) {
            getDetail();
        }
    }, []);

    useDidMountEffect(() => {
        searchCustomer();
    }, [querySize, queryPage]);

    useDidMountEffect(() => {
        getDestList();
    }, [queryDestPage, queryDestSize]);

    const getDetail = async () => {
        try {
            store.dispatch(showLoader());
            const res = await axiosInstance.get('/dest/getCustDestDetail?custDestSeq=' + cust_dest_seq);
            console.log(res);
            if (res.code === 200) {
                setRegisterData({
                    ...registerData,
                    cust_corp_code: res.data.cust_corp_code,
                    cust_corp_no: '',
                    corp_nm: res.data.corp_nm,

                    dest_sigun_cd: res.data.dest_sigun_cd,
                    dest_nm: res.data.co_dest_nm,
                    dest_seq: res.data.dest_seq,

                    car_desc: res.data.car_desc,
                    co_dest_nm: res.data.dest_nm,
                    note: res.data.note,
                    reps_yn: res.data.reps_yn,
                    use_yn: res.data.use_yn,
                    turn: res.data.turn,
                    addr: res.data.addr,
                    phone1: res.data.phone1,
                    phone2: res.data.phone2,
                    dest_memo: res.data.dest_memo,
                })
            } else {
                alert(res.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }

    const searchCustomer = async () => {
        let params;
        let filterParams = queryData;
        queryData.size = querySize;
        queryData.page = queryPage;
        params = getPath(filterParams);
        try {
            store.dispatch(showLoader());
            const response = await axiosInstance.get('/auth/searchCustomer' + params);
            console.log(response);
            if (response.code === 200) {
                toast.dismiss();
                toast.success(('Success'))
                setQueryResult(response.data.items);
                setQueryTotalPage(Number.parseInt(response.data.totalPage));
                store.dispatch(hideLoader());
            } else {
                store.dispatch(hideLoader());
                alert(response.message);
            }
        } catch (e) {
            store.dispatch(hideLoader());
        }
    }

    const selectCustomer = (item) => {
        setRegisterData({
            ...registerData,
            cust_corp_code: item.cust_corp_code,
            cust_corp_no: item.cust_corp_no,
            corp_nm: item.corp_nm
        })
        setShowFindCustomer(false);
    }

    async function clearDestInfo() {
        setDesitList([]);
        setQueryDestNm(null);
        setQueryDestSignCd(null);
    }

    async function selectDest(item) {
        setRegisterData({
            ...registerData,
            dest_sigun_cd: item.dest_sigun_cd,
            dest_seq: item.dest_seq,
            dest_nm: item.dest_nm,
        })
        setShowModalDestination(false);
    }

    async function getDestList() {
        let params;
        let filterParams = {
            dest_sigun_cd: queryDestSignCd,
            dest_nm: queryDestNm,
        }
        filterParams.size = queryDestSize;
        filterParams.page = queryDestPage;
        params = getPath(filterParams);
        store.dispatch(showLoader());
        try {
            const res = await axiosInstance.get('/dest/destList' + params);
            store.dispatch(hideLoader());
            if (res.code === 200) {
                setDesitList(res.data.items);
                setQueryDestTotalPage(Number.parseInt(res.data.totalPage));
            } else {
                alert(res.message);
            }
        } catch (e) {
            store.dispatch(hideLoader());
        }
    }

    async function register() {
        if (registerData.cust_corp_code === '' || registerData.turn === '' || registerData.dest_sigun_cd === '' ||
            registerData.addr === '' || registerData.car_desc === '' || registerData.phone1 === '' || registerData.phone2 === '' || registerData.co_dest_nm === '') {
            alert("Please enter values")
            return;
        }
        try {
            store.dispatch(showLoader());
            let res;
            if (cust_dest_seq) {
                registerData.cust_dest_seq = cust_dest_seq;
                res = await axiosInstance.post('/dest/updateCustDest', registerData);
            } else {
                res = await axiosInstance.post('/dest/register', registerData);
            }
            console.log(res);
            if (res.code === 200) {
                toast.dismiss();
                toast.success("SUCCESS");
            } else {
                alert(res.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }

    }

    return (
        <>
            <div id="wrap">
                <Header/>
                {/* [Start] main-area */}
                <section className="main-area" id="main">
                    <div className="menu-visual" style={{backgroundImage: `url(${Background})`}}>
                        <h2 className="menu-visual__tit">
                            {cust_dest_seq ? "목적지수정" : "목적지등록"}
                        </h2>
                        <div className="breadcrumbs-area">
                            <ol>
                                <li className="breadcrumbs-area__step breadcrumbs-area__step--root">
                                    <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                                        <i className="icon24 icon-home"/>
                                        <span className="a11y">홈으로</span>
                                    </a>
                                </li>
                                <li className="breadcrumbs-area__step breadcrumbs-area__step--more"> {/* [D] sub menu 있을 시, breadcrumbs-area__step--more 클래스 추가 */}
                                    <a href="javascript:void(0);" className="breadcrumbs-area__link">
                                        <span>시스템관리</span>
                                    </a>
                                    <ul className="breadcrumbs-area__sub-menu">
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 1</a>
                                        </li>
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 2</a>
                                        </li>
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 3</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="breadcrumbs-area__step">
                                    <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                                        <span>목적지등록</span>
                                    </a>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className="cont-area">
                        <div className="cont-area__grid">
                            <div className="cont-area__section">
                                <article>
                                    <div className="article-content">
                                        <div className="tbl-area">
                                            <div className="table-wrap">
                                                <table>
                                                    <colgroup>
                                                        <col style={{width: '160px'}}/>
                                                        <col style={{width: '400px'}}/>
                                                        <col style={{width: '160px'}}/>
                                                        <col style={{width: '140px'}}/>
                                                        <col style={{width: '160px'}}/>
                                                        <col style={{width: '140px'}}/>
                                                        <col style={{width: '160px'}}/>
                                                        <col/>
                                                    </colgroup>
                                                    <tbody>
                                                    <tr>
                                                        <th className="necessary">고객명</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item overlap-check">
                                                                    <input type="text" className="inp"
                                                                           defaultValue="카스코철강" disabled
                                                                           value={registerData.corp_nm}/>
                                                                    <button type="button" className="btn-check_001"
                                                                            onClick={() => {
                                                                                setQueryData({
                                                                                    corp_nm: '',
                                                                                    business_number: ''
                                                                                });
                                                                                setQueryResult([]);
                                                                                setQuerySize(10);
                                                                                setQueryTotalPage(0);
                                                                                setShowFindCustomer(true);
                                                                            }}>찾기
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>대표여부</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="check-box">
                                                                    <input type="checkbox" id="check1"
                                                                           defaultChecked={registerData.reps_yn === 'Y'}
                                                                           onChange={(e) => {
                                                                               setRegisterData({
                                                                                   ...registerData,
                                                                                   reps_yn: e.target.checked ? 'Y' : 'N'
                                                                               })
                                                                           }}/>
                                                                    <label htmlFor="check1" className="lb-chk">
                                                                        <span>대표</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>사용여부</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="check-box">
                                                                    <input type="checkbox" id="check2"
                                                                           defaultChecked={registerData.use_yn === 'Y'}
                                                                           onChange={(e) => {
                                                                               setRegisterData({
                                                                                   ...registerData,
                                                                                   use_yn: e.target.checked ? 'Y' : 'N'
                                                                               })
                                                                           }}/>
                                                                    <label htmlFor="check2" className="lb-chk">
                                                                        <span>사용</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th className="necessary">순번</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item">
                                                                    <input type="text" className="inp"
                                                                           defaultValue={registerData.turn}
                                                                           onChange={(e) => {
                                                                               setRegisterData({
                                                                                   ...registerData,
                                                                                   turn: e.target.value
                                                                               })
                                                                           }}/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th className="necessary">목적지</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item overlap-check">
                                                                    <input type="text" className="inp" disabled
                                                                           value={registerData.dest_nm}/>
                                                                    <button type="button" className="btn-check_001"
                                                                            onClick={() => setShowModalDestination(true)}>찾기
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th className="necessary">주소</th>
                                                        <td colSpan={5}>
                                                            <div className="form-area">
                                                                <div className="form-item">
                                                                    <input type="text" className="inp"
                                                                           defaultValue={registerData.addr}
                                                                           onChange={(e) => {
                                                                               setRegisterData({
                                                                                   ...registerData,
                                                                                   addr: e.target.value
                                                                               })
                                                                           }}/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th className="necessary">차량</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item">
                                                                    <input type="text" className="inp"
                                                                           defaultValue={registerData.car_desc}
                                                                           onChange={(e) => {
                                                                               setRegisterData({
                                                                                   ...registerData,
                                                                                   car_desc: e.target.value
                                                                               })
                                                                           }}/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th className="necessary">휴대폰</th>
                                                        <td colSpan={2}>
                                                            <div className="form-area">
                                                                <div className="form-item">
                                                                    <input type="text" className="inp"
                                                                           defaultValue={registerData.phone1}
                                                                           onChange={(e) => {
                                                                               setRegisterData({
                                                                                   ...registerData,
                                                                                   phone1: e.target.value
                                                                               })
                                                                           }}/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th className="necessary">연락처</th>
                                                        <td colSpan={2}>
                                                            <div className="form-area">
                                                                <div className="form-item">
                                                                    <input type="text" className="inp"
                                                                           defaultValue={registerData.phone2}
                                                                           onChange={(e) => {
                                                                               setRegisterData({
                                                                                   ...registerData,
                                                                                   phone2: e.target.value
                                                                               })
                                                                           }}/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th className="necessary">하차지명</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item">
                                                                    <input type="text" className="inp"
                                                                           defaultValue={registerData.co_dest_nm}
                                                                           onChange={(e) => {
                                                                               setRegisterData({
                                                                                   ...registerData,
                                                                                   co_dest_nm: e.target.value
                                                                               })
                                                                           }}/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>하차지특이사항</th>
                                                        <td colSpan={5}>
                                                            <p>진입가능한 차량종류, 하차지의 상황을 작성하세요.</p>
                                                            <div className="form-area">
                                                                <div className="form-item">
                                                                    <input type="text" className="inp"
                                                                           defaultValue={registerData.dest_memo}
                                                                           onChange={(e) => {
                                                                               setRegisterData({
                                                                                   ...registerData,
                                                                                   dest_memo: e.target.value
                                                                               })
                                                                           }}/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>비고</th>
                                                        <td colSpan={7}>
                                                            <div className="form-area">
                                                                <div className="form-item">
                                                                    <input type="text" className="inp"
                                                                           defaultValue={registerData.note}
                                                                           onChange={(e) => {
                                                                               setRegisterData({
                                                                                   ...registerData,
                                                                                   note: e.target.value
                                                                               })
                                                                           }}/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                <div className="btn-area gap-x40">
                                    <Link to="/kas-1901" className="btn-base type-secondary size-m">
                                        <span>취소</span>
                                    </Link>
                                    <button onClick={() => register()} className="btn-base type-primary size-m grid-m">
                    <span>
                      저장
                    </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Modal
                    dialogClassName="justify-content-center"
                    show={showFindCustomer}
                    size="lg"
                    onHide={() => setShowFindCustomer(false)}
                >
                    <Modal.Body style={{background: '#fff'}}>
                        <section className="main-area">
                            <article className="small-pop">
                                <div className="article-header">
                                    <h1 className="popup-header__tit"> 고객사 찾기</h1>
                                    <button type="button" className="btn-close"
                                            onClick={() => setShowFindCustomer(false)}><span
                                        className="a11y">팝업 닫기</span></button>
                                </div>
                                <div className="article-content">
                                    <div className="cont-area">
                                        <div className="cont-area__section">
                                            <article>
                                                <div className="article-content">
                                                    <div className="tbl-area">
                                                        <div className="table-wrap">
                                                            <table>
                                                                <colgroup>
                                                                    <col style={{width: '160px'}}/>
                                                                    <col/>
                                                                    <col style={{width: '160px'}}/>
                                                                    <col/>
                                                                </colgroup>
                                                                <tbody>
                                                                <tr>
                                                                    <th>고객사명</th>
                                                                    <td>
                                                                        <div className="form-area">
                                                                            <div className="form-item">
                                                                                <input type="text" className="inp"
                                                                                       value={queryData.corp_nm}
                                                                                       onChange={(e) => {
                                                                                           setQueryData({
                                                                                               ...queryData,
                                                                                               corp_nm: e.target.value
                                                                                           })
                                                                                       }}/>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <th>사업자번호</th>
                                                                    <td>
                                                                        <div className="form-area">
                                                                            <div className="form-item">
                                                                                <input type="text" className="inp"
                                                                                       value={queryData.business_number}
                                                                                       onChange={(e) => {
                                                                                           setQueryData({
                                                                                               ...queryData,
                                                                                               business_number: e.target.value
                                                                                           })
                                                                                       }}/>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                            <div className="btn-area gap-x40">
                                                <button onClick={() => {
                                                    setQueryData({
                                                        ...queryData,
                                                        corp_nm: '',
                                                        business_number: ''
                                                    });
                                                    setQueryResult([]);
                                                    setQuerySize(10);
                                                    setQueryTotalPage(0);
                                                }} className="btn-base type-secondary size-m">
                                                    <span>초기화</span>
                                                </button>
                                                <button onClick={() => searchCustomer()}
                                                        className="btn-base type-primary size-m grid-m">
                                                    <i className="icon24 icon-search-light"/>
                                                    <span>
                        검색
                      </span>
                                                </button>
                                            </div>
                                            <article className="space">
                                                <div className="article-content">
                                                    <div className="tbl-area">
                                                        <div className="tbl-upper">
                                                            <div className="tbl-upper__util">
                                                                <select className="selectbox type-light"
                                                                        onChange={(e) => setQuerySize(e.target.value)}>
                                                                    <option value='50'>50개씩</option>
                                                                    <option value='100'>100개씩</option>
                                                                    <option value='200'>200개씩</option>
                                                                    <option value='500'>500개씩</option>
                                                                    <option value='1000'>1000개씩</option>
                                                                    <option value='5000'>5000개씩</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="tbl-base type-hover">
                                                            <table>
                                                                <colgroup>
                                                                    <col style={{width: '100px'}}/>
                                                                    <col/>
                                                                    <col/>
                                                                </colgroup>
                                                                <thead>
                                                                <tr>
                                                                    <th>고객코드</th>
                                                                    <th>고객사명</th>
                                                                    <th>사업자번호</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    queryResult.map((item, index) => (
                                                                        <tr onClick={() => selectCustomer(item)}>
                                                                            <td>{item.cust_corp_code}</td>
                                                                            <td>{item.corp_nm}</td>
                                                                            <td>{item.business_num}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <Pagination pageCount={queryTotalPage}
                                                                paginate={(p) => setQueryPage(p.selected + 1)}></Pagination>
                                                </div>
                                            </article>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </section>
                    </Modal.Body>
                </Modal>

                <Modal
                    dialogClassName="justify-content-center"
                    show={showModalDestination}
                    size="lg"
                    onHide={() => setShowModalDestination(false)}
                >
                    <Modal.Body style={{background: '#fff'}}>
                        <div id="wrap" className="popup-area">
                            <section className="main-area">
                                <article className="small-pop">
                                    <div className="article-header">
                                        <h1 className="popup-header__tit">목적지 조회</h1>
                                        <button type="button" className="btn-close"
                                                onClick={() => setShowModalDestination(false)}><span
                                            className="a11y">팝업 닫기</span></button>
                                    </div>
                                    <div className="article-content">
                                        <div className="cont-area">
                                            <div className="cont-area__section">
                                                <article>
                                                    <div className="article-content">
                                                        <div className="tbl-area">
                                                            <div className="table-wrap">
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{width: '160px'}}/>
                                                                        <col/>
                                                                        <col style={{width: '160px'}}/>
                                                                        <col/>
                                                                    </colgroup>
                                                                    <tbody>
                                                                    <tr>
                                                                        <th>목적지코드</th>
                                                                        <td>
                                                                            <div className="form-area">
                                                                                <div className="form-item">
                                                                                    <input type="text" className="inp"
                                                                                           onChange={(e) => setQueryDestSignCd(e.target.value)}/>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <th>목적지명</th>
                                                                        <td>
                                                                            <div className="form-area">
                                                                                <div className="form-item">
                                                                                    <input type="text" className="inp"
                                                                                           onChange={(e) => setQueryDestNm(e.target.value)}/>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                                <div className="btn-area gap-x40">
                                                    <button className="btn-base type-secondary size-m"
                                                            onClick={() => clearDestInfo()}>
                                                        <span>초기화</span>
                                                    </button>
                                                    <button className="btn-base type-primary size-m grid-m"
                                                            onClick={() => getDestList()}>
                                                        <i className="icon24 icon-search-light"/>
                                                        <span>
                        검색
                      </span>
                                                    </button>
                                                </div>
                                                <article className="space">
                                                    <div className="article-content">
                                                        <div className="tbl-area">
                                                            <div className="tbl-upper">
                                                                <div className="tbl-upper__util">
                                                                    <select className="selectbox type-light"
                                                                            onChange={(e) => setQueryDestSize(e.target.value)}>
                                                                        <option value='50'>50개씩</option>
                                                                        <option value='100'>100개씩</option>
                                                                        <option value='200'>200개씩</option>
                                                                        <option value='500'>500개씩</option>
                                                                        <option value='1000'>1000개씩</option>
                                                                        <option value='5000'>5000개씩</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="tbl-base type-hover">
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{width: '100px'}}/>
                                                                        <col/>
                                                                    </colgroup>
                                                                    <thead>
                                                                    <tr>
                                                                        <th>목적자코드</th>
                                                                        <th>목적지(시/군/구)</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                    {
                                                                        destList.map((item, index) => (
                                                                                <tr onClick={() => selectDest(item)}>
                                                                                    <td>{item.dest_sigun_cd}</td>
                                                                                    <td>{item.dest_nm}</td>
                                                                                </tr>
                                                                            )
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <Pagination pageCount={queryDestTotalPage}
                                                                    paginate={(p) => setQueryDestPage(p.selected + 1)}></Pagination>
                                                    </div>
                                                </article>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </section>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* // [End] main-area */}
                <Footer/>
            </div>
        </>
    )
}

export default Kas1902
import React, {useEffect, useState, useRef} from 'react'
import { DownloadTableExcel, useDownloadExcel} from 'react-export-table-to-excel';
import Header from "../../containers/header/Header1";
import Footer from "../../containers/footer/Footer";
import Modal from "react-bootstrap/Modal";
import Background from "../../assets/img/menu-visual1.jpg";

function DestinationManagement() {
    const tableRef = useRef(null);
    const [enterAddress, setEnterAddress] = useState(false);
    const [enterAddressCorrection, setEnterAddressCorrection] = useState(false);
    return (
        <>
            <Header/>
            <div>
                <section className="main-area" id="main">
                    <div
                        className="menu-visual"
                        style={{backgroundImage: `url(${Background})`}}
                    >
                        <h2 className="menu-visual__tit">목적지관리</h2>
                        <div className="breadcrumbs-area">
                            <ol>
                                <li className="breadcrumbs-area__step breadcrumbs-area__step--root">
                                    <a
                                        href="/manageProduct1.html"
                                        className="breadcrumbs-area__link"
                                    >
                                        <i className="icon24 icon-home"/>
                                        <span className="a11y">홈으로</span>
                                    </a>
                                </li>
                                <li className="breadcrumbs-area__step breadcrumbs-area__step--more">
                                    {" "}
                                    {/* [D] sub menu 있을 시, breadcrumbs-area__step--more 클래스 추가 */}
                                    <a
                                        href="javascript:void(0);"
                                        className="breadcrumbs-area__link"
                                    >
                                        <span>시스템관리</span>
                                    </a>
                                    <ul className="breadcrumbs-area__sub-menu">
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 1</a>
                                        </li>
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 2</a>
                                        </li>
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 3</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="breadcrumbs-area__step">
                                    <a
                                        href="/manageProduct1.html"
                                        className="breadcrumbs-area__link"
                                    >
                                        <span>목적지관리</span>
                                    </a>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className="cont-area">
                        <div className="cont-area__grid">
                            <div className="cont-area__section">
                                <div className="cont-area__header">
                                    <div className="btn-area right">
                                        <a href="#" onClick={() => setEnterAddress(true)}
                                           className="btn-base type-dark">
                                            <span>목적지 등록</span>
                                            <i className="icon20 icon-round-arrow-right"/>
                                        </a>
                                    </div>
                                </div>
                                <article>
                                    <div className="article-content">
                                        <div className="tbl-area">
                                            <div className="table-wrap">
                                                <table>
                                                    <colgroup>
                                                        <col style={{width: 160}}/>
                                                        <col/>
                                                    </colgroup>
                                                    <tbody>
                                                    <tr>
                                                        <th>목적지</th>
                                                        <td>
                                                            <div className="form-area colspan2">
                                                                <div className="form-item overlap-check">
                                                                    <input type="text" className="inp"/>
                                                                    <button type="button" className="btn-check_001"
                                                                            onClick={() => setEnterAddressCorrection(true)}>
                                                                        찾기
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                <div className="btn-area gap-x40">
                                    <a href="#" className="btn-base type-secondary size-m">
                                        <span>초기화</span>
                                    </a>
                                    <a href="#" className="btn-base type-primary size-m grid-m">
                                        <i className="icon24 icon-search-light"/>
                                        <span>검색</span>
                                    </a>
                                </div>
                                <article className="space">
                                    <div className="article-content">
                                        <div className="tbl-area">
                                            <div className="tbl-upper">
                                                <div className="tbl-upper__cont">
                                                    <button type="button" className="btn-round-util">
                                                        <span>항목삭제</span>
                                                    </button>
                                                </div>
                                                <div className="tbl-upper__util">
                                                <DownloadTableExcel filename="kasco" sheet="users"
                                                                        currentTableRef={tableRef.current}>
                                                        <a href="#" className="btn-base type-dark">
                                                            <span>다운로드</span>
                                                        </a>
                                                    </DownloadTableExcel>
                                                    <select className="selectbox type-light">
                                                        <option value='50'>50개씩</option>
                                                        <option value='100'>100개씩</option>
                                                        <option value='200'>200개씩</option>
                                                        <option value='500'>500개씩</option>
                                                        <option value='1000'>1000개씩</option>
                                                        <option value='5000'>5000개씩</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="tbl-base type-hover">
                                                <table ref={tableRef}>
                                                    <caption>정보 테이블</caption>
                                                    <colgroup>
                                                        <col style={{width: 80}}/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                    </colgroup>
                                                    <thead>
                                                    <tr>
                                                        <th>
                                                            <input
                                                                type="checkbox"
                                                                id="chk1-all"
                                                                name="chk1Group"
                                                            />
                                                            <label htmlFor="chk1-all" className="lb-chk">
                                                                <span className="a11y">전체선택</span>
                                                            </label>
                                                        </th>
                                                        <th>번호</th>
                                                        <th>목적지코드</th>
                                                        <th>목적지 1</th>
                                                        <th>목적지 2(시/군/구)</th>
                                                        <th>최종수정자</th>
                                                        <th>최종수정일시</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                id="chk1-1"
                                                                name="chk1Group"
                                                            />
                                                            <label htmlFor="chk1-1" className="lb-chk">
                                                                <span className="a11y">선택</span>
                                                            </label>
                                                        </td>
                                                        <td>99</td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                E011
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                경남
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                김해시(00군)
                                                            </a>
                                                        </td>
                                                        <td>카스코</td>
                                                        <td>2022-11-11 11:11:11</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                id="chk1-1"
                                                                name="chk1Group"
                                                            />
                                                            <label htmlFor="chk1-1" className="lb-chk">
                                                                <span className="a11y">선택</span>
                                                            </label>
                                                        </td>
                                                        <td>98</td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                E011
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                경남
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                김해시(00군)
                                                            </a>
                                                        </td>
                                                        <td>카스코</td>
                                                        <td>2022-11-11 11:11:11</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                id="chk1-1"
                                                                name="chk1Group"
                                                            />
                                                            <label htmlFor="chk1-1" className="lb-chk">
                                                                <span className="a11y">선택</span>
                                                            </label>
                                                        </td>
                                                        <td>97</td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                E012
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                서울
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                송파구
                                                            </a>
                                                        </td>
                                                        <td>카스코</td>
                                                        <td>2022-11-11 11:11:11</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                id="chk1-1"
                                                                name="chk1Group"
                                                            />
                                                            <label htmlFor="chk1-1" className="lb-chk">
                                                                <span className="a11y">선택</span>
                                                            </label>
                                                        </td>
                                                        <td>96</td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                E011
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                경남
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                김해시(00군)
                                                            </a>
                                                        </td>
                                                        <td>카스코</td>
                                                        <td>2022-11-11 11:11:11</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                id="chk1-1"
                                                                name="chk1Group"
                                                            />
                                                            <label htmlFor="chk1-1" className="lb-chk">
                                                                <span className="a11y">선택</span>
                                                            </label>
                                                        </td>
                                                        <td>95</td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                E011
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                경남
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                김해시(00군)
                                                            </a>
                                                        </td>
                                                        <td>카스코</td>
                                                        <td>2022-11-11 11:11:11</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                id="chk1-1"
                                                                name="chk1Group"
                                                            />
                                                            <label htmlFor="chk1-1" className="lb-chk">
                                                                <span className="a11y">선택</span>
                                                            </label>
                                                        </td>
                                                        <td>94</td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                E011
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                경남
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                김해시(00군)
                                                            </a>
                                                        </td>
                                                        <td>카스코</td>
                                                        <td>2022-11-11 11:11:11</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                id="chk1-1"
                                                                name="chk1Group"
                                                            />
                                                            <label htmlFor="chk1-1" className="lb-chk">
                                                                <span className="a11y">선택</span>
                                                            </label>
                                                        </td>
                                                        <td>93</td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                E011
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                경남
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                김해시(00군)
                                                            </a>
                                                        </td>
                                                        <td>카스코</td>
                                                        <td>2022-11-11 11:11:11</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                id="chk1-1"
                                                                name="chk1Group"
                                                            />
                                                            <label htmlFor="chk1-1" className="lb-chk">
                                                                <span className="a11y">선택</span>
                                                            </label>
                                                        </td>
                                                        <td>92</td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                E011
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                경남
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                김해시(00군)
                                                            </a>
                                                        </td>
                                                        <td>카스코</td>
                                                        <td>2022-11-11 11:11:11</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                id="chk1-1"
                                                                name="chk1Group"
                                                            />
                                                            <label htmlFor="chk1-1" className="lb-chk">
                                                                <span className="a11y">선택</span>
                                                            </label>
                                                        </td>
                                                        <td>91</td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                E011
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                경남
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                김해시(00군)
                                                            </a>
                                                        </td>
                                                        <td>카스코</td>
                                                        <td>2022-11-11 11:11:11</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                id="chk1-1"
                                                                name="chk1Group"
                                                            />
                                                            <label htmlFor="chk1-1" className="lb-chk">
                                                                <span className="a11y">선택</span>
                                                            </label>
                                                        </td>
                                                        <td>90</td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                E011
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                경남
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="link">
                                                                김해시(00군)
                                                            </a>
                                                        </td>
                                                        <td>카스코</td>
                                                        <td>2022-11-11 11:11:11</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="tbl-footer">
                                            <div className="tbl-footer__cont">
                                                <strong className="tbl-footer__tit">총 30,000건</strong>
                                            </div>
                                        </div>
                                        <div className="paging-area">
                                            <button
                                                type="button"
                                                className="btn-jump first"
                                                disabled
                                            />
                                            <button
                                                type="button"
                                                className="btn-jump prev"
                                                disabled
                                            />
                                            <div className="paging-link">
                                                <a href="#" className="current">
                                                    1
                                                </a>{" "}
                                                {/* [D] 현재 페이지에 current 클래스 추가 */}
                                                <a href="#">2</a>
                                                <a href="#">3</a>
                                                <a href="#">4</a>
                                                <a href="#">5</a>
                                                <a href="#">6</a>
                                                <a href="#">7</a>
                                                <a href="#">8</a>
                                                <a href="#">9</a>
                                                <a href="#">10</a>
                                            </div>
                                            <button type="button" className="btn-jump next"/>
                                            <button type="button" className="btn-jump last"/>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </section>

                <Modal
                    dialogClassName="justify-content-center"
                    show={enterAddress}
                    size="lg"
                    onHide={() => setEnterAddress(false)}
                >
                    <Modal.Body style={{background: "#fff"}}>
                        <section className="main-area">
                            <article className="small-pop">
                                <div className="popup-header">
                                    <h1 className="popup-header__tit">목적지 등록</h1>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => setEnterAddress(false)}
                                    >
                                        <span className="a11y">팝업 닫기</span>
                                    </button>
                                </div>
                                <div className="article-content">
                                    <div className="tbl-area">
                                        <div className="table-wrap">
                                            <table>
                                                <colgroup>
                                                    <col style={{width: 160}}/>
                                                    <col/>
                                                </colgroup>
                                                <tbody>
                                                <tr>
                                                    <th>목적지코드</th>
                                                    <td>
                                                        <div className="form-area">
                                                            <div className="form-item">
                                                                <input
                                                                    type="text"
                                                                    className="inp"
                                                                    defaultValue="자동생성"
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>목적지 1</th>
                                                    <td>
                                                        <div className="form-area">
                                                            <div className="form-item col2">
                                                                <select className="selectbox">
                                                                    <option value>권역선택</option>
                                                                    <option value>옵션1</option>
                                                                    <option value>옵션2</option>
                                                                    <option value>옵션3</option>
                                                                </select>
                                                                <select className="selectbox">
                                                                    <option value>시/군/구선택</option>
                                                                    <option value>옵션1</option>
                                                                    <option value>옵션2</option>
                                                                    <option value>옵션3</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="necessary">목적지 2</th>
                                                    <td>
                                                        <div className="form-area">
                                                            <div className="form-item">
                                                                <input type="text" className="inp"/>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="btn-area">
                                        <a href="#" className="btn-base type-secondary size-m">
                                            <span>취소</span>
                                        </a>
                                        <a href="#" className="btn-base type-primary size-m grid-m">
                                            <span>저장</span>
                                        </a>
                                    </div>
                                </div>
                            </article>
                        </section>
                    </Modal.Body>
                </Modal>
                <Modal
                    dialogClassName="justify-content-center"
                    show={enterAddressCorrection}
                    size="lg"
                    onHide={() => setEnterAddressCorrection(false)}
                >
                    <Modal.Body style={{background: "#fff"}}>
                        <section className="main-area">
                            <article className="small-pop">
                                <div className="popup-header">
                                    <h1 className="popup-header__tit">목적지 상세/수정</h1>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => setEnterAddressCorrection(false)}
                                    >
                                        <span className="a11y">팝업 닫기</span>
                                    </button>
                                </div>
                                <div className="article-content">
                                    <div className="tbl-area">
                                        <div className="table-wrap">
                                            <table>
                                                <colgroup>
                                                    <col style={{width: 160}}/>
                                                    <col/>
                                                </colgroup>
                                                <tbody>
                                                <tr>
                                                    <th>목적지코드</th>
                                                    <td>
                                                        <div className="form-area">
                                                            <div className="form-item">
                                                                <input
                                                                    type="text"
                                                                    className="inp"
                                                                    defaultValue="자동생성"
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>목적지 1</th>
                                                    <td>
                                                        <div className="form-area">
                                                            <div className="form-item col2">
                                                                <select className="selectbox">
                                                                    <option value>권역선택</option>
                                                                    <option value>옵션1</option>
                                                                    <option value>옵션2</option>
                                                                    <option value>옵션3</option>
                                                                </select>
                                                                <select className="selectbox">
                                                                    <option value>시/군/구선택</option>
                                                                    <option value>옵션1</option>
                                                                    <option value>옵션2</option>
                                                                    <option value>옵션3</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="necessary">목적지 2</th>
                                                    <td>
                                                        <div className="form-area">
                                                            <div className="form-item">
                                                                <input type="text" className="inp"/>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>최종수정자</th>
                                                    <td>홍길동</td>
                                                </tr>
                                                <tr>
                                                    <th>최종수정일시</th>
                                                    <td>2022-11-11 11:11:11</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="btn-area">
                                        <a href="#" className="btn-base type-black size-m">
                                            <span>삭제</span>
                                        </a>
                                        <a href="#" className="btn-base type-secondary size-m">
                                            <span>취소</span>
                                        </a>
                                        <a href="#" className="btn-base type-primary size-m grid-m">
                                            <span>저장</span>
                                        </a>
                                    </div>
                                </div>
                            </article>
                        </section>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
}

export default DestinationManagement;

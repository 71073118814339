import { combineReducers } from 'redux';
import persistReducer from 'redux-persist/es/persistReducer';
import { authReducer } from './auth/auth.slice';
import storage from 'redux-persist/lib/storage';
import loader from "./loader/reducer"
import {commonReducers} from "./common/common.slice";

const authPersistConfig = {
  key: "auth",
  storage,
};

const commonPersistConfig = {
  key: "common",
  storage,
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  common: persistReducer(commonPersistConfig, commonReducers),
  loader,
});

export default rootReducer;

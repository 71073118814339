import React, {useEffect, useRef, useState} from 'react'
import Modal from 'react-bootstrap/Modal';

import Header from '../../containers/header/Header1'
import Footer from '../../containers/footer/Footer'

import Background from '../../assets/img/menu-visual1.jpg'
import Check from '../../assets/images/modal/check.svg'
import {formatUnixTime, getPath, getUnixTimeByDate} from "../../helpers/helpers";
import {addComma} from "../../Utils";
import {DownloadTableExcel} from "react-export-table-to-excel";
import {format} from "date-fns";
import Pagination from "../pagination/pagination";
import useDidMountEffect from "../../helpers/useDidMountEffect";
import {store} from "../../store";
import {hideLoader, showLoader} from "../../store/loader/actions";
import axiosInstance from "../../helpers/axiosInstance";
import {toast} from "react-toastify";

function Kas_0850() {

    const [query, setQuery] = useState({
        start_confirm_dt: '',
        end_confirm_dt: '',
        start_send_dt: '',
        end_send_dt: '',
        storage_div_cd: '',
        cust_corp_nm: '',
        cust_corp_code: '',
        cust_corp_no: '',
        goods_no_arr: ''
    });
    const isMounted = useRef(false);

    const [startDateFm, setStartDateFm] = useState(null);
    const [endDateFm, setEndDateFm] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dateType, setDateType] = useState('A');

    const [page, setPage] = useState(1);
    const [size, setSize] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [list, setList] = useState([]);

    const [totalInfo, setTotalInfo] = useState({
        total_wt: '',
        total_price: '',
        total_freight: ''
    })

    useEffect(() => {
        if (!isMounted.current) {
            let currentDate = new Date();
            let startOfDayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);
            setStartDateFm(format(startOfDayDate, 'yyyy-MM-dd'));
            setStartDate(getUnixTimeByDate(startOfDayDate));

            let endOfDayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);
            setEndDateFm(format(endOfDayDate, 'yyyy-MM-dd'))
            setEndDate(getUnixTimeByDate(endOfDayDate))
            isMounted.current = true;
        }
    }, []);


    const tableRef = useRef(null);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const [queryData, setQueryData] = useState({
        corp_nm: '', business_number: ''
    });
    const [queryResult, setQueryResult] = useState([]);
    const [queryPage, setQueryPage] = useState(1);
    const [querySize, setQuerySize] = useState(10);
    const [queryTotalPage, setQueryTotalPage] = useState(0);
    const [showFindCustomer, setShowFindCustomer] = useState(false);


    useDidMountEffect(() => {
        searchQuery();
    }, [size, page]);

    useDidMountEffect(() => {
        searchCustomer();
    }, [querySize, queryPage]);

    const searchCustomer = async () => {
        let params;
        let filterParams = queryData;
        queryData.size = querySize;
        queryData.page = queryPage;
        params = getPath(filterParams);
        try {
            store.dispatch(showLoader());
            const response = await axiosInstance.get('/auth/searchCustomer' + params);
            console.log(response);
            if (response.code === 200) {
                toast.dismiss();
                toast.success(('Success'))
                setQueryResult(response.data.items);
                setQueryTotalPage(Number.parseInt(response.data.totalPage));
                store.dispatch(hideLoader());
            } else {
                store.dispatch(hideLoader());
                alert(response.message);
            }
        } catch (e) {
            store.dispatch(hideLoader());
        }
    }

    const selectCustomer = (item) => {
        console.log(item);
        setQuery({
            ...query,
            cust_corp_code: item.cust_corp_code,
            business_num: item.business_num,
            cust_corp_no: item.cust_corp_no,
            cust_corp_nm: item.corp_nm
        })
        setShowFindCustomer(false);
    }

    function onChangeOrderNo(e) {
        const lines = e.split(/\n/);
        let arrays = [];
        for (let i = 0; i < lines.length; i++) {
            if (/\S/.test(lines[i])) {
                arrays.push(lines[i].trim());
            }
        }
        setQuery({
            ...query,
            goods_no_arr: arrays
        })
    }

    const searchQuery = async () => {
        setTotalAmount(0);
        let params = "";
        let filterParams = query;
        filterParams.start_dt = startDate;
        filterParams.end_dt = endDate;
        filterParams.date_type = dateType;
        filterParams.page = page;
        filterParams.size = size;
        if (Object.entries(filterParams).length > 0) {
            params = getPath(filterParams);
        }
        try {
            store.dispatch(showLoader());
            let totalWt = 0;
            let totalPrice = 0;
            let totalFreight = 0;
            const response = await axiosInstance.get('/shipment/orderList' + params);
            if (response.code === 200) {
                let listOfObjs = [];
                for (let i = 0; i < response.data.items.length; i++) {
                    let item = response.data.items[i];
                    item.is_checked = false;
                    listOfObjs.push(item);
                    totalWt += Number.parseFloat(item.goods_wt);
                    totalPrice += Number.parseFloat(item.order_price);
                    totalFreight += Number.parseFloat(item.freight_cost);
                }
                setList(listOfObjs);
                setTotalInfo({
                    ...totalInfo,
                    total_wt: totalWt,
                    total_price: totalPrice,
                    total_freight: totalFreight
                })
                setTotalPage(Number.parseInt(response.data.totalPage));
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }

    function checkAllOrders(e) {
        let listItems = [...list];
        let totalAmount = 0
        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            totalAmount += Number(item.goods_wt);
        }
        setTotalAmount(e ? totalAmount : 0);
        for (let i = 0; i < listItems.length; i++) {
            listItems[i].is_checked = e;
        }
        setList(listItems);
    }

    function selectOrderItem(e, item) {
        let listItems = [...list];
        if (e) {
            setTotalAmount(totalAmount + Number(item.goods_wt))
        }
        if (!e) {
            setTotalAmount(totalAmount - Number(item.goods_wt))
        }
        for (let i = 0; i < listItems.length; i++) {
            if (listItems[i].goods_no === item.goods_no) {
                listItems[i].is_checked = e;
            }
        }
        setList(listItems);
    }

    const cancelOrderShipment = async () => {
        let listOfObjs = [];
        for (let i = 0; i < list.length; i++) {
            if (list[i].is_checked) {
                listOfObjs.push(list[i].ord_no);
            }
        }
        if (listOfObjs.length === 0) {
            alert("Please select items")
            return;
        }
        console.log(listOfObjs);
        const payload = {
            order_no_arr: listOfObjs
        }
        store.dispatch(showLoader());
        try {
            const response = await axiosInstance.post('/shipment/cancelOrderShipment', payload);
            if (response.code === 200) {
                toast.dismiss();
                toast.success(('Success'));
                await searchQuery();
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }

    const updateShipmentTime = async () => {
        let listOfObjs = [];
        for (let i = 0; i < list.length; i++) {
            if (list[i].is_checked) {
                listOfObjs.push(list[i].ord_no);
            }
        }
        if (listOfObjs.length === 0) {
            alert("Please select items")
            return;
        }
        console.log(listOfObjs);
        const payload = {
            order_no_arr: listOfObjs
        }
        store.dispatch(showLoader());
        try {
            const response = await axiosInstance.post('/shipment/updateShipmentTime', payload);
            if (response.code === 200) {
                toast.dismiss();
                toast.success(('Success'));
                await searchQuery();
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }

    function clearList() {
        let currentDate = new Date();
        let startOfDayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);
        setStartDateFm(format(startOfDayDate, 'yyyy-MM-dd'));
        setStartDate(getUnixTimeByDate(startOfDayDate));

        let endOfDayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);
        setEndDateFm(format(endOfDayDate, 'yyyy-MM-dd'))
        setEndDate(getUnixTimeByDate(endOfDayDate))

        setQuery({
            ...query,
            start_confirm_dt: '',
            end_confirm_dt: '',
            start_send_dt: '',
            end_send_dt: '',
            storage_div_cd: '',
            cust_corp_nm: '',
            cust_corp_code: '',
            cust_corp_no: '',
            goods_no_arr: ''
        })

        setList([]);
        setSize(50);
        setPage(1);
        setTotalPage(0);
        setTotalInfo({
            ...totalInfo,
            total_wt: '',
            total_price: '',
            total_freight: ''
        })
    }

    return (
        <>
            <div id="wrap">
                <Header/>
                {/* [Start] main-area */}
                <section className="main-area" id="main">
                    <div className="menu-visual" style={{backgroundImage: `url(${Background})`}}>
                        <h2 className="menu-visual__tit">
                            출하지시관리
                        </h2>
                        <div className="breadcrumbs-area">
                            <ol>
                                <li className="breadcrumbs-area__step breadcrumbs-area__step--root">
                                    <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                                        <i className="icon24 icon-home"/>
                                        <span className="a11y">홈으로</span>
                                    </a>
                                </li>
                                <li className="breadcrumbs-area__step breadcrumbs-area__step--more"> {/* [D] sub menu 있을 시, breadcrumbs-area__step--more 클래스 추가 */}
                                    <a href="#!" className="breadcrumbs-area__link">
                                        <span>시스템관리</span>
                                    </a>
                                    <ul className="breadcrumbs-area__sub-menu">
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 1</a>
                                        </li>
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 2</a>
                                        </li>
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 3</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="breadcrumbs-area__step">
                                    <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                                        <span>출하지시관리</span>
                                    </a>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className="cont-area">
                        <div className="cont-area__grid">
                            <div className="cont-area__section">
                                <article>
                                    <div className="article-content">
                                        <div className="tbl-area">
                                            <div className="table-wrap">
                                                <table>
                                                    <colgroup>
                                                        <col style={{width: 160}}/>
                                                        <col/>
                                                        <col style={{width: 160}}/>
                                                        <col/>
                                                        <col style={{width: 160}}/>
                                                        <col/>
                                                    </colgroup>
                                                    <tbody>
                                                    <tr>
                                                        <th>주문일자/출하지시일자</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item type-range">
                                                                    <select className="selectbox" onChange={(e) => {
                                                                        setDateType(e.target.value)
                                                                    }}>
                                                                        <option value='A'>주문일자</option>
                                                                        <option value='B'>출하지시일자</option>
                                                                    </select>
                                                                    <input type="date" id className="inp type-date"
                                                                           defaultValue={startDateFm}
                                                                           onChange={(e) => setStartDate(getUnixTimeByDate(new Date(e.target.value)))}/>
                                                                    <span className="form-division">~</span>
                                                                    <input type="date" id className="inp type-date"
                                                                           defaultValue={endDateFm}
                                                                           onChange={(e) => setEndDate(getUnixTimeByDate(new Date(e.target.value)))}/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>창고</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item">
                                                                    <select className="selectbox"
                                                                            onChange={(e) => setQuery({
                                                                                ...query,
                                                                                storage_div_cd: e.target.value
                                                                            })}>
                                                                        <option value=''>전체</option>
                                                                        <option value='G101'>우성</option>
                                                                        <option value='G041'>삼우</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>고객코드/고객명</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item col2 overlap-check">
                                                                    <input
                                                                        type="text"
                                                                        className="inp"
                                                                        value={query.cust_corp_code}
                                                                        disabled
                                                                    />
                                                                    <input
                                                                        type="text"
                                                                        className="inp"
                                                                        value={query.cust_corp_nm}
                                                                        disabled
                                                                    />
                                                                    <button onClick={() => setShowFindCustomer(true)}
                                                                            type="button" className="btn-check_001">
                                                                        찾기
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th rowSpan={3} className="border-0">제품번호</th>
                                                        <td rowSpan={3} className="border-0">
                                                            <div className="product-num">
                                                                <div className="item">
                                                                    <div className="form-area">
                                                                        <div className="form-item">
                                                                            <textarea className="inp"
                                                                                      defaultValue={""}
                                                                                      onChange={(e) => {
                                                                                          onChangeOrderNo(e.target.value)
                                                                                      }}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="summary">
                                                                    <p>
                                                                        *제품번호, 패키지번호 구분은 , 또는 엔터로 구분<br/><br/>
                                                                        예) A3022,A0033
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                <div className="btn-area gap-x40">
                                    <button onClick={() => clearList()} className="btn-base type-secondary size-m">
                                        <span>초기화</span>
                                    </button>
                                    <button onClick={() => searchQuery()}
                                            className="btn-base type-primary size-m grid-m">
                                        <i className="icon24 icon-search-light"/>
                                        <span>
                조회
              </span>
                                    </button>
                                </div>
                                <article className="space">
                                    <div className="article-content">
                                        <div className="tbl-area">
                                            <div className="table-wrap">
                                                <table>
                                                    <colgroup>
                                                        <col style={{width: 120}}/>
                                                        <col style={{width: 160}}/>
                                                        <col/>
                                                        <col style={{width: 160}}/>
                                                        <col/>
                                                        <col style={{width: 160}}/>
                                                        <col/>
                                                    </colgroup>
                                                    <tbody>
                                                    <tr>
                                                        <th className="total">합계</th>
                                                        <th>제품중량</th>
                                                        <td>{addComma(totalInfo.total_wt)}</td>
                                                        <th>제품공급가액</th>
                                                        <td>{addComma(totalInfo.total_price)}</td>
                                                        <th>운임비공급가금액</th>
                                                        <td>{addComma(totalInfo.total_freight)}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                <article className="space">
                                    <div className="article-content">
                                        <div className="tbl-area">
                                            <div className="tbl-upper">
                                                <div className="tbl-upper__cont">
                                                    <button type="button" className="btn-round-util"
                                                            onClick={() => cancelOrderShipment()}>
                                                        <span>출하지시취소</span>
                                                    </button>
                                                    <button type="button" className="btn-round-util"
                                                            onClick={() => updateShipmentTime()}>
                                                        <span>출하지시</span>
                                                    </button>
                                                </div>
                                                <div className="tbl-upper__util">
                                                    <strong className="tbl-area__tit">
                                                        선택 중량(KG) {addComma(totalAmount)}
                                                    </strong>
                                                    <DownloadTableExcel filename="kasco" sheet="users"
                                                                        currentTableRef={tableRef.current}>
                                                        <a href="#" className="btn-base type-dark">
                                                            <span>다운로드</span>
                                                        </a>
                                                    </DownloadTableExcel>
                                                    <select className="selectbox type-light"
                                                            onChange={(e) => setSize(e.target.value)}>
                                                        <option value='50'>50개씩</option>
                                                        <option value='100'>100개씩</option>
                                                        <option value='200'>200개씩</option>
                                                        <option value='500'>500개씩</option>
                                                        <option value='1000'>1000개씩</option>
                                                        <option value='5000'>5000개씩</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="tbl-base type-hover">
                                                <table className="table-product" ref={tableRef}>
                                                    <caption>
                                                        정보 테이블
                                                    </caption>
                                                    <colgroup>
                                                        <col style={{width: '90px'}}/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                    </colgroup>
                                                    <thead>
                                                    <tr>
                                                        <th scope="col">
                                                            <input type="checkbox" id="chk2-all"
                                                                   name="chk2Group"
                                                                   onChange={(e) => checkAllOrders(e.target.checked)}
                                                            />
                                                            <label htmlFor="chk2-all" className="lb-chk">
                                                                <span className="a11y">전체선택</span></label>
                                                        </th>
                                                        <th scope="col">주문번호</th>
                                                        <th scope="col">입고확정일자</th>
                                                        <th scope="col">제품번호</th>
                                                        <th scope="col">두께</th>
                                                        <th scope="col">폭</th>
                                                        <th scope="col">길이</th>
                                                        <th scope="col">중량</th>
                                                        <th scope="col">규격약호</th>
                                                        <th scope="col">고객코드</th>
                                                        <th scope="col">고객사</th>
                                                        <th scope="col">목적지</th>
                                                        <th scope="col">도착지전화번호</th>
                                                        <th scope="col">경매번호</th>
                                                        <th scope="col">제품금액</th>
                                                        <th scope="col">운임비</th>
                                                        <th scope="col">총합계</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        list.map((item, index) => (
                                                            <tr>
                                                                <td>
                                                                    <input type="checkbox"
                                                                           checked={item.is_checked}
                                                                           id={`chkGoods${index}-1`}
                                                                           name="chkGoodsGroup"
                                                                           onChange={(e) => selectOrderItem(e.target.checked, item)}/>
                                                                    <label htmlFor={`chkGoods${index}-1`}
                                                                           className="lb-chk">
                                                                        <span className="a11y">선택</span>
                                                                    </label>
                                                                </td>
                                                                <td><a href>{item.ord_no}</a></td>
                                                                <td>{item.recv_confirm_dt}</td>
                                                                <td>{item.goods_no}</td>
                                                                <td>{addComma(item.goods_t_mm)}</td>
                                                                <td>{addComma(item.goods_w_mm)}</td>
                                                                <td>{addComma(item.goods_len_mm)}</td>
                                                                <td>{addComma(item.goods_wt)}</td>
                                                                <td>{item.spec_abbsym}</td>
                                                                <td>{item.cust_corp_code}</td>
                                                                <td>{item.corp_nm}</td>
                                                                <td>{item.dest_sigun_nm}</td>
                                                                <td>{item.dest_phone}</td>
                                                                <td>{item.auction_no}</td>
                                                                <td>{addComma(item.order_price)}</td>
                                                                <td>{addComma(item.freight_cost)}</td>
                                                                <td>{addComma(item.amount)}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                <Pagination pageCount={totalPage}
                                            paginate={(p) => setPage(p.selected + 1)}></Pagination>
                            </div>
                        </div>
                    </div>
                </section>
                {/* // [End] main-area */}
                <Footer/>
            </div>

            <Modal
                dialogClassName="justify-content-center"
                show={showFindCustomer}
                size="lg"
                onHide={() => setShowFindCustomer(false)}
            >
                <Modal.Body style={{background: '#fff'}}>
                    <section className="main-area">
                        <article className="small-pop">
                            <div className="article-header">
                                <h1 className="popup-header__tit"> 고객사 찾기</h1>
                                <button type="button" className="btn-close"
                                        onClick={() => setShowFindCustomer(false)}><span
                                    className="a11y">팝업 닫기</span></button>
                            </div>
                            <div className="article-content">
                                <div className="cont-area">
                                    <div className="cont-area__section">
                                        <article>
                                            <div className="article-content">
                                                <div className="tbl-area">
                                                    <div className="table-wrap">
                                                        <table>
                                                            <colgroup>
                                                                <col style={{width: '160px'}}/>
                                                                <col/>
                                                                <col style={{width: '160px'}}/>
                                                                <col/>
                                                            </colgroup>
                                                            <tbody>
                                                            <tr>
                                                                <th>고객사명</th>
                                                                <td>
                                                                    <div className="form-area">
                                                                        <div className="form-item">
                                                                            <input type="text" className="inp"
                                                                                   value={queryData.corp_nm}
                                                                                   onChange={(e) => {
                                                                                       setQueryData({
                                                                                           ...queryData,
                                                                                           corp_nm: e.target.value
                                                                                       })
                                                                                   }}/>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <th>사업자번호</th>
                                                                <td>
                                                                    <div className="form-area">
                                                                        <div className="form-item">
                                                                            <input type="text" className="inp"
                                                                                   value={queryData.business_number}
                                                                                   onChange={(e) => {
                                                                                       setQueryData({
                                                                                           ...queryData,
                                                                                           business_number: e.target.value
                                                                                       })
                                                                                   }}/>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                        <div className="btn-area gap-x40">
                                            <button onClick={() => {
                                                setQueryData({
                                                    ...queryData,
                                                    corp_nm: '',
                                                    business_number: ''
                                                });
                                                setQueryResult([]);
                                                setQuerySize(10);
                                                setQueryTotalPage(0);
                                            }} className="btn-base type-secondary size-m">
                                                <span>초기화</span>
                                            </button>
                                            <button onClick={() => searchCustomer()}
                                                    className="btn-base type-primary size-m grid-m">
                                                <i className="icon24 icon-search-light"/>
                                                <span>
                        검색
                      </span>
                                            </button>
                                        </div>
                                        <article className="space">
                                            <div className="article-content">
                                                <div className="tbl-area">
                                                    <div className="tbl-upper">
                                                        <div className="tbl-upper__util">
                                                            <select className="selectbox type-light"
                                                                    onChange={(e) => setQuerySize(e.target.value)}>
                                                                <option value='50'>50개씩</option>
                                                                <option value='100'>100개씩</option>
                                                                <option value='200'>200개씩</option>
                                                                <option value='500'>500개씩</option>
                                                                <option value='1000'>1000개씩</option>
                                                                <option value='5000'>5000개씩</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="tbl-base type-hover">
                                                        <table>
                                                            <colgroup>
                                                                <col style={{width: '100px'}}/>
                                                                <col/>
                                                                <col/>
                                                            </colgroup>
                                                            <thead>
                                                            <tr>
                                                                <th>고객코드</th>
                                                                <th>고객사명</th>
                                                                <th>사업자번호</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                queryResult.map((item, index) => (
                                                                    <tr onClick={() => selectCustomer(item)}>
                                                                        <td>{item.cust_corp_code}</td>
                                                                        <td>{item.corp_nm}</td>
                                                                        <td>{item.business_num}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <Pagination pageCount={queryTotalPage}
                                                            paginate={(p) => setQueryPage(p.selected + 1)}></Pagination>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </section>
                </Modal.Body>
            </Modal>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter main_modal"
                centered
                contentClassName="main_modal_body"
                dialogClassName='justify-content-center'
                show={showSuccessModal}
                onHide={() => setShowSuccessModal(false)}>
                <Modal.Body>
                    <div className="d-flex flex-column align-items-center justify-content-center h-100">
                        <img src={Check} alt="" className="mb-40"/>
                        <div className="main_modal_text">
                            Your password has been changed.
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="w-100">
                        <button className="main_modal_btn_002" onClick={() => setShowSuccessModal(false)}>
                            OK
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Kas_0850
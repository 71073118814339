import React, {useEffect, useState, useRef} from 'react'
import { DownloadTableExcel, useDownloadExcel} from 'react-export-table-to-excel';
import Header from '../../containers/header/Header1'
import Footer from '../../containers/footer/Footer'

import Background from '../../assets/img/menu-visual1.jpg'

function Kas0602() {
  const tableRef = useRef(null);
  return (
    <>
      <div id="wrap">
        <Header />
        {/* [Start] main-area */}
        <section className="main-area" id="main">
          <div
            className="menu-visual"
            style={{ backgroundImage: `url(${Background})` }}
          >
            <h2 className="menu-visual__tit">경매몰</h2>
            <div className="breadcrumbs-area">
              <ol>
                <li className="breadcrumbs-area__step breadcrumbs-area__step--root">
                  <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                    <i className="icon24 icon-home" />
                    <span className="a11y">홈으로</span>
                  </a>
                </li>
                <li className="breadcrumbs-area__step breadcrumbs-area__step--more">
                  {/* [D] sub menu 있을 시, breadcrumbs-area__step--more 클래스 추가 */}
                  <a href="javascript:void(0);" className="breadcrumbs-area__link">
                    <span>시스템관리</span>
                  </a>
                  <ul className="breadcrumbs-area__sub-menu">
                    <li>
                      <a href="/manageProduct1.html">시스템관리 1</a>
                    </li>
                    <li>
                      <a href="/manageProduct1.html">시스템관리 2</a>
                    </li>
                    <li>
                      <a href="/manageProduct1.html">시스템관리 3</a>
                    </li>
                  </ul>
                </li>
                <li className="breadcrumbs-area__step">
                  <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                    <span>경매몰</span>
                  </a>
                </li>
              </ol>
            </div>
          </div>
          <div className="cont-area">
            <div className="cont-area__grid">
              <div className="cont-area__header">
                <div className="cont-area__header-inner">
                  <div className="tab-area">
                    <a href="#" className="tab-area__btn tab-area__btn--active">
                      {/* [D] 활성화 탭 버튼에 tab-area__btn--active 클래스 추가 */}
                      <span>경매응찰</span>
                    </a>
                    <a href="#" className="tab-area__btn">
                      <span>경매진행조회</span>
                    </a>
                    <a href="#" className="tab-area__btn">
                      <span>경매진행상세조회</span>
                    </a>
                    <a href="#" className="tab-area__btn">
                      <span>경매낙찰</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="cont-area__section">
                <div className="board-frame">
                  <div>
                    [낙찰예상 중량 <span>8</span>톤] - [최소낙찰 <span>10</span>톤] -
                    = [<span>-2톤 상태입니다.</span>]
                  </div>
                  <div>
                    2022년 06월 28일 1회차 14:00 경매 진행중{" "}
                    <span className="under-txt">남은시간 11:11:11</span>
                  </div>
                </div>
                <article className="gap-x40">
                  <div className="article-content">
                    <div className="tbl-area">
                      <div className="table-wrap">
                        <table>
                          <colgroup>
                            <col style={{ width: 160 }} />
                            <col />
                            <col style={{ width: 160 }} />
                            <col />
                            <col style={{ width: 160 }} />
                            <col />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>경매시작일자/회차</th>
                              <td>
                                <div className="form-area">
                                  <div className="form-item col2">
                                    <input
                                      type="date"
                                      id=""
                                      className="inp type-date"
                                      defaultValue="2022-11-01"
                                    />
                                    <select className="selectbox">
                                      <option value="">전체</option>
                                      <option value="">옵션1</option>
                                      <option value="">옵션2</option>
                                      <option value="">옵션3</option>
                                    </select>
                                  </div>
                                </div>
                              </td>
                              <th>판매구분</th>
                              <td>
                                <div className="form-area">
                                  <div className="check-box">
                                    <input type="checkbox" id="check1" />
                                    <label htmlFor="check1" className="lb-chk">
                                      <span>일반경매</span>
                                    </label>
                                    <input type="checkbox" id="check2" />
                                    <label htmlFor="check2" className="lb-chk">
                                      <span>패키지경매</span>
                                    </label>
                                  </div>
                                </div>
                              </td>
                              <th>경매구분</th>
                              <td>
                                <div className="form-area">
                                  <div className="check-box">
                                    <input type="checkbox" id="check3" />
                                    <label htmlFor="check3" className="lb-chk">
                                      <span>정기경매</span>
                                    </label>
                                    <input type="checkbox" id="check4" />
                                    <label htmlFor="check4" className="lb-chk">
                                      <span>추가경매</span>
                                    </label>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>제조사</th>
                              <td>
                                <div className="form-area">
                                  <div className="form-item">
                                    <select className="selectbox">
                                      <option value="">전체</option>
                                      <option value="">옵션1</option>
                                      <option value="">옵션2</option>
                                      <option value="">옵션3</option>
                                    </select>
                                  </div>
                                </div>
                              </td>
                              <th>창고</th>
                              <td>
                                <div className="form-area">
                                  <div className="form-item">
                                    <select className="selectbox">
                                      <option value="">전체</option>
                                      <option value="">옵션1</option>
                                      <option value="">옵션2</option>
                                      <option value="">옵션3</option>
                                    </select>
                                  </div>
                                </div>
                              </td>
                              <th>낙찰상태</th>
                              <td>
                                <div className="form-area">
                                  <div className="form-item">
                                    <select className="selectbox">
                                      <option value="">전체</option>
                                      <option value="">옵션1</option>
                                      <option value="">옵션2</option>
                                      <option value="">옵션3</option>
                                    </select>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>제품군</th>
                              <td>
                                <div className="form-area">
                                  <div className="form-item">
                                    <select className="selectbox">
                                      <option value="">전체</option>
                                      <option value="">옵션1</option>
                                      <option value="">옵션2</option>
                                      <option value="">옵션3</option>
                                    </select>
                                  </div>
                                </div>
                              </td>
                              <th>제품등급</th>
                              <td>
                                <div className="form-area">
                                  <div className="check-box">
                                    <input type="checkbox" id="check5" />
                                    <label htmlFor="check5" className="lb-chk">
                                      <span>1등급</span>
                                    </label>
                                    <input type="checkbox" id="check6" />
                                    <label htmlFor="check6" className="lb-chk">
                                      <span>2등급</span>
                                    </label>
                                  </div>
                                </div>
                              </td>
                              <th>규격약호</th>
                              <td>
                                <div className="form-area">
                                  <div className="form-item overlap-check">
                                    <input type="text" className="inp" />
                                    <button type="button" className="btn-check">
                                      찾기
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>두께</th>
                              <td>
                                <div className="form-area">
                                  <div className="form-item type-range">
                                    <input type="text" className="inp" />
                                    <span className="form-division">~</span>
                                    <input type="text" className="inp" />
                                  </div>
                                </div>
                              </td>
                              <th>폭</th>
                              <td>
                                <div className="form-area">
                                  <div className="form-item type-range">
                                    <input type="text" className="inp" />
                                    <span className="form-division">~</span>
                                    <input type="text" className="inp" />
                                  </div>
                                </div>
                              </td>
                              <th>길이</th>
                              <td>
                                <div className="form-area">
                                  <div className="form-item type-range">
                                    <input type="text" className="inp" />
                                    <span className="form-division">~</span>
                                    <input type="text" className="inp" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th rowSpan={4} className="border-0">
                                제품번호
                              </th>
                              <td rowSpan={4} className="border-0">
                                <div className="product-num">
                                  <div className="item">
                                    <div className="form-area">
                                      <div className="form-item">
                                        <textarea className="inp" defaultValue={""} />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="summary">
                                    <p>
                                      *제품번호, 패키지번호 구분은 , 또는 엔터로 구분
                                      <br />
                                      <br />
                                      예) A3022,A0033
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <th rowSpan={4} className="border-0">
                                패키지번호
                              </th>
                              <td rowSpan={4} className="border-0">
                                <div className="product-num">
                                  <div className="item">
                                    <div className="form-area">
                                      <div className="form-item">
                                        <textarea className="inp" defaultValue={""} />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="summary">
                                    <p>
                                      *제품번호, 패키지번호 구분은 , 또는 엔터로 구분
                                      <br />
                                      <br />
                                      예) A3022,A0033
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <th>경매번호</th>
                              <td>
                                <div className="form-area">
                                  <div className="form-item">
                                    <input type="text" className="inp" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>관심여부</th>
                              <td>
                                <div className="form-area">
                                  <div className="check-box">
                                    <input type="checkbox" id="check7" />
                                    <label htmlFor="check7" className="lb-chk">
                                      <span>Y</span>
                                    </label>
                                    <input type="checkbox" id="check8" />
                                    <label htmlFor="check8" className="lb-chk">
                                      <span>N</span>
                                    </label>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>응찰여부</th>
                              <td>
                                <div className="form-area">
                                  <div className="check-box">
                                    <input type="checkbox" id="check9" />
                                    <label htmlFor="check9" className="lb-chk">
                                      <span>Y</span>
                                    </label>
                                    <input type="checkbox" id="check10" />
                                    <label htmlFor="check10" className="lb-chk">
                                      <span>N</span>
                                    </label>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>낙찰실패</th>
                              <td>
                                <div className="form-area">
                                  <div className="check-box">
                                    <input type="checkbox" id="check11" />
                                    <label htmlFor="check11" className="lb-chk">
                                      <span>Y</span>
                                    </label>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </article>
                <div className="btn-area gap-x40">
                  <a href="#" className="btn-base type-secondary size-m">
                    <span>초기화</span>
                  </a>
                  <a href="#" className="btn-base type-primary size-m grid-m">
                    <i className="icon24 icon-search-light" />
                    <span>조회</span>
                  </a>
                </div>
                <article>
                  <div className="article-header">
                    <h3 className="sub-title">경매응찰 제품</h3>
                  </div>
                  <div className="article-content">
                    <div className="tbl-area">
                      <div className="tbl-upper">
                        <div className="tbl-upper__util">
                          <strong className="tbl-area__tit">
                            낙찰 예상 중량 1,000 kg &nbsp;&nbsp; | &nbsp;&nbsp;{" "}
                            <span>22,000,000원</span>
                          </strong>
                        </div>
                      </div>
                      <div className="tbl-base type-hover">
                        <table>
                          <colgroup>
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                          </colgroup>
                          <thead>
                            <tr>
                              <th>출발지(상품 창고명)</th>
                              <th>목적지(고객 목적지 명)</th>
                              <th>수량</th>
                              <th>중량</th>
                              <th>공급가</th>
                              <th>운임비</th>
                              <th>합계</th>

                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>삼수</td>
                              <td>화성시</td>
                              <td>2</td>
                              <td>10,000</td>
                              <td>1,900,000</td>
                              <td>500,000</td>
                              <td>2,400,000</td>
                            </tr>
                            <tr>
                              <td>삼수</td>
                              <td>화성시</td>
                              <td>4</td>
                              <td>25,000</td>
                              <td>4,500,000</td>
                              <td>900,000</td>
                              <td>5,400,000</td>
                            </tr>
                            <tr>
                              <td>우성</td>
                              <td>울산시</td>
                              <td>4</td>
                              <td>25,000</td>
                              <td>4,500,000</td>
                              <td>900,000</td>
                              <td>5,400,000</td>
                            </tr>
                            <tr>
                              <td>우성</td>
                              <td>부산시</td>
                              <td>4</td>
                              <td>25,000</td>
                              <td>4,500,000</td>
                              <td>900,000</td>
                              <td>5,400,000</td>
                            </tr>
                            <tr>
                              <td>합계</td>
                              <td></td>
                              <td>14</td>
                              <td>85,000</td>
                              <td>15,400,000</td>
                              <td>3,200,000</td>
                              <td>18,600,000</td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="tbl-footer">
                      <div className="tbl-footer__cont">
                        <strong className="tbl-footer__tit">총 30,000건</strong>
                      </div>
                    </div>
                  </div>
                </article>
                <article>
                  <div className="article-header">
                    <h3 className="sub-title">경매대상 제품</h3>
                  </div>
                  <div className="aticle-content">
                    <div className="tbl-area">
                      <div className="table-wrap">
                        <table>
                          <colgroup>
                            <col style={{ width: 120 }} />
                            <col />
                            <col style={{ width: 100 }} />
                            <col style={{ width: 420 }} />
                            <col style={{ width: 120 }} />
                            <col style={{ width: 430 }} />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th className="necessary">응찰단가적용</th>
                              <td>
                                <div className="form-group">
                                  <dl>
                                    <dt>최고가+</dt>
                                    <dd>
                                      <div className="form-item overlap-check">
                                        <input type="text" className="inp" />
                                        <button type="button" className="btn-check_001">
                                          일괄적용
                                        </button>
                                      </div>
                                    </dd>
                                  </dl>
                                </div>
                              </td>
                              <th>목적지</th>
                              <td>
                                <div className="form-area col2">
                                  <div className="form-item overlap-check">
                                    <input type="text" className="inp" />
                                    <button type="button" className="btn-check_001">
                                      찾기
                                    </button>
                                  </div>
                                  <div className="form-item">
                                    <input type="text" className="inp" />
                                  </div>
                                </div>
                              </td>
                              <th>착지전화번호</th>
                              <td>
                                <div className="form-area col2">
                                  <div className="form-item">
                                    <input type="text" className="inp" />
                                  </div>
                                  <div className="form-item">
                                    <div className="btn-area d-flex">
                                      <button className="btn-default_01">
                                        목적지 적용
                                      </button>
                                      <button className="btn-default_01">
                                        경매 응찰
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </article>
                <article className="space">
                  <div className="article-content">
                    <div className="tbl-area">
                      <div className="tbl-upper">
                        <div className="tbl-upper__cont">
                          <button type="button" className="btn-round-util">
                            <span>저장</span>
                          </button>
                        </div>
                        <div className="tbl-upper__util">
                          <strong className="tbl-area__tit">
                            선택중량(KG) 993,934
                          </strong>
                          <DownloadTableExcel filename="kasco" sheet="users"
                                                                        currentTableRef={tableRef.current}>
                                                        <a href="#" className="btn-base type-dark">
                                                            <span>다운로드</span>
                                                        </a>
                                                    </DownloadTableExcel>
                          <select className="selectbox type-light">
                            <option value='50'>50개씩</option>
                            <option value='100'>100개씩</option>
                            <option value='200'>200개씩</option>
                            <option value='500'>500개씩</option>
                            <option value='1000'>1000개씩</option>
                            <option value='5000'>5000개씩</option>
                          </select>
                        </div>
                      </div>
                      <div className="tbl-base type-hover scroll-warp">
                        <table ref={tableRef}>
                          <caption>정보 테이블</caption>
                          <colgroup />
                          <thead>
                            <tr>
                              <th scope="col">
                                <input
                                  type="checkbox"
                                  id="chk1-all"
                                  name="chk1Group"
                                />
                                <label htmlFor="chk1-all" className="lb-chk">
                                  <span className="a11y">전체선택</span>
                                </label>
                              </th>
                              <th scope="col">번호</th>
                              <th scope="col" className="th-point">
                                관심여부
                              </th>
                              <th scope="col">낙찰상태</th>
                              <th scope="col">제품구분</th>
                              <th scope="col">경매구분</th>
                              <th scope="col">경매번호</th>
                              <th scope="col">회차</th>
                              <th scope="col">경매상태</th>
                              <th scope="col">경매시작일</th>
                              <th scope="col">경매종료일시</th>
                              <th scope="col">패키지명</th>
                              <th scope="col">패키지번호</th>
                              <th scope="col">ProNo</th>
                              <th scope="col">제품번호</th>
                              <th scope="col">제조사</th>
                              <th colSpan={2} scope="col">
                                판매유형
                              </th>
                              <th scope="col">제품상태</th>
                              <th scope="col">창고</th>
                              <th scope="col">기준단가</th>
                              <th scope="col">시작단가</th>
                              <th scope="col">응찰가</th>
                              <th scope="col">낙찰가능여부</th>
                              <th scope="col">목적지코드</th>
                              <th scope="col">목적지주소</th>
                              <th scope="col">착지전화번호</th>
                              <th scope="col">제품군</th>
                              <th scope="col">대표품명</th>
                              <th scope="col">품명</th>
                              <th scope="col">진도코드</th>
                              <th scope="col">제품등급</th>
                              <th scope="col">두께</th>
                              <th scope="col">폭</th>
                              <th scope="col">길이</th>
                              <th scope="col">중량</th>
                              <th scope="col">YP</th>
                              <th scope="col">TS</th>
                              <th scope="col">EL</th>
                              <th scope="col">C%</th>
                              <th scope="col">SI</th>
                              <th scope="col">MN</th>
                              <th scope="col">P</th>
                              <th scope="col">S</th>
                              <th scope="col">야드구분</th>
                              <th scope="col">매수</th>
                              <th scope="col">인장시험</th>
                              <th scope="col">화학성분1</th>
                              <th scope="col">화학성분2</th>
                              <th scope="col">여재원인</th>
                              <th scope="col">여제원인명1</th>
                              <th scope="col">여제원인명2</th>
                              <th scope="col">여제원인명3</th>
                              <th scope="col">용도코드</th>
                              <th scope="col">용도명</th>
                              <th scope="col">정척여부</th>
                              <th scope="col">규격약호</th>
                              <th scope="col">제품사양</th>
                              <th scope="col">유찰횟수</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <input type="checkbox" id="chk1-1" name="chk1Group" />
                                <label htmlFor="chk1-1" className="lb-chk">
                                  <span className="a11y">선택</span>
                                </label>
                              </td>
                              <td>999</td>
                              <td>
                                <select
                                  name="select"
                                  id="select"
                                  className="selectbox type-tiny"
                                >
                                  <option value="">Y</option>
                                  <option value="">N</option>
                                </select>
                              </td>
                              <td>응찰</td>
                              <td>일반경매</td>
                              <td>정기경매</td>
                              <td>
                                <a href="">202220331</a>
                              </td>
                              <td>03</td>
                              <td>경매대기</td>
                              <td>2022.06.23 00:00:00</td>
                              <td>2022.06.23 00:00:00</td>
                              <td>패키지명1</td>
                              <td>
                                <a href="">PK013949</a>
                              </td>
                              <td>
                                <a href="">P02222</a>
                              </td>
                              <td>
                                <a href="">P1011112</a>
                              </td>
                              <td>현대</td>
                              <td>일반</td>
                              <td>특판</td>
                              <td>판매중</td>
                              <td>삼우1</td>
                              <td>8,390,000</td>
                              <td>8,390,000</td>
                              <td>8,200,000</td>
                              <td>X</td>
                              <td>F011</td>
                              <td>대전시 유성구</td>
                              <td>011-1111-1111</td>
                              <td>후판</td>
                              <td>대표품명1</td>
                              <td>PCJ</td>
                              <td>출하지시대기</td>
                              <td>1</td>
                              <td>9.00</td>
                              <td>1,934.0</td>
                              <td>2,032</td>
                              <td>1,393</td>
                              <td>0.001</td>
                              <td>392.9</td>
                              <td>392.9</td>
                              <td>0.992</td>
                              <td>0.001</td>
                              <td>0.001</td>
                              <td>0.001</td>
                              <td>0.001</td>
                              <td>A</td>
                              <td>1</td>
                              <td>
                                <p>-EL</p>
                              </td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>1C</td>
                              <td>OVER ROLL</td>
                              <td>OVER ROLL</td>
                              <td>OVER ROLL</td>
                              <td>A01</td>
                              <td>구조일반-일반강관</td>
                              <td>Y</td>
                              <td>23M32</td>
                              <td>83 X 3,409 X 3,900</td>
                              <td>3</td>
                            </tr>
                            <tr>
                              <td>
                                <input type="checkbox" id="chk1-1" name="chk1Group" />
                                <label htmlFor="chk1-1" className="lb-chk">
                                  <span className="a11y">선택</span>
                                </label>
                              </td>
                              <td>999</td>
                              <td>
                                <select
                                  name="select"
                                  id="select"
                                  className="selectbox type-tiny"
                                >
                                  <option value="">Y</option>
                                  <option value="">N</option>
                                </select>
                              </td>
                              <td>응찰</td>
                              <td>일반경매</td>
                              <td>정기경매</td>
                              <td>
                                <a href="">202220331</a>
                              </td>
                              <td>03</td>
                              <td>경매대기</td>
                              <td>2022.06.23 00:00:00</td>
                              <td>2022.06.23 00:00:00</td>
                              <td>패키지명1</td>
                              <td>
                                <a href="">PK013949</a>
                              </td>
                              <td>
                                <a href="">P02222</a>
                              </td>
                              <td>
                                <a href="">P1011112</a>
                              </td>
                              <td>현대</td>
                              <td>일반</td>
                              <td>특판</td>
                              <td>판매중</td>
                              <td>삼우1</td>
                              <td>8,390,000</td>
                              <td>8,390,000</td>
                              <td>8,200,000</td>
                              <td>X</td>
                              <td>F011</td>
                              <td>대전시 유성구</td>
                              <td>011-1111-1111</td>
                              <td>후판</td>
                              <td>대표품명1</td>
                              <td>PCJ</td>
                              <td>출하지시대기</td>
                              <td>1</td>
                              <td>9.00</td>
                              <td>1,934.0</td>
                              <td>2,032</td>
                              <td>1,393</td>
                              <td>0.001</td>
                              <td>392.9</td>
                              <td>392.9</td>
                              <td>0.992</td>
                              <td>0.001</td>
                              <td>0.001</td>
                              <td>0.001</td>
                              <td>0.001</td>
                              <td>A</td>
                              <td>1</td>
                              <td>
                                <p>-EL</p>
                              </td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>1C</td>
                              <td>OVER ROLL</td>
                              <td>OVER ROLL</td>
                              <td>OVER ROLL</td>
                              <td>A01</td>
                              <td>구조일반-일반강관</td>
                              <td>Y</td>
                              <td>23M32</td>
                              <td>83 X 3,409 X 3,900</td>
                              <td>3</td>
                            </tr>
                            <tr>
                              <td>
                                <input type="checkbox" id="chk1-1" name="chk1Group" />
                                <label htmlFor="chk1-1" className="lb-chk">
                                  <span className="a11y">선택</span>
                                </label>
                              </td>
                              <td>999</td>
                              <td>
                                <select
                                  name="select"
                                  id="select"
                                  className="selectbox type-tiny"
                                >
                                  <option value="">Y</option>
                                  <option value="">N</option>
                                </select>
                              </td>
                              <td>응찰</td>
                              <td>일반경매</td>
                              <td>정기경매</td>
                              <td>
                                <a href="">202220331</a>
                              </td>
                              <td>03</td>
                              <td>경매대기</td>
                              <td>2022.06.23 00:00:00</td>
                              <td>2022.06.23 00:00:00</td>
                              <td>패키지명1</td>
                              <td>
                                <a href="">PK013949</a>
                              </td>
                              <td>
                                <a href="">P02222</a>
                              </td>
                              <td>
                                <a href="">P1011112</a>
                              </td>
                              <td>현대</td>
                              <td>일반</td>
                              <td>특판</td>
                              <td>판매중</td>
                              <td>삼우1</td>
                              <td>8,390,000</td>
                              <td>8,390,000</td>
                              <td>8,200,000</td>
                              <td>X</td>
                              <td>F011</td>
                              <td>대전시 유성구</td>
                              <td>011-1111-1111</td>
                              <td>후판</td>
                              <td>대표품명1</td>
                              <td>PCJ</td>
                              <td>출하지시대기</td>
                              <td>1</td>
                              <td>9.00</td>
                              <td>1,934.0</td>
                              <td>2,032</td>
                              <td>1,393</td>
                              <td>0.001</td>
                              <td>392.9</td>
                              <td>392.9</td>
                              <td>0.992</td>
                              <td>0.001</td>
                              <td>0.001</td>
                              <td>0.001</td>
                              <td>0.001</td>
                              <td>A</td>
                              <td>1</td>
                              <td>
                                <p>-EL</p>
                              </td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>1C</td>
                              <td>OVER ROLL</td>
                              <td>OVER ROLL</td>
                              <td>OVER ROLL</td>
                              <td>A01</td>
                              <td>구조일반-일반강관</td>
                              <td>Y</td>
                              <td>23M32</td>
                              <td>83 X 3,409 X 3,900</td>
                              <td>3</td>
                            </tr>
                            <tr>
                              <td>
                                <input type="checkbox" id="chk1-1" name="chk1Group" />
                                <label htmlFor="chk1-1" className="lb-chk">
                                  <span className="a11y">선택</span>
                                </label>
                              </td>
                              <td>999</td>
                              <td>
                                <select
                                  name="select"
                                  id="select"
                                  className="selectbox type-tiny"
                                >
                                  <option value="">Y</option>
                                  <option value="">N</option>
                                </select>
                              </td>
                              <td>응찰</td>
                              <td>일반경매</td>
                              <td>정기경매</td>
                              <td>
                                <a href="">202220331</a>
                              </td>
                              <td>03</td>
                              <td>경매대기</td>
                              <td>2022.06.23 00:00:00</td>
                              <td>2022.06.23 00:00:00</td>
                              <td>패키지명1</td>
                              <td>
                                <a href="">PK013949</a>
                              </td>
                              <td>
                                <a href="">P02222</a>
                              </td>
                              <td>
                                <a href="">P1011112</a>
                              </td>
                              <td>현대</td>
                              <td>일반</td>
                              <td>특판</td>
                              <td>판매중</td>
                              <td>삼우1</td>
                              <td>8,390,000</td>
                              <td>8,390,000</td>
                              <td>8,200,000</td>
                              <td>X</td>
                              <td>F011</td>
                              <td>대전시 유성구</td>
                              <td>011-1111-1111</td>
                              <td>후판</td>
                              <td>대표품명1</td>
                              <td>PCJ</td>
                              <td>출하지시대기</td>
                              <td>1</td>
                              <td>9.00</td>
                              <td>1,934.0</td>
                              <td>2,032</td>
                              <td>1,393</td>
                              <td>0.001</td>
                              <td>392.9</td>
                              <td>392.9</td>
                              <td>0.992</td>
                              <td>0.001</td>
                              <td>0.001</td>
                              <td>0.001</td>
                              <td>0.001</td>
                              <td>A</td>
                              <td>1</td>
                              <td>
                                <p>-EL</p>
                              </td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>1C</td>
                              <td>OVER ROLL</td>
                              <td>OVER ROLL</td>
                              <td>OVER ROLL</td>
                              <td>A01</td>
                              <td>구조일반-일반강관</td>
                              <td>Y</td>
                              <td>23M32</td>
                              <td>83 X 3,409 X 3,900</td>
                              <td>3</td>
                            </tr>
                            <tr>
                              <td>
                                <input type="checkbox" id="chk1-1" name="chk1Group" />
                                <label htmlFor="chk1-1" className="lb-chk">
                                  <span className="a11y">선택</span>
                                </label>
                              </td>
                              <td>999</td>
                              <td>
                                <select
                                  name="select"
                                  id="select"
                                  className="selectbox type-tiny"
                                >
                                  <option value="">Y</option>
                                  <option value="">N</option>
                                </select>
                              </td>
                              <td>응찰</td>
                              <td>일반경매</td>
                              <td>정기경매</td>
                              <td>
                                <a href="">202220331</a>
                              </td>
                              <td>03</td>
                              <td>경매대기</td>
                              <td>2022.06.23 00:00:00</td>
                              <td>2022.06.23 00:00:00</td>
                              <td>패키지명1</td>
                              <td>
                                <a href="">PK013949</a>
                              </td>
                              <td>
                                <a href="">P02222</a>
                              </td>
                              <td>
                                <a href="">P1011112</a>
                              </td>
                              <td>현대</td>
                              <td>일반</td>
                              <td>특판</td>
                              <td>판매중</td>
                              <td>삼우1</td>
                              <td>8,390,000</td>
                              <td>8,390,000</td>
                              <td>8,200,000</td>
                              <td>X</td>
                              <td>F011</td>
                              <td>대전시 유성구</td>
                              <td>011-1111-1111</td>
                              <td>후판</td>
                              <td>대표품명1</td>
                              <td>PCJ</td>
                              <td>출하지시대기</td>
                              <td>1</td>
                              <td>9.00</td>
                              <td>1,934.0</td>
                              <td>2,032</td>
                              <td>1,393</td>
                              <td>0.001</td>
                              <td>392.9</td>
                              <td>392.9</td>
                              <td>0.992</td>
                              <td>0.001</td>
                              <td>0.001</td>
                              <td>0.001</td>
                              <td>0.001</td>
                              <td>A</td>
                              <td>1</td>
                              <td>
                                <p>-EL</p>
                              </td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>1C</td>
                              <td>OVER ROLL</td>
                              <td>OVER ROLL</td>
                              <td>OVER ROLL</td>
                              <td>A01</td>
                              <td>구조일반-일반강관</td>
                              <td>Y</td>
                              <td>23M32</td>
                              <td>83 X 3,409 X 3,900</td>
                              <td>3</td>
                            </tr>
                            <tr>
                              <td>
                                <input type="checkbox" id="chk1-1" name="chk1Group" />
                                <label htmlFor="chk1-1" className="lb-chk">
                                  <span className="a11y">선택</span>
                                </label>
                              </td>
                              <td>999</td>
                              <td>
                                <select
                                  name="select"
                                  id="select"
                                  className="selectbox type-tiny"
                                >
                                  <option value="">Y</option>
                                  <option value="">N</option>
                                </select>
                              </td>
                              <td>응찰</td>
                              <td>일반경매</td>
                              <td>정기경매</td>
                              <td>
                                <a href="">202220331</a>
                              </td>
                              <td>03</td>
                              <td>경매대기</td>
                              <td>2022.06.23 00:00:00</td>
                              <td>2022.06.23 00:00:00</td>
                              <td>패키지명1</td>
                              <td>
                                <a href="">PK013949</a>
                              </td>
                              <td>
                                <a href="">P02222</a>
                              </td>
                              <td>
                                <a href="">P1011112</a>
                              </td>
                              <td>현대</td>
                              <td>일반</td>
                              <td>특판</td>
                              <td>판매중</td>
                              <td>삼우1</td>
                              <td>8,390,000</td>
                              <td>8,390,000</td>
                              <td>8,200,000</td>
                              <td>X</td>
                              <td>F011</td>
                              <td>대전시 유성구</td>
                              <td>011-1111-1111</td>
                              <td>후판</td>
                              <td>대표품명1</td>
                              <td>PCJ</td>
                              <td>출하지시대기</td>
                              <td>1</td>
                              <td>9.00</td>
                              <td>1,934.0</td>
                              <td>2,032</td>
                              <td>1,393</td>
                              <td>0.001</td>
                              <td>392.9</td>
                              <td>392.9</td>
                              <td>0.992</td>
                              <td>0.001</td>
                              <td>0.001</td>
                              <td>0.001</td>
                              <td>0.001</td>
                              <td>A</td>
                              <td>1</td>
                              <td>
                                <p>-EL</p>
                              </td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>1C</td>
                              <td>OVER ROLL</td>
                              <td>OVER ROLL</td>
                              <td>OVER ROLL</td>
                              <td>A01</td>
                              <td>구조일반-일반강관</td>
                              <td>Y</td>
                              <td>23M32</td>
                              <td>83 X 3,409 X 3,900</td>
                              <td>3</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </section>
        {/* // [End] main-area */}
        <Footer />
      </div>

    </>
  )
}

export default Kas0602
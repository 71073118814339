import React, {useEffect, useState, useRef} from 'react'
import { DownloadTableExcel, useDownloadExcel} from 'react-export-table-to-excel';
import Header from '../../containers/header/Header1'
import Footer from '../../containers/footer/Footer'

import Background from '../../assets/img/menu-visual1.jpg'
import {Link} from "react-router-dom";

function Kas0607_20() {
    const tableRef = useRef(null);

    return (
        <>
            <div id="wrap">
                <Header/>
                {/* [Start] main-area */}
                <section className="main-area" id="main">
                    <div
                        className="menu-visual"
                        style={{backgroundImage: `url(${Background})`}}
                    >
                        <h2 className="menu-visual__tit">경매몰</h2>
                        <div className="breadcrumbs-area">
                            <ol>
                                <li className="breadcrumbs-area__step breadcrumbs-area__step--root">
                                    <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                                        <i className="icon24 icon-home"/>
                                        <span className="a11y">홈으로</span>
                                    </a>
                                </li>
                                <li className="breadcrumbs-area__step breadcrumbs-area__step--more">
                                    {/* [D] sub menu 있을 시, breadcrumbs-area__step--more 클래스 추가 */}
                                    <a href="javascript:void(0);" className="breadcrumbs-area__link">
                                        <span>시스템관리</span>
                                    </a>
                                    <ul className="breadcrumbs-area__sub-menu">
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 1</a>
                                        </li>
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 2</a>
                                        </li>
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 3</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="breadcrumbs-area__step">
                                    <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                                        <span>경매몰</span>
                                    </a>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className="cont-area">
                        <div className="cont-area__grid">
                            <div className="cont-area__header">
                                <div className="cont-area__header-inner">
                                    <div className="tab-area">
                                        <Link to="/kas-0601" className="tab-area__btn">
                                            {/* [D] 활성화 탭 버튼에 tab-area__btn--active 클래스 추가 */}
                                            <span>경매응찰</span>
                                        </Link>
                                        <a href="#" className="tab-area__btn">
                                            <span>경매진행조회</span>
                                        </a>
                                        <Link to="/kas-0607_20" className="tab-area__btn tab-area__btn--active">
                                            <span>경매진행상세조회</span>
                                        </Link>
                                        <Link to="/kas-0607" className="tab-area__btn">
                                            <span>경매낙찰</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="cont-area__section">
                                <article>
                                    <div className="article-content">
                                        <div className="tbl-area">
                                            <div className="table-wrap">
                                                <table>
                                                    <colgroup>
                                                        <col style={{width: 140}}/>
                                                        <col/>
                                                        <col style={{width: 140}}/>
                                                        <col style={{width: 300}}/>
                                                        <col style={{width: 140}}/>
                                                        <col/>
                                                    </colgroup>
                                                    <tbody>
                                                    <tr>
                                                        <th>경매시작일자/회차</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-item col4">
                                                                    <input
                                                                        type="date"
                                                                        id=""
                                                                        className="inp type-date"
                                                                        defaultValue="2022-11-01"
                                                                    />
                                                                    <input
                                                                        type="date"
                                                                        id=""
                                                                        className="inp type-date mr-10"
                                                                        defaultValue="2022-11-01"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>경매번호</th>
                                                        <td>
                                                            <div className="product-num">
                                                                <div className="item">
                                                                    <div className="form-area">
                                                                        <div className="form-item">
                                                                            <textarea className="inp"
                                                                                      defaultValue={""}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="summary">
                                                                    <p>
                                                                        *제품번호, 패키지번호 구분은 , 또는 엔터로 구분
                                                                        <br/>
                                                                        <br/>
                                                                        예) A3022,A0033
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>판매구분</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="check-box">
                                                                    <input type="checkbox" id="check1"/>
                                                                    <label htmlFor="check1" className="lb-chk">
                                                                        <span>일반경매</span>
                                                                    </label>
                                                                    <input type="checkbox" id="check2"/>
                                                                    <label htmlFor="check2" className="lb-chk">
                                                                        <span>패키지경매</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>제품번호</th>
                                                        <td>
                                                            <div className="product-num">
                                                                <div className="item">
                                                                    <div className="form-area">
                                                                        <div className="form-item">
                                                                            <textarea className="inp"
                                                                                      defaultValue={""}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="summary">
                                                                    <p>
                                                                        *제품번호, 패키지번호 구분은 , 또는 엔터로 구분
                                                                        <br/>
                                                                        <br/>
                                                                        예) A3022,A0033
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                <div className="btn-area gap-x40">
                                    <a href="#" className="btn-base type-secondary size-m">
                                        <span>초기화</span>
                                    </a>
                                    <a href="#" className="btn-base type-primary size-m grid-m">
                                        <i className="icon24 icon-search-light"/>
                                        <span>조회</span>
                                    </a>
                                </div>
                                <article className="space">
                                    <div className="article-content">
                                        <div className="tbl-area">
                                            <div className="tbl-upper">
                                                <div className="tbl-upper__util">
                                                    <strong className="tbl-area__tit">
                                                        선택중량(KG) 993,934
                                                    </strong>
                                                    <DownloadTableExcel filename="kasco" sheet="users"
                                                                        currentTableRef={tableRef.current}>
                                                        <a href="#" className="btn-base type-dark">
                                                            <span>다운로드</span>
                                                        </a>
                                                    </DownloadTableExcel>
                                                    <select className="selectbox type-light">
                                                        <option value='50'>50개씩</option>
                                                        <option value='100'>100개씩</option>
                                                        <option value='200'>200개씩</option>
                                                        <option value='500'>500개씩</option>
                                                        <option value='1000'>1000개씩</option>
                                                        <option value='5000'>5000개씩</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="tbl-base type-hover scroll-warp">
                                                <table ref={tableRef}> 
                                                    <caption>정보 테이블</caption>
                                                    <colgroup/>
                                                    <thead>
                                                    <tr>
                                                        <th scope="col">번호</th>
                                                        <th scope="col">경매번호</th>
                                                        <th scope="col">회차</th>
                                                        <th scope="col">제품번호</th>
                                                        <th scope="col">입찰순번</th>
                                                        <th scope="col">입찰고객코드</th>
                                                        <th scope="col">입찰고객명</th>
                                                        <th scope="col">임찰자 ID</th>
                                                        <th scope="col">입찰일시</th>
                                                        <th scope="col">시작단가</th>
                                                        <th scope="col">입찰단가</th>
                                                        <th scope="col">낙찰여부</th>
                                                        <th scope="col">중량</th>
                                                        <th scope="col">입찰거절사유</th>
                                                        <th scope="col">제품등급</th>
                                                        <th scope="col">두께</th>
                                                        <th scope="col">폭</th>
                                                        <th scope="col">길이</th>
                                                        <th scope="col">중량</th>
                                                        <th scope="col">YP</th>
                                                        <th scope="col">TS</th>
                                                        <th scope="col">EL</th>
                                                        <th scope="col">C%</th>
                                                        <th scope="col">SI</th>
                                                        <th scope="col">MN</th>
                                                        <th scope="col">P</th>
                                                        <th scope="col">S</th>
                                                        <th scope="col">야드구분</th>
                                                        <th scope="col">정척여부</th>
                                                        <th scope="col">규격약호</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>999</td>
                                                        <td>
                                                            <a href="">202220331</a>
                                                        </td>
                                                        <td>01</td>
                                                        <td>
                                                            <a href="">P1011112</a>
                                                        </td>
                                                        <td>1</td>
                                                        <td>K00322</td>
                                                        <td>석영에스엔티(주)</td>
                                                        <td>KK3923</td>
                                                        <td>2022-11-11 11:11:11</td>
                                                        <td>&nbsp;</td>
                                                        <td>989,000</td>
                                                        <td>Y</td>
                                                        <td>16,34</td>
                                                        <td>10톤 미만</td>
                                                        <td>1</td>
                                                        <td>9.00</td>
                                                        <td>1,934.0</td>
                                                        <td>2,032</td>
                                                        <td>1,393</td>
                                                        <td>0.001</td>
                                                        <td>392.9</td>
                                                        <td>392.9</td>
                                                        <td>0.992</td>
                                                        <td>0.001</td>
                                                        <td>0.001</td>
                                                        <td>0.001</td>
                                                        <td>0.001</td>
                                                        <td>A</td>
                                                        <td>Y</td>
                                                        <td>23M32</td>
                                                    </tr>
                                                    <tr>
                                                        <td>999</td>
                                                        <td rowSpan={2}>
                                                            <a href="">202220331</a>
                                                        </td>
                                                        <td rowSpan={2}>03</td>
                                                        <td>
                                                            <a href="">P1011112</a>
                                                        </td>
                                                        <td>1</td>
                                                        <td>K00322</td>
                                                        <td>석영에스엔티(주)</td>
                                                        <td>KKK3923</td>
                                                        <td>2022-11-11 11:11:11</td>
                                                        <td>98,000</td>
                                                        <td>989,000</td>
                                                        <td>Y</td>
                                                        <td>16,34</td>
                                                        <td>관리자 낙찰취소</td>
                                                        <td>1</td>
                                                        <td>9.00</td>
                                                        <td>1,934.0</td>
                                                        <td>2,032</td>
                                                        <td>1,393</td>
                                                        <td>0.001</td>
                                                        <td>392.9</td>
                                                        <td>392.9</td>
                                                        <td>0.992</td>
                                                        <td>0.001</td>
                                                        <td>0.001</td>
                                                        <td>0.001</td>
                                                        <td>0.001</td>
                                                        <td>A</td>
                                                        <td>Y</td>
                                                        <td>23M32</td>
                                                    </tr>
                                                    <tr>
                                                        <td>999</td>
                                                        <td>
                                                            <a href="">P1011112</a>
                                                        </td>
                                                        <td>1</td>
                                                        <td>K00322</td>
                                                        <td>석영에스엔티(주)</td>
                                                        <td>KKK3923</td>
                                                        <td>2022-11-11 11:11:11</td>
                                                        <td>98,000</td>
                                                        <td>989,000</td>
                                                        <td>Y</td>
                                                        <td>16,34</td>
                                                        <td>관리자 낙찰취소</td>
                                                        <td>1</td>
                                                        <td>9.00</td>
                                                        <td>1,934.0</td>
                                                        <td>2,032</td>
                                                        <td>1,393</td>
                                                        <td>0.001</td>
                                                        <td>392.9</td>
                                                        <td>392.9</td>
                                                        <td>0.992</td>
                                                        <td>0.001</td>
                                                        <td>0.001</td>
                                                        <td>0.001</td>
                                                        <td>0.001</td>
                                                        <td>A</td>
                                                        <td>Y</td>
                                                        <td>23M32</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                <div className="tbl-footer">
                                    <div className="tbl-footer__cont">
                                        <strong className="tbl-footer__tit">총 30,000건</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* // [End] main-area */}
                <Footer/>
            </div>
        </>
    )
}

export default Kas0607_20
import React from 'react'
import ReactPaginate from 'react-paginate'

function Pagination({ pageCount, paginate }) {
	return (
		<>
			<button className="btn-jump first"></button>
			{
				pageCount >= 1 &&
				<ReactPaginate
					breakLabel={''}
					breakClassName={'break-me'}
					pageCount={pageCount}
					marginPagesDisplayed={0}
					pageRangeDisplayed={5}
					onPageChange={paginate}
					containerClassName={'paging-area'}
					activeClassName={'current'}
					pageClassName={'paging-link'}
					nextClassName="btn-jump next"
					previousClassName="btn-jump prev"
					nextLabel=""
					previousLabel=""
				/>
			}
			<button className="btn-jump last"></button>
		</>
	)
}

export default Pagination

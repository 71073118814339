import React from 'react'

function kas1904() {
  return (
    <>
      <div id="wrap" className="popup-area">
        <section className="main-area">
          <article className="small-pop">
            <div className="article-header">
              <h1 className="popup-header__tit">목적지 조회</h1>
              <button type="button" className="btn-close" onclick="window.close();"><span className="a11y">팝업 닫기</span></button>
            </div>
            <div className="article-content">
              <div className="cont-area">
                <div className="cont-area__section">
                  <article>
                    <div className="article-content">
                      <div className="tbl-area">
                        <div className="table-wrap">
                          <table>
                            <colgroup>
                              <col style={{ width: '160px' }} />
                              <col />
                              <col style={{ width: '160px' }} />
                              <col />
                            </colgroup>
                            <tbody>
                              <tr>
                                <th>목적지코드</th>
                                <td>
                                  <div className="form-area">
                                    <div className="form-item">
                                      <input type="text" className="inp" />
                                    </div>
                                  </div>
                                </td>
                                <th>목적지명</th>
                                <td>
                                  <div className="form-area">
                                    <div className="form-item">
                                      <input type="text" className="inp" />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </article>
                  <div className="btn-area gap-x40">
                    <a href="#" className="btn-base type-secondary size-m">
                      <span>초기화</span>
                    </a>
                    <a href="#" className="btn-base type-primary size-m grid-m">
                      <i className="icon24 icon-search-light" />
                      <span>
                        검색
                      </span>
                    </a>
                  </div>
                  <article className="space">
                    <div className="article-content">
                      <div className="tbl-area">
                        <div className="tbl-upper">
                          <div className="tbl-upper__util">
                            <a href="#" className="btn-base type-dark">
                              <span>다운로드</span>
                            </a>
                            <select className="selectbox type-light">
                              <option value>50개씩</option>
                              <option value>옵션1</option>
                              <option value>옵션2</option>
                              <option value>옵션3</option>
                            </select>
                          </div>
                        </div>
                        <div className="tbl-base type-hover">
                          <table>
                            <colgroup>
                              <col style={{ width: '100px' }} />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                            </colgroup>
                            <thead>
                              <tr>
                                <th>번호</th>
                                <th>목적지코드</th>
                                <th>목적지 1</th>
                                <th>목적지 2(시/군/구)</th>
                                <th>최종수정자</th>
                                <th>최종수정일시</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>99</td>
                                <td>E011</td>
                                <td>경남</td>
                                <td>김해시(00군)</td>
                                <td>카스코</td>
                                <td>2022-11-11 11:11:11</td>
                              </tr>
                              <tr>
                                <td>98</td>
                                <td>E011</td>
                                <td>경남</td>
                                <td>김해시(00군)</td>
                                <td>카스코</td>
                                <td>2022-11-11 11:11:11</td>
                              </tr>
                              <tr>
                                <td>97</td>
                                <td>E011</td>
                                <td>경남</td>
                                <td>김해시(00군)</td>
                                <td>카스코</td>
                                <td>2022-11-11 11:11:11</td>
                              </tr>
                              <tr>
                                <td>96</td>
                                <td>E011</td>
                                <td>경남</td>
                                <td>김해시(00군)</td>
                                <td>카스코</td>
                                <td>2022-11-11 11:11:11</td>
                              </tr>
                              <tr>
                                <td>95</td>
                                <td>E011</td>
                                <td>경남</td>
                                <td>김해시(00군)</td>
                                <td>카스코</td>
                                <td>2022-11-11 11:11:11</td>
                              </tr>
                              <tr>
                                <td>94</td>
                                <td>E011</td>
                                <td>경남</td>
                                <td>김해시(00군)</td>
                                <td>카스코</td>
                                <td>2022-11-11 11:11:11</td>
                              </tr>
                              <tr>
                                <td>93</td>
                                <td>E011</td>
                                <td>경남</td>
                                <td>김해시(00군)</td>
                                <td>카스코</td>
                                <td>2022-11-11 11:11:11</td>
                              </tr>
                              <tr>
                                <td>92</td>
                                <td>E011</td>
                                <td>경남</td>
                                <td>김해시(00군)</td>
                                <td>카스코</td>
                                <td>2022-11-11 11:11:11</td>
                              </tr>
                              <tr>
                                <td>91</td>
                                <td>E011</td>
                                <td>경남</td>
                                <td>김해시(00군)</td>
                                <td>카스코</td>
                                <td>2022-11-11 11:11:11</td>
                              </tr>
                              <tr>
                                <td>90</td>
                                <td>E011</td>
                                <td>경남</td>
                                <td>김해시(00군)</td>
                                <td>카스코</td>
                                <td>2022-11-11 11:11:11</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </article>
        </section>
      </div>
    </>
  )
}

export default kas1904
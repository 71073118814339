import React, {useState, useEffect, useRef} from "react";

import {
    formatMoney,
    formatUnixTime,
    getPath,
    getUnixTimeByDate, sleep,
    todayDate,
} from "../../helpers/helpers";
import axiosInstance from "../../helpers/axiosInstance";
import {DownloadTableExcel} from "react-export-table-to-excel";

import Header from "../../containers/header/Header1";
import Footer from "../../containers/footer/Footer";

import Background from "../../assets/img/menu-visual1.jpg";

import Modal from "react-bootstrap/Modal";
import {sha256Message} from "../../Utils";
import {store} from "../../store";
import {hideLoader, showLoader} from "../../store/loader/actions";
import {toast} from "react-toastify";
import Pagination from "../pagination/pagination";
import useDidMountEffect from "../../helpers/useDidMountEffect";
import {useHistory, useNavigate} from "react-router-dom";

function SignupCustomer() {


    const navigate = useNavigate();
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [queryResult, setQueryResult] = useState([]);
    const [queryPage, setQueryPage] = useState(1);
    const [querySize, setQuerySize] = useState(50);
    const [queryTotalPage, setQueryTotalPage] = useState(0);
    const isVerifiedUid = useRef(false);

    const [queryData, setQueryData] = useState({
        corp_nm: '',
        business_number: ''
    });

    const [registerData, setRegisterData] = useState({
        uid: '', // 회원아이디
        cust_corp_code: '', // 고객사명
        cust_corp_no: '', //
        business_num: '', // 사업자번호
        user_name: '', // 담당자 성명
        password: '', // 비밀번호
        re_password: '', // 비밀번호 재입력
        corp_division_nm: '', // 부서명
        corp_position_nm: '', // 담당자 직급
        user_mobile: '', // 휴대폰번호
        user_phone: '', // 전화번호
        user_email: '', //이메일
    })

    const [showFindCustomer, setShowFindCustomer] = useState(false);

    useDidMountEffect(() => {
        searchCustomer();
    }, [querySize, queryPage]);

    const searchCustomer = async () => {
        let params;
        let filterParams = queryData;
        queryData.size = querySize;
        queryData.page = queryPage;
        params = getPath(filterParams);
        try {
            store.dispatch(showLoader());
            const response = await axiosInstance.get('/auth/searchCustomer' + params);
            console.log(response);
            if (response.code === 200) {
                toast.dismiss();
                toast.success(('Success'))
                setQueryResult(response.data.items);
                setQueryTotalPage(Number.parseInt(response.data.totalPage));
                store.dispatch(hideLoader());
            } else {
                store.dispatch(hideLoader());
                alert(response.message);
            }
        } catch (e) {
            store.dispatch(hideLoader());
        }
    }

    const selectCustomer = (item) => {
        setRegisterData({
            ...registerData,
            cust_corp_code: item.cust_corp_code,
            business_num: item.business_num,
            cust_corp_no: item.cust_corp_no,
            corp_nm: item.corp_nm
        })
        setShowFindCustomer(false);
    }

    const checkUid = async () => {
        if (registerData.uid === '') {
            alert("Please enter value")
            return;
        }
        const payload = {
            name: registerData.uid
        }
        try {
            store.dispatch(showLoader());
            const res = await axiosInstance.post('/auth/checkUsername', payload);
            console.log(res);
            store.dispatch(hideLoader());
            if (res.data) {
                toast.dismiss();
                toast.warn(('Already exist 회원아이디'))
                isVerifiedUid.current = false;
            } else {
                toast.success(('Valid 회원아이디'))
                isVerifiedUid.current = true;
            }
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }

    const register = async () => {

        if (registerData.cust_corp_code === '' || registerData.business_num === '' || registerData.uid === ''
            || registerData.user_name === '' || registerData.password === '' || registerData.re_password === '' || registerData.user_phone === ''
            || registerData.user_mobile === '' || registerData.user_email === '') {
            alert("Please enter required fields")
            return;
        }

        if (registerData.password !== registerData.re_password) {
            alert("Password does not match")
            return;
        }

        if (!isVerifiedUid.current) {
            alert("not verified 회원아이디")
            return;
        }

        console.log(registerData);
        try {
            store.dispatch(showLoader());
            const res = await axiosInstance.post('/auth/register', registerData);
            console.log(res);
            store.dispatch(hideLoader());
            if (res.code === 200) {
                toast.dismiss();
                toast.success(('SUCCESS'));
                await sleep(3000);
                navigate('/login');
            } else {
                alert(res.message);
            }
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }


    return (
        <>
            <div id="wrap">

                <header className="header-area"/>
                {/* [Start] main-area */}
                <section>
                    <div
                        className="menu-visual"
                        style={{backgroundImage: `url(${Background})`}}
                    >
                        <h2 className="menu-visual__tit">
                            회원가입
                        </h2>
                        <div className="breadcrumbs-area">
                            <ol>
                                <li className="breadcrumbs-area__step breadcrumbs-area__step--root">
                                    <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                                        <i className="icon24 icon-home"/>
                                        <span className="a11y">홈으로</span>
                                    </a>
                                </li>
                                <li className="breadcrumbs-area__step"> {/* [D] sub menu 있을 시, breadcrumbs-area__step--more 클래스 추가 */}
                                    <a href="javascript:;" className="breadcrumbs-area__link">회원가입</a>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className="cont-area">
                        <div className="cont-area__grid">
                            <div className="cont-area__section">
                                <article>
                                    <div className="article-content">
                                        <div className="tbl-area">
                                            <div className="table-wrap">
                                                <table>
                                                    <colgroup>
                                                        <col style={{width: 160}}/>
                                                        <col/>
                                                        <col style={{width: 160}}/>
                                                        <col/>
                                                    </colgroup>
                                                    <tbody>
                                                    <tr>
                                                        <th>고객구분</th>
                                                        <td colSpan={3}>
                                                            <div className="form-area colspan2">
                                                                <div className="radio-box">
                                                                    {/*<input type="radio" name="radioCheck1" id="radio1"/>*/}
                                                                    {/*<label htmlFor="radio1"*/}
                                                                    {/*       className="rdo-txt">신규고객</label>*/}
                                                                    <input type="radio" name="radioCheck1" id="radio2"
                                                                           defaultChecked/>
                                                                    <label htmlFor="radio2" className="rdo-txt">기존고객
                                                                        사용자등록</label>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>고객사명</th>
                                                        <td>
                                                            <div className="form-area colspan2">
                                                                <div className="form-item overlap-check">
                                                                    <input type="text" className="inp" disabled
                                                                           value={registerData.corp_nm}/>
                                                                    <button onClick={() => {
                                                                        setQueryData({
                                                                            corp_nm: '',
                                                                            business_number: ''
                                                                        });
                                                                        setQueryResult([]);
                                                                        setQuerySize(10);
                                                                        setQueryTotalPage(0);
                                                                        setShowFindCustomer(true);
                                                                    }}
                                                                            type="button" className="btn-check_001">찾기
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>사업자번호</th>
                                                        <td>
                                                            <div className="form-area colspan2">
                                                                <div className="form-item overlap-check">
                                                                    <input type="text" className="inp" disabled
                                                                           value={registerData.business_num}/>
                                                                    {/*<button type="button" className="btn-check_001">찾기*/}
                                                                    {/*</button>*/}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                <article>
                                    <div className="article-header">
                                        <h3 className="sub-title">일반계정</h3>
                                    </div>
                                    <div className="article-content">
                                        <div className="tbl-area">
                                            <div className="table-wrap">
                                                <table>
                                                    <colgroup>
                                                        <col style={{width: 160}}/>
                                                        <col/>
                                                        <col style={{width: 160}}/>
                                                        <col/>
                                                    </colgroup>
                                                    <tbody>
                                                    <tr>
                                                        <th className="necessary">회원아이디</th>
                                                        <td>
                                                            <div className="form-area colspan2">
                                                                <div className="form-item overlap-check">
                                                                    <input type="text" className="inp"
                                                                           onChange={(e) => setRegisterData({
                                                                               ...registerData, uid: e.target.value
                                                                           })}/>
                                                                    <button type="button"
                                                                            className="btn-check_001"
                                                                            onClick={() => checkUid()}>중복확인
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th className="necessary">담당자 성명</th>
                                                        <td>
                                                            <div className="form-area colspan2">
                                                                <div className="form-item">
                                                                    <input type="text" className="inp"
                                                                           onChange={(e) => setRegisterData({
                                                                               ...registerData,
                                                                               user_name: e.target.value
                                                                           })}/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th className="necessary">비밀번호</th>
                                                        <td>
                                                            <div className="form-area colspan2">
                                                                <div className="form-item">
                                                                    <input type="password" className="inp"
                                                                           onChange={(e) => setRegisterData({
                                                                               ...registerData, password: e.target.value
                                                                           })}/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th className="necessary">비밀번호 재입력</th>
                                                        <td>
                                                            <div className="form-area colspan2">
                                                                <div className="form-item">
                                                                    <input type="password" className="inp"
                                                                           onChange={(e) => setRegisterData({
                                                                               ...registerData,
                                                                               re_password: e.target.value
                                                                           })}/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>부서명</th>
                                                        <td>
                                                            <div className="form-area colspan2">
                                                                <div className="form-item">
                                                                    <input type="text" className="inp"
                                                                           onChange={(e) => setRegisterData({
                                                                               ...registerData,
                                                                               corp_division_nm: e.target.value
                                                                           })}/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>담당자 직급</th>
                                                        <td>
                                                            <div className="form-area colspan2">
                                                                <div className="form-item">
                                                                    <input type="text" className="inp"
                                                                           onChange={(e) => setRegisterData({
                                                                               ...registerData,
                                                                               corp_position_nm: e.target.value
                                                                           })}/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th className="necessary">휴대폰번호</th>
                                                        <td>
                                                            <div className="form-area colspan2">
                                                                <div className="form-item">
                                                                    <input type="text" className="inp"
                                                                           onChange={(e) => setRegisterData({
                                                                               ...registerData,
                                                                               user_mobile: e.target.value
                                                                           })}/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th className="necessary">전화번호</th>
                                                        <td>
                                                            <div className="form-area colspan2">
                                                                <div className="form-item">
                                                                    <input type="text" className="inp"
                                                                           onChange={(e) => setRegisterData({
                                                                               ...registerData,
                                                                               user_phone: e.target.value
                                                                           })}/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th className="necessary">이메일</th>
                                                        <td colSpan="3'">
                                                            <div className="form-area colspan2">
                                                                <div className="form-item">
                                                                    <input type="text" className="inp"
                                                                           onChange={(e) => setRegisterData({
                                                                               ...registerData,
                                                                               user_email: e.target.value
                                                                           })}/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                <div className="btn-area gap-x40">
                                    <button onClick={() => navigate('/login')}
                                            className="btn-base type-secondary size-m">
                                        <span>취소</span>
                                    </button>
                                    <button className="btn-base type-primary size-m grid-m"
                                            onClick={() => register()}>
                                        <span>등록</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <Modal
                    dialogClassName="justify-content-center"
                    show={showFindCustomer}
                    size="lg"
                    onHide={() => setShowFindCustomer(false)}
                >
                    <Modal.Body style={{background: '#fff'}}>
                        <section className="main-area">
                            <article className="small-pop">
                                <div className="article-header">
                                    <h1 className="popup-header__tit"> 고객사 찾기</h1>
                                    <button type="button" className="btn-close"
                                            onClick={() => setShowFindCustomer(false)}><span
                                        className="a11y">팝업 닫기</span></button>
                                </div>
                                <div className="article-content">
                                    <div className="cont-area">
                                        <div className="cont-area__section">
                                            <article>
                                                <div className="article-content">
                                                    <div className="tbl-area">
                                                        <div className="table-wrap">
                                                            <table>
                                                                <colgroup>
                                                                    <col style={{width: '160px'}}/>
                                                                    <col/>
                                                                    <col style={{width: '160px'}}/>
                                                                    <col/>
                                                                </colgroup>
                                                                <tbody>
                                                                <tr>
                                                                    <th>고객사명</th>
                                                                    <td>
                                                                        <div className="form-area">
                                                                            <div className="form-item">
                                                                                <input type="text" className="inp" value={queryData.corp_nm}
                                                                                       onChange={(e) => {
                                                                                           setQueryData({
                                                                                               ...queryData,
                                                                                               corp_nm: e.target.value
                                                                                           })
                                                                                       }}/>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <th>사업자번호</th>
                                                                    <td>
                                                                        <div className="form-area">
                                                                            <div className="form-item">
                                                                                <input type="text" className="inp" value={queryData.business_number}
                                                                                       onChange={(e) => {
                                                                                           setQueryData({
                                                                                               ...queryData,
                                                                                               business_number: e.target.value
                                                                                           })
                                                                                       }}/>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                            <div className="btn-area gap-x40">
                                                <button onClick={() => {
                                                    setQueryData({
                                                        ...queryData,
                                                        corp_nm: '',
                                                        business_number: ''
                                                    });
                                                    setQueryResult([]);
                                                    setQuerySize(10);
                                                    setQueryTotalPage(0);
                                                }} className="btn-base type-secondary size-m">
                                                    <span>초기화</span>
                                                </button>
                                                <button onClick={() => searchCustomer()}
                                                        className="btn-base type-primary size-m grid-m">
                                                    <i className="icon24 icon-search-light"/>
                                                    <span>
                        검색
                      </span>
                                                </button>
                                            </div>
                                            <article className="space">
                                                <div className="article-content">
                                                    <div className="tbl-area">
                                                        <div className="tbl-upper">
                                                            <div className="tbl-upper__util">
                                                                <select className="selectbox type-light"
                                                                        onChange={(e) => setQuerySize(e.target.value)}>
                                                                    <option value='50'>50개씩</option>
                                                                    <option value='100'>100개씩</option>
                                                                    <option value='200'>200개씩</option>
                                                                    <option value='500'>500개씩</option>
                                                                    <option value='1000'>1000개씩</option>
                                                                    <option value='5000'>5000개씩</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="tbl-base type-hover">
                                                            <table>
                                                                <colgroup>
                                                                    <col style={{width: '100px'}}/>
                                                                    <col/>
                                                                    <col/>
                                                                </colgroup>
                                                                <thead>
                                                                <tr>
                                                                    <th>고객코드</th>
                                                                    <th>고객사명</th>
                                                                    <th>사업자번호</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    queryResult.map((item, index) => (
                                                                        <tr onClick={() => selectCustomer(item)}>
                                                                            <td>{item.cust_corp_code}</td>
                                                                            <td>{item.corp_nm}</td>
                                                                            <td>{item.business_num}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <Pagination pageCount={queryTotalPage}
                                                                paginate={(p) => setQueryPage(p.selected + 1)}></Pagination>
                                                </div>
                                            </article>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </section>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
}

export default SignupCustomer;

import React from 'react';
import Index from "./router/index"

import 'bootstrap/dist/js/bootstrap.bundle';


import './assets/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/index.css';
// import './assets/css/app.css';
import './assets/css/base.css'
import './assets/css/component.css'
import './assets/css/font.css'
import './assets/css/footer.css'
import './assets/css/icon.css'
import './assets/css/index.css'
import './assets/css/main.css'
import './assets/css/sub.css'
import './assets/css/main.css.map'
import './assets/css/sub.css.map'
import './assets/css/header.css'
import {Provider, useSelector} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import GlobalFunctionsProvider from "./provider/GlobalFunctionsProvider";
import {persistor, store} from "./store";

import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import LoadingGif from './assets/images/loading.gif'

function App() {
    const loader = useSelector((store) => store.loader);

    return (
        <>
            <GlobalFunctionsProvider/>
            <Index/>

            <ToastContainer
                position="bottom-left"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                theme="colored"
            />

            {
                loader &&
                <div id="loading-bg">
                    <div className="loading">
                        <img src={LoadingGif} alt=""/>
                    </div>
                </div>
            }
        </>
    );
}

export default App;

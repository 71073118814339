import {getTime, format} from 'date-fns'

export function deleteSymbols(str) {
    if (str) {
        return str.replace(/[^A-Z0-9]/gi, '');
    }
    return ""
}

export function regexNumeric(str) {
    if (str) {
        return str.replace(/[^0-9]/gi, '');
    }
    return ""
}

export function returnSign(str) {
    return str.includes("?") ? '&' : '?'
}

export function regexPhoneNumber(str) {
    if (str) {
        return str.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, "$1$2 $3-$4-$5");
    }
    return ""
}

export function clearTemporaryStorage() {
    localStorage.removeItem('tokenTime')
    localStorage.removeItem('authUser')
    localStorage.removeItem('username')
    localStorage.removeItem('authorities')
    localStorage.removeItem('access_token')
    localStorage.removeItem('role')
    sessionStorage.clear();
}

export function findFromArrayById(array, id) {
    if (array.length > 0 && id) {
        return array.filter(x => x.id === id)[0].name;
    }
}

export function findIdFromArray(array, id) {
    if (array.length > 0 && id) {
        if (array.filter(x => x.id === id).length > 0) {
            return array.filter(x => x.id === id)[0].id;
        } else {
            return false
        }
    }
}

export function formatMoney(amount, decimalCount = null, decimal = "", thousands = ",") {
    if (!decimalCount && decimalCount !== 0) {
        const state = JSON.parse(localStorage.getItem('state'))
        if (state) {
            //decimalCount = state.settings.decimalPoint
            decimalCount = 0
        } else {
            decimalCount = 0
        }
    }

    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 0 : decimalCount; // 0 was 2

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
}

export function formatDateWithTime(date) {
    if (date) {
        var formattedDate = format(new Date(date), 'dd.MM.yyyy HH:mm');
        return formattedDate
    }
}

export function formatDate(date) {
    if (date) {
        var formattedDate = format(new Date(date), 'dd.MM.yyyy');
        return formattedDate
    }
}

export function formatDateBackend(date) {
    if (date) {
        var formattedDate = format(new Date(date), 'yyyy-MM-dd');
        return formattedDate
    }
}

export function getUnixTime() {
    return getTime(new Date())
}

export function getCurrentDay() {
    const date = new Date();
    return format(new Date(), 'yyyy-MM-dd')
}

export function getNextMonthDay() {
    const date = new Date();
    return format(new Date(date.setMonth(date.getMonth() + 1)), 'yyyy-MM-dd')
}


export function todayDate(value) {
    const date = new Date()
    if (value) {
        return format(new Date(date.setMonth(date.getMonth() - 1)), 'yyyy-MM-dd')
    }
    return format(new Date(), 'yyyy-MM-dd')
}

export function todayDDMMYYYY() {
    return format(new Date(), 'dd.MM.yyyy')
}

export function getHHmm() {
    return format(new Date(), 'HH:mm')
}

export function getUnixTimeByDate(date) {
    return getTime(date)
}

export function formatUnixTime(unixTime) {
    if (unixTime)
        return format(new Date(unixTime), 'yyyy-MM-dd HH:mm:ss')
}

export function formatUnixTimePdf(unixTime) {
    if (unixTime)
        return format(new Date(unixTime), 'yyyy.MM.dd')
}

export function getRemainingTimeToFinish(fromDate, toDate) {
    const total = Date.parse(toDate) - Date.parse(fromDate);
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    const yearR = toDate.getFullYear();
    const monthR = toDate.getMonth() + 1;
    const dayR = toDate.getDate();
    const hoursR = toDate.getHours();
    const minutesR = toDate.getMinutes();

    return {
        start_date_fm: yearR + "년" + " " + monthR + "월" + " " + dayR + "일",
        start_hours_fm: ("0" + hoursR).slice(-2) + ":" + ("0" + minutesR).slice(-2),
        remaining_hours: hours,
        remaining_minutes: minutes,
        remaining_seconds: seconds,
        toDate: toDate,
    }

}

export function getPath(payload) {
    let iterations = Object.entries(payload).length;
    var pathArr = "?";

    for (let key in payload) {
        if (payload[key] != null && payload[key] != '') {
            if (!--iterations) {
                pathArr += key + "=" + payload[key];
            } else {
                pathArr += key + "=" + payload[key] + "&";
            }
        }
    }
    return pathArr;
}

export async function sleep(millis) {
    return new Promise(resolve => setTimeout(resolve, millis));
}
import React, {useEffect, useState, useRef} from 'react'
import {DownloadTableExcel, useDownloadExcel} from 'react-export-table-to-excel';
import Header from '../../containers/header/Header1'
import Footer from '../../containers/footer/Footer'
import Logo from '../../assets/img/logo.png'
import Background from '../../assets/img/menu-visual1.jpg'
import {Link} from "react-router-dom";
import {format} from "date-fns";
import {formatUnixTime, formatUnixTimePdf, getPath, getUnixTimeByDate} from "../../helpers/helpers";
import useDidMountEffect from "../../helpers/useDidMountEffect";
import Modal from "react-bootstrap/Modal";
import Pagination from "../pagination/pagination";
import {store} from "../../store";
import {hideLoader, showLoader} from "../../store/loader/actions";
import axiosInstance from "../../helpers/axiosInstance";
import {toast} from "react-toastify";
import {addComma, isValidValueOrEmpty} from "../../Utils";
import {useSelector} from "react-redux";
import Pdf from 'react-to-pdf';
import ReactToPrint, {useReactToPrint} from 'react-to-print';

function Kas0607() {
    const tableRef1 = useRef(null);
    const tableRef2 = useRef(null);

    const ref = useRef();

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const {token, user} = useSelector((store) => store.auth);

    const [query, setSetQuery] = useState({
        'auction_start_dt': '', // 경매시작일자
        'auction_end_dt': '', // 경매시작일자
        'bidding_stat_cd': [], // 낙찰상태
        'approve_stat_cd': '', // 승인상태 ???
        'goods_no_arr': '',
        'corp_nm': '',
        'cust_corp_code': '',
        'cust_corp_no': '',
        'business_num': '',
    });
    const [startDateFm, setStartDateFm] = useState(null);
    const [endDateFm, setEndDateFm] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [biddingStatCd1, setBiddingStatCd1] = useState(false); //BSC05
    const [biddingStatCd2, setBiddingStatCd2] = useState(false); //BSC06
    const [biddingStatCd3, setBiddingStatCd3] = useState(false); //BSC99
    const [fullscreen, setFullscreen] = useState(true);
    const [approveStatCd1, setApproveStatCd1] = useState(false); //RES10
    const [approveStatCd2, setApproveStatCd2] = useState(false); //RES30
    const [approveStatCd3, setApproveStatCd3] = useState(false); //RES90
    const isMounted = useRef(false);


    const [queryData, setQueryData] = useState({
        corp_nm: '',
        business_number: ''
    });
    const [queryResult, setQueryResult] = useState([]);
    const [queryPage, setQueryPage] = useState(1);
    const [querySize, setQuerySize] = useState(50);
    const [queryTotalPage, setQueryTotalPage] = useState(0);
    const [showFindCustomer, setShowFindCustomer] = useState(false);

    const [auctionList, setAuctionList] = useState([]);
    const [selectedAuction, setSelectedAuction] = useState(null);

    const [goodsList, setGoodsList] = useState([]);
    const [goodsTotalPage, setTotalPage] = useState(0);
    const [totalAmountGoods, setTotalAmountGoods] = useState(0);
    const [pageGoods, setPageGoods] = useState(1);
    const [sizeGoods, setSizeGoods] = useState(50);
    const [showModalEntrance, setShowModalEntrance] = useState(false);
    const [showModalDestination, setShowModalDestination] = useState(false);
    const [queryDestSignCd, setQueryDestSignCd] = useState(null);
    const [queryDestNm, setQueryDestNm] = useState(null);
    const [destList, setDesitList] = useState([]);
    const [destSize, setDestSize] = useState(50);
    const [destPage, setDestPage] = useState(1);
    const [destTotalPage, setDestTotalPage] = useState(0);


    const [selectedDest, setSelectedDest] = useState({
        dest_sigun_cd: '',
        dest_sigun_cd_nm: '',
        cust_dest_seq: '',
        dest_nm: '',
        dest_addr: '',
        phone1: '',
    });

    const [depositRequestList, setDepositRequestList] = useState([]);
    const [depositRequestTotal, setDepositRequestTotal] = useState({
        total_goods_wt: 0,
        total_goods_price: 0,
        total_freight_cost: 0,
        total_total_price: 0,
        total_goods_price_vat: 0,
        total_freight_cost_vat: 0,
        total_total_vat: 0,
        total_total_amount: 0,
    });


    useEffect(() => {
        if (selectedAuction !== null) {
            getAuctionGoodsList();
        }
    }, [selectedAuction]);

    useDidMountEffect(() => {
        getAuctionGoodsList();
    }, [sizeGoods, pageGoods]);

    useDidMountEffect(() => {
        getDestList();
    }, [destSize, destPage]);


    async function getAuctionGoodsList() {
        setTotalAmountGoods(0);
        let params = "";
        let filterParams = {};
        filterParams.auction_no = selectedAuction.auction_no;
        filterParams.cust_dest_seq = selectedAuction.cust_dest_seq;
        filterParams.bidding_stat_cd = selectedAuction.bidding_stat_cd;
        filterParams.storage_div_cd = selectedAuction.storage_div_cd;
        filterParams.page = pageGoods;
        filterParams.size = sizeGoods;
        if (Object.entries(filterParams).length > 0) {
            params = getPath(filterParams);
        }
        console.log(params)
        store.dispatch(showLoader());
        try {
            const response = await axiosInstance.get('/auction/completedAuctionGoodsList' + params);
            if (response.code === 200) {
                let listOfObjs = [];
                for (let i = 0; i < response.data.items.length; i++) {
                    let item = response.data.items[i];
                    item.is_checked = false;
                    listOfObjs.push(item);
                }
                setGoodsList(listOfObjs);
                console.log(response.data.totalPage)
                setTotalPage(Number.parseInt(response.data.totalPage));
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }


    useEffect(() => {
        if (!isMounted.current) {
            let currentDate = new Date();
            let startOfDayDate = new Date(currentDate.getFullYear()
                , currentDate.getMonth()
                , currentDate.getDate()
                , 0, 0, 0);
            setStartDateFm(format(startOfDayDate, 'yyyy-MM-dd'))
            setStartDate(getUnixTimeByDate(startOfDayDate))
            let endOfDayDate = new Date(currentDate.getFullYear()
                , currentDate.getMonth()
                , currentDate.getDate()
                , 23, 59, 59);
            setEndDate(getUnixTimeByDate(endOfDayDate))
            setEndDateFm(format(endOfDayDate, 'yyyy-MM-dd'))
            isMounted.current = true;
        }

        if (user.role === 'CUSTOMER') {
            getCustomerInfo()
        }

    }, []);


    function onChangeGoodsNo(e) {
        const lines = e.split(/\n/);
        let arrays = [];
        for (let i = 0; i < lines.length; i++) {
            if (/\S/.test(lines[i])) {
                arrays.push(lines[i].trim());
            }
        }
        setSetQuery({
            ...query,
            goods_no_arr: arrays
        })
    }

    useDidMountEffect(() => {
        searchCustomer();
    }, [querySize, queryPage]);

    const searchCustomer = async () => {
        let params;
        let filterParams = queryData;
        queryData.size = querySize;
        queryData.page = queryPage;
        params = getPath(filterParams);
        try {
            store.dispatch(showLoader());
            const response = await axiosInstance.get('/auth/searchCustomer' + params);
            console.log(response);
            if (response.code === 200) {
                toast.dismiss();
                toast.success(('Success'))
                setQueryResult(response.data.items);
                setQueryTotalPage(Number.parseInt(response.data.totalPage));
                store.dispatch(hideLoader());
            } else {
                store.dispatch(hideLoader());
                alert(response.message);
            }
        } catch (e) {
            store.dispatch(hideLoader());
        }
    }

    const selectCustomer = (item) => {
        setSetQuery({
            ...query,
            cust_corp_code: item.cust_corp_code,
            business_num: item.business_num,
            cust_corp_no: item.cust_corp_no,
            corp_nm: item.corp_nm
        })
        setShowFindCustomer(false);
    }

    const searchQuery = async (fromGoodsList) => {
        if (!fromGoodsList) {
            setSelectedAuction(null);
        }
        let params = "";
        let filterParams = query;
        filterParams.auction_start_dt = startDate;
        filterParams.auction_end_dt = endDate;
        let bidStatCdList = [];
        if (biddingStatCd1) {
            bidStatCdList.push("BSC05");
        }
        if (biddingStatCd2) {
            bidStatCdList.push("BSC06");
        }
        if (biddingStatCd3) {
            bidStatCdList.push("BSC99");
        }
        let approveStatCdList = [];
        if (approveStatCd1) {
            approveStatCdList.push("RES10")
        }
        if (approveStatCd2) {
            approveStatCdList.push("RES30")
        }
        if (approveStatCd3) {
            approveStatCdList.push("RES90")
        }
        filterParams.bidding_stat_cd = bidStatCdList;
        filterParams.approve_stat_cd = approveStatCdList;
        if (Object.entries(filterParams).length > 0) {
            params = getPath(filterParams);
        }
        try {
            store.dispatch(showLoader());
            const response = await axiosInstance.get('/auction/completedAuctionList' + params);
            console.log(response);
            if (response.code === 200) {
                let listOfObjs = [];
                for (let i = 0; i < response.data.items.length; i++) {
                    let item = response.data.items[i];
                    item.is_checked = false;
                    listOfObjs.push(item);
                }
                setAuctionList(listOfObjs);
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }

    }

    function selectGoodsItem(e, item) {
        let listItems = [...goodsList];
        if (e) {
            setTotalAmountGoods(totalAmountGoods + Number(item.goods_wt))
        }
        if (!e) {
            setTotalAmountGoods(totalAmountGoods - Number(item.goods_wt))
        }
        for (let i = 0; i < listItems.length; i++) {
            if (listItems[i].goods_no === item.goods_no) {
                listItems[i].is_checked = e;
            }
        }
        setGoodsList(listItems);
    }

    function selectAuctionItem(e, item) {
        let listItems = [...auctionList];
        for (let i = 0; i < listItems.length; i++) {
            if (listItems[i].ord_no === item.ord_no) {
                listItems[i].is_checked = e;
            }
        }
        setAuctionList(listItems);
    }

    function checkAllGoods(e) {
        let listItems = [...goodsList];
        let totalAmount = 0
        for (let i = 0; i < goodsList.length; i++) {
            let item = goodsList[i];
            totalAmount += Number(item.goods_wt);
        }
        setTotalAmountGoods(e ? totalAmount : 0);
        for (let i = 0; i < listItems.length; i++) {
            listItems[i].is_checked = e;
        }
        setGoodsList(listItems);
    }

    function checkAllAuctions(e) {
        let listItems = [...auctionList];
        for (let i = 0; i < listItems.length; i++) {
            listItems[i].is_checked = e;
        }
        setAuctionList(listItems);
    }

    const approveReject = async () => {
        let listOfObjs = [];
        for (let i = 0; i < goodsList.length; i++) {
            if (goodsList[i].is_checked) {
                listOfObjs.push(goodsList[i].ord_no);
            }
        }
        if (listOfObjs.length === 0) {
            alert("Please select items")
            return;
        }
        console.log(listOfObjs);
        const payload = {
            ord_no_ids: listOfObjs
        }
        // setShowAuctionDelete(false);
        store.dispatch(showLoader());
        try {
            const response = await axiosInstance.post('/auction/approveReject', payload);
            if (response.code === 200) {
                toast.dismiss();
                toast.success(('Success'));
                await getAuctionGoodsList();
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }

    const approveComplete = async () => {
        let listOfObjs = [];
        let isBrokenList = false;
        for (let i = 0; i < goodsList.length; i++) {
            if (goodsList[i].is_checked) {
                console.log(goodsList[i]);
                // if (goodsList[i].req_dest_sigun_cd === undefined || goodsList[i].req_dest_addr === undefined || goodsList[i].req_dest_phone === undefined) {
                //     isBrokenList = true;
                //     break;
                // }
                if (goodsList[i].ord_no === undefined) {
                    isBrokenList = true;
                    break;
                }
                listOfObjs.push({
                    ord_no: goodsList[i].ord_no,
                    req_dest_sigun_cd: goodsList[i].req_dest_sigun_cd,
                    req_dest_addr: goodsList[i].req_dest_addr,
                    req_dest_phone: goodsList[i].req_dest_phone,
                });
            }
        }

        if (isBrokenList) {
            alert("Broken List which has Order Number undeifed");
            return;
        }

        if (listOfObjs.length === 0) {
            alert("Please select items")
            return;
        }
        console.log(listOfObjs);
        const payload = {
            orderReqDestList: listOfObjs,
        }
        // setShowAuctionDelete(false);
        store.dispatch(showLoader());
        try {
            const response = await axiosInstance.post('/auction/approveComplete', payload);
            if (response.code === 200) {
                toast.dismiss();
                toast.success(('Success'));
                await getAuctionGoodsList();
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }

    const winningBidCancel = async () => {
        let listOfOrderNo = [];
        let listOfGoodsNo = [];
        let isBrokenList = false;
        for (let i = 0; i < goodsList.length; i++) {
            if (goodsList[i].is_checked) {
                if (goodsList[i].ord_no === undefined) {
                    isBrokenList = true;
                    break;
                }
                listOfOrderNo.push(goodsList[i].ord_no);
                listOfGoodsNo.push(goodsList[i].goods_no);
            }
        }
        if (isBrokenList) {
            alert("Order Number is undefiend")
            return;
        }
        if (listOfOrderNo.length === 0 || listOfGoodsNo.length === 0) {
            alert("Please select items")
            return;
        }
        console.log(listOfOrderNo);
        const payload = {
            auction_no: selectedAuction.auction_no,
            ord_no_ids: listOfOrderNo,
            goods_no_ids: listOfGoodsNo
        }
        // setShowAuctionDelete(false);
        store.dispatch(showLoader());
        try {
            const response = await axiosInstance.post('/auction/winningBidCancel', payload);
            if (response.code === 200) {
                toast.dismiss();
                toast.success(('Success'));
                await getAuctionGoodsList();
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }

    const confirmWinningBid = async () => {
        let listOfOrderNo = [];
        let listOfGoodsNo = [];
        let isBrokenList = false;
        for (let i = 0; i < goodsList.length; i++) {
            if (goodsList[i].is_checked) {
                if (goodsList[i].ord_no === undefined) {
                    isBrokenList = true;
                    break;
                }
                listOfOrderNo.push(goodsList[i].ord_no);
                listOfGoodsNo.push(goodsList[i].goods_no);
            }
        }
        if (isBrokenList) {
            alert("Order Number is undefiend")
            return;
        }
        if (listOfOrderNo.length === 0 || listOfGoodsNo.length === 0) {
            alert("Please select items")
            return;
        }

        let isExistDestRequest = false;

        for (let i = 0; i < goodsList.length; i++) {
            if (goodsList[i].req_edit_stat_cd_nm === '승인요청') {
                isExistDestRequest = true;
                break;
            }
        }

        if (isExistDestRequest) {
            alert("Please make sure not to have : " + '승인요청')
            return;
        }

        console.log(listOfOrderNo);
        const payload = {
            auction_no: selectedAuction.auction_no,
            ord_no_ids: listOfOrderNo,
            goods_no_ids: listOfGoodsNo
        }
        // setShowAuctionDelete(false);
        store.dispatch(showLoader());
        try {
            const response = await axiosInstance.post('/auction/confirmWinningBid', payload);
            if (response.code === 200) {
                toast.dismiss();
                toast.success(('Success'));
                await getAuctionGoodsList();
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }

    const confirmWinningBidAll = async () => {
        let selectedAuctions = [];
        for (let i = 0; i < auctionList.length; i++) {
            if (auctionList[i].is_checked) {
                selectedAuctions.push(auctionList[i]);
            }
        }
        if (selectedAuctions.length === 0) {
            alert("please select");
            return;
        }
        let list = [];
        for (let i = 0; i < selectedAuctions.length; i++) {
            const item = selectedAuctions[i];
            const payload = {
                ord_no: item.ord_no,
                auction_no: item.auction_no,
                cust_corp_no: item.cust_corp_no,
                cust_dest_seq: item.cust_dest_seq,
                storage_div_cd: item.storage_div_cd,
            }
            list.push(payload)
        }

        const payload = {
            'list': list
        }

        store.dispatch(showLoader());
        try {
            const response = await axiosInstance.post('/auction/confirmWinningBidAll', payload);
            if (response.code === 200) {
                toast.dismiss();
                toast.success(('Success'));
                await searchQuery(false);
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }

    }


    async function getDestList() {
        let params;
        let filterParams = {
            dest_sigun_cd: queryDestSignCd,
            dest_nm: queryDestNm,
            page: destPage,
            size: destSize,
            cust_corp_code: user.cust_corp_code,
            is_uid_required: "Y",
        }
        params = getPath(filterParams);
        store.dispatch(showLoader());
        try {
            const res = await axiosInstance.get('/dest/customerDestList' + params);
            console.log(res);
            store.dispatch(hideLoader());
            if (res.code === 200) {
                setDesitList(res.data.items);
                setDestTotalPage(Number.parseInt(res.data.totalPage));
            } else {
                alert(res.message);
            }
        } catch (e) {
            store.dispatch(hideLoader());
        }
    }

    async function clearDestInfo() {
        setDesitList([]);
        setQueryDestNm(null);
        setQueryDestSignCd(null);
    }

    async function selectDest(item) {
        setSelectedDest({
            ...selectedDest,
            dest_sigun_cd: item.dest_sigun_cd,
            dest_sigun_cd_nm: item.dest_sigun_cd_nm,
            cust_dest_seq: item.cust_dest_seq,
            dest_addr: item.addr,
            dest_nm: item.dest_nm,
            phone1: item.phone1,
        });
        console.log(item);
        setShowModalDestination(false);
    }

    const getCustomerInfo = async () => {
        try {
            store.dispatch(showLoader());
            const custCorpCode = user.cust_corp_code;
            const res = await axiosInstance.get('/dest/getDestInfo?custCorpCode=' + custCorpCode);
            console.log(res);
            if (res.code === 200) {
                setSelectedDest({
                    ...selectedDest,
                    dest_sigun_cd: res.data.dest_sigun_cd,
                    dest_sigun_cd_nm: res.data.dest_sigun_cd_nm,
                    cust_dest_seq: res.data.cust_dest_seq,
                    dest_addr: res.data.addr,
                    dest_nm: res.data.dest_nm,
                    phone1: res.data.phone1,
                });
            }
            store.dispatch(hideLoader());
        } catch (e) {
            store.dispatch(hideLoader());
        }
    }


    async function updateDest() {
        let tempList = [...goodsList];
        if (Object.keys(selectedDest).length === 0) {
            alert("Please select destination")
            return;
        }
        let listOfObj = [];
        for (let i = 0; i < tempList.length; i++) {
            if (tempList[i].is_checked) {
                listOfObj.push({
                    goods_no: tempList[i].goods_no,
                });
            }
        }
        if (listOfObj.length === 0) {
            alert("Please select GOODS")
            return;
        }
        console.log(selectedDest);
        for (let i = 0; i < listOfObj.length; i++) {
            var index = tempList.findIndex(x => x.goods_no === listOfObj[i].goods_no);
            console.log(index);
            console.log(selectedDest);
            tempList[index]['cust_dest_seq'] = selectedDest.cust_dest_seq;
            tempList[index]['req_dest_sigun_cd'] = selectedDest.dest_sigun_cd;
            tempList[index]['req_dest_sigun_cd_nm'] = selectedDest.dest_sigun_cd_nm;
            tempList[index]['req_dest_addr'] = selectedDest.dest_addr;
            tempList[index]['req_dest_phone'] = selectedDest.phone1;
            tempList[index]['req_cust_dest_seq'] = selectedDest.cust_dest_seq;
            tempList[index]['is_changed'] = true;
            console.log(selectedDest);
            console.log(tempList[index]);
        }
        console.log(tempList);
        setGoodsList(tempList);
    }

    const reqDestApply = async () => {
        if (Object.keys(selectedDest).length === 0) {
            alert("Please select destination")
            return;
        }
        let listOfOrderObj = [];
        let isBrokenListOrd = false;
        let isBrokenList = false;
        for (let i = 0; i < goodsList.length; i++) {
            const item = goodsList[i];
            if (item.is_checked) {
                if (item.ord_no === undefined) {
                    isBrokenListOrd = true;
                    break;
                }
                if (!isValidValueOrEmpty(item.req_dest_sigun_cd)
                    || !isValidValueOrEmpty(item.req_dest_addr)
                    || !isValidValueOrEmpty(item.req_dest_phone)) {
                    isBrokenList = true;
                    break;
                }
                listOfOrderObj.push({
                    ord_no: item.ord_no,
                    req_dest_sigun_cd: item.req_dest_sigun_cd,
                    req_dest_addr: item.req_dest_addr,
                    req_dest_phone: item.req_dest_phone,
                    req_cust_dest_seq: item.req_cust_dest_seq
                });
            }
        }
        if (isBrokenList) {
            alert("Please attach destionation")
            return;
        }
        if (isBrokenListOrd) {
            alert("Order Number is undefiend")
            return;
        }
        if (listOfOrderObj.length === 0) {
            alert("Please select items")
            return;
        }
        console.log(listOfOrderObj);
        const payload = {
            auction_no: selectedAuction.auction_no,
            list_of_obj: listOfOrderObj
        }
        console.log(payload)
        // setShowAuctionDelete(false);
        store.dispatch(showLoader());
        try {
            const response = await axiosInstance.post('/auction/reqDestApply', payload);
            if (response.code === 200) {
                toast.dismiss();
                toast.success(('Success'));
                await searchQuery(true);
                await getAuctionGoodsList();
                setSelectedDest({
                    dest_sigun_cd: '',
                    dest_sigun_cd_nm: '',
                    cust_dest_seq: '',
                    dest_nm: '',
                    dest_addr: '',
                    phone1: '',
                });
            } else {
                alert(response.message);
            }
            store.dispatch(hideLoader());
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }

    const getDepositRequestList = async () => {
        store.dispatch(showLoader());
        try {
            const res = await axiosInstance.get('/shipment/depositRequestList?auction_no=' + selectedAuction.auction_no
                + "&cust_corp_no=" + selectedAuction.cust_corp_no + '&storage_div_cd=' + selectedAuction.storage_div_cd);
            console.log(res);
            store.dispatch(hideLoader());
            if (res.code === 200) {

                let total_goods_wt = 0, total_goods_price = 0, total_freight_cost = 0, total_total_price = 0,
                    total_goods_price_vat = 0,
                    total_freight_cost_vat = 0,
                    total_total_vat = 0, total_total_amount = 0;
                for (let i = 0; i < res.data.length; i++) {
                    total_goods_wt += Number.parseInt(res.data[i].goods_wt);
                    total_goods_price += Number.parseInt(res.data[i].goods_price);
                    total_freight_cost += Number.parseInt(res.data[i].freight_cost);
                    total_total_price += Number.parseInt(res.data[i].total_price);
                    total_goods_price_vat += Number.parseInt(res.data[i].goods_price_vat);
                    total_freight_cost_vat += Number.parseInt(res.data[i].freight_cost_vat);
                    total_total_vat += Number.parseInt(res.data[i].total_vat);
                    total_total_amount += Number.parseInt(res.data[i].total_amount);
                }
                setDepositRequestTotal({
                    total_goods_wt: total_goods_wt,
                    total_goods_price: total_goods_price,
                    total_freight_cost: total_freight_cost,
                    total_total_price: total_total_price,
                    total_goods_price_vat: total_goods_price_vat,
                    total_freight_cost_vat: total_freight_cost_vat,
                    total_total_vat: total_total_vat,
                    total_total_amount: total_total_amount,
                })

                setDepositRequestList(res.data);
                setShowModalEntrance(true);
            } else {
                alert(res.message);
            }
        } catch (e) {
            console.log(e);
            store.dispatch(hideLoader());
        }
    }

    return (
        <>
            <div id="wrap">
                <Header/>
                {/* [Start] main-area */}
                <section className="main-area" id="main">
                    <div
                        className="menu-visual"
                        style={{backgroundImage: `url(${Background})`}}
                    >
                        <h2 className="menu-visual__tit">경매몰</h2>
                        <div className="breadcrumbs-area">
                            <ol>
                                <li className="breadcrumbs-area__step breadcrumbs-area__step--root">
                                    <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                                        <i className="icon24 icon-home"/>
                                        <span className="a11y">홈으로</span>
                                    </a>
                                </li>
                                <li className="breadcrumbs-area__step breadcrumbs-area__step--more">
                                    {/* [D] sub menu 있을 시, breadcrumbs-area__step--more 클래스 추가 */}
                                    <a href="javascript:void(0);" className="breadcrumbs-area__link">
                                        <span>시스템관리</span>
                                    </a>
                                    <ul className="breadcrumbs-area__sub-menu">
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 1</a>
                                        </li>
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 2</a>
                                        </li>
                                        <li>
                                            <a href="/manageProduct1.html">시스템관리 3</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="breadcrumbs-area__step">
                                    <a href="/manageProduct1.html" className="breadcrumbs-area__link">
                                        <span>경매몰</span>
                                    </a>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className="cont-area">
                        <div className="cont-area__grid">
                            <div className="cont-area__header">
                                <div className="cont-area__header-inner">
                                    <div className="tab-area">
                                        <Link to="/kas-0601" className="tab-area__btn">
                                            {/* [D] 활성화 탭 버튼에 tab-area__btn--active 클래스 추가 */}
                                            <span>경매응찰</span>
                                        </Link>
                                        {(user.role === 'ADMIN') && (
                                            <>
                                                <a href="#" className="tab-area__btn">
                                                    <span>경매진행조회</span>
                                                </a>
                                                <Link to="/kas-0607_20" className="tab-area__btn">
                                                    <span>경매진행상세조회</span>
                                                </Link>

                                            </>
                                        )}
                                        <Link to="#!" className="tab-area__btn tab-area__btn--active">
                                            <span>경매낙찰</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="summary-top col4">
                                <div className="summary-list">
                                    <ol>
                                        <li>1. 경매일 익일 12:00시 한 입금 필수 (낙찰확정)</li>
                                        <li>
                                            2. 낙찰 후 지정 입금 요청일까지 미 입금 시 2주간 경매 참여가
                                            제한되며, 경매 제한 3회 발생 시 당사 경매 참여가 제한되오니
                                            주의하시기 바랍니다.
                                        </li>
                                        <li>
                                            3. 낙찰금액은 제품대공급가, 제품대부가세를 합한 금액입니다.
                                        </li>
                                        <li>
                                            4. 운반금액은 운임비공급가,운임비부가세를 합한 최소 운임이며,
                                            빠른 배송을 원하실 경우 추가운임 발생 할 수 있습니다.
                                        </li>
                                    </ol>
                                </div>
                                <div className="bank-info">
                                    <dl>
                                        <dt>입금계좌</dt>
                                        <dd>
                                            <ul>
                                                <li>
                                                    우리은행 1005-301-817070
                                                    <br/> 신한은행 140-013-498612
                                                </li>
                                                <li>
                                                    기업은행 070-8889-3456
                                                    <br/> 예금주 : 카스코철강
                                                </li>
                                            </ul>
                                        </dd>
                                    </dl>
                                </div>
                            </div>

                            <div className="cont-area__section">

                                <article className="gap-x40">
                                    <div className="article-content">
                                        <div className="tbl-area">
                                            <div className="table-wrap">
                                                <table>
                                                    <colgroup>
                                                        <col style={{width: 160}}/>
                                                        <col/>
                                                        <col style={{width: 160}}/>
                                                        <col/>
                                                        <col style={{width: 160}}/>
                                                        <col/>
                                                    </colgroup>
                                                    <tbody>
                                                    <tr>
                                                        <th>경매일시</th>
                                                        <td colSpan={1}>
                                                            <div className="form-area">
                                                                <div className="form-item row-cols-4">
                                                                    <input type="date" id className="inp type-date"
                                                                           defaultValue={startDateFm}
                                                                           onChange={(e) => setStartDate(getUnixTimeByDate(new Date(e.target.value)))}/>
                                                                    <span className="form-division">~</span>
                                                                    <input type="date" id className="inp type-date"
                                                                           defaultValue={endDateFm}
                                                                           onChange={(e) => setEndDate(getUnixTimeByDate(new Date(e.target.value)))}/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>낙찰상태</th>
                                                        <td>
                                                            <div className="form-area">
                                                                <div className="form-area">
                                                                    <div className="check-box">
                                                                        <input type="checkbox" id="check3"
                                                                               onChange={(e) => setBiddingStatCd1(e.target.checked)}/>
                                                                        <label htmlFor="check3" className="lb-chk">
                                                                            <span>낙찰</span>
                                                                        </label>
                                                                        <input type="checkbox" id="check4"
                                                                               onChange={(e) => setBiddingStatCd2(e.target.checked)}/>
                                                                        <label htmlFor="check4" className="lb-chk"
                                                                        >
                                                                            <span>낙찰확정</span>
                                                                        </label>
                                                                        <input type="checkbox" id="check5"
                                                                               onChange={(e) => setBiddingStatCd3(e.target.checked)}/>
                                                                        <label htmlFor="check5" className="lb-chk">
                                                                            <span>낙찰취소</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="form-area">

                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th rowSpan={4} className="border-0">
                                                            제품번호
                                                        </th>
                                                        <td rowSpan={4} className="border-0">
                                                            <div className="product-num">
                                                                <div className="item">
                                                                    <div className="form-area">
                                                                        <div className="form-item">
                                                                            <textarea className="inp"
                                                                                      onChange={(e) => onChangeGoodsNo(e.target.value)}
                                                                                      defaultValue={""}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="summary">
                                                                    <p>
                                                                        *제품번호, 패키지번호 구분은 , 또는 엔터로 구분
                                                                        <br/>
                                                                        <br/>
                                                                        예) A3022,A0033
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                    </tr>
                                                    <tr>

                                                    </tr>
                                                    {user.role === 'ADMIN' && (
                                                        <tr>
                                                            <th>고객사명</th>
                                                            <td>
                                                                <div className="form-area colspan2">
                                                                    <div className="form-item overlap-check">
                                                                        <input type="text" className="inp" disabled
                                                                               value={query.corp_nm}/>
                                                                        <button onClick={() => {
                                                                            setQueryData({
                                                                                corp_nm: '',
                                                                                business_number: ''
                                                                            });
                                                                            setQueryResult([]);
                                                                            setQuerySize(10);
                                                                            setQueryTotalPage(0);
                                                                            setShowFindCustomer(true);
                                                                        }}
                                                                                type="button"
                                                                                className="btn-check_001">찾기
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                <div className="btn-area gap-x40">
                                    <a href="#" className="btn-base type-secondary size-m">
                                        <span>초기화</span>
                                    </a>
                                    <button onClick={() => searchQuery(false)}
                                            className="btn-base type-primary size-m grid-m">
                                        <i className="icon24 icon-search-light"/>
                                        <span>조회</span>
                                    </button>

                                </div>
                                <article className="space">
                                    <div className="aticle-content">
                                        <div className="tbl-area">
                                            <div className="tbl-upper">
                                                <div className="tbl-upper__cont">
                                                    {user.role === 'ADMIN' && (
                                                        <>
                                                            <button type="button" className="btn-round-util"
                                                                    onClick={() => confirmWinningBidAll()}>
                                                                <span>입금확인</span>
                                                            </button>
                                                        </>
                                                    )}
                                                </div>
                                                <div className="tbl-upper__util">
                                                    <DownloadTableExcel filename="kasco" sheet="users"
                                                                        currentTableRef={tableRef1.current}>
                                                        <button className="btn-base type-dark">
                                                            <span>다운로드</span>
                                                        </button>
                                                    </DownloadTableExcel>
                                                </div>
                                            </div>
                                            <div className="tbl-base" ref={tableRef1}>
                                                <table>
                                                    <colgroup>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        {user.role === 'ADMIN' && (
                                                            <col/>
                                                        )}
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                        <col/>
                                                    </colgroup>
                                                    <thead>
                                                    <tr>
                                                        <th scope="col">
                                                            <input type="checkbox" id="chk22-all"
                                                                   name="chk2Group"
                                                                   onChange={(e) => checkAllAuctions(e.target.checked)}
                                                            />
                                                            <label htmlFor="chk22-all" className="lb-chk">
                                                                <span className="a11y">전체선택</span></label>
                                                        </th>
                                                        <th>경매일자</th>
                                                        {/*<th>주문번호</th>*/}
                                                        <th>경매번호</th>
                                                        {user.role === 'ADMIN' && (
                                                            <th>고객사명</th>
                                                        )}
                                                        <th>출발지</th>
                                                        <th>목적지</th>
                                                        <th>낙찰상태</th>
                                                        <th>승인상태</th>
                                                        <th>제품수량</th>
                                                        <th>제품중량</th>
                                                        <th>제품금액(VAT 포함)</th>
                                                        <th>운임비(VAT 포함)</th>
                                                        <th>입금요청액</th>
                                                        <th>주소</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        auctionList.map((item, index) => (
                                                            <tr>

                                                                <td>
                                                                    <input type="checkbox"
                                                                           checked={item.is_checked}
                                                                           id={`chkAuctions${index}-1`}
                                                                           name="chkAuctionsGroup"
                                                                           onChange={(e) => selectAuctionItem(e.target.checked, item)}/>
                                                                    <label htmlFor={`chkAuctions${index}-1`}
                                                                           className="lb-chk">
                                                                        <span className="a11y">선택</span>
                                                                    </label>
                                                                </td>
                                                                <td><a href>{formatUnixTime(item.auction_start_dt)}</a>
                                                                </td>
                                                                {/*<td>{item.ord_no}</td>*/}
                                                                <td>
                                                                    <button onClick={() => {
                                                                        setSelectedAuction(item);
                                                                    }}>{item.auction_no}</button>
                                                                </td>
                                                                {user.role === 'ADMIN' && (
                                                                    <td>{item.corp_nm}</td>
                                                                )}
                                                                <td>{item.storage_nm}</td>
                                                                <td> {user.role === 'ADMIN' ? item.dest_nm_amdin : item.dest_nm_cust}</td>
                                                                <td>{item.bidding_stat_cd_nm}</td>
                                                                <td>{item.req_edit_stat_cd_nm}</td>
                                                                <td>{addComma(item.order_count)}</td>
                                                                <td>{addComma(item.order_weight)}</td>
                                                                <td>{addComma(item.order_amount)}</td>
                                                                <td>{addComma(item.freight_amount)}</td>
                                                                <td>{addComma(item.amount)}</td>
                                                                <td>{item.dest_addr}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                {selectedAuction !== null && (
                                    <article className="space">
                                        <div className="article-content">
                                            <div className="tbl-area">
                                                <div className="tbl-upper">
                                                    <div className="tbl-upper__cont">
                                                        목적지
                                                        <input type="text" className="inp"
                                                               value={selectedDest.dest_sigun_cd}
                                                               disabled/>
                                                        <button type="button"
                                                                className="btn-round-util"
                                                                onClick={() => setShowModalDestination(true)}>
                                                            찾기
                                                        </button>
                                                        <input type="text" className="inp"
                                                               value={selectedDest.dest_nm}
                                                               disabled/>
                                                        <button className="btn-round-util"
                                                                onClick={() => updateDest()}>
                                                            목적지 적용
                                                        </button>
                                                        <button className="btn-round-util"
                                                                onClick={() => reqDestApply()}>
                                                            목적지 승인요청
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="tbl-upper">
                                                    <div className="tbl-upper__cont">
                                                        {user.role === 'ADMIN' && (
                                                            <>
                                                                <button type="button" className="btn-round-util"
                                                                        onClick={() => winningBidCancel()}>
                                                                    <span>낙찰취소</span>
                                                                </button>
                                                                <button type="button" onClick={() => approveComplete()}
                                                                        className="btn-round-util">
                                                                    <span>승인완료</span>
                                                                </button>
                                                                <button type="button" onClick={() => approveReject()}
                                                                        className="btn-round-util">
                                                                    <span>승인반려</span>
                                                                </button>
                                                                <button type="button" className="btn-round-util"
                                                                        onClick={() => confirmWinningBid()}>
                                                                    <span>입금확인</span>
                                                                </button>
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className="tbl-upper__util">
                                                        <button type="button" className="btn-round-util"
                                                                onClick={() => getDepositRequestList()}>
                                                            <span>입금요청서</span>
                                                        </button>
                                                        <strong className="tbl-area__tit">
                                                            선택중량(KG) {addComma(totalAmountGoods)}
                                                        </strong>
                                                        <DownloadTableExcel filename="kasco" sheet="users"
                                                                            currentTableRef={tableRef2.current}>
                                                            <button className="btn-base type-dark">
                                                                <span>다운로드</span>
                                                            </button>
                                                        </DownloadTableExcel>
                                                        <select className="selectbox type-light"
                                                                onChange={(e) => setSizeGoods(e.target.value)}>
                                                            <option value='50'>50개씩</option>
                                                            <option value='100'>100개씩</option>
                                                            <option value='200'>200개씩</option>
                                                            <option value='500'>500개씩</option>
                                                            <option value='1000'>1000개씩</option>
                                                            <option value='5000'>5000개씩</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="tbl-base type-hover scroll-warp">
                                                    <table className="table-product" ref={tableRef2}>
                                                        <caption>
                                                            정보 테이블
                                                        </caption>
                                                        <colgroup/>
                                                        <thead>
                                                        <tr>
                                                            <th scope="col">
                                                                <input type="checkbox" id="chk2-all"
                                                                       name="chk2Group"
                                                                       onChange={(e) => checkAllGoods(e.target.checked)}
                                                                />
                                                                <label htmlFor="chk2-all" className="lb-chk">
                                                                    <span className="a11y">전체선택</span></label>
                                                            </th>
                                                            <th scope="col">번호</th>
                                                            <th scope="col">경매번호</th>
                                                            {user.role === 'ADMIN' && (
                                                                <th scope="col">고객사</th>
                                                            )}
                                                            <th scope="col">제품번호</th>
                                                            <th scope="col">낙찰상태</th>
                                                            <th scope="col">승인상태</th>
                                                            <th scope="col">목적지시군</th>
                                                            <th scope="col">목적지주소</th>
                                                            <th scope="col">창고</th>
                                                            <th scope="col">착지전화번호</th>
                                                            <th scope="col">변경요청목적지시군</th>
                                                            <th scope="col">변경요청목적지주소</th>
                                                            <th scope="col">변경요청전화번호</th>
                                                            <th scope="col">낙찰단가</th>
                                                            <th scope="col">기본운임단가</th>
                                                            <th scope="col">할증운임단가</th>
                                                            <th scope="col">낙찰총단가</th>
                                                            <th scope="col">제품공급가</th>
                                                            <th scope="col">운반비공급가</th>
                                                            <th scope="col">총공급가액</th>
                                                            <th scope="col">제품부가세</th>
                                                            <th scope="col">운반비부가세</th>
                                                            <th scope="col">총부가세</th>
                                                            <th scope="col">합계금액</th>
                                                            <th scope="col">제품등급</th>
                                                            <th scope="col">두께</th>
                                                            <th scope="col">폭</th>
                                                            <th scope="col">길이</th>
                                                            <th scope="col">중량</th>
                                                            <th scope="col">YP</th>
                                                            <th scope="col">TS</th>
                                                            <th scope="col">EL</th>
                                                            <th scope="col">C%</th>
                                                            <th scope="col">SI</th>
                                                            <th scope="col">MN</th>
                                                            <th scope="col">P</th>
                                                            <th scope="col">S</th>
                                                            <th scope="col">여재원인</th>
                                                            <th scope="col">규격약호</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            goodsList.map((item, index) => (
                                                                <tr>
                                                                    <td>
                                                                        <input type="checkbox"
                                                                               checked={item.is_checked}
                                                                               id={`chkGoods${index}-1`}
                                                                               name="chkGoodsGroup"
                                                                               onChange={(e) => selectGoodsItem(e.target.checked, item)}/>
                                                                        <label htmlFor={`chkGoods${index}-1`}
                                                                               className="lb-chk">
                                                                            <span className="a11y">선택</span>
                                                                        </label>
                                                                    </td>
                                                                    <td>{goodsList.length - index}</td>
                                                                    <td><a href>{item.auction_no}</a></td>
                                                                    {user.role === 'ADMIN' && (
                                                                        <td>{item.corp_nm}</td>
                                                                    )}
                                                                    <td>{item.goods_no}</td>
                                                                    <td>{item.bidding_stat_cd_nm}</td>
                                                                    <td>{item.req_edit_stat_cd_nm}</td>
                                                                    <td>{user.role === 'ADMIN' ? item.dest_nm_amdin : item.dest_nm_cust}</td>
                                                                    <td>{item.dest_addr}</td>
                                                                    <td>{item.storage_div_cd_nm}</td>
                                                                    <td>{item.dest_phone}</td>
                                                                    <td>{item.req_dest_sigun_cd_nm}</td>
                                                                    <td>{item.req_dest_addr}</td>
                                                                    <td>{item.req_dest_phone}</td>
                                                                    <td>{addComma(item.bidding_price * 1000)}</td>
                                                                    <td>{addComma(item.freight_fee)}</td>
                                                                    <td>{addComma(item.extra_unit_price)}</td>
                                                                    <td>{addComma(item.unit_price)}</td>
                                                                    <td>{addComma(item.goods_price)}</td>
                                                                    <td>{addComma(item.freight_total_cost)}</td>
                                                                    <td>{addComma(item.tot_price)}</td>
                                                                    <td>{addComma(item.goods_vat)}</td>
                                                                    <td>{addComma(item.freight_vat)}</td>
                                                                    <td>{addComma(Number.parseFloat(item.goods_vat) + Number.parseFloat(item.freight_vat))}</td>
                                                                    <td>{addComma(item.amount)}</td>
                                                                    <td>{item.goods_grade_cd_nm}</td>
                                                                    <td>{item.goods_t_mm}</td>
                                                                    <td>{item.goods_w_mm}</td>
                                                                    <td>{item.goods_len_mm}</td>
                                                                    <td>{addComma(item.goods_wt)}</td>
                                                                    <td>{item.yp_wrslt}</td>
                                                                    <td>{item.ts_wrslt}</td>
                                                                    <td>{item.el_wrslt}</td>
                                                                    <td>{item.c_wrslt}</td>
                                                                    <td>{item.si_wrslt}</td>
                                                                    <td>{item.mn_wrslt}</td>
                                                                    <td>{item.p_wrslt}</td>
                                                                    <td>{item.s_wrslt}</td>
                                                                    <td>{item.yeojae_cause_cd_nm}</td>
                                                                    <td>{item.spec_abbsym}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                )}
                                <Pagination pageCount={goodsTotalPage}
                                            paginate={(p) => setPageGoods(p.selected + 1)}></Pagination>
                                <div className="tbl-footer">
                                    <div className="tbl-footer__cont">
                                        <strong className="tbl-footer__tit">총 {goodsList.length}건</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal
                        dialogClassName="justify-content-center"
                        show={showFindCustomer}
                        size="lg"
                        onHide={() => setShowFindCustomer(false)}
                    >
                        <Modal.Body style={{background: '#fff'}}>
                            <section className="main-area">
                                <article className="small-pop">
                                    <div className="article-header">
                                        <h1 className="popup-header__tit"> 고객사 찾기</h1>
                                        <button type="button" className="btn-close"
                                                onClick={() => setShowFindCustomer(false)}><span
                                            className="a11y">팝업 닫기</span></button>
                                    </div>
                                    <div className="article-content">
                                        <div className="cont-area">
                                            <div className="cont-area__section">
                                                <article>
                                                    <div className="article-content">
                                                        <div className="tbl-area">
                                                            <div className="table-wrap">
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{width: '160px'}}/>
                                                                        <col/>
                                                                        <col style={{width: '160px'}}/>
                                                                        <col/>
                                                                    </colgroup>
                                                                    <tbody>
                                                                    <tr>
                                                                        <th>고객사명</th>
                                                                        <td>
                                                                            <div className="form-area">
                                                                                <div className="form-item">
                                                                                    <input type="text" className="inp"
                                                                                           value={queryData.corp_nm}
                                                                                           onChange={(e) => {
                                                                                               setQueryData({
                                                                                                   ...queryData,
                                                                                                   corp_nm: e.target.value
                                                                                               })
                                                                                           }}/>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <th>사업자번호</th>
                                                                        <td>
                                                                            <div className="form-area">
                                                                                <div className="form-item">
                                                                                    <input type="text" className="inp"
                                                                                           value={queryData.business_number}
                                                                                           onChange={(e) => {
                                                                                               setQueryData({
                                                                                                   ...queryData,
                                                                                                   business_number: e.target.value
                                                                                               })
                                                                                           }}/>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                                <div className="btn-area gap-x40">
                                                    <button onClick={() => {
                                                        setQueryData({
                                                            ...queryData,
                                                            corp_nm: '',
                                                            business_number: ''
                                                        });
                                                        setQueryResult([]);
                                                        setQuerySize(10);
                                                        setQueryTotalPage(0);
                                                    }} className="btn-base type-secondary size-m">
                                                        <span>초기화</span>
                                                    </button>
                                                    <button onClick={() => searchCustomer()}
                                                            className="btn-base type-primary size-m grid-m">
                                                        <i className="icon24 icon-search-light"/>
                                                        <span>
                        검색
                      </span>
                                                    </button>
                                                </div>
                                                <article className="space">
                                                    <div className="article-content">
                                                        <div className="tbl-area">
                                                            <div className="tbl-upper">
                                                                <div className="tbl-upper__util">
                                                                    <select className="selectbox type-light"
                                                                            onChange={(e) => setQuerySize(e.target.value)}>
                                                                        <option value='50'>50개씩</option>
                                                                        <option value='100'>100개씩</option>
                                                                        <option value='200'>200개씩</option>
                                                                        <option value='500'>500개씩</option>
                                                                        <option value='1000'>1000개씩</option>
                                                                        <option value='5000'>5000개씩</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="tbl-base type-hover">
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{width: '100px'}}/>
                                                                        <col/>
                                                                        <col/>
                                                                    </colgroup>
                                                                    <thead>
                                                                    <tr>
                                                                        <th>고객코드</th>
                                                                        <th>고객사명</th>
                                                                        <th>사업자번호</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {
                                                                        queryResult.map((item, index) => (
                                                                            <tr onClick={() => selectCustomer(item)}>
                                                                                <td>{item.cust_corp_code}</td>
                                                                                <td>{item.corp_nm}</td>
                                                                                <td>{item.business_num}</td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <Pagination pageCount={queryTotalPage}
                                                                    paginate={(p) => setQueryPage(p.selected + 1)}></Pagination>
                                                    </div>
                                                </article>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </section>
                        </Modal.Body>
                    </Modal>
                </section>
                <Modal
                    dialogClassName="justify-content-center"
                    show={showModalDestination}
                    size="lg"
                    onHide={() => setShowModalDestination(false)}
                >
                    <Modal.Body style={{background: '#fff'}}>
                        <div id="wrap" className="popup-area">
                            <section className="main-area">
                                <article className="small-pop">
                                    <div className="article-header">
                                        <h1 className="popup-header__tit">목적지 조회</h1>
                                        <button type="button" className="btn-close"
                                                onClick={() => setShowModalDestination(false)}><span
                                            className="a11y">팝업 닫기</span></button>
                                    </div>
                                    <div className="article-content">
                                        <div className="cont-area">
                                            <div className="cont-area__section">
                                                <article>
                                                    <div className="article-content">
                                                        <div className="tbl-area">
                                                            <div className="table-wrap">
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{width: '160px'}}/>
                                                                        <col/>
                                                                        <col style={{width: '160px'}}/>
                                                                        <col/>
                                                                    </colgroup>
                                                                    <tbody>
                                                                    <tr>
                                                                        <th>목적지코드</th>
                                                                        <td>
                                                                            <div className="form-area">
                                                                                <div className="form-item">
                                                                                    <input type="text" className="inp"
                                                                                           onChange={(e) => setQueryDestSignCd(e.target.value)}/>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <th>목적지명</th>
                                                                        <td>
                                                                            <div className="form-area">
                                                                                <div className="form-item">
                                                                                    <input type="text" className="inp"
                                                                                           onChange={(e) => setQueryDestNm(e.target.value)}/>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                                <div className="btn-area gap-x40">
                                                    <button className="btn-base type-secondary size-m"
                                                            onClick={() => clearDestInfo()}>
                                                        <span>초기화</span>
                                                    </button>
                                                    <button className="btn-base type-primary size-m grid-m"
                                                            onClick={() => getDestList()}>
                                                        <i className="icon24 icon-search-light"/>
                                                        <span>
                        검색
                      </span>
                                                    </button>
                                                </div>
                                                <article className="space">
                                                    <div className="article-content">
                                                        <div className="tbl-area">
                                                            <div className="tbl-upper">
                                                                <div className="tbl-upper__util">
                                                                    <select className="selectbox type-light"
                                                                            onChange={(e) => setDestSize(e.target.value)}>
                                                                        <option value='50'>50개씩</option>
                                                                        <option value='100'>100개씩</option>
                                                                        <option value='200'>200개씩</option>
                                                                        <option value='500'>500개씩</option>
                                                                        <option value='1000'>1000개씩</option>
                                                                        <option value='5000'>5000개씩</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="tbl-base type-hover">
                                                                <table>
                                                                    <thead>
                                                                    <tr>
                                                                        <th>목적자코드</th>
                                                                        <th>목적지 2(시/군/구)</th>
                                                                        <th>전화번호</th>
                                                                        <th>주소</th>
                                                                        <th>하차지명</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {
                                                                        destList.map((item, index) => (
                                                                                <tr onClick={() => selectDest(item)}>
                                                                                    <td>{item.dest_sigun_cd}</td>
                                                                                    <td>{item.dest_nm}</td>
                                                                                    <td>{item.phone1}</td>
                                                                                    <td>{item.addr}</td>
                                                                                    <td>{item.dest_nm}</td>
                                                                                </tr>
                                                                            )
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                                <Pagination pageCount={destTotalPage}
                                                            paginate={(p) => setDestPage(p.selected + 1)}></Pagination>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </section>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    dialogClassName="justify-content-center"
                    show={showModalEntrance}
                    size="lg"
                    // fullscreen={fullscreen}
                    onHide={() => setShowModalEntrance(false)}
                >
                    <Modal.Header className="text-end justify-content-end">
                        <div className="text-end justify-content-end">
                            <button className="btn-round-util" onClick={handlePrint}>PDF Print</button>
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{background: '#fff', margin: "10px"}} ref={componentRef}>
                        <div id="wrap" className="popup-area print-container">
                            <section className="main-area">
                                <article className="small-pop pb-0">
                                    <div className="article-header">
                                        <h5 className=" text-center">경매 입금요청서
                                            ( {selectedAuction !== null && formatUnixTimePdf(selectedAuction.auction_start_dt)}일자)
                                        </h5>
                                        <button type="button" className="btn-close"
                                                onClick={() => setShowModalEntrance(false)}><span
                                            className="a11y">팝업 닫기</span></button>
                                    </div>
                                </article>
                                <article>

                                    <div className="mb-3">
                                        *저희 주식회사 카스코철강 제품을 구매해주시는 귀사에 항상 감사드립니다. <br/>
                                        해당 거래에 대해 귀사가 입금하셔야 할 낙찰 금액은 아래와 같사오니, 확인 하신후 입금해주시기 바랍니다
                                    </div>
                                    <div className="article-content">
                                        <div className="cont-area">
                                            <div className="cont-area__section">
                                                <article>
                                                    <div className="article-content">
                                                        <div className="maka_pdf_top_table">
                                                            <div className="row p-10">
                                                                <div className="col-lg-3 top_table_text">
                                                                    *연락처:070-8889-3456 <br/>
                                                                    *입금기한:경매일 익영업일 12시
                                                                </div>
                                                                <div className="col-lg-9 top_table_text">
                                                                    *입금계좌: 우리은행 1005-301-817070, 신한은행 140-013-498612,
                                                                    기업은행
                                                                    070-8889-3456 카스코철강
                                                                </div>
                                                            </div>
                                                            <div className="">
                                                                <table className="make_pdf_table">
                                                                    <tbody>
                                                                    <tr>
                                                                        <th>경매일자</th>
                                                                        <td>
                                                                            {selectedAuction !== null && selectedAuction.auction_no}
                                                                        </td>
                                                                        <th>고객명</th>
                                                                        <td>
                                                                            {selectedAuction !== null && selectedAuction.corp_nm}
                                                                        </td>
                                                                        <th>낙찰중량</th>
                                                                        <td>
                                                                            {addComma(depositRequestTotal.total_goods_wt)}
                                                                        </td>
                                                                        <th>낙찰금액</th>
                                                                        <td>
                                                                            {addComma(depositRequestTotal.total_total_amount)}
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>

                                                <div className="article-content mt-5">
                                                    <div className="table-pdf">
                                                        <table className="table">
                                                            <colgroup>
                                                                <col/>
                                                                <col style={{width: '40px'}}/>
                                                                <col style={{width: '90px'}}/>
                                                                <col style={{width: '100px'}}/>
                                                                <col/>
                                                                <col/>
                                                                <col/>
                                                                <col/>

                                                            </colgroup>
                                                            <thead>
                                                            <tr>

                                                                <th>제품번호</th>
                                                                <th>품명</th>
                                                                <th>규격약호</th>
                                                                <th>제품사양</th>
                                                                <th>중량</th>
                                                                <th colSpan={3}>공급가액
                                                                    <br/>
                                                                    <div className="row">
                                                                        <span className="col-4 border-right"> 제품대</span>
                                                                        <span className="col-4 border-right">운송비</span>
                                                                        <span className="col-4 ">계</span>
                                                                    </div>
                                                                </th>
                                                                <th colSpan={3}>VAT
                                                                    <br/>
                                                                    <div className="row">
                                                                        <span className="col-4 border-right"> 제품대</span>
                                                                        <span className="col-4 border-right">운송비</span>
                                                                        <span className="col-4 ">계</span>
                                                                    </div>
                                                                </th>
                                                                <th>총계</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                depositRequestList.map((item, index) => (
                                                                    <tr>

                                                                        <td>{item.goods_no}</td>
                                                                        <td>{item.item_name}</td>
                                                                        <td>{item.spec_abbsym}</td>
                                                                        <td>{addComma(item.goods_wdh)}</td>
                                                                        <td>{addComma(item.goods_wt)}</td>
                                                                        <td>{addComma(item.goods_price)}</td>
                                                                        <td>{addComma(item.freight_cost)}</td>
                                                                        <td>{addComma(item.total_price)}</td>
                                                                        <td>{addComma(item.goods_price_vat)}</td>
                                                                        <td>{addComma(item.freight_cost_vat)}</td>
                                                                        <td>{addComma(item.total_vat)}</td>
                                                                        <td className="emphasis">
                                                                            {addComma(item.total_amount)}
                                                                        </td>
                                                                    </tr>

                                                                ))
                                                            }

                                                            </tbody>
                                                            <thead>
                                                            <tr>
                                                                <th colSpan={4}>총계</th>
                                                                <th>{addComma(depositRequestTotal.total_goods_wt)}</th>
                                                                <th>{addComma(depositRequestTotal.total_goods_price)}</th>
                                                                <th>{addComma(depositRequestTotal.total_freight_cost)}</th>
                                                                <th>{addComma(depositRequestTotal.total_total_price)}</th>
                                                                <th>{addComma(depositRequestTotal.total_goods_price_vat)}</th>
                                                                <th>{addComma(depositRequestTotal.total_freight_cost_vat)}</th>
                                                                <th>{addComma(depositRequestTotal.total_total_vat)}</th>
                                                                <th>{addComma(depositRequestTotal.total_total_amount)}</th>
                                                            </tr>
                                                            </thead>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </section>
                            <div className="logo_for_print">
                                <img src={Logo} alt="카스코철강" style={{backgroundColor: '#fff'}}/>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
                {/* // [End] main-area */}
                <Footer/>
            </div>
        </>
    )
}

export default Kas0607